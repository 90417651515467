import React from "react";
import { showToast } from "../../../utils/ToastHelper";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import swal from "sweetalert";

const MultipleVideoUpload = ({ setUploadedVideos, uploadedVideos }) => {
    const VideoHandle = (e) => {
        const newFiles = e.target.files;
        const url = `${BASE_URL}api/v1/auth/documents/upload/`;
      
        if (newFiles.length) {
          showToast("success", "Uploading files, please wait!");
          Array.from(newFiles).forEach((file) => {
            const formData = new FormData();
            formData.append("document", file);
            formData.append("doc_type", 1);
      
            axios.post(url, formData)
              .then((res) => {
                if (res.data.status) {
                  const newVideo = {
                    id: res.data.data.id,
                    name: file.name,
                    url: URL.createObjectURL(file)
                  };
                  setUploadedVideos(prevVideos => [...prevVideos, newVideo]);
                  showToast("success", "File uploaded");
                }
              })
              .catch((error) => {
                showToast("error", error.response.data.errors.document[0]);
              });
          });
        }
      };
      
      const removeVideo = (videoId) => {
        swal({
          title: "Are you sure?",
          text: "Once removed, you will not be able to recover this file!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setUploadedVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
            swal("Poof! Your file has been removed!", { icon: "success" });
          } else {
            swal("Your file is safe!");
          }
        });
      };
  return (
    <div className="col-12">
      <h5 style={{ marginTop: "30px" }}>Video</h5>
      <p style={{ color: "#8E8E93" }}>(Select your files & upload)</p>
      <div
        className="w-100 browse-main d-flex align-items-center"
        style={{
          height: "55px",
        //   border: "1px solid #E0E0E0",
          borderRadius: "7px",
          overflowX: "hidden",
        }}
      >
        <input type="file" onChange={VideoHandle} accept="video/*" multiple />
      </div>
      <div className="d-flex gap-2">
      {uploadedVideos.map((video) => (
        <div key={video.id} className="my-2">
          <div>
            <video height={90} width={90} controls>
              <source src={video.url} />
            </video>
          </div>
          <button
            onClick={() => removeVideo(video.id)}
            className="remove-btn btn btn-small mt-1 rounded"
            style={{ border: "1px solid gray !important" }}
          >
            Remove
          </button>
        </div>
      ))}
      </div>
    </div>
  );
};

export default MultipleVideoUpload;
