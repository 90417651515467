import React, { useEffect, useState } from "react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../utils/HandleInputs";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { showToast } from "../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import * as path from "../Routes/RoutePaths";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../CustomCommons/Loader";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const GlobalSettings = () => {
  const [value, setValue] = useState("");
  const [about_us_text, setAbout_us_text] = useState("");
  const [delivery_information, setDelivery_information] = useState("");
  const [privacy_policy, setPrivacy_policy] = useState("");
  const [terms_and_condition, setTerms_and_condition] = useState("");
  const [terms_and_condition_club, setTerms_and_condition_club] = useState("");
  const [search_terms, setSearch_terms] = useState("");
  const [return_and_refund, setReturn_and_refund] = useState("");
  console.log(about_us_text, "vv");
  const [multiImg, setMultiImage] = useState([]);
  const [globalData, setGlobalData] = useState({
    site_name: "",
    website_url: "",
    email: "",
    phone: "",
    address: "",
  });
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  // logo
  const [logoUrl, setLogoUrl] = useState("");
  const [logoId, setLogoId] = useState(null);
  const [logoName, setLogoName] = useState("");
  const [logo, setLogo] = useState(null);
  // qr_code
  const [qr_codeUrl, setQr_codeUrl] = useState("");
  const [qr_codeId, setQr_codeId] = useState(null);
  const [qr_codeName, setQr_codeName] = useState("");
  const [qr_code, setqr_code] = useState(null);
  // qr_code2
  const [qr_codeUrl2, setQr_codeUrl2] = useState("");
  const [qr_codeId2, setQr_codeId2] = useState(null);
  const [qr_codeName2, setQr_codeName2] = useState("");
  const [qr_code2, setqr_code2] = useState(null);
  // PMI
  const [pmiUrl, setPmiUrl] = useState("");
  const [pmiId, setPmiId] = useState(null);
  const [pmiName, setPmiName] = useState("");
  const [pmi, setPmi] = useState(null);
  // PMI
  const [aboutUsImgUrl, setAboutUsImgUrl] = useState("");
  const [aboutUsImgId, setAboutUsImgId] = useState(null);
  const [aboutUsImgName, setAboutUsImgName] = useState("");
  const [aboutUsImg, setAboutUsImg] = useState(null);
  // banner abt
  const [aboutUsBanerUrl, setAboutUsBanerUrl] = useState("");
  const [aboutUsBanerId, setAboutUsBanerId] = useState(null);
  const [aboutUsBanerName, setAboutUsBanerName] = useState("");
  // offer ban
  const [bannerOfferUrl, setBannerOfferUrl] = useState("");
  const [bannerOfferId, setBannerOfferId] = useState(null);
  const [bannerOfferName, setBannerOfferName] = useState("");
  // loader
  // arrival ban
  const [bannerArrivalUrl, setBannerArrivalUrl] = useState("");
  const [bannerArrivalId, setBannerArrivalId] = useState(null);
  const [bannerArrivalName, setBannerArrivalName] = useState("");
  // matching Des
  const [matchingImgUrl, setMatchingImgUrl] = useState("");
  const [matchingImgId, setMatchingImgId] = useState(null);
  const [matchingImgName, setMatchingimgName] = useState("");
  // matching Col
  const [matchingImgUrlColor, setMatchingImgUrlColor] = useState("");
  const [matchingImgIdColor, setMatchingImgIdColor] = useState(null);
  const [matchingImgNameColor, setMatchingimgNameColor] = useState("");
  // loader
  const [loading, setLoading] = useState(true);
  const fileHandle = (e, setThumbId, setURL, setFileName) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
          }
          console.log(res.data.data, "img");
          setFileName(e.target.files[0].name);
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          setURL(reader.result);
          reader.onload = () => {
            if (reader.readyState === 2) {
              setURL(reader.result);
            }
          };
          showToast("success", "image uploaded");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const uploadMultiple = (e) => {
    console.log(e.target.files);
    const images = e.target.files;
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    for (let i = 0; i <= images.length; i++) {
      const file = images[i];
      const data1 = new FormData();
      data1.append("document", file);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setMultiImage([
                  ...multiImg,
                  { id: res.data.data.id, url: reader.result },
                ]);
              }
            };
            showToast("success", "image uploaded");
          }
        })
        .catch((error) => {
          // showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  console.log(multiImg, "mm");
  const removeMultiImg = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        const newArray = multiImg?.filter((imges) => imges?.id !== id);
        setMultiImage(newArray);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeImg = (setThumbId, setURL, setName) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumbId(null);
        setURL("");
        setName("");
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        setGlobalData({
          site_name: data?.site_name,
          website_url: data?.website_url,
          email: data?.email,
          phone: data?.phone,
          address: data?.address,
        });
        setAbout_us_text(data?.about_us_text);
        setDelivery_information(data?.delivery_information);
        setPrivacy_policy(data?.privacy_policy);
        setReturn_and_refund(data?.return_and_refund);
        setSearch_terms(data?.search_terms);
        setTerms_and_condition(data?.terms_and_condition);
        setTerms_and_condition_club(data?.kaaruj_club_conditions);
        // logo
        setLogoId(data?.logo);
        setLogoUrl(data?.logo_url);
        // qrcode
        setQr_codeId(data?.qr_code);
        setQr_codeId2(data?.qr_code2);
        setQr_codeUrl(data?.qr_code_url);
        setQr_codeUrl2(data?.qr_code_url);
        // pmi
        setPmiId(data?.payment_method_images);
        setPmiUrl(data?.payment_method_images_url);
        // about us
        setAboutUsImgId(data?.about_us_image);
        setAboutUsImgUrl(data?.about_us_image_url);
        // about us banner
        setAboutUsBanerId(data?.about_us_banner);
        setAboutUsBanerUrl(data?.about_us_banner_url);
        // offer banner
        setBannerOfferId(data?.offer_banner);
        setBannerOfferUrl(data?.offer_banner_url);
        // offer banner
        setBannerArrivalId(data?.new_arrival_banner);
        setBannerArrivalUrl(data?.new_arrival_banner_url);
        // matching
        setMatchingImgId(data?.matching_image)
        setMatchingImgUrl(data?.matching_image_url)
        // matching
        setMatchingImgIdColor(data?.matching_image_2)
        setMatchingImgUrlColor(data?.matching_image_url_2)

        const data2 = data?.brand_url.map((item) => {
          return { id: item.id, url: item.doc_url };
        });
        setMultiImage(data2);
        // setMultiImage()
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/utility/admin/global-settings/`;
    apiFunction(url);
  }, []);
  const UpdateSettings = () => {
    const data = {
      site_name: globalData?.site_name,
      website_url: globalData?.website_url,
      about_us_text: about_us_text,
      delivery_information: delivery_information,
      privacy_policy: privacy_policy,
      terms_and_condition: terms_and_condition,
      kaaruj_club_conditions: terms_and_condition_club,
      search_terms: search_terms,
      return_and_refund: return_and_refund,
      email: globalData?.email,
      phone: globalData?.phone,
      address: globalData?.address,
      logo: logoId ? logoId : null,
      qr_code: qr_codeId ? qr_codeId : null,
      qr_code2: qr_codeId2 ? qr_codeId2 : null,
      payment_method_images: pmiId ? pmiId : null,
      about_us_image: aboutUsImgId ? aboutUsImgId : null,
      brand_images: multiImg?.map((img) => img?.id),
      about_us_banner:aboutUsBanerId ? aboutUsBanerId : null,
      new_arrival_banner: bannerArrivalId ? bannerArrivalId :null,
      offer_banner: bannerOfferId ? bannerOfferId : null,
      matching_image: matchingImgId ? matchingImgId : null,
      matching_image_2: matchingImgIdColor ? matchingImgIdColor : null
    };
    const url = `${BASE_URL}api/v1/utility/admin/global-settings/`;
    setLoading(true);
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Global Setting Updated.");
          setLoading(false);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };
  const handleDrag = (result) => {
    if (!result.destination) {
      return;
    }
    console.log(result, multiImg, "rrr");
    const newList = reorder(
      multiImg.slice(),
      result.source.index,
      result.destination.index
    );
    const newIdList = newList.map(({ id }) => id);
    setMultiImage(newList);
    // setSliders(newIdList);
    console.log(newList, "nnn");
    newList.map((item, index) => {
      return axios
        .patch(`${BASE_URL}/api/v1/auth/documents/update/${item.id}/`, {
          order: index,
        })
        .then((res) => console.log(res, "res"));
    });
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Global Settings
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Site Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="site_name"
                value={globalData["site_name"]}
                onChange={(e) => handleInputs(e, setGlobalData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Phone"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="phone"
                value={globalData["phone"]}
                onChange={(e) => handleInputs(e, setGlobalData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Email"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                type="email"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="email"
                value={globalData["email"]}
                onChange={(e) => handleInputs(e, setGlobalData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Web Url"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                type="text"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="website_url"
                value={globalData["website_url"]}
                onChange={(e) => handleInputs(e, setGlobalData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Address"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                type="text"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="address"
                value={globalData["address"]}
                onChange={(e) => handleInputs(e, setGlobalData)}
              />
            </InputGroup>
          </div>
          <div className="d-none d-md-block col-12 col-md-6"></div>
          <div className="col-12 col-md-6 mb-4">
            <RequiredLabel text={"About Us"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={about_us_text}
                onChange={setAbout_us_text}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mb-4">
            <RequiredLabel text={"Delivery Information"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={delivery_information}
                onChange={setDelivery_information}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mt-2 mb-2">
            <RequiredLabel text={"Privacy Policy"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={privacy_policy}
                onChange={setPrivacy_policy}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mt-2 mb-2">
            <RequiredLabel text={"Terms And Condition"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={terms_and_condition}
                onChange={setTerms_and_condition}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mt-4">
            <RequiredLabel text={"Search Terms"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={search_terms}
                onChange={setSearch_terms}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mt-4">
            <RequiredLabel text={"Return And Refund"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={return_and_refund}
                onChange={setReturn_and_refund}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6 mt-4">
            <RequiredLabel text={"Kaaruj Club Condition"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={terms_and_condition_club}
                onChange={setTerms_and_condition_club}
              />
            </InputGroup>
          </div>

          {/* img */}
          <div className="row d-flex justify-content-between mt-3">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload Logo image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {logoUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"Logo File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(e, setLogoId, setLogoUrl, setLogoName)
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {logoUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={logoUrl} alt="" /> <br />
                  <button
                    onClick={() =>
                      removeImg(setLogoId, setLogoUrl, setLogoName)
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload Home-Decoer Qrcode </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {qr_codeUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"Qrcode File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(e, setQr_codeId, setQr_codeUrl, setQr_codeName)
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {qr_codeUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={qr_codeUrl} alt="" /> <br />
                  <button
                    onClick={() =>
                      removeImg(setQr_codeId, setQr_codeUrl, setQr_codeName)
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload In Style Qrcode</h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {qr_codeUrl2 === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"Qrcode File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setQr_codeId2,
                        setQr_codeUrl2,
                        setQr_codeName2
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {qr_codeUrl2 && (
                <div className="my-2">
                  <img height={90} width={90} src={qr_codeUrl2} alt="" /> <br />
                  <button
                    onClick={() =>
                      removeImg(setQr_codeId2, setQr_codeUrl2, setQr_codeName2)
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload Payment image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {pmiUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"Payment File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(e, setPmiId, setPmiUrl, setPmiName)
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {pmiUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={pmiUrl} alt="" /> <br />
                  <button
                    onClick={() => removeImg(setPmiId, setPmiUrl, setPmiName)}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload About Us image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-550px, width-550px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {aboutUsImgUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setAboutUsImgId,
                        setAboutUsImgUrl,
                        setAboutUsImgName
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {aboutUsImgUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={aboutUsImgUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setAboutUsImgId,
                        setAboutUsImgUrl,
                        setAboutUsImgName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            {/* about us banner */}
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload About Us Banner image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-200px, width-1920px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {aboutUsBanerUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setAboutUsBanerId,
                        setAboutUsBanerUrl,
                        setAboutUsBanerName
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {aboutUsBanerUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={aboutUsBanerUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setAboutUsBanerId,
                        setAboutUsBanerUrl,
                        setAboutUsBanerName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Matching Products Banner image(Desgin) </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-600px, width-960px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {matchingImgId === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setMatchingImgId,
                        setMatchingImgUrl,
                        setMatchingimgName
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {matchingImgUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={matchingImgUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setMatchingImgId,
                        setMatchingImgUrl,
                        setMatchingimgName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Matching Products Banner image(Color) </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-600px, width-960px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {matchingImgIdColor === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setMatchingImgIdColor,
                        setMatchingImgUrlColor,
                        setMatchingimgNameColor
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {matchingImgUrlColor && (
                <div className="my-2">
                  <img height={90} width={90} src={matchingImgUrlColor} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setMatchingImgIdColor,
                        setMatchingImgUrlColor,
                        setMatchingimgNameColor
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>


            {/* offer Banner */}
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload Offer Banner image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-200px, width-1920px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {bannerOfferUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setBannerOfferId,
                        setBannerOfferUrl,
                        setBannerOfferName
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {bannerOfferUrl&& (
                <div className="my-2">
                  <img height={90} width={90} src={bannerOfferUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setBannerOfferId,
                        setBannerOfferUrl,
                        setBannerOfferName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            {/* new arrival */}
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Upload New Arrivals Banner image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-200px, width-1920px) for Banner.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {bannerArrivalUrl === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {"About File Choosen"}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) =>
                      fileHandle(
                        e,
                        setBannerArrivalId,
                        setBannerArrivalUrl,
                        setBannerArrivalName
                      )
                    }
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {bannerArrivalUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={bannerArrivalUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setBannerArrivalId,
                        setBannerArrivalUrl,
                        setBannerArrivalName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>


            <div className="col-12  ">
              <h5 style={{ marginTop: "30px" }}>Add Brand image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & upload one by one )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {" File Choosen"}
                </p>

                <div className="file-up">
                  <input
                    type="file"
                    onChange={uploadMultiple}
                    // multiple
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {/* {aboutUsImgUrl && (
                <div className="my-2">
                  <img height={90} width={90} src={aboutUsImgUrl} alt="" />{" "}
                  <br />
                  <button
                    onClick={() =>
                      removeImg(
                        setAboutUsImgId,
                        setAboutUsImgUrl,
                        setAboutUsImgName
                      )
                    }
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )} */}
              <div className="d-flex gap-2">
                <DragDropContext onDragEnd={handleDrag}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="d-flex"
                        >
                          {multiImg?.length > 0 &&
                            multiImg?.map((data, index) => (
                              <Draggable
                                key={data?.id}
                                draggableId={data.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="my-2 mx-2"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <img
                                      height={90}
                                      width={90}
                                      src={data?.url}
                                      alt=""
                                    />{" "}
                                    <br />
                                    <button
                                      onClick={() => removeMultiImg(data?.id)}
                                      className="remove-btn btn btn-small mt-1  rounded"
                                      style={{
                                        border: "1px solid gray !important",
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                        </div>
                        {provided.placeholder}
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={UpdateSettings}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
