import React, { useEffect, useState } from "react";
import qrCode from "../../assets/Icon/qrcode.svg";
import print from "../../assets/Icon/print.svg";
import send from "../../assets/Icon/send.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Const/Url.js";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Loader from "../CustomCommons/Loader";
import cImg from "../../assets/Icon/KAARUJ 1.png";
import { showToast } from "../../utils/ToastHelper";
import * as path from "./../Routes/RoutePaths";
// import "./invoice.css";
import { logout_func2 } from "../Const/logoutCommon";
import EditIcon from "../CustomCommons/EditIcon";

const ChalanView = () => {
  const { id } = useParams();
  const [isLoading, SetisLoading] = useState(true);
  let url = `${BASE_URL}api/v1/sales/admin/chalan/${id}/`;
  const [data, setData] = useState({});
  const [Jdata, setJData] = useState([]);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [dataDetails, setDataDetails] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const productArray = (data, chalanFullData) => {
    const newProducts = [];
    data.forEach((product, index) => {
      product.variants.forEach((variant) => {
        const newProduct = {
          name: product.name + " { " + variant.name + "}",
          quantity: variant.initial_stock ? variant.initial_stock : "",
          stock: variant.admin_stock ? variant.admin_stock : "",
          price: parseFloat(product.price) + parseFloat(variant.price),
          sku: chalanFullData?.outlet_product_sku[product?.sku],
          sku2: product?.sku,
          image:
            variant?.images_details?.length > 0
              ? variant?.images_details[0]?.resized_doc_url
              : product?.thumb_resized_url,
        };
        newProducts.push(newProduct);
      });
    });
    return newProducts;
  };

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "---------single invoice---------");
        // console.log(res.data.data.product_list_json);
        // const temp1 = res.data.data.product_list_json.mainstate;
        // var output = [];
        // for (let i in temp1) {
        //   console.log(temp1[i]["variantObj"]);
        //   for (let j in temp1[i]["variantObj"]) {
        //     console.log(j);
        //     console.log(temp1[i]["variantObj"][j]);
        //     const d = temp1[i]["variantObj"][j];
        //     d.total !== 0 &&
        //       output.push({
        //         name: i,
        //         basePrice: temp1[i].price,
        //         discount: temp1[i].discount,
        //         thumb: temp1[i].thumb,
        //         price: d["price"],
        //         quantity: d["quantity"],
        //         id: d["id"],
        //         total: d["total"],
        //         variant: d["name"],
        //       });
        //   }
        // }
        // setJData(output);
        setDataDetails(
          productArray(
            res.data.data?.chalan_outlet_products_details,
            res.data.data
          )
        );
        console.log(res.data.data?.chalan_outlet_products_details,'dddddd 84')
        setData(res.data.data);
        SetisLoading(false);
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err?.request?.response)?.code === 401
        // ) {
        //   // history.push('/login')
        //   logout_func2(setOnlogoutHide, history);
        // }
      });
  }, []);

  const paymentStatus = ["Paid", "Unpaid", "Due"];
  const DeliveryTypeStatus = ["Regular", "Urgent"];

  const cRef = useRef();
  const history = useHistory();

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Invoice",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };

  if (isLoading) {
    return <Loader />;
  }

  //   const dataDetails = productArray(data?.chalan_outlet_products_details);
  console.log(dataDetails, "ddf");
  return (
    <>
      <div className="categorey-parent pd-right2" style={{ height: "100%" }}>
        <div className="bg-white rounded printe" ref={cRef}>
          <div className="company-img ">
            <div className="w-100 d-flex justify-content-center align-items-center">
              <img width={200} className="mt-4" height={40} src={cImg} alt="" />
            </div>
          </div>
          <div className=" row py-4 px-lg-4 px-md-3 px-3 top-m ds-topm">
            {/* d-flex flex-column flex-md-row justify-content-between */}
            <div className="col-6 view-inv dd col-md-6 mb-2 mb-md-0">
              <div>
                <h4 className="mt-1">#{data.number}</h4>
                {/* <div className="mt-2">
                <span>Delivery:</span>{" "}
                <span>{DeliveryTypeStatus[data.delivery_type]}</span>
              </div> */}
                {/* <div className="mt-2">
                <span>Payment Type:</span>{" "}
                <span>{paymentType[data?.payment_type]}</span>
              </div> */}
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex   justify-content-end  align-items-start gap-2 invoice_qr inv_qr2 extra-inv">
              <div>
                <span>Chalan Date: </span>
                <br />
                <span>
                  {new Date(data.created_at).toLocaleDateString(
                    "en-US",
                    options
                  )}
                </span>
                {/* {new Date(data.invoice_date).toLocaleDateString("en-US", options)} */}
                <br />
                {/* <span style={{ marginRight: "4px" }}> Due Date: </span> <span>22 June, 2022</span> */}
              </div>
              <img
                className="pointer"
                style={{ marginTop: "-10px" }}
                src={data?.qr_code ? data.qr_code : qrCode}
                alt=""
              />
            </div>
          </div>
          {/* border */}
          <div className="mx-2 mx-md-3 w-100">
            <div className="border-ex"></div>
          </div>
          {/* address */}
          <div className="address">
            <div className="row  justify-content-between an">
              <div className="col-12 col-md-6 col-lg-5">
                <h5>From:</h5>
                <span>
                  {data?.bill_from ? data?.bill_from : "Kaaruj"}{" "}
                </span>{" "}
                <br />
                <span>
                  {data?.from_address
                    ? data?.from_address
                    : "Road: 42, Gulshan 2, Dhaka, Bangladesh"}{" "}
                </span>{" "}
                <br />
                {/* <span>Dhaka, Bangladesh</span> <br /> */}
                <span>
                  Phone:{" "}
                  {data?.from_mobile ? data?.from_mobile : "+8801980907892"}
                </span>{" "}
                <br />
                <span>Email: kaarujbangladesh@gmail.com</span>
              </div>
              <div className="col-12 col-md-6 col-lg-5 mt-3 mt-md-0 ddd">
                <h5>To:</h5>
                <span>
                  {data?.outlet_details?.name
                    ? data?.outlet_details?.name
                    : "Zakariya Rahman"}{" "}
                </span>{" "}
                <br />
                <span className="to_address">
                  {data.to_address === "undefined" || ""
                    ? "Zoo Road, Mirpur,Dhaka, Bangladesh"
                    : data.outlet_details?.location}
                </span>{" "}
                <br />
                {/* <span>Dhaka, Bangladesh</span> <br /> */}
                <span>
                  Phone:{" "}
                  {data?.outlet_details?.mobile
                    ? data?.outlet_details?.mobile
                    : "+4480976645"}
                </span>{" "}
                <br />
                {data?.outlet_details?.email && (
                  <span>Email:{data.outlet_details?.email}</span>
                )}
              </div>
            </div>
            <div className="table-cont">
              <h5 className="mb-2">Chalan Summary</h5>
              {data?.status === 0 && (
                <button
                  onClick={() => {
                    history.push(
                      `/outlet/add_chalan_product/${data?.slug}/${data?.outlet_name}/${data?.outlet}`
                    );
                  }}
                  className="add-button btn btn-sm btn-primary mb-2"
                >
                  Add Product
                </button>
              )}

              <div className=" ">
                <table className="w w1" style={{ width: "100%" }}>
                  <thead>
                    <tr
                      className={`bg-dark text-white `}
                      style={{ height: "50px" }}
                    >
                      <th className="ps-4" style={{ minWidth: "80px" }}>
                        No
                      </th>
                      {data?.status === 0 && (
                        <th
                          className="ps-1 action"
                          style={{ minWidth: "80px" }}
                        >
                          Edit
                        </th>
                      )}

                      <th className="minw minw1" style={{ minWidth: "150px" }}>
                        Item
                      </th>
                      <th
                        className="minw minw1 ps-3"
                        style={{ minWidth: "100px" }}
                      >
                        SKU
                      </th>
                      <th className="minw minw1 ps-2">Image</th>

                      <th className="minw minw1 ">
                        <p className="text-center my-auto">Price</p>
                      </th>

                      <th className="minw minw1 " style={{ maxWidth: "100px" }}>
                        <p className="text-center my-auto">Curr Stock</p>
                      </th>
                      <th
                        className="pe-4"
                        style={{ minWidth: "130px", textAlign: "right" }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data !== undefined &&
                      data !== null &&
                      dataDetails.map((curr, index) => {
                        return (
                          <tr
                            className={`tab-b ${
                              Number(curr?.quantity) < 1 && ""
                            }`}
                            style={{ height: "50px" }}
                          >
                            <td className="ps-4">{index + 1}</td>
                            {data?.status === 0 && (
                              <td className="action">
                                <div
                                  style={{
                                    width: "40px",
                                    height: "auto",
                                  }}
                                >
                                  <div
                                    className="edit-del green"
                                    onClick={() => {
                                      history.push(
                                        `/outlet/edit_outlet_products/${curr?.sku}`
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </div>
                                </div>
                              </td>
                            )}

                            <td>{curr.name}</td>
                            <td className="ps-3">{curr.sku2}</td>
                            <td className="ps-2">
                              <img
                                className="rounded"
                                width={40}
                                height={40}
                                src={curr?.image}
                                alt=""
                              />
                            </td>
                            <td>
                              <p className="text-center my-auto">
                                ৳ {Number(curr?.price)}
                              </p>
                            </td>

                            <td>
                              <p className="text-center my-auto">
                                {Number(curr?.stock)}
                              </p>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end me-3">
                                ৳ {Number(curr?.stock) * Number(curr?.price)}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="row d4 d5 calc-main">
                <div className="col-12 col-md-4 col-lg-6 calc-empty"></div>
                <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-end calc">
                  <div className=" ">
                    <table>
                      <tbody>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td
                            style={{ minWidth: "230px" }}
                            className="tab-b ps-3"
                          >
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Sub Total : </div>
                              <div>
                                ৳{" "}
                                {dataDetails
                                  ?.map(
                                    (curr) =>
                                      Number(curr.price) * Number(curr.stock)
                                  )
                                  .reduce((a, b) => a + b, 0)}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td className="tab-b ps-3">
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Delivery Charge : </div>
                              <div>৳ {0.0}</div>
                            </div>
                          </td>
                        </tr>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td className="tab-b ps-3">
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Discount : </div>
                              <div>৳ {data.total_discount}</div>
                            </div>
                          </td>
                        </tr>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td className="tab-b ps-3">
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Total : </div>
                              <div>
                                ৳{" "}
                                {dataDetails
                                  ?.map(
                                    (curr) =>
                                      Number(curr.price) * Number(curr.stock)
                                  )
                                  .reduce((a, b) => a + b, 0)}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td className="tab-b ps-3">
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Paid Amount: </div>
                              <div>৳ {data?.total_paid}</div>
                            </div>
                          </td>
                        </tr>
                        <tr className="" style={{ height: "50px" }}>
                          <td className="ps-4"></td>
                          <td></td>
                          <td className="tab-b ps-3">
                            <div
                              style={{ marginRight: "14px" }}
                              className="d-flex justify-content-between "
                            >
                              <div>Due Amount : </div>
                              <div style={{ color: "red" }}>
                                ৳ {data.total_due}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="calc-main2">
                <div className=" calc2">
                  <div className="t-class">
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Sub Total : </div>
                        <div>
                          ৳{" "}
                          {dataDetails
                            ?.map(
                              (curr) => Number(curr.price) * Number(curr.stock)
                            )
                            .reduce((a, b) => a + b, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Delivery Charge : </div>
                        <div>৳ {data.delivery_charge}</div>
                      </div>
                    </div>
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Discount : </div>
                        <div>৳ {data.total_discount}</div>
                      </div>
                    </div>
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Total : </div>
                        <div>
                          ৳{" "}
                          {dataDetails
                            ?.map(
                              (curr) => Number(curr.price) * Number(curr.stock)
                            )
                            .reduce((a, b) => a + b, 0)}
                        </div>
                      </div>
                    </div>
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Paid Amount: </div>
                        <div>৳ {data?.total_paid}</div>
                      </div>
                    </div>
                    <div className="tab-b">
                      <div
                        style={{ marginRight: "14px" }}
                        className="d-flex justify-content-between "
                      >
                        <div>Due Amount : </div>
                        <div style={{ color: "red" }}>৳ {data.total_due}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* table */}
          <div className="mx-2 mx-md-3 my-2">
            Total Products : {data?.products_count}
          </div>
          <div className="mx-2 mx-md-3 ">
            <div className="border-ex"></div>
          </div>
          <div></div>

          <div className="mx-2 my-4 mx-md-3 d-flex justify-content-between">
            <div>
              <span>{data?.created_by_name}</span> <br />
              <span>----------------------</span> <br />
              <span>Prepared By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Recieved By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Checked By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span className="">Authorized By</span>
            </div>
          </div>
          {data?.note !== "" && (
            <div style={{ height: "50px" }} className="mx-2">
             Custom Note : {data?.note}
            </div>
          )}
          
            <div style={{ height: "50px" }} className="m-2">
             Special Note : Please check out this product list. Any questions, ask the logistics team. No complaint shall be entertained after the goods  left the warehouse.
            </div>
          
        </div>
        <div className=" invoice-button">
          <button className="me-2 my-2 button-1" onClick={handlePrints}>
            <img src={print} alt="" /> Print
          </button>
          {/* <button
          onClick={sendPdfToEmail}
          className={`my-2 button-2 ${!data.to_email && "d-none"}`}
        >
          <img src={send} alt="" /> Send Invoice
        </button> */}
        </div>
      </div>
    </>
  );
};

export default ChalanView;
