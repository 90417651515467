import React, { useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import * as path from "../../Routes/RoutePaths";
import eye from "../../../assets/Icon/eye.svg";
import { useParams } from "react-router-dom";
import LoaderClock from "../../CustomCommons/LoaderClock";
import Loader from "../../CustomCommons/Loader";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import MultipleVideoUpload from "./MultipleVideoUpload";

const EditEvent = () => {
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const history = useHistory();
  const { slug } = useParams();
  const [description, setDescription] = useState("");
  const [multiImg, setMultiImage] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [singleurl, setSingleUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const storeUrls = (data, id) => {
    setYoutubeUrl((prev) => [
      ...prev,
      {
        id: id,
        doc_url: data,
      },
    ]);
    setSingleUrl("");
  };

  const [testi, setTesti] = useState({
    name: "",
    designation: "",
    order: 0,
    desc: "hlw",
  });
  const [vfileName, setVFileName] = useState("");
  const [VURL, setVURL] = useState("");
  const [vthumbId, setVThumbId] = useState(null);
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          // console.error("An error occurred:", error.response.data.errors.document[0]);
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const uploadMultiple = (e) => {
    console.log(e.target.files);
    const images = e.target.files;
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    for (let i = 0; i <= images.length; i++) {
      const file = images[i];
      const data1 = new FormData();
      data1.append("document", file);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setMultiImage([
                  ...multiImg,
                  { id: res.data.data.id, url: reader.result },
                ]);
              }
            };
            showToast("success", "image uploaded");
          }
        })
        .catch((error) => {
          // showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeMultiImg = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        const newArray = multiImg?.filter((imges) => imges?.id !== id);
        setMultiImage(newArray);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const VideoHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      showToast("success", "large file uploading . Please wait!");
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 1);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setVThumbId(res.data.data.id);
            showToast("success", "file uploaded");
            setVFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setVURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setVURL(reader.result);
              }
            };
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          // console.error("An error occurred:", error.response.data.errors.document[0]);
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeV = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        // setVThumb(null);
        setVThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "setTesti");
        const data = res.data.data;
        setTesti({
          name: data?.title,
          order: data?.order,
          desc: data?.desc,
        });
        const data2 = data?.images_details?.map((item) => {
          return { id: item.id, url: item.doc_url };
        });
        setMultiImage(data2);
        setThumb(data?.thumb_url);
        setURL(data?.thumb_url);
        setThumbId(data?.thumb);
        setVThumbId(data?.videos[0]);

        setVURL(data?.videos_details[0]?.doc_url);
        setCategoryswitch(data?.is_active);
        setDescription(data?.desc);
        setYoutubeUrl(data?.url_list);
        // setMultiImage()
        setLoading(false);

        const newArray = data?.videos_details?.map((item) => ({
          id: item.id,
          name: item.doc_url.split("/").pop(), // Extracting the file name from the URL
          url: item?.doc_url,
        }));
        setUploadedVideos(newArray);
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err.request.response).code === 401
        // ) {
        // }
        console.log(err);
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/utility/admin/events/${slug}`;
    apiFunction(url);
  }, []);

  const postMember = () => {
    const url = `${BASE_URL}api/v1/utility/admin/events/${slug}/`;
    const data = new FormData();
    data.append("name", testi.name);
    data.append("designation", testi.designation);
    if (testi.name === "") {
      showToast("error", "Add a title .");
      return 0;
    }

    thumbId !== null && data.append("thumb", thumbId);
    const dataFormat = {
      title: testi.name,
      desc: description,
      order: testi.order,
      is_active: Categoryswitch,
      thumb: thumbId ? thumbId : null,
      images: multiImg?.map((img) => img?.id),
      videos: uploadedVideos ? uploadedVideos.map((item) => item.id) : [],
      url_list: youtubeUrl ? youtubeUrl : [],
    };
    axios
      .patch(url, dataFormat)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Event Added.");
          history.push(path.events);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  if (loading === true) {
    return <Loader />;
  }

  console.log(loading, "setTesti");
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Add Event</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Title"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={testi["name"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Order"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="order"
                type="number"
                value={testi["order"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 ">
            <RequiredLabel text={"Description"} />
            <InputGroup className="mb-3" style={{ height: "150px" }}>
              <ReactQuill
                style={{ width: "100%", maxHeight: "150px" }}
                theme="snow"
                value={description}
                onChange={setDescription}
              />
            </InputGroup>
          </div>

          {/* banner */}
          <div className="col-12  ">
            <h5 style={{ marginTop: "30px" }}>Add image </h5> <span></span>
            <p style={{ marginTop: "10px", color: "red" }}>
              NB: You can add multiple banner image its not mandatory.The actual
              size of the uploaded images will be displayed on the website.{" "}
            </p>
            <p style={{ color: "#8E8E93" }}>
              ( Select your file & upload one by one )
            </p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                {" "}
                {" File Choosen"}
              </p>

              <div className="file-up">
                <input
                  type="file"
                  onChange={uploadMultiple}
                  // multiple
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {multiImg?.length > 0 &&
                multiImg?.map((data) => (
                  <>
                    <div className="my-2" key={data?.id}>
                      <img height={90} width={90} src={data?.url} alt="" />{" "}
                      <br />
                      <button
                        onClick={() => removeMultiImg(data?.id)}
                        className="remove-btn btn btn-small mt-1  rounded"
                        style={{ border: "1px solid gray !important" }}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ))}
            </div>
          </div>
          <div>
            {youtubeUrl &&
              youtubeUrl?.map((url) => (
                <>
                  <div className="d-flex align-items-center my-2" key={url?.id}>
                    <p style={{ color: "blue" }}>{url?.doc_url}</p>{" "}
                    <p
                      class="ms-3 px-2"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setYoutubeUrl(
                          youtubeUrl?.filter((ul) => url?.id !== ul?.id)
                        )
                      }
                    >
                      Remove
                    </p>
                  </div>
                </>
              ))}
          </div>
          <div className="row gap-2">
            <p>Youtube URL</p>
            <div className="col-md-9 col-12">
              <InputGroup>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="order"
                  type="text"
                  value={singleurl}
                  onChange={(e) => setSingleUrl(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="col-2 d-flex align-items-center">
              <button
                className="btn btn-primary"
                onClick={() =>
                  storeUrls(singleurl, Math.floor(Math.random() * 900) + 100)
                }
              >
                Add Url
              </button>
            </div>
          </div>
          {/* video */}
          <>
            {/* <div className="col-12">
              <h5 style={{ marginTop: "30px" }}>Video *</h5> <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {vfileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {vfileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => VideoHandle(e)}
                    accept="video/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {VURL && (
                <div className="my-2">
                  <div>
                    <img
                      height={90}
                      width={90}
                      src={"https://i.stack.imgur.com/PtbGQ.png"}
                      alt=""
                    />
                  </div>
                  
                  <button
                    onClick={removeV}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div> */}
          </>
          <MultipleVideoUpload
            setUploadedVideos={setUploadedVideos}
            uploadedVideos={uploadedVideos}
          />

          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image <span style={{color:'red'}}>*</span></h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-500px, width-500px) for Thumbnail.{" "}
              </p>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={postMember}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.events)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
