import React from 'react'
import AreaChart from '../dashboard/AreaChart'
import CustomSalesChart from '../dashboard/CustomSalesChart'
import SaleReportCustom from '../Reports/SaleReportCustom'

const CustomSalesReport = () => {
  return (
    <div className="categorey-parent ">
        <div className="row bg-white mr-box2 rounded">
          <CustomSalesChart chartLabel='Custom Sale' />
      </div>
      <SaleReportCustom/>
    </div>
  )
}

export default CustomSalesReport