// newOutlet multiple user 15/ feb 2024
import { Eye, PlusCircle } from "phosphor-react";
import React from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import "../user/css/user.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import CustomSelect from "../CustomCommons/CustomSelect";
import { handleInputs } from "../../utils/HandleInputs";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import SelectDropDown from "../CustomCommons/SelectDropDown";
import { useEffect } from "react";
import { showToast } from "../../utils/ToastHelper";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import * as path from "../Routes/RoutePaths";
import {
  can_add_user,
  has_permissions,
  test_has_permissions,
} from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import Select from "react-select";
import { logout_func2 } from "../Const/logoutCommon";

const NewoutletAddUser = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [gender, setGender] = useState(0);
  const [phone, setphone] = useState(1);
  const [fileName, setFileName] = useState("");
  const [optional, setOptional] = useState("");
  const [ProfileImage, setProfileImage] = useState(null);

  const [status, setStatus] = useState(true);
  const history = useHistory();
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [outletList, setOutletList] = useState([]);
  const [userAllData, SetuserAllData] = useState({
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile: "",
    user_blood_group: "",
    user_password: "",
  });
  const [cCode, setCCode] = useState("880");
  const [selectedOptionRole, setSelectedOptionRole] = useState(null);
  const [RoleList, SetRoleList] = useState([]);

  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    setProfileImage(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const getRoles = () => {
    const url = `${BASE_URL}api/v1/users/inventory/role/`;
    axios
      .get(url)
      .then((res) => {
        console.log("get_roles", res.data.data);
        const options = res.data.data.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        SetRoleList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setOutletList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };

  useEffect(() => {
    getRoles();
    getOutlet();
  }, []);

  const clearData = () => {
    SetuserAllData({
      product_name: "",
      product_description: "",
      product_tax: "",
      product_vat: "",
      product_discount: "",
      product_quantity: "",
      product_price: "",
    });
  };

  const postUser = () => {
    const bd = /^[1][0-9]{9}$/;
    if (!bd.test(userAllData["user_mobile"]?.replace("+880", ""))) {
      showToast("error", "From Mobile : Please Enter Valid phone number");
      return 0;
    }
    if (selectedOutlet === null) {
      showToast("error", "Select outlet please!");
      return 0;
    }
    if (selectedOptionRole === null) {
      showToast("error", "Select role please!");
      return 0;
    }
    const url = `${BASE_URL}api/v1/users/inventory/user/`;
    const phoneData = `+${cCode}${userAllData["user_mobile"]}`;

    const data = new FormData();
    data.append("first_name", userAllData["user_first_name"]);
    data.append("last_name", userAllData["user_last_name"]);
    data.append("email", userAllData["user_email"]);
    data.append("mobile", phoneData);
    data.append("password", userAllData["user_password"]);
    data.append("image", ProfileImage);
    data.append("thumb", thumbId);
    data.append("is_active", status);
    data.append("is_outlet_user", true);
    data.append("gender", gender);
    
    data.append("outlet_multi", selectedOutlet?.map(item => item.value))

    // const data2 = {
    //   first_name: userAllData["user_first_name"],
    //   last_name: userAllData["user_last_name"],
    //   email: userAllData["user_email"],
    //   mobile: phoneData,
    //   password: userAllData["user_password"],
    //   // image: ProfileImage,
    //   thumb: thumbId,
    //   is_active: status,
    //   is_outlet_user: true,
    //   gender: gender,
    //   // outlet: selectedOutlet?.map(item => item.value),
    //   outlet_multi: selectedOutlet?.map(item => item.value)
    // };

    data.append("role", selectedOptionRole.value);

    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          clearData();
          showToast("success", "User Added.");
          history.push(path.outlet_user_list);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
      });
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const changeStat = () => {
    setStatus((prev) => !prev);
  };
  const getSelectRole = (val, action) => {
    setSelectedOptionRole(val);
  };
  const getSelectOutlet = (val, action) => {
    setSelectedOutlet(val);
  };
  console.log(selectedOutlet,'sd 230')

  const handleCancelButton = () => {
    history.push(path.outlet_user_list);
  };

  if (!has_permissions(can_add_user)) {
    return <Permission />;
  }
  return (
    <div
      className="side_components_container"
      style={{ background: "#FAFAFA" }}
    >
      <div className="bg-white ">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          {/* <PlusCircle size={30} /> */}
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Add outlet User
          </h3>
        </div>
        {/* -----------product form---------- */}
        <div
          style={{
            paddingTop: "20px",
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
        >
          <div className="row">
            <div className="col-12 col-md-6">
              <RequiredLabel text="First Name" />
              <InputGroup className="mb-3">
                <Form.Control
                  name="user_first_name"
                  value={userAllData["user_first_name"]}
                  onChange={(e) => handleInputs(e, SetuserAllData)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text="Last Name" />
              <InputGroup className="mb-3">
                <Form.Control
                  name="user_last_name"
                  value={userAllData["user_last_name"]}
                  onChange={(e) => handleInputs(e, SetuserAllData)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="Email" />
              <InputGroup className="mb-3">
                <Form.Control
                  name="user_email"
                  value={userAllData["user_email"]}
                  onChange={(e) => handleInputs(e, SetuserAllData)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="Phone Number" />

              <div className="d-flex phoneContainer ">
                <PhoneInput
                  disabled
                  disableDropdown
                  inputClass="phoneinput"
                  enableSearch={true}
                  onChange={(e) => setCCode(e)}
                  country={"bd"}
                />
                <div className="w-100">
                  <Form.Control
                    name="user_mobile"
                    value={userAllData["user_mobile"]}
                    onChange={(e) => handleInputs(e, SetuserAllData)}
                    aria-label="Input number"
                    placeholder="Input number"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    className="phone_insert_input"
                  />
                </div>
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <RequiredLabel text="Role" />

              <div className="inputgroup_container">
                <Select
                  placeholder="---Select---"
                  onChange={getSelectRole}
                  options={RoleList && RoleList}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <RequiredLabel text="Password" />

              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="user_password"
                  value={userAllData["user_password"]}
                  onChange={(e) => handleInputs(e, SetuserAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 ">
              <RequiredLabel text="Outlet" />
              <div className="inputgroup_container">
                <Select
                  isMulti
                  placeholder="---Select---"
                  onChange={getSelectOutlet}
                  options={outletList}
                />
              </div>
            </div>

            <div
              className=" my-1 bg-white cat-child"
              style={{ border: "none!important" }}
            >
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-lg-5 ">
                  <h5 style={{ marginTop: "30px" }}>Profile image *</h5>
                  <p style={{ marginTop: "10px", color: "red" }}>
                    NB: Upload image in (height-300px, width-300px) for Profile.{" "}
                  </p>
                  <p style={{ color: "#8E8E93" }}>
                    ( Select your file & uploded )
                  </p>
                  <div
                    className="w-100 browse-main d-flex align-items-center"
                    style={{
                      height: "55px",
                      border: "1px solid #E0E0E0",
                      borderRadius: "7px",
                    }}
                  >
                    {fileName === "" ? (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        No File Choosen
                      </p>
                    ) : (
                      <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                        {" "}
                        {fileName}
                      </p>
                    )}

                    <div className="file-up">
                      <input type="file" onChange={(e) => fileHandle(e)} />
                      <p>Browse</p>
                    </div>
                  </div>
                  {URL && (
                    <div className="my-2">
                      <img height={90} width={90} src={URL} alt="" /> <br />
                      <button
                        onClick={removeImg}
                        className="remove-btn btn btn-small mt-1  rounded"
                        style={{ border: "1px solid gray !important" }}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>

                {/* ----------status--------- */}
                <div className="col-12 col-lg-5">
                  <h5 style={{ marginTop: "30px" }}>Status</h5>
                  <p style={{ color: "#8E8E93" }}>
                    ( If the Category Option is available )
                  </p>
                  <div className="row mr-4">
                    <div
                      className=" d-flex justify-content-between col-12 py-3"
                      style={{
                        backgroundColor: "#F2F2F7",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="d-flex w-100">
                        <div
                          className="d-flex py-1 justify-content-center align-items-center"
                          style={{
                            backgroundColor: "#212121",
                            width: "32px",
                            height: "32",
                            borderRadius: "3px",
                          }}
                        >
                          <Eye size={20} color={"white"} />{" "}
                        </div>
                        <span className="mx-3">
                          {status === true ? "ON" : "OFF"}
                        </span>
                      </div>

                      <div class="form-check form-switch ">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          width={40}
                          height={40}
                          checked={status}
                          onChange={() => setStatus(!status)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
                  <button
                    className="save-btn me-2 mt-2"
                    style={{ background: "#000", color: "white" }}
                    onClick={postUser}
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancelButton}
                    className="save-btn mt-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewoutletAddUser;
