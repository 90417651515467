import React, { useEffect, useState } from "react";
import qrCode from "../../assets/Icon/qrcode.svg";
import print from "../../assets/Icon/print.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Const/Url.js";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Loader from "../CustomCommons/Loader";
import cImg from "../../assets/Icon/KAARUJ 1.png";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";

const NewReturnProductView = () => {
  const { id } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [variants, setVariants] = useState([]);
  const [isLoading, SetisLoading] = useState(true);
  const [outletDetail, setOutletDetail] = useState({});
  let url = `${BASE_URL}api/v1/inventory/admin/return-chalan/${id}/`;
  const [data, setData] = useState({});
  const [Jdata, setJData] = useState([]);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [dataDetails, setDataDetails] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [checkedIds, setCheckedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = dataDetails
        .filter((item) => item.status === 0)
        .map((item) => item.id);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };

  console.log(dataDetails, "dd");

  const apiFunction = () => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        setDataDetails(res.data?.data?.return_products);
        // setDataDetails(
        //   productArray(
        //     res.data.data?.return_products,
        //     res.data.data
        //   )
        // );
        setOutletDetail(JSON.parse(localStorage.getItem("userData")));
        SetisLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    apiFunction();
  }, []);

  const paymentStatus = ["Paid", "Unpaid", "Due"];
  const DeliveryTypeStatus = ["Regular", "Urgent"];

  const cRef = useRef();
  const history = useHistory();

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Invoice",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };

  const statusBulkChanges = (ids, stat) => {
    swal({
      title: "Are you sure?",
      text: "Once Click, You will not be able to go back.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/inventory/admin/outlet-product-return/${id}/`;
          return axios.patch(url, { status: stat });
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been updated!", {
              icon: "success",
            });
            setCheckedIds([]);
            SetisLoading(true);
            apiFunction();
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  console.log(checkedIds, "156");

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="categorey-parent pd-right2" style={{ height: "100%" }}>
      <div className="bg-white rounded printe" ref={cRef}>
        <div className="company-img ">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <img width={200} className="mt-4" height={40} src={cImg} alt="" />
          </div>
        </div>
        <div className=" row py-4 px-lg-4 px-md-3 px-3 top-m ds-topm">
          {/* d-flex flex-column flex-md-row justify-content-between */}
          <div className="col-6 view-inv dd col-md-6 mb-2 mb-md-0">
            <div>
              <h4 className="mt-1">#{data.number}</h4>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex   justify-content-end  align-items-start gap-2 invoice_qr inv_qr2 extra-inv">
            <div>
              <span>Return Date: </span>
              <br />
              <span>
                {new Date(data.created_at).toLocaleDateString("en-US", options)}
              </span>
              {/* {new Date(data.invoice_date).toLocaleDateString("en-US", options)} */}
              <br />
              {/* <span style={{ marginRight: "4px" }}> Due Date: </span> <span>22 June, 2022</span> */}
            </div>
            <img
              className="pointer"
              style={{ marginTop: "-10px" }}
              src={data?.qr_code ? data.qr_code : qrCode}
              alt=""
            />
          </div>
        </div>
        {/* border */}
        <div className="mx-2 mx-md-3 w-100">
          <div className="border-ex"></div>
        </div>
        {/* address */}
        <div className="address">
          <div className="row  justify-content-between an">
            <div className="col-12 col-md-6 col-lg-5 mt-3 mt-md-0 ddd">
              <h5>From:</h5>
              <span>
                {data?.outlet_name ? data?.outlet_name : "Zakariya Rahman"}{" "}
              </span>{" "}
              <br />
              <span className="to_address">{data?.outlet_location}</span> <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>
                Phone: {data.outlet_mobile ? data?.outlet_mobile : "+4480976645"}
              </span>{" "}
              <br />
              {data?.outlet_email && <span>Email:{data?.outlet_email}</span>}
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <h5>To:</h5>
              <span>{data?.bill_from ? data?.bill_from : "Kaaruj"} </span>{" "}
              <br />
              <span>
                {data?.from_address
                  ? data?.from_address
                  : "Road: 42, Gulshan 2, Dhaka, Bangladesh"}{" "}
              </span>{" "}
              <br />
              {/* <span>Dhaka, Bangladesh</span> <br /> */}
              <span>
                Phone:{" "}
                {data?.from_mobile ? data?.from_mobile : "+8801980907892"}
              </span>{" "}
              <br />
              <span>Email: kaarujbangladesh@gmail.com</span>
            </div>
          </div>

          <div className="table-cont">
            <h5 className="mb-4">Return Summary</h5>
            <p style={{ fontSize: "14px", color: "red" }} className="dt">
              NB: Please select a product to either accept or reject. Click on
              the name to view details.
            </p>
            {checkedIds?.length > 0 && (
              <>
                <div className="my-2 d-flex dt">
                  <button
                    className="btn text-light me-2"
                    style={{
                      background: "black",
                      cursor: "pointer",
                      maxWidth: "160px",
                      color: "white",
                    }}
                    onClick={() => statusBulkChanges(checkedIds, 2)}
                  >
                    Reject
                  </button>
                  <button
                    className="btn text-light"
                    style={{
                      background: "black",
                      cursor: "pointer",
                      maxWidth: "160px",
                      color: "white",
                    }}
                    onClick={() => statusBulkChanges(checkedIds, 1)}
                  >
                    Accept
                  </button>
                </div>
              </>
            )}
            <div className=" ">
              <table className="w w1" style={{ width: "100%" }}>
                <thead>
                  <tr
                    className={`bg-dark text-white `}
                    style={{ height: "50px" }}
                  >
                    <th
                      style={{
                        fontSize: "16px",
                        fontWeight: "normal",
                        width: "120px",
                        margin: "center",
                      }}
                      className="dt"
                    >
                      <div className="d-flex justify-content-center">
                        <input
                          style={{
                            margin: "0 auto",
                            opacity: "1",
                            width: "17px",
                            height: "17px",
                          }}
                          type="checkbox"
                          checked={checkedIds?.length > 0}
                          onChange={(event) => handleCheckboxChangeAll(event)}
                        />
                      </div>
                    </th>
                    <th className="ps-4" style={{ minWidth: "80px" }}>
                      No
                    </th>

                    <th className="minw minw1" style={{ minWidth: "150px" }}>
                      Item
                    </th>
                    <th
                      className="minw minw1 ps-3"
                      style={{ minWidth: "100px" }}
                    >
                      SKU
                    </th>
                    <th className="minw minw1 ps-2">Image</th>

                    <th className="minw minw1 " style={{ maxWidth: "100px" }}>
                      <p className="text-center my-auto">Curr Stock</p>
                    </th>
                    <th
                      className="pe-4"
                      style={{ minWidth: "130px", textAlign: "right" }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data !== undefined &&
                    data !== null &&
                    dataDetails.map((curr, index) => {
                      return (
                        <tr
                          className={`tab-b ${
                            Number(curr?.quantity) < 1 && ""
                          }`}
                          style={{ height: "50px", cursor: "pointer" }}
                        >
                          <td className="dt">
                            <div className="d-flex justify-content-center">
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  opacity: `${curr?.status === 0 ? "1" : "0"}`,
                                }}
                                checked={checkedIds.includes(curr.id)}
                                // disabled={category?.status === 2 || category?.status === 1}
                                onChange={() => handleCheckboxChange(curr?.id)}
                              />
                            </div>
                          </td>
                          <td className="ps-4">{index + 1}</td>

                          <td
                            onClick={() => {
                              setVariants(curr?.outletVariant);
                              setModalShow(true);
                            }}
                          >
                            {curr?.product_details?.name}
                          </td>
                          <td className="ps-3">{curr?.product_details.sku}</td>
                          <td className="ps-2">
                            <img
                              className="rounded"
                              width={40}
                              height={40}
                              src={curr?.product_details.thumb_url2}
                              alt=""
                            />
                          </td>

                          <td>
                            <p className="text-center my-auto">
                              {Number(curr?.stock)}
                            </p>
                          </td>
                          <td>
                            <p className="text-end my-auto me-2">
                              {curr?.status === 0
                                ? "Pending"
                                : curr?.status === 1
                                ? "Accepted"
                                : "Rejected"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="row d4 d5 calc-main d-none">
              <div className="col-12 col-md-4 col-lg-6 calc-empty"></div>
              <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-end calc">
                <div className=" ">
                  <table>
                    <tbody>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td
                          style={{ minWidth: "230px" }}
                          className="tab-b ps-3"
                        >
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Sub Total : </div>
                            <div>
                              ৳{" "}
                              {dataDetails
                                ?.map(
                                  (curr) =>
                                    Number(curr.price) * Number(curr?.stock)
                                )
                                .reduce((a, b) => a + b, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Delivery Charge : </div>
                            <div>৳ {0.0}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Discount : </div>
                            <div>৳ {data.total_discount}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Total : </div>
                            <div>
                              ৳{" "}
                              {dataDetails
                                ?.map(
                                  (curr) =>
                                    Number(curr.price) * Number(curr?.stock)
                                )
                                .reduce((a, b) => a + b, 0)}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Paid Amount: </div>
                            <div>৳ {data?.total_paid}</div>
                          </div>
                        </td>
                      </tr>
                      <tr className="" style={{ height: "50px" }}>
                        <td className="ps-4"></td>
                        <td></td>
                        <td className="tab-b ps-3">
                          <div
                            style={{ marginRight: "14px" }}
                            className="d-flex justify-content-between "
                          >
                            <div>Due Amount : </div>
                            <div style={{ color: "red" }}>
                              ৳ {data.total_due}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="calc-main2 d-none">
              <div className=" calc2">
                <div className="t-class">
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Sub Total : </div>
                      <div>
                        ৳{" "}
                        {dataDetails
                          ?.map(
                            (curr) => Number(curr.price) * Number(curr?.stock)
                          )
                          .reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Delivery Charge : </div>
                      <div>৳ {data.delivery_charge}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Discount : </div>
                      <div>৳ {data.total_discount}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Total : </div>
                      <div>
                        ৳{" "}
                        {dataDetails
                          ?.map(
                            (curr) => Number(curr.price) * Number(curr?.stock)
                          )
                          .reduce((a, b) => a + b, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Paid Amount: </div>
                      <div>৳ {data?.total_paid}</div>
                    </div>
                  </div>
                  <div className="tab-b">
                    <div
                      style={{ marginRight: "14px" }}
                      className="d-flex justify-content-between "
                    >
                      <div>Due Amount : </div>
                      <div style={{ color: "red" }}>৳ {data.total_due}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        {/* <div className="mx-2 mx-md-3 my-2">
          Total Products : {data?.products_count}
        </div> */}
        <div className="mx-2 mx-md-3 ">
          <div className="border-ex"></div>
          <div className="mx-2 my-4 mx-md-3 d-flex justify-content-between">
            <div>
              <span>{data?.created_by_name}</span> <br />
              <span>----------------------</span> <br />
              <span>Prepared By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Recieved By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span>Checked By</span>
            </div>
            <div>
              <span></span> <br />
              <span>----------------------</span> <br />
              <span className="">Authorized By</span>
            </div>
          </div>
        </div>
        <div style={{ height: "50px" }} className="m-2">
             Special Note : Please check out this product list. Any questions, ask the logistics team. No complaint shall be entertained after the goods  left the Outlet.
            </div>
      </div>
      <div className=" invoice-button">
        <button className="me-2 my-2 button-1" onClick={handlePrints}>
          <img src={print} alt="" /> Print
        </button>
        {/* <button
      onClick={sendPdfToEmail}
      className={`my-2 button-2 ${!data.to_email && "d-none"}`}
    >
      <img src={send} alt="" /> Send Invoice
    </button> */}
        <>
          <Modal
            // {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Product Variants
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {variants?.map((data) => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Name : {data?.variant_details?.name}</h5>
                    <h5>Stock : {data?.stock}</h5>
                  </div>
                </>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                onClick={() => setModalShow(false)}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default NewReturnProductView;
