import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import swal from "sweetalert";
import { handleInputs } from "../../../utils/HandleInputs";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import * as path from "../../Routes/RoutePaths";
import { showToast } from "../../../utils/ToastHelper";
import Loader from "../../CustomCommons/Loader";

const EditTeamMember = () => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [testi, setTesti] = useState({
    name: "",
    designation: "",
    comment: "",
  });
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast('success','image uploaded')
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        setTesti({
          name: res.data.data?.name,
          designation: res.data.data?.designation,
          
        });
        setURL(res.data.data?.thumb_url);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/utility/admin/team-member/${id}/`;
    apiFunction(url);
  }, []);
  const postMember = () => {
    const url = `${BASE_URL}api/v1/utility/admin/team-member/${id}/`;
    const data = new FormData();
    testi.name && data.append("name", testi.name);
    testi.designation && data.append("designation", testi.designation);
    thumbId !== null && data.append("thumb", thumbId);
    axios
      .patch(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Testimonial Updated.");
          history.push(path.teammember);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
          Edit Member
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={testi["name"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Designation"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="designation"
                value={testi["designation"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          {/* <div className="col-12 col-md-6">
            <RequiredLabel text={"Comment"} />
            <InputGroup className="mb-3" style={{ height: "100px" }}>
              <Form.Control
                as={"textarea"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="comment"
                value={testi["comment"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div> */}
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
          NB: Upload image in (height-300px, width-300px) for Thumbnail.{" "}
        </p>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    You can select 
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={postMember}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.teammember)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTeamMember;
