import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
// import "./invoice.css";
import * as path from "./../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { BASE_URL } from "../Const/Url";
import PreviewCreate from "../invoice/PreviewCreate";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { PencilSimple } from "phosphor-react";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AddChalanProduct = () => {
  const [selectedVariants, setSelectedVariants] = useState({});
  const { id, outlateName, outletId } = useParams();
  console.log(id, outlateName, outletId, "ch22");
  const history = useHistory();

  // Outlet select section
  const [outlet, setOutlet] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(outletId);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/v1/sales/admin/outlet/`)
      .then((response) => response.data.data.results)
      .then((res) => {
        const outletArr = res.map((outlet) => ({
          label: outlet.name,
          value: outlet.id,
        }));
        setOutlet(outletArr.filter((arr) => arr.value === parseInt(outletId)));
        console.log(outlet, "ot");
      });
  }, [outletId]);

  const handleOutletSelect = (newVal, action) => {
    setSelectedOutlet(newVal.value);
  };
  // Product select section
  const [mainState, setMainState] = useState({});
  const [mainInnerState, setMainInnerState] = useState({});
  const [ProductCom, setProductCom] = useState([]);
  const [defaultValue, setdefaultValue] = useState([]);
  const [inputQuery, setInputQuery] = useState("");
  const [ProductsList, setProductsList] = useState([]);
  const [pS, setPs] = useState(0);
  const [isLoading, SetisLoading] = useState(false);

  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/?query=${inputQuery}`;
      }
      axios
        .get(url)
        .then((res) => {
          // console.log("productlistinvoice", res.data);
          const result = res.data.data;
          console.log(result, "------productlistinvoice-----");
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
          }));

          setProductsList(options);
          // SetisLoading(false);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  useEffect(() => {
    getProductList();
  }, [inputQuery]);
  const handleMultiInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    const newVal = actionMeta?.option?.label;
    setProductCom(newValue);
    console.log("newValue 123", newValue);
    console.log("newValue 1234", actionMeta?.option?.label);
    if (actionMeta.action === "select-option" && actionMeta?.option?.variant) {
      let variants = actionMeta?.option?.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option?.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
          })
      );

      // setMainState({
      //   ...mainState,
      //   [newVal]: {
      //     id: actionMeta.option.value,
      //     variant: variants,
      //     variantObj: variantsObj,
      //     price: parseInt(actionMeta.option.price),
      //     stock: parseInt(actionMeta.option.stock),
      //     thumb: actionMeta.option.thumb,
      //     discount: parseInt(actionMeta.option.discount),
      //   },
      // });
      setMainState((prevMainState) => ({
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
        },
        ...prevMainState,
      }));
      setdefaultValue([
        ...defaultValue,
        {
          value: actionMeta.option.value,
          label: newVal,
        },
      ]);
    }
    //remove data from select
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
      setdefaultValue((prevDefaultValue) =>
        prevDefaultValue.filter(
          (value) =>
            value.label !== actionMeta.removedValue.label &&
            value.value !== actionMeta.removedValue.value
        )
      );
      // setScanCode("");
    }
  };
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
    console.log("searched", inputQuery);
  };
  const handleInnerVariant = (currentName, value) => {
    // console.log("currentName", currentName);
    console.log("value", currentName, value);
    setMainInnerState({
      ...mainInnerState,
      [currentName]: value,
    });
  };

  const VariantThead = () => {
    return (
      <>
        {Object.keys(mainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              {/* <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th> */}
            </tr>
          </thead>
        )}
      </>
    );
  };
  const getDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      mainState[curr] !== undefined &&
        (mainState[curr].discount === 0
          ? mainState[curr].price
          : mainState[curr].discount)
    );
  };
  const getBasePlusVariant = (curr, innerCur) => {
    return getDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const saveProduct = () => {
    // Initialize an array to store the resulting objects
    const payload = [];
    // Iterate over mainState and create objects
    // prev code
    // for (const productName in mainState) {
    //   if (mainState.hasOwnProperty(productName)) {
    //     const productInfo = mainState[productName];
    //     const variants = mainInnerState[productName].map((variant) => {
    //       return {
    //         stock: mainState[productName].variant.find(
    //           ({ id }) => id == variant.value
    //         ).quantity,
    //         admin_stock: mainState[productName].variant.find(
    //           ({ id }) => id == variant.value
    //         ).quantity,
    //         variant: variant.value,
    //       };
    //     });

    //     payload.push({
    //       outletVariant: variants,
    //       stock: variants.reduce((accumulator, currentValue) => {
    //         return accumulator + parseInt(currentValue.stock);
    //       }, 0),
    //       admin_stock: variants.reduce((accumulator, currentValue) => {
    //         return accumulator + parseInt(currentValue.stock);
    //       }, 0),
    //       product: productInfo.id,
    //       outlet: selectedOutlet, // You can set this value as needed
    //     });
    //   }
    // }
    // new code 
    for (const productName in mainState) {
      if (mainState.hasOwnProperty(productName)) {
        const productInfo = mainState[productName];
        const variants = mainInnerState[productName].map((variant) => {
          const foundVariant = mainState[productName].variant.find(
            ({ id }) => id == variant.value
          );
          const stock = foundVariant.quantity < 0 ? 0 : foundVariant.quantity;
          const admin_stock = foundVariant.quantity < 0 ? 0 : foundVariant.quantity;
    
          return {
            stock: stock,
            admin_stock: admin_stock,
            variant: variant.value,
          };
        });
    
        // Filter variants where both stock and admin_stock are greater than 0
        const filteredVariants = variants.filter(variant => variant.stock > 0 && variant.admin_stock > 0);
    
        const totalStock = filteredVariants.reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue.stock);
        }, 0);
    
        const totalAdminStock = filteredVariants.reduce((accumulator, currentValue) => {
          return accumulator + parseInt(currentValue.admin_stock);
        }, 0);
    
        if (filteredVariants.length > 0) {
          payload.push({
            outletVariant: filteredVariants,
            stock: totalStock,
            admin_stock: totalAdminStock,
            product: productInfo.id,
            outlet: selectedOutlet, // You can set this value as needed
          });
        }
      }
    }
    

    console.log(mainState, mainInnerState, selectedOutlet, "aaa1");

    console.log(payload, "ppp");
    const outletObj = {
      chalan_outlet_products: payload,
      outlet: selectedOutlet,
    };
    axios
      .patch(`${BASE_URL}/api/v1/sales/admin/chalan/${id}/`, outletObj)
      .then((res) => {
        if (res.data.status) {
          // clearData();
          showToast("success", "Product Added to Chalan");
          history.push("/outlet/chalan_list");
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  return (
    <>
      <div
        className="invoice-b my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Select Outlet</h4>
        <div className="border-p"></div>
        <div className="padding-main">
          <div className="main-select">
            <Select
              // styles={styles}
              // isMulti
              placeholder="---Select---"
              // isClearable
              value={outlet}
              defaultValue={outlet[0]}
              onChange={handleOutletSelect}
              // onInputChange={inputProduct}
              options={outlet}
              // value={selectedOutlet}
            />
          </div>
          
          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="invoice-b my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Product Details</h4>
        <div className="border-p"></div>
        <div className="padding-main">
          <div className="main-select">
            <Select
              // styles={styles}
              isMulti
              placeholder="---Select---"
              isClearable
              // defaultValue={selectedOption}
              onChange={handleMultiInputChange}
              onInputChange={inputProduct}
              options={ProductsList}
              value={defaultValue}
            />
          </div>

          {/* </div> */}
          {/* </div> */}
          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <table className="item-tables table_my2">
                <tbody>
                  {/* //Here-------------------------- */}
                  {Object.keys(mainState).map((curr, index) => {
                    // console.log("curr---------", curr);
                    // console.log("curr---------var", mainState[curr]["variant"]);
                    const variants = mainState[curr]["variant"];
                    return (
                      <>
                        {mainState[curr]["variant"]?.length > 0 ? (
                          <>
                            <div
                              className="mt-4  px-2"
                              style={{
                                background: " rgba(245, 245, 245, 0.36)",
                                border: "none",
                                height: "119px",
                              }}
                            >
                              <div
                                className="row py-3 my-3"
                                style={{ height: "45px" }}
                              >
                                <div
                                  className="col-2 minWidthForInvoiceCreateTable ps-4"
                                  style={{ textAlign: "start" }}
                                >
                                  <p>Product</p>
                                </div>
                                <div
                                  className="col-4 minWidthForInvoiceCreateTable  py-1"
                                  style={{ textAlign: "center" }}
                                >
                                  Total Stock
                                </div>
                                <div
                                  className="col-5 minWidthForInvoiceCreateTable  py-1"
                                  style={{ textAlign: "center" }}
                                >
                                  Variants
                                </div>
                              </div>
                              <div className="row  " style={{ height: "45px" }}>
                                <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                  <p className="text-capitalize">{curr}</p>
                                  <img
                                    width={50}
                                    style={{
                                      margin: "6px 0px !important",
                                      border: "0.2px solid #bcb0b0",
                                    }}
                                    height={50}
                                    src={!isLoading && mainState[curr]["thumb"]}
                                    alt="failed"
                                    className="ms-3 shadow-sm"
                                  />
                                  {/* {console.log(" mainState[curr][thumb", mainState[curr]["thumb"])} */}
                                </div>
                                <div className="col-4 py-1">
                                  <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                    {Number(mainState[curr]["stock"])}
                                    {/* {Number(mainState[curr]["stock"]) - Number(pS)} */}
                                  </p>
                                </div>
                                <div className="col-5 text-center   py-1 pe-2">
                                  <div
                                    className=""
                                    style={{ marginTop: "-10px" }}
                                  >
                                    {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                    <Select
                                      styles={{
                                        height: "45px",
                                        background: "black",
                                      }}
                                      isMulti
                                      placeholder="-Select Variants-"
                                      isClearable
                                      // onChange={(value) => {
                                      //   handleInnerVariant(curr, value);
                                      // }}
                                      value={selectedVariants[curr] || []}
                                      onChange={(value) => {
                                        handleInnerVariant(curr, value);
                                        setSelectedVariants({
                                          ...selectedVariants,
                                          [curr]: value,
                                        });
                                      }}
                                      options={
                                        mainState[curr]["variant"] !==
                                          undefined &&
                                        Object.values(
                                          mainState[curr]["variant"]
                                        ).map((curr) => ({
                                          label: curr.name,
                                          value: curr.id,
                                          price: curr.price,
                                          stock: curr.stock,
                                          quantity: 1,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <table className="mapTable shadow-sm ms-2">
                              <thead
                                className="mt-4"
                                style={{
                                  background: " rgba(245, 245, 245, 0.36)",
                                }}
                              ></thead>
                              <tbody className="innerTbody w-100">
                                {mainInnerState !== undefined &&
                                  mainInnerState[curr] !== undefined &&
                                  mainInnerState[curr].map(
                                    (innerCur, index) => {
                                      return (
                                        <>
                                          {index === 0 && <VariantThead />}

                                          <tbody>
                                            <tr
                                              className="border-invoice"
                                              style={{ height: "45px" }}
                                            >
                                              <td className="ps-4 py-3">
                                                <p className="text-capitalize">
                                                  {innerCur.label}
                                                </p>
                                                {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                              </td>
                                              <td
                                                className="text-center   py-3"
                                                style={{ minWidth: "300px" }}
                                              >
                                                <input
                                                  name="in_price"
                                                  // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                  value={getBasePlusVariant(
                                                    curr,
                                                    innerCur
                                                  )}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="text"
                                                  //
                                                />
                                              </td>
                                              <td className="text-center   py-3">
                                                <input
                                                  name="in_price"
                                                  // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                  value={Number(innerCur.stock)}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="text"
                                                />
                                                {/* {console.log(mainState[curr]["variantObj"][innerCur],'------vvv----------')} */}
                                              </td>
                                              <td className="text-center   py-3">
                                                <input
                                                  min={0}
                                                  name="in_quantity"
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                  onChange={(e) => {
                                                    // HandleNewProductsInputValue(e, innerCur);
                                                    const productName = curr;
                                                    if (e.target.value < 0) {
                                                      setPs(0);
                                                    } else {
                                                      setPs(e.target.value);
                                                    }

                                                    setMainState(
                                                      (prevInputs) => {
                                                        const copiedObjects =
                                                          Object.assign(
                                                            {},
                                                            mainState
                                                          ); // Shallow copy
                                                        // console.log("copiedObjects", copiedObjects);
                                                        // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
                                                        const keyOfVariant =
                                                          Object.keys(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"]
                                                          ).filter(
                                                            (curr) =>
                                                              copiedObjects[
                                                                productName
                                                              ]["variant"][
                                                                curr
                                                              ]["name"] ===
                                                              innerCur.label
                                                          );
                                                        const variantName =
                                                          innerCur.label;
                                                        // console.log("innercur", innerCur);
                                                        if (
                                                          parseInt(
                                                            e.target.value
                                                          ) < 0
                                                        ) {
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] = 0;
                                                        } else if (
                                                          parseInt(
                                                            e.target.value
                                                          ) > innerCur.stock
                                                        ) {
                                                          showToast(
                                                            "error",
                                                            `Max Stock : ${innerCur.stock}`
                                                          );
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] = 0;
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] =
                                                            innerCur.stock;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] =
                                                            innerCur.stock;
                                                        } else {
                                                          copiedObjects[
                                                            productName
                                                          ]["variant"][
                                                            keyOfVariant
                                                          ]["quantity"] =
                                                            e.target.value;
                                                          copiedObjects[
                                                            productName
                                                          ]["variantObj"][
                                                            variantName
                                                          ]["quantity"] =
                                                            e.target.value;
                                                        }

                                                        let checkDiscountPrice =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["discount"]
                                                          ) === 0
                                                            ? parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["price"]
                                                              )
                                                            : parseInt(
                                                                copiedObjects[
                                                                  productName
                                                                ]["discount"]
                                                              );
                                                        // console.log("checkDiscountPrice", checkDiscountPrice);
                                                        const baseAndVariant =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"][
                                                              keyOfVariant
                                                            ]["price"]
                                                          ) +
                                                          checkDiscountPrice;
                                                        copiedObjects[
                                                          productName
                                                        ]["variant"][
                                                          keyOfVariant
                                                        ]["total"] =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variant"][
                                                              keyOfVariant
                                                            ]["quantity"]
                                                          ) * baseAndVariant;

                                                        const baseAndVariantObj =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variantObj"][
                                                              variantName
                                                            ]["price"]
                                                          ) +
                                                          checkDiscountPrice;
                                                        // console.log("baseAndVariantObj price--", baseAndVariantObj);
                                                        copiedObjects[
                                                          productName
                                                        ]["variantObj"][
                                                          variantName
                                                        ]["total"] =
                                                          parseInt(
                                                            copiedObjects[
                                                              productName
                                                            ]["variantObj"][
                                                              variantName
                                                            ]["quantity"]
                                                          ) * baseAndVariantObj;

                                                        return copiedObjects; // Return copied object
                                                      }
                                                    );
                                                  }}
                                                  className="invoiceCreateSmallInputs"
                                                  placeholder="00.00"
                                                  type="number"
                                                  value={
                                                    (mainState === undefined &&
                                                      mainState[curr] ===
                                                        undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ] === undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label] ===
                                                        undefined &&
                                                      mainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label][
                                                        "quantity"
                                                      ] === undefined) ||
                                                    mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ] === "-1" ||
                                                    mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ] === -1
                                                      ? 0
                                                      : mainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ]
                                                  }
                                                />
                                              </td>
                                              {/* <td className="text-center py-3 ">
                                            <input
                                              // name="in_total"
                                              value={
                                                (mainState ===
                                                  undefined &&
                                                  mainState[curr] ===
                                                    undefined) ||
                                                isNaN(
                                                  mainState[curr][
                                                    "variantObj"
                                                  ][innerCur.label][
                                                    "total"
                                                  ]
                                                )
                                                  ? 0
                                                  : mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "total"
                                                    ]
                                              }
                                              className="invoiceCreateSmallInputs"
                                              placeholder="00.00"
                                              type="text"
                                            />
                                          </td>{" "} */}
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                      // return <h2>{innerCurr.label}</h2>;
                                    }
                                  )}
                              </tbody>

                              {/* <VariantTable /> */}
                            </table>
                          </>
                        ) : (
                          <div>No variant</div>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>

              {/* <div className="d-flex flex-column flex-md-row mt-4 justify-content-between mx-4">
            <div>
              <h6>Total Item : {Object?.keys(mainState)?.length}</h6>
            </div> */}
              {/* sub_total, delivery_charge, discount, total, paid_amount, Due_amount */}
              {/* <div>
              <div
                className="d-flex justify-content-between "
                style={{
                  color: "#212121",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                <p>Sub Total :</p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss"
                    type="text"
                    value={isNaN(getSubTotal()) ? 0 : getSubTotal()}
                  />
                </p>
              </div>

              <div className="d-flex justify-content-between cl">
                <p>Delivery Charge : </p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss"
                    type="number"
                    onChange={(e) => setCustomCharge(e.target.value)}
                    value={getDeliveryCharge()}
                    style={{ border: "1px solid gray" }}
                  />
                </p>
              </div>
              <div className="d-flex justify-content-between pl pt-2">
                <p>
                  Discount{" "}
                  {invoice_discount_type === "0" ||
                  invoice_discount_type === 0
                    ? ""
                    : "%"}{" "}
                  :
                </p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss paid_input_css"
                    name="invoice_paid"
                    type="text"
                    value={Discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </p>
              </div>
              <div
                className="d-flex justify-content-between"
                style={{
                  color: "#212121",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                <p>Total amount :</p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss"
                    type="text"
                    value={isNaN(getGrandTotal()) ? 0 : getGrandTotal()}
                  />
                </p>
              </div>

              <div className="d-flex justify-content-between pl pt-2">
                <p>Paid amount :</p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss paid_input_css"
                    name="invoice_paid"
                    type="text"
                    value={paid}
                    onChange={(e) => setpaid(e.target.value)}
                  />
                </p>
              </div>

              <div className="d-flex justify-content-between cl">
                <p>Due amount :</p>
                <p className="ms-3 ">
                  <input
                    className="ms-3 invoiceInpCss"
                    type="text"
                    name="invoice_due"
                    value={isNaN(getDueAmount()) ? 0 : getDueAmount()}
                  />
                </p>{" "}
              </div>
            </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
        style={{ border: "0.5px solid #E0E0E0" }}
      >
        <div className="" style={{ marginLeft: "-10px" }}>
          <button
            onClick={saveProduct}
            className="btn rounded border me-2 mt-2"
          >
            Save
          </button>
          <button
            className="btn rounded border me-2 mt-2"
            onClick={() => history.push(path.outlet_products_list)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default AddChalanProduct;
