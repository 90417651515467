import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import plus from "../../assets/Icon/plus.svg";
import { BASE_URL } from "../Const/Url";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import EditIcon from "../CustomCommons/EditIcon";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import Select from "react-select";
import {
  can_add_invoice,
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import ViewIcon from "../CustomCommons/ViewIcon";
import * as path from "../Routes/RoutePaths";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import { ArrowDown, ArrowUp } from "phosphor-react";
import { getExportFile } from "../../utils/HelperFunction";
import HelperExportComponent from "../../utils/HelperExportComponent";

const ReportFromInv = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");
  const [invoiceSource, setInvoiceSource] = useState("");

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [sortBy, setSortBy] = useState("");
  const [sortState, setSortState] = useState(false);
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [adminOrOutlet, setAdminOrOutlet] = useState("");
  const [total, setTotal] = useState(0);

  const getSelectOutlet = (val, action) => {
    setSelectedOutlet(val.value);
  };

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setTotal(res.data?.total);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    if (adminOrOutlet == 0 || adminOrOutlet == 1) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice-sales-report/"}?limit=${limit}&offset=${offset}&query=${query}&source=${adminOrOutlet}`
      );
    } else {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice-sales-report/"}?limit=${limit}&offset=${offset}&source=${adminOrOutlet}&query=${query}&outlet=${selectedOutlet}`
      );
    }
  }, [pageNumber, query, adminOrOutlet, selectedOutlet]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  // ofset code

  const history = useHistory();

  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);

  const invoice_choices = ["Paid", "Unpaid", "Due", "Exchanged"];
  const delivery_choices = [
    "Returned",
    "Order Placed",
    "Delivered",
    "Pending",
    "Hold",
    "Dispached",
  ];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "C.O.D",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
  ];
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  };
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });

  const getInvoiceList = () => {
    let url;
    if (date1 && date2) {
      // url = `${BASE_URL}api/v1/sales/inventory/invoice-sales-report/filter/?start=${date1}&end=${date2}&is_outlet=False&is_regular=True`;
      url = `${BASE_URL}${"api/v1/sales/inventory/invoice-sales-report/"}?limit=${limit}&offset=${offset}&start=${date1}&end=${date2}&query=${query}&source=${adminOrOutlet}${
        selectedOutlet && adminOrOutlet === 2 ? `&outlet=${selectedOutlet}` : ""
      }`;
    }
    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setTotal(res.data?.total);

        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };

  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setOutletList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    if (date1 && date2) getInvoiceList();
  }, []);
  useEffect(() => {
    getOutlet();
  }, []);
  // console.log(data);

  // const pageCount = Math.ceil(getData.length / usersPerPage);

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getInvoiceList();
    }
  };
  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  // useEffect(() => {

  const options3 = [
    { value: "", label: "See All" },
    { value: 1, label: "Admin" },
    { value: 0, label: "Website" },
    { value: 2, label: "Outlet" },
    { value: 3, label: "Admin + Website" },
  ];
  // }, [invoiceSource, query])

  if (!has_permissions(can_view_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  const sourceList = ["Website", "Admin", "Outlet"];
  const handleAdminOrOutlet = (newVal, action) => {
    setSelectedOutlet("");
    setAdminOrOutlet(newVal.value);
  };

  return (
    <div>
      <div className="categorey-parent">
        <div className="cotagorey-head mb-4">
          <div>
            <h3>Sales Report </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                // changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("invoicePageNbr", selected);
                // (updatedPageNumber = selected)
              }}
            />
          </div>
          {/* <div>
            <h3>Prepared from</h3>
            <select
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              value={invoiceSource}
              onChange={handleInvoiceSourceFilter}
            >
              <option value={""}>All</option>
              <option value={1}>Inventory</option>
              <option value={0}>Website</option>
              <option value={2}>Outlet</option>
            </select>
          </div> */}

          <div className="me-2 mb-3 mb-md-0">
            <p className="">Source :</p>
            <div className="sl" style={{ width: "180px", marginTop: "-15px" }}>
              <Select
                onChange={handleAdminOrOutlet}
                defaultValue={
                  adminOrOutlet === 2
                    ? [{ value: 2, label: "Outlet" }]
                    : adminOrOutlet === 1
                    ? [{ value: 1, label: "Admin" }]
                    : adminOrOutlet === 0
                    ? [{ value: 0, label: "Website" }]
                    : adminOrOutlet === 3
                    ? [{ value: 3, label: "Admin + Website" }]
                    : [{ value: "", label: "See All" }]
                }
                value={
                  adminOrOutlet === 2
                    ? [{ value: 2, label: "Outlet" }]
                    : adminOrOutlet === 1
                    ? [{ value: 1, label: "Admin" }]
                    : adminOrOutlet === 0
                    ? [{ value: 0, label: "Website" }]
                    : adminOrOutlet === 3
                    ? [{ value: 3, label: "Admin + Website" }]
                    : [{ value: "", label: "See All" }]
                }
                options={options3}
                placeholder="---Select---"
              />
            </div>
          </div>
          {adminOrOutlet === 2 && (
            <div className="me-2 mb-3 mb-md-0">
              <p className="">Outlets :</p>
              <div
                className="sl"
                style={{ width: "180px", marginTop: "-15px" }}
              >
                <Select
                  onChange={getSelectOutlet}
                  options={outletList}
                  placeholder="---Select---"
                />
              </div>
            </div>
          )}
          <div className="filter_div">
            <div className="my-2 my-lg-0">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            <div
              className="button-div ms-2 my-auto me-2  text-white "
              style={{ background: "black", cursor: "pointer" }}
            >
              {/* {items_data?.length !== 0 ? (
                <>
                  <span
                    onClick={() =>
                      getExportFile(
                        `${BASE_URL}api/v1/sales/inventory/invoice-sales-report/?export=true&start=${date1}&end=${date2}&source=${adminOrOutlet}&outlet=${
                          selectedOutlet ? selectedOutlet : ""
                        }&query=${query?.toLocaleLowerCase()}`,
                        "sales_export_file.xlsx"
                      )
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() =>
                      swal({
                        title: "Error!",
                        text: "There Is No Data",
                      })
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              )} */}

              <HelperExportComponent
                url={`${BASE_URL}api/v1/sales/inventory/invoice-sales-report/?export=true&start=${date1}&end=${date2}&source=${adminOrOutlet}&outlet=${
                  selectedOutlet ? selectedOutlet : ""
                }&query=${query?.toLocaleLowerCase()}`}
                fileName={"sales_export_file.xlsx"}
                items_data={items_data}
              />
              
            </div>
          </div>
        </div>

        <div>
          <h6>Total : {total ? total : 0}</h6>
        </div>

        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my">
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  <p className="ms-3 my-auto">Invoice No</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Create On
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "187px",
                  }}
                >
                  Prepared From
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  <div
                    style={{ width: "150px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Customer Name</p>
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                    padding: "0 4px",
                  }}
                >
                  <div
                    style={{ width: "90px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Total </p>
                  </div>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Product Discount
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  Invoice Discount
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "165px",
                  }}
                >
                  Last Modified
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    <td>
                      <p className="ms-3 my-auto">{category.number}</p>
                    </td>
                    <td>{category?.invoice_date}</td>
                    <td>{sourceList[category?.source]}</td>
                    <td>{category?.bill_to}</td>
                    <td>৳ {category?.total_amount}</td>
                    <td>৳ {category?.product_discount}</td>
                    <td>৳ {category?.invoice_discount}</td>

                    <td className="ms-1">{category.last_modified_by}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* ----------2nd Table for pdf print------*/}
        {/* ----------2nd Table for pdf print------*/}
        {/* entites and paggination */}
        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>
            {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportFromInv;
