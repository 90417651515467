import axios from "axios";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import swal from "sweetalert";
import { BASE_URL } from "../Const/Url";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import { can_view_invoice, has_permissions } from "../CustomCommons/utils";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";

import HelperExportComponent from "../../utils/HelperExportComponent";
import { useParams } from "react-router-dom";

const ViewVoucher = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const cRef = useRef();
  const [query, setQuery] = useState("");
  const { id } = useParams();

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);

  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${`api/v1/promotions/admin/voucher/${id}/usage_history/`}?limit=${limit}&offset=${offset}&search=${query}`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {
    if (pageNumber > 1 || query) {
      apiFunction(
        `${BASE_URL}${`api/v1/promotions/admin/voucher/${id}/usage_history/`}?limit=${limit}&offset=${offset}&search=${query}`
      );
    }
  }, [pageNumber, query]);

  // ofset code

  const history = useHistory();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <div className="categorey-parent">
          <div className="cotagorey-head mb-4">
            <div>
              <h3>Voucher Use History</h3>
              <input
                style={{ color: "black" }}
                type="text"
                className="filter-input"
                placeholder="Search here"
                onChange={(e) => {
                  setQuery(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
            <div className="filter_div">
              <div
                // className="button-div my-auto mx-2 "
                className="button-div my-auto ms-2 px-3 py-2 text-white rounded"
                style={{ background: "black", cursor: "pointer" }}
              >
                <HelperExportComponent
                  url={`${BASE_URL}${`api/v1/promotions/admin/voucher/${id}/usage_history/`}?limit=${limit}&offset=${offset}&search=${query}&export=true`}
                  fileName={"voucher_history_export_file.xlsx"}
                  items_data={items_data}
                />
              </div>
            </div>
          </div>
          {/* categorey list table */}
          <div style={{ display: "block", overflowX: "auto" }}>
            <table className="table_my">
              <thead
                className="bg-dark text-white head-row"
                style={{
                  height: "50px",
                  borderTopLeftRadius: "20px !important",
                }}
              >
                <tr className="">
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "166px",
                    }}
                  >
                    <p className="ms-3 my-auto">Invoice No</p>
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "166px",
                    }}
                  >
                    <p className="ms-3 my-auto">Code No</p>
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "187px",
                    }}
                  >
                    Customer Name
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "180px",
                    }}
                  >
                    Mobile
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "147px",
                    }}
                  >
                    Invoice Total
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                    Source
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                    Address
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                    Invoice Date
                  </th>
                </tr>
              </thead>
              <tbody className="tb">
                {items_data?.length > 0 && items_data ? (
                  items_data?.map((category, index) => (
                    <tr className="table-row " key={category?.slug}>
                      <td>
                        <p className="ms-3 my-auto">{category.number}</p>
                      </td>
                      <td>
                        <p className="ms-3 my-auto">{category.voucher_code}</p>
                      </td>
                      <td>{category.bill_to}</td>
                      <td>{category.to_mobile}</td>

                      <td>৳{category.total_amount}</td>
                      <td>{category.created_from}</td>

                      <td className="p-1">{category?.to_address}</td>
                      <td className="p-1">{category?.invoice_date?.split('T')[0]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="9">
                      <div className="not_found d-flex justify-content-center align-items-center py-4">
                        <h4 className="my-4">No Data Found</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* entites and paggination */}
          {totalData > 0 && (
            <div className="row pag" style={{ marginTop: "30px" }}>
              <div className="col-6 col-md-5">
                <p
                  className="inter"
                  style={{ color: "#AEAEB2", fontSize: "16px" }}
                >
                  Total Entries:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {totalData}
                  </span>
                  <span className="ms-2"></span>
                  Total Page:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {Math.ceil(totalData / 20)}
                  </span>
                </p>
              </div>
              {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
              <div className="col-12 col-md-6 d-flex paggination-button">
                <>
                  <div className="row lead_pagination bg-transparent">
                    <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                      <nav aria-label="...">
                        <ul className="pagination pagination-sm">
                          {!isLoading ? (
                            <li className="page-item ">
                              {prevUrl !== null ? (
                                <Link
                                  onClick={handlePaginationPrevious}
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast(
                                      "success",
                                      "You are on first page"
                                    )
                                  }
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item ">
                              <Link
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            </li>
                          )}

                          {[...Array(pageCount === null ? 1 : pageCount)].map(
                            (elem, index) => (
                              <li
                                className="page-item"
                                style={{
                                  display: `${
                                    index + 1 !== pageNumber ? "none" : "block"
                                  }`,
                                }}
                              >
                                {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                                {/* setPageNumber(prev=> prev - 1) */}

                                <Link
                                  onClick={() =>
                                    handleChangeCurrentPage(index + 1)
                                  }
                                  className={`${
                                    index + 1 == pageNumber
                                      ? "paginationActive"
                                      : ""
                                  } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                  to="#"
                                >
                                  <span className="px-1 py-1">
                                    {pageNumber}
                                  </span>
                                </Link>
                              </li>
                            )
                          )}
                          {!isLoading ? (
                            <li className="page-item">
                              {nextUrl !== null ? (
                                <Link
                                  onClick={handlePaginationNext}
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />{" "}
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast("success", "You are on last page")
                                  }
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item">
                              <Link
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <AddresTopLow/> */}
    </>
  );
};

export default ViewVoucher;
