import React from "react";
import { BASE_URL } from "../Const/Url";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../CustomCommons/Loader";
import { useEffect } from "react";
import { logout_func2 } from "../Const/logoutCommon";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import plus from "../../assets/Icon/plus.svg";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import NextIcon from "../CustomCommons/NextIcon";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import ViewIcon from "../CustomCommons/ViewIcon";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";

const WorkWithUs = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [checkedIds, setCheckedIds] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [query, setQuery] = useState("");
  const limit = 20;
  const [isLoading, SetisLoading] = useState(true);
  const history = useHistory();
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    apiFunction(
      `${BASE_URL}api/v1/utility/admin/contact-us/?contact_type=1&search=${query}`
    );
  }, [query]);
  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  console.log(items_data, "offer");
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  if (isLoading) {
    return <Loader />;
  }
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/utility/admin/contact-us/${id}/`;

        axios
          .delete(url)
          .then(() => {
            showToast("Delele", "Data Deleted");
            apiFunction(
              `${BASE_URL}api/v1/utility/admin/contact-us/?contact_type=1`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.id);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/utility/admin/contact-us/${id}/`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([]);
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
          })
          .finally(() => {
            apiFunction(
              `${BASE_URL}api/v1/utility/admin/contact-us/?contact_type=1`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  return (
    <div className="categorey-parent">
      <div className="cotagorey-head mb-4">
        <div className="">
          <h3>Work With Us </h3>
          <input
            style={{ color: "black" }}
            type="text"
            className="filter-input"
            placeholder="Search here"
            onChange={(e) => setQuery(e.target?.value)}
          />
        </div>
        <div className="filter_div "></div>
        {checkedIds?.length > 0 && (
        <>
          <div className="my-2 d-flex ">
            <button
              className="btn text-light me-2"
              style={{
                background: "black",
                cursor: "pointer",
                maxWidth: "160px",
                color: "white",
              }}
              onClick={() => deleteDataMulti(checkedIds)}
            >
              Delete
            </button>
          </div>
        </>
      )}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my pdf-table my-3">
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px" }}
            >
              <tr className="">
              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "120px",
                  // display:'flex',
                  // justifyContent:'center',
                  margin: "center",
                }}
              >
                <div className="d-flex justify-content-center">
                  <input
                    style={{
                      margin: "0 auto",
                      opacity: "1",
                      width: "17px",
                      height: "17px",
                    }}
                    type="checkbox"
                    checked={checkedIds?.length > 0}
                    onChange={(event) => handleCheckboxChangeAll(event)}
                  />
                </div>
              </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    textAlign: "center",
                  }}
                >
                  <p className="my-auto">ID</p>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Mobile
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Inquery Type
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Messege
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Created At
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Download
                </th>

                <th
                  className="action"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "135px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((offer, index) => (
                  <tr className="table-row " key={offer?.slug}>
                    <td>
                    <div className="d-flex justify-content-center">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        style={{ width: "17px", height: "17px" }}
                        checked={checkedIds.includes(offer.id)}
                        onChange={() => handleCheckboxChange(offer.id)}
                      />
                    </div>
                  </td>
                    <td className="text-center">{offer?.id}</td>
                    <td>{offer?.name}</td>
                    <td>{offer?.email}</td>
                    <td>{offer?.mobile}</td>
                    <td>{offer?.inquiry_type === 0 ? "Urgent" : "Normal"}</td>

                    <td>{offer?.message}</td>
                    <td>
                      {new Date(offer.created_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>
                      {
                        offer?.document_details?.document ? <a href={offer?.document_details?.document} target="_blank">
                        Download
                      </a> : <span>Download</span>
                      }
                      
                    </td>
                    <td className="action">
                      <div className="d-flex justify-content-center gap-3">
                      <div
                          className="edit-del red"
                          onClick={() => {
                            setSingleData(offer);
                            setModalShow(true);
                          }}
                        >
                          <ViewIcon />
                        </div>
                        <div
                          className="edit-del red"
                          onClick={() => deleteData(offer?.id)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="8">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* paggination */}
      {totalData > 0 && (
        <div className="row pag" style={{ marginTop: "30px" }}>
          <div className="col-6 col-md-5">
            <p className="inter" style={{ color: "#AEAEB2", fontSize: "16px" }}>
              Total Entries:{" "}
              <span style={{ color: "black", fontSize: "14px" }}>
                {totalData}
              </span>
              <span className="ms-2"></span>
              Total Page:{" "}
              <span style={{ color: "black", fontSize: "14px" }}>
                {Math.ceil(totalData / 20)}
              </span>
            </p>
          </div>

          <div className="col-12 col-md-6 d-flex paggination-button">
            <>
              <div className="row lead_pagination bg-transparent">
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm">
                      {!isLoading ? (
                        <li className="page-item ">
                          {prevUrl !== null ? (
                            <Link
                              onClick={handlePaginationPrevious}
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          ) : (
                            <Link
                              onClick={() =>
                                showToast("success", "You are on first page")
                              }
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li className="page-item ">
                          <Link
                            className={` ${
                              prevUrl === null ? "disabled" : ""
                            } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                            to="#"
                            tabindex="-1"
                          >
                            <PreviousIcon />
                          </Link>
                        </li>
                      )}

                      {[...Array(pageCount === null ? 1 : pageCount)].map(
                        (elem, index) => (
                          <li
                            className="page-item"
                            style={{
                              display: `${
                                index + 1 !== pageNumber ? "none" : "block"
                              }`,
                            }}
                          >
                            {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                            {/* setPageNumber(prev=> prev - 1) */}

                            <Link
                              onClick={() => handleChangeCurrentPage(index + 1)}
                              className={`${
                                index + 1 == pageNumber
                                  ? "paginationActive"
                                  : ""
                              } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                              to="#"
                            >
                              <span className="px-1 py-1">{pageNumber}</span>
                            </Link>
                          </li>
                        )
                      )}
                      {!isLoading ? (
                        <li className="page-item">
                          {nextUrl !== null ? (
                            <Link
                              onClick={handlePaginationNext}
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />{" "}
                            </Link>
                          ) : (
                            <Link
                              onClick={() =>
                                showToast("success", "You are on last page")
                              }
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li className="page-item">
                          <Link
                            className={`${
                              nextUrl === null ? "disabled" : ""
                            } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                            to="#"
                          >
                            <NextIcon />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
      {/* modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        style={{ borderRadius: "none" }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            View Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>Offer Name</p>
              <InputGroup className="mb-3" style={{ height: "47px" }}>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="name"
                  value={singleData?.name}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <p>Email</p>
              <InputGroup className="mb-3" style={{ height: "47px" }}>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="name"
                  value={singleData?.email}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <p>Mobile</p>
              <InputGroup className="mb-3" style={{ height: "47px" }}>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="name"
                  value={singleData?.mobile}
                />
              </InputGroup>
            </div>
            <div className="col-12 ">
              <p>Messege</p>
              <InputGroup className="mb-3" style={{ height: "70px" }}>
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="name"
                  value={singleData?.message}
                />
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(!true)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WorkWithUs;
