import React, { useState } from "react";
import Select from "react-select";

const SelectCustomType = ({
  options,
  setSelectedOption,
  styles,
  defaultValue,
}) => {
    const handleOption = (newVal)=>{
        console.log(newVal,'nv');  // new selected value
        setSelectedOption(newVal.value);
    }
  return (
    <Select
      styles={styles}
      defaultValue={defaultValue}
      placeholder="---Select---"
      isClearable
      onChange={handleOption}
      options={options}
    />
  );
};

export default SelectCustomType;
