import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import eye from "./../../../assets/Icon/eye.svg";

import * as path from "../../Routes/RoutePaths";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Loader from "../../CustomCommons/Loader";
// import ProductPaggination from "./ProductPaggination";
import { showToast } from "../../../utils/ToastHelper";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { logout_func2, logout_func3 } from "../../Const/logoutCommon";
import { CheckCircle } from "phosphor-react";

const ViewMatching = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const { id } = useParams();
  const [festivalName, setFestivalName] = useState("");
  const [query, setQuery] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [productList, setProductList] = useState([]);
  const [multiImg, setMultiImage] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [matchingType, setMatchingType] = useState(0);
  const [inputQueryExPd, setInputQueryExPd] = useState("");
  const [excludeProduct, setExcludeProduct] = useState([]);
  const [productListExPd, setProductsListExPd] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data, "161");
        const result = res.data.data;
        setFestivalName(result.title);
        setMatchingType(result.type);
        setCategoryswitch(result?.is_active);
        setMultiImage([
          { id: res.data.data.thumb, url: res.data.data?.thumb_url },
        ]);
        const exPd = result?.exclude_products_details;
        setExcludeProduct(exPd);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  const getProducts2 = (url) => {
    // const url = `${BASE_URL}api/v1/users/inventory/notifications/?limit=50`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        setProductList(result);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const getProducts = (url) => {
    // const url = `${BASE_URL}api/v1/users/inventory/notifications/?limit=50`;
    axios
      .get(url)
      .then((res) => {
        setIsLoading(true);
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        // setNotificationList([...notificationList,result]);
        setProductList([...productList, ...result]);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func3(history);
        }
      });
  };
  const loadMore = () => {
    getProducts(nextUrl);
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}/api/v1/inventory/inventory/matching-products/${id}`
    );
    getProducts2(
      `${BASE_URL}/api/v1/inventory/inventory/matching-products/${id}/product_view/?limit=20`
    );
  }, []);
  useEffect(() => {
    let timeoutId;
    const delayedFetch = () => {
      timeoutId = setTimeout(() => {
        getProducts2(
          `${BASE_URL}/api/v1/inventory/inventory/matching-products/${id}/product_view/?limit=20&query=${query}`
        );
      }, 1000); //
    };
    delayedFetch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  const toggleProductSelection = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const index = prevSelectedProducts.findIndex(
        (item) => item.id === product.id
      );
      if (index === -1) {
        // Product not found, add it to selectedProducts
        return [...prevSelectedProducts, product];
      } else {
        // Product found, remove it from selectedProducts
        return prevSelectedProducts.filter((item) => item.id !== product.id);
      }
    });
  };

  const prevExclude = excludeProduct
    ? excludeProduct?.map((item) => item.id)
    : [];
  const newExclude = selectedProducts
    ? selectedProducts?.map((item) => item.id)
    : [];
  const combine = [...prevExclude, ...newExclude];
  console.log(
    {
      selectedProducts: selectedProducts,
      excludeProduct: excludeProduct,
      combine: combine,
    },
    "140"
  );
  const updateMatching = () => {
    const url = `${BASE_URL}/api/v1/inventory/inventory/matching-products/${id}/`;
    const dataFormat = {
      exclude_products: combine ? combine : [],
    };
    setLoading(true);
    axios
      .patch(url, dataFormat)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Matcing Updated.");
          history.push(path.matching_list);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  if (loading === true) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Matching Product
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <p>Matching Type</p>
          <h5>{matchingType === 0 ? "Desgin" : "Color"}</h5>
          <p>Title</p>
          <h5>{festivalName}</h5>

          <div className="col-12 row justify-content-between">
            {productList && (
              <>
                <h5 className="mt-3 "> Products</h5> <br />
                <span className="my-1 ">
                  Please choose the product you wish to exclude.
                </span>
              </>
            )}

            {selectedProducts?.length > 0 && (
              <>
                <div className="pg-div my-3 d-flex  gap-3">
                  <button
                    onClick={updateMatching}
                    className="px-3 py-2 "
                    style={{
                      border: "1px solid black",
                      outline: "none",
                      background: "black",
                      color: "white",
                      borderRadius: "6px",
                    }}
                  >
                    Save Exclude
                  </button>
                </div>
              </>
            )}

            <div className="cotagorey-head mb-4">
              <div className="">
                <h3>Search </h3>
                <input
                  style={{ color: "black" }}
                  type="text"
                  className="filter-input"
                  placeholder="Search here"
                  onChange={(e) => setQuery(e.target?.value)}
                />
              </div>
              <div className="filter_div "></div>
            </div>

            {productList &&
              productList?.map((data) => (
                <>
                  <div
                    key={data?.id}
                    className="col-md-3  col-6 "
                    onClick={() => toggleProductSelection(data)}
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    <div className="card mb-3" style={{ maxWidth: "300px" }}>
                      <div className="row no-gutters">
                        <div className="col-md-4">
                          <img
                            src={data?.thumb_url}
                            className="card-img m-1 img-fluid"
                            style={{ objectFit: "contain", width: "100%" }}
                            alt="..."
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5 className="card-title">
                              {data?.name?.length > 15
                                ? `${data?.name.slice(0, 15)}...`
                                : `${data?.name}`}
                            </h5>
                            <span
                              style={{ fontSize: "12px" }}
                              className="card-text"
                            >
                              SKU : {data?.sku}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {selectedProducts.find(
                      (selectedProduct) => selectedProduct.id === data.id
                    ) ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "20px",
                        }}
                      >
                        <CheckCircle />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ))}
          </div>
          <div className="pg-div my-3 d-flex justify-content-center gap-3">
            {prevUrl !== null && (
              <button
                onClick={() =>
                  getProducts2(
                    `${BASE_URL}/api/v1/inventory/inventory/matching-products/${id}/product_view/?limit=20`
                  )
                }
                className="px-3 py-2 "
                style={{
                  border: "1px solid black",
                  outline: "none",
                  background: "black",
                  color: "white",
                  borderRadius: "6px",
                }}
              >
                Show Less
              </button>
            )}

            {nextUrl !== null && (
              <button
                onClick={loadMore}
                className="px-3 py-2 "
                style={{
                  border: "1px solid black",
                  outline: "none",
                  background: "black",
                  color: "white",
                  borderRadius: "6px",
                }}
              >
                Show More
              </button>
            )}
          </div>

          <div className="col-12 row ">
            {excludeProduct && <h5 className="my-3">Exclude Products</h5>}

            {excludeProduct &&
              excludeProduct?.map((data) => (
                <>
                  <div key={data?.id} className="col-md-3 col-6">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row no-gutters">
                        <div className="col-md-4">
                          <img
                            src={data?.thumb_url}
                            className="card-img m-1 img-fluid"
                            style={{ objectFit: "contain", width: "100%" }}
                            alt="..."
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5 className="card-title">
                              {data?.name?.length > 15
                                ? `${data?.name.slice(0, 15)}...`
                                : `${data?.name}`}
                            </h5>
                            <span
                              style={{ fontSize: "12px" }}
                              className="card-text"
                            >
                              SKU : {data?.sku}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewMatching;
