import React, { useEffect, useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import Select from "react-select";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../../utils/ToastHelper";
import { logout_func3 } from "../../Const/logoutCommon";
import { useHistory } from "react-router-dom";
import { selectStyle } from "./CustomStyle";

const CreateWithPathao = ({
  setPathaoCity,
  pathaoCity,
  setPathaoZone,
  pathaoZone,
  setPathaoArea,
  pathaoArea,
  mainState,
  setCustomCharge,
  subTotal=0
}) => {
  const [cityList, setCityList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const history = useHistory();
  
  
  const getNestedData = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const getData = (url, setState, details) => {
    axios
      .get(url)
      .then((res) => {
        const result = getNestedData(res, details.res);
        if (result) {
          const options = result.map((curr) => ({
            label: curr[details.label],
            value: curr[details.value],
          }));
          setState(options);
        } else {
          console.error("Result is undefined or path is incorrect", result);
        }
      })
      .catch((err) => {
        console.error("Error fetching data", err);
      });
  };
  useEffect(() => {
    getData(`${BASE_URL}api/v1/utility/admin/pathao/cities/`, setCityList, {
      res: "data.data.data.data",
      label: "city_name",
      value: "city_id",
    });
  }, []); // This useEffect will only run once when the component mounts
  
  useEffect(() => {
    if (pathaoCity?.value) {
      getData(
        `${BASE_URL}api/v1/utility/admin/pathao/cities/${pathaoCity.value}/zones/`,
        setZoneList,
        {
          res: "data.data.data.data",
          label: "zone_name",
          value: "zone_id",
        }
      );
    }
  }, [pathaoCity]); // This useEffect will only run when pathaoCity changes
  
  useEffect(() => {
    if (pathaoZone?.value) {
      getData(
        `${BASE_URL}api/v1/utility/admin/pathao/zones/${pathaoZone.value}/areas/`,
        setAreaList,
        {
          res: "data.data.data.data",
          label: "area_name",
          value: "area_id",
        }
      );
    }
  }, [pathaoZone]); 

  const handleCity = (newVal) => {
    setPathaoCity(newVal);
  };
  const handleZone = (newVal) => {
    setPathaoZone(newVal);
  };
  const handleArea = (newVal) => {
    setPathaoArea(newVal);
  };
  function processData(data) {
    const result = [];
    // Iterate through each item in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const item = data[key];
        const id = item.id;
        const variants = item.variant;

        // Sum the quantities greater than 0 in the variants array
        const totalQuantity = variants.reduce((sum, variant) => {
          const quantity = parseInt(variant.quantity, 10);
          return quantity > 0 ? sum + quantity : sum;
        }, 0);

        // Push the result in the desired format
        result.push({
          id: id,
          quantity: totalQuantity,
        });
      }
    }

    return result;
  }
  
  const getPathaoDeliveryCrg = async() => {
    const productData = await processData(mainState);
    console.log(mainState,'mainState pathao');
    const url = `${BASE_URL}api/v1/utility/admin/pathao/price-calculate/`;
    const data = {
      products_data: productData,
      recipient_city: pathaoCity.value,
      recipient_zone: pathaoZone.value,
      products_subtotal: subTotal
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
        //   showToast("success", "Delivery Charge Calculated.");
          console.log("Delivery Charge", res.data?.data.delivery_charge);
          setCustomCharge(res.data?.data.delivery_charge ? res.data?.data.delivery_charge : 0);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          logout_func3(history);
        }
      });
  };
  useEffect(() => {
    if (pathaoZone.value) {
      getPathaoDeliveryCrg();
    }
  }, [pathaoZone]);
  return (
    <>
      <div className="col-12 col-md-6">
        <RequiredLabel text="To City" />
        <Select
          onChange={handleCity}
          options={cityList}
          value={!pathaoCity?.value ? [] : pathaoCity}
          placeholder="Select City"
          styles={selectStyle}
        />
      </div>
      <div className="col-12 col-md-6 mt-2">
        <RequiredLabel text="To Zone" />
        <Select
          onChange={handleZone}
          options={zoneList}
          value={!pathaoZone?.value ? [] : pathaoZone}
          placeholder="Select Zone"
          styles={selectStyle}
        />
      </div>
      <div className="col-12 col-md-6 mt-2">
        <RequiredLabel text="To Area" />
        <Select
          onChange={handleArea}
          options={areaList}
          value={!pathaoArea?.value ? [] : pathaoArea}
          placeholder="Select Area"
          styles={selectStyle}
        />
      </div>
    </>
  );
};

export default CreateWithPathao;
