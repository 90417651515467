import Header from "./components/navbar/components/header/Header";
import SideNav from "./components/navbar/SideNav";
import "./App.css";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BASE_URL } from "./components/Const/Url";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
const App = () => {
  //checks token valid or not
  const location = useLocation();
  const current_location = location.pathname;

  const history = useHistory();

  const check_token = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message);
        if (message === "Invalid token.") {
          // localStorage.setItem("expired", true);
          localStorage.removeItem("is_logged_in");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userData");
          swal("Login expired!", "Please login again..", "warning");
          history.push("/login");
        }
      });
  };
  // useEffect(() => {
  //   if (current_location.includes("login")) {
  //     console.log("include");
  //   } else {
  //     check_token();
  //   }
  // }, [location.pathname]);
  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("prodDashCurrentUrl");
    localStorage.removeItem("CatDashQuery");
    localStorage.removeItem("renkingDashCat");
    localStorage.removeItem("catDeshOrdering");
  };
  const removeHomeDecorLocalStorage = () => {
    localStorage.removeItem("homeDecorCurrentUrl");
    localStorage.removeItem("homeDecorQuery");
    localStorage.removeItem("renkingHomeDecor");
  };
  const removeCategoryListLocalStorage = () => {
    localStorage.removeItem('categoryListCurrentUrl');
    localStorage.removeItem('categoryListOrdering');
    localStorage.removeItem('categoryListQuery');
    localStorage.removeItem('categoryPageNbr');
  };
  const currentPath = window.location.pathname;
  useEffect(() => {
    // Check if the URL is not /product/categories/:id/:id2
    // const handleNavigation = () => {
    const currentPath = window.location.pathname;
    if (!currentPath.startsWith("/product/categories/")) {
      removeDataFromLocalStorage();
      console.log("Calling removeDataFromLocalStorage");
    }
    //  else if (
    //   !currentPath.startsWith("/inventory/edit-product/") ||
    //   !currentPath.startsWith("/inventory/view-product/")
    // ) 
    // {
    //   console.log(
    //     !currentPath.startsWith("/inventory/edit-product/"),
    //     "removeDataFromLocalStorage"
    //   );
    //   removeHomeDecorLocalStorage();
    //   console.log("Calling removeDataFromLocalStorage11");
    // } 
    
    // };

    // window.addEventListener('popstate', handleNavigation);

    // // Clean up the event listener when the component unmounts
    return () => {
      // window.removeEventListener('popstate', handleNavigation);
    };
  }, [currentPath]);
  // useEffect(() => {
  //   // Check if the URL is not /product/categories/:id/:id2
  //   // const handleNavigation = () => {
  //   const currentPath = window.location.pathname;
  //   if (
  //     !currentPath.startsWith("/inventory/edit-product/") ||
  //     !currentPath.startsWith("/inventory/view-product/")
  //   ) {
  //     console.log(
  //       !currentPath.startsWith("/inventory/edit-product/"),
  //       "removeDataFromLocalStorage"
  //     );
  //     removeHomeDecorLocalStorage();
  //     console.log("Calling removeDataFromLocalStorage11");
  //   } 
  //   // };

  //   // window.addEventListener('popstate', handleNavigation);

  //   // // Clean up the event listener when the component unmounts
  //   // return () => {
  //   //   window.removeEventListener('popstate', handleNavigation);
  //   // };
  // }, [currentPath]);
  useEffect(() => {
    const currentPath = window.location.pathname;

    // Define a regex pattern for matching paths
    const pathRegex = /^\/inventory\/(edit-product|product-view)\//;
    // Check if the currentPath does not match the pattern
    if (!pathRegex.test(currentPath)) {
      console.log("removeDataFromLocalStorage");
      removeHomeDecorLocalStorage();
      console.log("Calling removeDataFromLocalStorage11");
    }

    // Clean up the event listener when the component unmounts
    return () => {
      // You might want to remove any event listeners or perform cleanup here
    };
}, [currentPath]);

  

  return (
    <>
      <SideNav />
      <ToastContainer  />
    </>
  );
};

export default App;
