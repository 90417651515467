import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import eye from "./../../../assets/Icon/eye.svg";

import * as path from "../../Routes/RoutePaths";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Loader from "../../CustomCommons/Loader";
// import ProductPaggination from "./ProductPaggination";
import { showToast } from "../../../utils/ToastHelper";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";

const FestivalList = () => {
  const [festivalName, setFestivalName] = useState("");
  const [loading, setLoading] = useState(true);
  const [multiImg, setMultiImage] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const uploadMultiple = (e) => {
    console.log(e.target.files);
    const images = e.target.files;
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
  
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const data1 = new FormData();
      data1.append("document", file);
      data1.append("doc_type", 0);
  
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setMultiImage([
                  ...multiImg,
                  { id: res.data.data.id, url: res.data.data?.doc_url },
                ]);
              }
            };
            showToast("success", "image uploaded");
          }
        })
        .catch((error) => {
          // showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  console.log(multiImg,'57')
  const removeMultiImg = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // setURL("");
        // setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        const newArray = multiImg?.filter((imges) => imges?.id !== id);
        setMultiImage(newArray);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data.data;
        setFestivalName(data?.name)
        setCategoryswitch(data?.is_active)
        const data2 = data?.images_details?.map((item) => {
          return { id: item.id, url: item.doc_url };
        });
        setMultiImage(data2 ? data2 : []);

        setLoading(false);
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err.request.response).code === 401
        // ) {
        // }
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/promotions/admin/festival/`;
    apiFunction(url);
  }, []);


  const updateFestival = () => {
    const url = `${BASE_URL}/api/v1/promotions/admin/festival/`;
    const dataFormat = {
      name: festivalName,
      is_active: Categoryswitch,
      images: multiImg?.map((img) => img?.id),
    };
    setLoading(true)
    axios
      .post(url, dataFormat)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Festival Updated.");
          apiFunction(`${BASE_URL}/api/v1/promotions/admin/festival/`);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  if (loading === true) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Festival</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Festival Title"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={festivalName}
                onChange={(e) => setFestivalName(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="col-12  ">
            <h5 style={{ marginTop: "30px" }}>Add image </h5> <span></span>
            <p style={{ marginTop: "10px", color: "red" }}>
              NB: You can add multiple banner image its not mandatory.The actual
              size of the uploaded images will be displayed on the website.{" "}
            </p>
            <p style={{ color: "#8E8E93" }}>
              ( Select your file & upload one by one )
            </p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                {" "}
                {" File Choosen"}
              </p>

              <div className="file-up">
                <input
                  type="file"
                  onChange={uploadMultiple}
                  // multiple
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {multiImg?.length > 0 &&
                multiImg?.map((data) => (
                  <>
                    <div className="my-2" key={data?.id}>
                      <img height={90} width={90} src={data?.url} alt="" />{" "}
                      <br />
                      <button
                        onClick={() => removeMultiImg(data?.id)}
                        className="remove-btn btn btn-small mt-1  rounded"
                        style={{ border: "1px solid gray !important" }}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ))}
            </div>
          </div>
          <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={updateFestival}
            >
              Save
            </button>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default FestivalList;
