import React, { useRef } from "react";

import InputGroup from "react-bootstrap/InputGroup";

import Form from "react-bootstrap/Form";
import eye from "./../../../assets/Icon/eye.svg";

import "../product/Product.css";
import { useState } from "react";
import { PlusCircle, X, XCircle } from "phosphor-react";

import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { DropzoneArea } from "material-ui-dropzone";
import * as path from "../../Routes/RoutePaths";
import {
  AttachFile,
  AudioTrack,
  Description,
  PictureAsPdf,
  Theaters,
} from "@material-ui/icons";
import swal from "sweetalert";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import SelectDropDown from "../../CustomCommons/SelectDropDown";
import { useEffect } from "react";
import { handleInputs } from "../../../utils/HandleInputs";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";

import { can_add_products, has_permissions } from "../../CustomCommons/utils";
import Permission from "../../CustomCommons/Permission";
import { logout_func2, logout_func3 } from "../../Const/logoutCommon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AddComboProduct = () => {
  const { id } = useParams();
  const dropzoneRef = useRef(null);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const history = useHistory();
  const [inputQuery, setInputQuery] = useState("");
  const [productList, setProductsList] = useState([]);
  const [foundProduct, setFoundProduct] = useState(null);
  const [attribute, setAttribute] = useState([
    { value: "Color", label: "Color" },
    { value: "Size", label: "Size" },
  ]);
  const [attributesCom, setAttributeCom] = useState([]);
  const [colorOption, setColorOption] = useState(["Red", "Red"]);
  const [sizeOption, setSizeOption] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  // state
  const [mainState, setMainState] = useState({});
  // state

  const [optional, setOptional] = useState([]);
  const [fileLenth, setFilelength] = useState(null);
  const [URL, setURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [URL2, setURL2] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [URL3, setURL3] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const [categoryList, setcategoryList] = useState([]);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);
  const [atttibutesList, setAtttibutesList] = useState([]);
  const [status, setStatus] = useState(null);
  const [Dropzoneimages, setDropzoneimages] = useState(null);
  const [multiId, setMultiId] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [isTrend, setIsTrend] = useState(true);
  const [mainCategory, setMainCategory] = useState(0);
  const [tags, setTegs] = useState(null);
  const [metaTags, setMetaTegs] = useState(null);

  const [productAllData, SetproductAllData] = useState({
    product_name: "",
    product_description: "",
    product_tax: 0,
    product_vat: 0,
    product_discount: 0,
    product_quantity: 0,
    product_price: 0,
    sku: "",
    // new field
    vendor_info: "none",
    about_brand: "none",
    shipping_info: "none",
    meta_desc: "",
  });

  const [AttributesInputValue, SetAttributesInputValue] = useState({});

  const [SubmittedProduct, SetSubmittedProduct] = useState(0);

  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [Thumb2, setThumb2] = useState(null);
  const [Thumb3, setThumb3] = useState(null);
  const [thumbId2, setThumbId2] = useState(null);
  const [thumbId3, setThumbId3] = useState(null);
  const [itemData, setitemData] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [isShowWebsite, setIsShowWebsite] = useState(true);
  const [reload, setReload] = useState(false);

  // ===========combo functions=====================
  // const [isCombo, setIsCombo] = useState(true);
  const [comboType, setComboType] = useState(0);
  const [comboConnectedProducts, setComboConnectedProducts] = useState([]);

  // const handleCheckboxChange = (event) => {
  //   setIsCombo(event.target.checked);
  // };
  const handleComboType = (event) => {
    setComboType(parseInt(event.target.value));
  };
  // ===========combo functions=====================

  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);

    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const fileHandle2 = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb2(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId2(res.data.data.id);
            setFileName2(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL2(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL2(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const fileHandle3 = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb3(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId3(res.data.data.id);
            setFileName3(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL3(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL3(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const getCategory = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=2&limit=100&main_category=${
      Array.isArray(mainCategory) === true
        ? mainCategory[0]?.value
        : mainCategory
    }&is_combo_category=true`;

    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        // console.log(options,'options')
        setcategoryList(options);
        setSelectedOptionCategory(options[0]?.value);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/?query=${inputQuery}`;
      }
      axios
        .get(url)
        .then((res) => {
          const result = res.data.data.results;

          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            category: curr?.category[0],
            main_category: curr?.main_category,
            is_new_arrival: curr?.is_new_arrival,
            is_show_website: curr?.is_show_website,
            is_trending: curr?.is_trending,
            description: curr?.description,
            name: curr.name,
            price: curr?.price,
            category_details: curr?.category_details,
            tag_details: curr?.tag_details,
          }));
          console.log(options, "result");
          setProductsList(options);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };

  const GetTotalQuantity = () => {
    var totalQuantity = 0;
    for (let i in AttributesInputValue) {
      console.log(i);
      console.log(AttributesInputValue[i]);
      totalQuantity += parseInt(AttributesInputValue[i]["variant_stock"]);
    }
    return isNaN(totalQuantity) === true ? 0 : totalQuantity;
  };

  const getAttributeList = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/attributes/`;

    axios
      .get(url)
      .then((res) => {
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        console.log(options, "option");
        const newData = options.filter((item) => item.label !== "Default");
        setAtttibutesList(newData);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
      });
  };

  const clearData = () => {
    SetproductAllData({
      product_name: "",
      product_description: "",
      product_tax: "0.00",
      product_vat: "00.0",
      product_discount: "",
      product_quantity: "",
      product_price: "",
    });
    setURL("");
    setFileName("");
    setSelectedOptionCategory(null);
    setDropzoneimages(null);
    setAttributeCom([]);
    setMainState({});
  };

  function findAttributeKeyWithValue(data, value) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let values = data[key];

        if (values.includes(value)) {
          const data = attributesCom?.filter((data) => data?.label === key);

          return data[0]?.value;
        }
      }
    }

    return null; // Return null if the value is not found
  }
  const data = attributesCom?.filter((data) => {
    if (data?.label === "Size") return data?.value;
  });
  console.log(data, "221");

  function convertToObjectArray(obj) {
    let result = [];

    for (let variantName in obj) {
      if (obj.hasOwnProperty(variantName)) {
        let variant = obj[variantName];
        variant.variant_name = variantName;

        // Split the variant name using '-'
        let parts = variantName.split("~");
        // Create the attribute_value array dynamically
        variant.attribute_value = parts.map((part, index) => ({
          attribute: findAttributeKeyWithValue(mainState, part),
          value: part,
        }));

        result.push(variant);
      }
    }

    const newArray = result.map((obj) => ({
      price: obj?.variant_price ? Number(obj?.variant_price) : 0,
      name: obj.variant_name,
      stock:
        obj?.variant_stock !== null || obj?.variant_stock !== undefined
          ? Number(obj?.variant_stock)
          : null,
      is_active:
        Categoryswitch === true
          ? obj?.variant_action === false
            ? obj?.variant_action
            : true
          : false,
      attribute_value: obj.attribute_value,
      images: obj.images,
    }));

    return newArray;
  }

  const PostVariants = (productID) => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products-variant/`;
    console.log("AttributesInputValue", AttributesInputValue);

    for (let item in AttributesInputValue) {
      const data = new FormData();

      if (AttributesInputValue[item]["variant_action"] === undefined) {
        data.append("is_active", true);
      } else {
        data.append("is_active", AttributesInputValue[item]["variant_action"]);
      }

      if (
        AttributesInputValue[item]["variant_price"] === undefined ||
        AttributesInputValue[item]["variant_price"] === ""
      ) {
        // showToast("error", `${item} Variant Price is empty `);
        data.append("price", 0);
        // data.append("price", parseInt(productAllData["product_price"]) + 0);
      } else {
        data.append(
          "price",
          parseInt(AttributesInputValue[item]["variant_price"])
        );
        // data.append("price", parseInt(productAllData["product_price"]) + parseInt(AttributesInputValue[item]["variant_price"]));
      }

      // if (AttributesInputValue[item]["variant_stock"] === undefined) {
      //   showToast("error", `${item} Variant Stock is empty `);
      // } else {
      data.append("stock", AttributesInputValue[item]["variant_stock"]);
      // }

      data.append("variant", item);
      data.append("product", productID);

      // data.append("product", SubmittedProduct);
      // data.append("product", SubmittedProduct["product"]);

      console.log(data);
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            // showToast("success", "product variant added");
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          // const errorMsg = JSON.parse(err.request.response).errors;
          // for (let value of Object.values(errorMsg)) {
          //   showToast("error", value[0]);
          // }
          showToast("error", "err.message");
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        setThumb(null);
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeImg2 = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL2("");
        setFileName2("");
        setThumb2(null);
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeImg3 = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL3("");
        setFileName3("");
        setThumb3(null);
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const ValidateData = () => {
    console.log("selectedOptionCategory.value", selectedOptionCategory.value);
    console.log("selectedOptionCategory", selectedOptionCategory);
    // if (productAllData["product_name"] === "" || foundProduct?.product_name) {
    //   showToast("error", "Product Name can't be empty..");
    //   return 0;
    // }

    // else if (
    //   selectedOptionCategory === null ||
    //   selectedOptionCategory === undefined ||
    //   selectedOptionCategory.value === null ||
    //   selectedOptionCategory.value === undefined
    // ) {
    //   showToast("error", "Category can't be empty..");
    //   return 0;
    // }
    if (
      productAllData["product_price"] === "" ||
      productAllData["product_price"] === 0
    ) {
      showToast("error", "Price  can't be empty..");
      return 0;
    }

    if (productAllData["sku"].length < 1) {
      showToast("error", "SKU can not be empty!");
      return 0;
    }

    // if (isEmpty(AttributesInputValue)) {
    //   showToast("error", `No attribute added `);
    //   return 0;
    // }
    for (let item in AttributesInputValue) {
      console.log("AttributesInputValue[item]", AttributesInputValue[item]);
      if (
        isEmpty(
          AttributesInputValue[item] ||
            AttributesInputValue[item]["variant_stock"] === null
        )
      ) {
        showToast("error", `${item} Variant Price,Stock is empty `);
        return 0;
      }

      if (
        AttributesInputValue[item]["variant_stock"] === undefined ||
        AttributesInputValue[item]["variant_stock"] === null
      ) {
        showToast("error", `${item} Variant Stock is empty `);
        return 0;
      }
    }

    // if (Thumb === null) {
    //   showToast("error", "Thumbnail can't be empty..");
    //   return 0;
    // }

    postProduct();
  };

  const getVariantAction = (item) => {
    return AttributesInputValue[item] !== undefined &&
      AttributesInputValue[item]["variant_action"] !== null
      ? AttributesInputValue[item]["variant_action"]
      : true;
  };
  console.log(productAllData?.name);
  console.log(Dropzoneimages, "dd");
  function handleClearFiles() {
    dropzoneRef.current.clearFiles();
  }
  // const uploadMulti = (data) => {
  //   console.log(data, "image Data inside");
  //   const url = `${BASE_URL}api/v1/auth/documents/upload/`;
  //   const uploadPromises = data.map((curr, index) => {
  //     const data1 = new FormData();
  //     data1.append("document", curr);
  //     data1.append("doc_type", 0);
  //     data1.append("order", index);
  //     return axios.post(url, data1);
  //   });

  //   // Use Promise.all to wait for all uploads to complete
  //   Promise.all(uploadPromises)
  //     .then((responses) => {
  //       const newMultiId = responses
  //         .filter((res) => res.data.status)
  //         .map((res, index) => ({
  //           id: res.data.data.id,
  //           doc_url: res.data.data.doc_url,
  //           order: index,
  //         }));

  //       // Update the state with the uploaded file information
  //       setMultiId((prevData) => [...prevData, ...newMultiId]);
  //       // handleClearFiles();
  //     })
  //     .catch((error) => {
  //       console.error("Error uploading files:", error);
  //     });
  // };
  const uploadMulti = (data) => {
    console.log(data, "image Data inside");
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    // Filter out data that has already been uploaded
    const dataToUpload = data.filter((curr) => {
      // Check if the data is not already in the list of uploaded data
      return !multiId.some((uploadedItem) => uploadedItem.name === curr.name);
    });

    // Create an array to hold all upload promises
    const uploadPromises = dataToUpload.map((curr, index) => {
      const data1 = new FormData();
      data1.append("document", curr);
      data1.append("doc_type", 0);
      data1.append("order", index);

      // Return the axios promise
      return axios.post(url, data1);
    });

    // Use Promise.all to wait for all uploads to complete
    Promise.all(uploadPromises)
      .then((responses) => {
        const newMultiId = responses
          .filter((res) => res.data.status)
          .map((res, index) => ({
            id: res.data.data.id,
            doc_url: res.data.data.doc_url,
            order: index,
            name: dataToUpload[index].name, // Assuming name is a unique identifier for your data
          }));

        // Update the list of uploaded data
        // setUploadedData((prevData) => [...prevData, ...newMultiId]);

        // Update the state with the uploaded file information
        setMultiId((prevData) => [...prevData, ...newMultiId]);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  console.log(productAllData, "image Dta out");
  // console.log(
  //   foundProduct,
  //   "image Dta out foundProduct"
  // );
  const postFeatureImages = (productID) => {
    Dropzoneimages.map((curr) => {
      console.log("images", curr);
      const data = new FormData();
      data.append("image", curr);
      data.append("product", productID);
    });
  };

  console.log(
    convertToObjectArray(AttributesInputValue),
    "------------selectedOptionCategory----------"
  );
  console.log(
    AttributesInputValue,
    "------------selectedOptionCategory 12----------"
  );
  console.log(
    {
      mainCategory: mainCategory,
      selectedOptionCategory: selectedOptionCategory,
    },
    "tags 123"
  );
  const postProduct = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/`;
    const data = new FormData();
    data.append("name", productAllData["product_name"]);
    isEmpty(AttributesInputValue) &&
      data.append("stock", productAllData["product_quantity"]);
    isEmpty(AttributesInputValue) &&
      data.append("quantity", productAllData["product_quantity"]);
    !isEmpty(AttributesInputValue) &&
      data.append("quantity", parseInt(GetTotalQuantity()));
    !isEmpty(AttributesInputValue) &&
      data.append("stock", parseInt(GetTotalQuantity()));
    data.append("description", productAllData["product_description"]);
    productAllData["product_price"] !== 0 &&
      data.append("price", parseInt(productAllData["product_price"]));
    data.append("discount", productAllData["product_discount"]);
    data.append("vendor_info", productAllData["vendor_info"]);
    data.append("about_brand", productAllData["about_brand"]);
    data.append("shipping_info", productAllData["shipping_info"]);
    data.append("is_new_arrival", isNew);
    data.append("is_trending", isTrend);
    data.append("vat", 0);
    data.append("tax", 0);
    Thumb != null && data.append("thumb", Thumb);
    data.append("is_active", Categoryswitch);
    data.append("category", [selectedOptionCategory.value]);

    data.append("sku", productAllData["sku"]);

    data.append(
      "variants_json",
      JSON.stringify({
        mainState: mainState,
        AttributesInputValue: AttributesInputValue,
      })
    );
    const tg = tags?.map((obj) => ({ name: obj.label }));
    data.append("tags", tg);
    const v = convertToObjectArray(AttributesInputValue);

    data.append("variant", v);
    data.append("main_category", 1);

    const postData = {
      // main_category:
      //   foundProduct !== undefined ? foundProduct?.main_category : mainCategory,
      main_category: mainCategory,
      variant: v,
      tags: tg ? tg : [],
      sku: productAllData["sku"],
      is_active: Categoryswitch,
      related_products: selectProduct?.map((data) => data?.value),
      is_update_related: true,
      // category: foundProduct !== undefined
      //   ? [Number(foundProduct?.category)]
      //   : [selectedOptionCategory],
      category: [Number(selectedOptionCategory)],
      name:
        foundProduct && foundProduct?.product_name !== undefined
          ? foundProduct?.product_name
          : productAllData["product_name"],
      stock:
        parseInt(GetTotalQuantity()) === 0
          ? productAllData["product_quantity"]
          : parseInt(GetTotalQuantity()),
      quantity:
        parseInt(GetTotalQuantity()) === 0
          ? productAllData["product_quantity"]
          : parseInt(GetTotalQuantity()),
      description: productAllData["product_description"],
      price:
        foundProduct && foundProduct?.product_price !== undefined
          ? foundProduct?.product_price
          : parseInt(productAllData["product_price"]),

      vendor_info: productAllData["vendor_info"],
      about_brand: productAllData["about_brand"],
      shipping_info: productAllData["shipping_info"],
      is_new_arrival: isNew,
      is_trending: isTrend,
      is_show_website: Categoryswitch === true ? isShowWebsite : false,
      thumb: thumbId,
      thumb2: thumbId2 ? thumbId2 : null,
      feature_images: multiId.map((data) => data?.id),
      variant_chart: thumbId3 ? thumbId3 : null,
      variants_json: {
        mainState: mainState,
        AttributesInputValue: AttributesInputValue,
      },
      meta_tags: metaTags?.map((obj) => ({ name: obj.label })),
      meta_description: productAllData["meta_desc"],
      is_combo_product: true,
      combo_type: comboType,
      combo_connected_products: comboType === 2 ? [] :  comboConnectedProducts,
    };
    axios
      .post(url, postData)
      .then((res) => {
        if (res.data.status) {
          SetSubmittedProduct(res.data.data.id);
          // SetSubmittedProduct({ ...SubmittedProduct, product: res.data.data.id });
          showToast("success", "Product Added.");
          // PostVariants(res.data.data.id);
          // postFeatureImages(res.data.data.id);
          // if (reload) {
          //   window.location.reload();
          // } else {
          //   if (mainCategory === 1) {
          //     history.push(path.product_list);
          //   } else {
          //     history.push(path.home_decor_list);
          //   }
          // }
          console.log(res.data.data.id, "803");
          console.log(res.data.data.status, "803");
          // if (
          //   res.data.data.main_category === 1 ||
          //   res.data.data.main_category === "1"
          // ) {
          //   history.push(path.product_list);
          // } else {
          //   history.push(path.home_decor_list);
          // }
          history.push("/inventory/combo-product-list");
          // clearData();
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  console.log(
    productAllData["product_price"],
    '------productAllData["product_price"]-------'
  );

  const postALLProductsData = () => {
    ValidateData();
  };

  useEffect(() => {
    getCategory();
  }, [mainCategory, id]);
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };
  useEffect(() => {
    getProductList();
  }, [inputQuery]);

  const handleOptional = (event) => {
    setSelectedImages([]);
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    console.log(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      console.log("file", file);
      return window.URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    window.URL.revokeObjectURL(image);
  }
  const [test, setTest] = useState("");

  const combinationMake = (arrays, joinChar) => {
    console.log(arrays, "output1");
    var result = arrays.reduce((a, b) =>
      a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
    );
    let output = result.map((a) => a.join(joinChar));
    console.log(mainState, "output3");
    console.log(output, "output");

    return output;
  };
  const handleMetaTags = (newValue, actionMeta) => {
    setMetaTegs(newValue);
  };
  const handleTags = (newValue, actionMeta) => {
    setTegs(newValue);
  };
  const handleMultiInputChange = (newValue, actionMeta) => {
    console.log(actionMeta);
    const newVal = actionMeta?.option?.label;
    setAttributeCom(newValue);
    console.log("actionMeta.action", actionMeta.action);
    if (
      actionMeta.action === "create-option" ||
      actionMeta.action === "select-option"
    ) {
      setMainState({ ...mainState, [newVal]: [] });
    }
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
    }
  };
  const handleMultiInputChange2 = (newValue, actionMeta) => {
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    console.log(newValue, "newValue");
    setSelectProduct(newValue);
    setFoundProduct(newValue[0]);
    if (!foundProduct) {
      SetproductAllData((prevData) => ({
        ...prevData,
        product_name: newValue[0]?.name ? newValue[0]?.name : "",
        product_description: newValue[0]?.description,
        product_price: newValue[0]?.price,
      }));
      setMainCategory(newValue[0]?.main_category);
      setIsNew(newValue[0]?.is_new_arrival);
      setIsShowWebsite(newValue[0]?.is_show_website);
      setIsTrend(newValue[0] ? newValue[0]?.is_trending : isTrend);
      setSelectedOptionCategory(newValue[0].category);
      setTegs(
        newValue[0]?.tag_details?.map((data) => ({
          label: data?.name,
          value: data?.id,
        }))
      );
    }
  };
  const handleComboMultiInputChange = (newValue, actionMeta) => {
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    console.log(newValue, "newValue");
    const comboId = newValue.map(({ value }) => value);
    setComboConnectedProducts(comboId);
  };
  const handleComboSingleInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    console.log([newValue.value], "newValue");
    // const combeoId = newValue.map(({ value }) => value);
    setComboConnectedProducts([newValue.value]);
  };
  useEffect(() => {
    SetproductAllData((prevData) => ({
      ...prevData,
      product_name: foundProduct?.name
        ? foundProduct?.name
        : productAllData?.product_name,
      product_description: foundProduct?.description
        ? foundProduct?.description
        : productAllData?.product_description,
      product_price: foundProduct?.price
        ? foundProduct?.price
        : productAllData?.product_price,
    }));
    setTegs(
      foundProduct?.tag_details?.map((data) => ({
        label: data?.name,
        value: data?.id,
      }))
    );
    setSelectedOptionCategory(foundProduct?.category);
  }, [foundProduct]);
  console.log("foundProduct", {
    foundProduct: foundProduct,
    isTrend: isTrend,
    isNew: isNew,
    selectedOptionCategory: selectedOptionCategory,
    mainCategory: mainCategory,
  });

  const handleMultiInputInner = (item, val) => {
    mainState[item] = val.map((curr) => curr.label.trim());
    console.log("mainState[item]", mainState[item]);
    console.log("mainState", val);
    let arrays = [];
    for (let i in mainState) {
      console.log(i);
      console.log(mainState[i]);
      arrays.push(mainState[i]);

      // if (mainState[i].length != 0) {
      //   arrays.push(mainState[i]);
      // }
    }
    // console.log("arrays", arrays);
    if (arrays.length > 1) {
      // console.log(combinationMake(arrays, "-"));
      setitemData(combinationMake(arrays, "~"));
    }
    if (arrays.length <= 1) {
      arrays.push([""]);
      // console.log(combinationMake(arrays, ""));
      setitemData(combinationMake(arrays, ""));
    }
    //   console.log(itemData, "12");
    //   const data =combinationMake(arrays, "")
    //  console.log(data,'output4')

    SetAttributesInputValue({});
  };
  const handleSize = (newValue, actionMeta) => {
    setAtttibutesList(newValue);
    setSizeOption(newValue);
  };
  console.log(itemData, "output4");
  useEffect(() => {
    const resultObject = {};
    for (const item of itemData) {
      resultObject[item] = {
        variant_price: 0,
        variant_action: true,
        variant_stock: null,
        images: [],
        img_details: [],
        attribute_value: [],
      };
    }
    SetAttributesInputValue(resultObject);
  }, [itemData]);

  const HandleVariantsInputValue = (e, index, variant) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log("variant", variant);
    SetAttributesInputValue({
      ...AttributesInputValue,
      [variant]: {
        ...AttributesInputValue[variant],
        [name]: value ? value : 0,
      },
    });
  };
  const HandleVariantsImage = (e, variant) => {
    const selectedFiles = e.target.files;
    const promises = [];
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i] !== null) {
        const data = new FormData();
        data.append("document", selectedFiles[i]);
        data.append("doc_type", 0);

        promises.push(
          axios.post(url, data).then((res) => {
            if (res.data.status) {
              SetAttributesInputValue((prevAttributes) => {
                const imagesPrev = prevAttributes[variant]?.images;
                const imagesPrevDetails = prevAttributes[variant]?.img_details;
                const newImageId = res.data.data.id;
                const dataUrl = res.data.data.doc_url;
                return {
                  ...prevAttributes,
                  [variant]: {
                    ...prevAttributes[variant],
                    images: imagesPrev
                      ? [...imagesPrev, newImageId]
                      : [newImageId],
                    img_details: imagesPrevDetails
                      ? [
                          ...imagesPrevDetails,
                          {
                            id: newImageId,
                            url: dataUrl,
                          },
                        ]
                      : [
                          {
                            id: newImageId,
                            url: dataUrl,
                          },
                        ],
                  },
                };
              });
            }
          })
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        showToast("success", "Images uploaded");
      })
      .catch((error) => {
        showToast("error", error.response.data.errors.document[0]);
      });
  };

  const HandleVariantsImage2 = (e, variant) => {
    let name = "images";
    let value = e.target.value;
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            const imagesPrev = AttributesInputValue[variant]?.images;
            if (imagesPrev === undefined) {
              SetAttributesInputValue({
                ...AttributesInputValue,
                [variant]: {
                  ...AttributesInputValue[variant],
                  images: [res.data.data.id],
                },
              });
            } else {
              SetAttributesInputValue({
                ...AttributesInputValue,
                [variant]: {
                  ...AttributesInputValue[variant],
                  images: [...imagesPrev, res.data.data.id],
                },
              });
            }

            console.log(imagesPrev, "AttributesInputValue bablu 11");
            reader.onload = () => {
              if (reader.readyState === 2) {
                // setURL2(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const deleteVariantImage = (id, variant) => {
    SetAttributesInputValue((prevAttributes) => {
      const imagesPrev = prevAttributes[variant]?.images;
      const imagesPrevDetails = prevAttributes[variant]?.img_details;
      return {
        ...prevAttributes,
        [variant]: {
          ...prevAttributes[variant],
          images: imagesPrev?.filter((data) => data !== id),
          img_details: imagesPrevDetails?.filter((data) => data?.id !== id),
        },
      };
    });
  };
  const HandleVariantsSwitch = (e, variant) => {
    let name = e.target.name;
    let value = e.target.checked;
    console.log("variant 12345", value);
    SetAttributesInputValue({
      ...AttributesInputValue,
      [variant]: { ...AttributesInputValue[variant], [name]: value },
    });
  };

  console.log("variant 12345", AttributesInputValue);

  useEffect(() => {
    getAttributeList();
  }, []);

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />;
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };
  const handleCancelButton = () => {
    history.push("/inventory/combo-product-list");
  };

  const handleSaveAndAddNew = () => {
    setReload(true);
    console.log("reload", reload);
    postALLProductsData();
  };

  const handleDescriptionChange = (value) => {
    // Update only the product_description field while keeping the rest of the state unchanged
    SetproductAllData((prevData) => ({
      ...prevData,
      product_description: value,
    }));
  };
  if (!has_permissions(can_add_products)) {
    return <Permission />;
  }

  // Reorder with drag-n-drop
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      multiId,
      result.source.index,
      result.destination.index
    );

    setMultiId(items);
    items.map((item) => {
      axios
        .patch(`${BASE_URL}/api/v1/auth/documents/update/${item.id}/`, {
          order: item.order,
        })
        .then((res) => console.log(res, "res"));
    });
  };

  return (
    <div className="categorey-parent">
      <div className="bg-white cat-child ">
        <div
          className="d-flex flex-column align-items-start"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          {/* <img src={add} alt="" /> */}
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Add New Combo</h3>
          {/* ====================combo section=============== */}
          {/* <div>
        <label>
          <input
            type="checkbox"
            name="isCombo"
            checked={isCombo}
            onChange={handleCheckboxChange}
            id=""
          />
          Create Combo
        </label>
      </div> */}

          {/* {isCombo ? (
        <> */}
          <div className="mt-4">
            <h4>Select Combo Type</h4>
            <select
              name="combo-type"
              id=""
              value={comboType}
              onChange={handleComboType}
            >
              <option value="0">Same product combo</option>
              <option value="1">Different product combo</option>
              <option value="2">Custom Combo</option>
            </select>
          </div>
          {comboType !== 2 && (
            <>
              {comboType == 1 ? (
                <div className="col-12 mt-3 mb-3">
                  {/* <RequiredLabel text="Products" /> */}
                  <p>Select Products</p>
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      isMulti
                      placeholder="---Select---"
                      isClearable
                      // defaultValue={selectedOption}
                      onChange={handleComboMultiInputChange}
                      onInputChange={inputProduct}
                      options={productList}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-12 mt-3 mb-3">
                  {/* <RequiredLabel text="Products" /> */}
                  <p>Select Product</p>
                  <div className="position-relative">
                    <Select
                      // styles={styles}
                      placeholder="---Select---"
                      isClearable
                      // defaultValue={selectedOption}
                      onChange={handleComboSingleInputChange}
                      onInputChange={inputProduct}
                      options={productList}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* -----------product form---------- */}
        <div className="product-main">
          <div className="row">
            <div className="col-12 mb-3 d-none">
              {/* <RequiredLabel text="Products" /> */}
              <p>Related Products</p>
              <div className="position-relative">
                <Select
                  // styles={styles}
                  isMulti
                  placeholder="---Select---"
                  isClearable
                  // defaultValue={selectedOption}
                  onChange={handleMultiInputChange2}
                  onInputChange={inputProduct}
                  options={productList}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              {/* <p>Name*</p> */}
              <RequiredLabel text={"Name"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_name"
                  value={productAllData["product_name"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Barcode"} />
              {/* <p>*</p> */}
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  placeholder="This will be generated automatically"
                  disabled
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Main Category"} />
              <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  onChange={(e) => {
                    setMainCategory(e.target.value);
                    setFoundProduct((prev) => ({
                      ...prev,
                      main_category: e.target.value,
                    }));
                  }}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  value={
                    foundProduct !== null
                      ? foundProduct?.main_category
                      : mainCategory
                  }
                >
                  <option value={0}>Home Decor</option>
                  <option value={1}>In Style</option>
                </Form.Select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Category"} />
              <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  onChange={(e) => {
                    const newValue = Number(e.target.value);
                    setSelectedOptionCategory(newValue);
                    setFoundProduct((prev) => ({
                      ...prev,
                      category: newValue,
                    }));
                  }}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  value={
                    foundProduct !== null
                      ? foundProduct?.category
                      : selectedOptionCategory
                  }
                >
                  {/* <option value={1}>Flat </option>
                  <option value={0}>Percentage</option> */}

                  {categoryList?.map((data) => (
                    <option key={data?.value} value={data?.value}>
                      {data?.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>

            {isEmpty(AttributesInputValue) && (
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Quantity"} />
                {/* <p>*</p> */}
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    name="product_quantity"
                    disabled
                    type="number"
                    onChange={(e) => handleInputs(e, SetproductAllData)}
                    value={productAllData["product_quantity"]}
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    // value={productAllData["product_quantity"]}
                  />
                </InputGroup>
              </div>
            )}
            {console.log("AttributesInputValue", AttributesInputValue)}

            {!isEmpty(AttributesInputValue) && (
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text={"Quantity"} /> */}
                <p>Quantity</p>
                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    name="product_quantity"
                    value={GetTotalQuantity()}
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    // value={productAllData["product_quantity"]}
                  />
                </InputGroup>
              </div>
            )}

            {/* Price hidden */}
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Price"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_price"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_price"]}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Vat(%)"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_vat"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_vat"]}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              {/* <p>*</p> */}
              <RequiredLabel text={"Tax"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_tax"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_tax"]}
                />
              </InputGroup>
            </div>
            <div className="col-12 ">
              <RequiredLabel text={"Stock Keeping Unit -SKU"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="sku"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["sku"]}
                />
              </InputGroup>
            </div>
            <div className="col-12 mb-3 ">
              <RequiredLabel text={"Tags"} />
              <div>
                <CreatableSelect
                  isMulti
                  placeholder="Enter a choice value"
                  onChange={handleTags}
                  value={tags}
                  defaultValue={tags}
                />
              </div>
            </div>
            <div className="col-12 mb-3 ">
              {/* <RequiredLabel text={"Meta Tags"} /> */}
              <p>Meta Tags</p>
              <div>
                <CreatableSelect
                  isMulti
                  placeholder="Enter a choice value"
                  onChange={handleMetaTags}
                  value={metaTags}
                  defaultValue={metaTags}
                />
              </div>
            </div>
            <div className="col-12 mb-3 ">
              {/* <RequiredLabel text={"Meta Tags"} /> */}
              <p>Meta Description</p>
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="meta_desc"
                  value={productAllData["meta_desc"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            {/* ====================new fields start ============== */}
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Vendor Info"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="vendor_info"
                  value={productAllData["vendor_info"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"About Brand"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="about_brand"
                  value={productAllData["about_brand"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Shipping Info"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="shipping_info"
                  value={productAllData["shipping_info"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Is New"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isNew ? "Yes" : "No"}`}
                  checked={isNew === true}
                  onChange={() => setIsNew(!isNew)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Is Trending"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isTrend ? "Yes" : "No"}`}
                  checked={isTrend === true}
                  onChange={() => setIsTrend(!isTrend)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 mb-4">
              {/* <p></p> */}
              {/* <RequiredLabel text={""} /> */}
              <p>Description</p>
              {/* <Form.Control
                as="textarea"
                placeholder=""
                className="mb-3"
                name="product_description"
                value={productAllData["product_description"]}
                onChange={(e) => handleInputs(e, SetproductAllData)}
                style={{
                  height: "100px",
                  resize: "none",
                  backgroundColor: "#FAFAFA",
                }}
              /> */}
              <InputGroup className="mb-3" style={{ height: "150px" }}>
                <ReactQuill
                  style={{ width: "100%", maxHeight: "150px" }}
                  theme="snow"
                  // name='product_description'
                  value={productAllData["product_description"]}
                  onChange={handleDescriptionChange}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Is Show Website"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isShowWebsite ? "Yes" : "No"}`}
                  checked={isShowWebsite === true}
                  onChange={() => setIsShowWebsite(!isShowWebsite)}
                />
              </InputGroup>
            </div>

            {/* ====================new fields end ============== */}
          </div>
        </div>
      </div>
      {/* ====================attribute=================== */}

      <div
        className="product-main my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className="fw-bold my-2 mb-4 ">Attribute </h4>
        <div>
          <h6 style={{ color: "red" }}>
            NB : Don't Use (~) for make any product and upload variant image in
            (height: 50px, width-auto) this size.
          </h6>
        </div>
        {/* <AttributeMultipleInput /> */}
        <div className="main-select">
          <Select
            isMulti
            styles={{ height: "45px" }}
            options={atttibutesList}
            placeholder="Enter a choice value"
            onChange={handleMultiInputChange}
          />
        </div>
        {console.log("attributesCom", attributesCom)}
        {attributesCom.map((newInput, index) => (
          <div className="row mt-3" key={index}>
            <div className=" col-lg-5 col-md-5  col-sm-12">
              <input
                type="text"
                style={{ height: "38px" }}
                className="multiple_input ps-3"
                value={newInput.label}
                disabled
              />
            </div>
            <div className="col-lg-7 col-md-5 col-sm-12 main-select">
              <CreatableSelect
                isMulti
                placeholder="Enter a choice value "
                onChange={(value) =>
                  handleMultiInputInner(newInput.label, value)
                }
              />
            </div>
          </div>
        ))}

        <div>
          <table
            className="item-tables mt-3 "
            style={{ overflowX: "auto", display: "block" }}
          >
            <thead>
              <tr className="trod" style={{ height: "45px" }}>
                <td
                  className="minw"
                  style={{ width: "340px", textAlign: "center" }}
                >
                  Variant
                </td>
                <td
                  className="minw"
                  style={{ width: "380px", textAlign: "center" }}
                >
                  Variant Price
                </td>
                <td
                  className="minw"
                  style={{ width: "255px", textAlign: "center" }}
                >
                  Variant Stock
                </td>
                <td
                  className="minw"
                  style={{ width: "410px", textAlign: "center" }}
                >
                  Images
                </td>
                <td
                  className="minw"
                  style={{ width: "190px", textAlign: "center" }}
                >
                  Action
                </td>
              </tr>
            </thead>
            <tbody>
              {itemData.map((item, index) => (
                <tr className="trod" key={index + 1} style={{ height: "45px" }}>
                  <td className="ps-1 ps-md-2 ps-lg-4">{item}</td>
                  <td className="text-cente ">
                    <div className="justify-content-center align-items-center d-flex">
                      Base Price +
                      <input
                        name="variant_price"
                        onChange={(e) => {
                          HandleVariantsInputValue(e, 0, item);

                          // SetAttributesInputValue({
                          //   ...AttributesInputValue,
                          //   [item]: {
                          //     ...AttributesInputValue[item],
                          //     ["variant_stock"]:
                          //       AttributesInputValue[item]?.variant_stock ||
                          //       AttributesInputValue[item]
                          //         ? AttributesInputValue[item]?.variant_stock
                          //         : 0,
                          //   },
                          // });
                        }}
                        className=" productVariantTdinput"
                        type="text"
                        placeholder={0}
                        value={
                          AttributesInputValue[item] !== undefined
                            ? AttributesInputValue[item]["variant_price"]
                            : 0
                        }
                      />
                    </div>
                  </td>
                  <td className="text-center">
                    <input
                      name="variant_stock"
                      onChange={(e) => {
                        HandleVariantsInputValue(e, 1, item);
                      }}
                      className="attribute_input"
                      type="text"
                      placeholder="0"
                      value={
                        AttributesInputValue[item] !== undefined
                          ? AttributesInputValue[item]["variant_stock"]
                          : null
                      }
                    />
                  </td>

                  <td>
                    <div className="row m-1 m-md-2">
                      {AttributesInputValue[item]?.img_details &&
                        AttributesInputValue[item]?.img_details?.map((data) => (
                          <>
                            <div
                              className="col-md-4"
                              style={{ position: "relative" }}
                              key={data?.id}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  deleteVariantImage(data?.id, item)
                                }
                              >
                                <XCircle size={32} weight="fill" />
                              </div>
                              <img
                                width={60}
                                style={{ objectFit: "cover" }}
                                src={data?.url}
                                alt=""
                              />
                            </div>
                          </>
                        ))}
                    </div>
                    {
                      <div
                        className="d-flex justify-content-center my-1 my-md-2"
                        style={{
                          position: "relative",
                        }}
                      >
                        <input
                          type="file"
                          onChange={(e) => HandleVariantsImage(e, item)}
                          accept="image/*"
                          multiple
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            cursor: "pointer",
                          }}
                        />
                        <PlusCircle
                          style={{ cursor: "pointer" }}
                          size={40}
                          weight="bold"
                        />
                      </div>
                    }
                  </td>
                  <td>
                    <div class="form-check form-switch d-flex justify-content-center">
                      <input
                        class="form-check-input "
                        name="variant_action"
                        value={getVariantAction(item)}
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={getVariantAction(item)}
                        // onChange={(e) => console.log(e.target.checked)}
                        onChange={(e) => HandleVariantsSwitch(e, item)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ====================attribute=================== */}

      <div
        className="product-main my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <p style={{ marginTop: "10px", color: "red" }}>
          NB: Upload image in (height-523px, width-392px) for thumbnail.{" "}
        </p>{" "}
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>

            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  No File Choosen
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL && (
              <div className="my-2">
                <img height={90} width={90} src={URL} alt="" /> <br />
                <button
                  onClick={removeImg}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Thumbnail image (optional)</h5>
            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName2 === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  No File Choosen
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName2}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle2(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL2 && (
              <div className="my-2">
                <img height={90} width={90} src={URL2} alt="" /> <br />
                <button
                  onClick={removeImg2}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Size Chart image (optional)</h5>
            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName3 === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  No File Choosen
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName3}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle3(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL3 && (
              <div className="my-2">
                <img height={90} width={90} src={URL3} alt="" /> <br />
                <button
                  onClick={removeImg3}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>

          {/* ----------status--------- */}
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Status</h5>

            <div className="row mr-4">
              <div
                className=" d-flex justify-content-between col-12 py-3"
                style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
              >
                <div className="d-flex w-100">
                  <div
                    className="d-flex py-1 justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#212121",
                      width: "32px",
                      height: "32",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={eye} alt="" />
                  </div>
                  <span className="mx-3">{Categoryswitch ? "ON" : "OFF"}</span>
                </div>

                <div class="form-check form-switch ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    width={40}
                    height={40}
                    checked={Categoryswitch}
                    onClick={toggleSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="optional">
            <p>Feature Images (Optional)</p>
          </div>
          <div>
            <span>NB: Add image with 1664px(width) 600px(height)</span> <br />
            <span></span> <br />
          </div>
          <div className="col-12 ">
            <DropzoneArea
              ref={dropzoneRef}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => uploadMulti(files)}
              filesLimit={40}
            />
          </div>
          <div className="d-flex my-4">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                    className="d-flex"
                  >
                    {/* {selectedImages &&
                      selectedImages.map((imges, index) => (
                        <Draggable key={index} draggableId={imges} index={index}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="main-box-img"
                              style={{ cursor: "pointer", position: "relative" }}
                            >
                              <img
                                key={index}
                                src={imges}
                                width={70}
                                height={70}
                                className="mx-2"
                                alt=""
                                srcset=""
                              />{" "}
                              <br />
                              <button
                                className="del-btn"
                                onClick={() => deleteHandler(imges)}
                              >
                                <X size={14} />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))} */}
                    {multiId &&
                      multiId?.map((data, index) => (
                        <Draggable
                          key={data?.id}
                          draggableId={data.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="my-2 mx-2"
                            >
                              <img
                                height={90}
                                width={90}
                                src={data?.doc_url}
                                alt=""
                              />{" "}
                              <br />
                              <button
                                onClick={() => {
                                  const filteredData = multiId?.filter(
                                    (item) => item.id !== data?.id
                                  );
                                  setMultiId(filteredData);
                                }}
                                className="remove-btn btn btn-small mt-1  rounded"
                                style={{ border: "1px solid gray !important" }}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={postALLProductsData}
              className="save-btn me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button className="save-btn mt-2" onClick={handleCancelButton}>
              Cancel
            </button>
            <button
              onClick={handleSaveAndAddNew}
              className="save-btn me-2 mt-2"
              style={{
                background: "#000",
                color: "white",
                marginLeft: "0.5rem",
              }}
            >
              Save & Add New
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddComboProduct;
