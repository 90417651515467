import React, { useRef } from "react";
import plus from "../../assets/Icon/plus.svg";
import user1 from "../../assets/Icon/user1.svg";
import user2 from "../../assets/Icon/user2.svg";
import user3 from "../../assets/Icon/user3.svg";
import user4 from "../../assets/Icon/user4.svg";
import { useHistory } from "react-router-dom";
import Paggination from "../CustomCommons/Paggination";
import ViewIcon from "../CustomCommons/ViewIcon";
import EditIcon from "../CustomCommons/EditIcon";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import { useState } from "react";
import swal from "sweetalert";
import * as path from "../Routes/RoutePaths";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import profile from "./img/profile.png";
import UseData from "../Const/UseData";
import Loader from "../CustomCommons/Loader";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import NextIcon from "../CustomCommons/NextIcon";
import Permission from "../CustomCommons/Permission";
import kaarujLogo from "../../assets/Icon/KAARUJ 1.png";
import {
  can_add_group,
  can_change_group,
  can_change_user,
  can_delete_user,
  can_view_user,
  has_permissions,
  test_has_permissions,
} from "../CustomCommons/utils";
import { useReactToPrint } from "react-to-print";
import { logout_func2 } from "../Const/logoutCommon";

const EmployeList = () => {
  const [isLoading, SetisLoading] = useState(true);
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [query, setQuery] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const cRef = useRef();
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  const history = useHistory();

  const [userList, setUserList] = useState([]);
  const updatedPageNumber = parseInt(
    localStorage.getItem("userPageNbr")
      ? localStorage.getItem("userPageNbr")
      : 0
  );
  const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const handleCheckboxClick = (id) => {
    if (checkedIds.includes(id)) {
      // If ID is already in the array, remove it
      setCheckedIds(checkedIds.filter((existingId) => existingId !== id));
    } else {
      // If ID is not in the array, add it
      setCheckedIds([...checkedIds, id]);
    }
  };
  const getUserList = () => {
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}api/v1/users/inventory/user/?is_customer=False&is_outlet_user=False&is_employee=True&start=${date1}&end=${date2}&limit=1000`;
    } else {
      url = `${BASE_URL}api/v1/users/inventory/user/?is_customer=False&is_employee=True&is_outlet_user=False&limit=1000`;
    }
    // const url = `${BASE_URL}api/v1/users/inventory/user/`;
    axios
      .get(url)
      .then((res) => {
        console.log("res", res);
        setUserList(res.data.data);

        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    getUserList();
  }, []);
  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getUserList();
    }
  };
  useEffect(() => {
    getUserList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  console.log(userList);
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };

  const keys = [
    "email",
    "last_name",
    "first_name",
    "joining_date",
    "mobile",
    "role_name",
  ];
  const getData = userList?.filter((p) =>
    keys?.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  const pageCount = Math.ceil(getData.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    localStorage.setItem("userPageNbr", selected);
  };
  const deleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/users/inventory/user/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            getUserList();
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  var options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  // console.log(userList)
  const statusBulkChanges = (ids, name, id) => {
    swal({
      title: "Are you sure?",
      text: "Once Click, You will not be able to go back.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((i) => {
          const url = `${BASE_URL}api/v1/users/inventory/user/${i}/`;
          return axios.patch(url, { [name]: id });
        });
        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been updated!", {
              icon: "success",
            });
            setCheckedIds([]);
            // setBulkStatus([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure

            getUserList();
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const statusBulkChangesCustomer = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once Click, You will not be able to go back.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((i) => {
          const url = `${BASE_URL}api/v1/users/inventory/user/${i}/`;
          return axios.patch(url, { is_employee: false, is_customer: true });
        });
        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been updated!", {
              icon: "success",
            });
            setCheckedIds([]);
            // setBulkStatus([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure

            getUserList();
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = getData.map((item) => item.slug);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  if (!has_permissions(can_view_user)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="categorey-parent my-4" style={{ height: "100%" }}>
        <div className="cotagorey-head mb-4">
          <div>
            <h3>Employee List</h3>
            <input
              type="text"
              style={{ color: "black" }}
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                changePage({ selected });
                setQuery(e.target.value.toLocaleLowerCase());
                localStorage.setItem("userPageNbr", selected);
                // (updatedPageNumber = selected)
              }}
            />
          </div>
          <div className="filter_div">
            <div className="my-2 my-lg-0">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {getData?.length > 0 && (
              <div
                className="button-div my-auto me-2"
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => handlePrints()}
              >
                <span> Print</span>
              </div>
            )}
            {has_permissions(can_add_group) && (
              <div
                className="button-div"
                onClick={() => history.push(path.add_employe)}
              >
                <img src={plus} alt="" />
                <span>Add Employee</span>
              </div>
            )}
          </div>
        </div>
        {/* ----------user table -----------*/}
        {checkedIds?.length > 0 && (
          <div className="mb-2 d-flex align-items-center gap-2">
            <select
              style={{ color: "black", width: "190px" }}
              type="text"
              className="filter-input"
              placeholder=""
              onChange={(e) =>
                statusBulkChanges(checkedIds, "offer_status", e.target.value)
              }
            >
              <option value="">Change Offer Status</option>
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
            <select
              style={{ color: "black", width: "220px" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) =>
                statusBulkChanges(checkedIds, "is_active", e.target.value)
              }
            >
              <option value="">Change Employe Status</option>
              <option value={true}>Enable</option>
              <option value={false}>Disable</option>
            </select>
            <div>
              <button
                onClick={() => statusBulkChangesCustomer(checkedIds)}
                className="btn btn-sm bg-dark text-white"
              >
                Convert Customer
              </button>
            </div>
          </div>
        )}

        <div style={{ overflowX: "auto" }} className="table-responsive">
          <div>
            {/* <div style={{ minHeight: "100vw" }}> */}
            <table className="table_my">
              <thead
                className="bg-dark text-white head-row"
                style={{ height: "50px" }}
              >
                <tr className="">
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "110px",
                      // display:'flex',
                      // justifyContent:'center',
                      margin: "center",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <input
                        style={{
                          margin: "0 auto",
                          opacity: "1",
                          width: "17px",
                          height: "17px",
                        }}
                        type="checkbox"
                        checked={checkedIds?.length > 0}
                        onChange={(event) => handleCheckboxChangeAll(event)}
                      />
                    </div>
                  </th>
                  <th
                    className="ms-3"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      borderTopLeftRadius: "4px ",
                      // margin: "0px 20px",
                    }}
                  >
                    <p className="ms-3 mt-3">ID</p>
                  </th>
                  <th
                    className="ms-3"
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <p className="ms-3 mt-3">Full Name</p>
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    User Type
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    Discount
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    Phone
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    Created On
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    Offer Status
                  </th>
                  <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                    Status
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      borderTopRightRadius: "4px ",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="tb">
                {getData.length > 0 ? (
                  getData
                    .slice(pagesVisited, pagesVisited + usersPerPage)
                    .map((user, index) => (
                      <tr key={user?.slug} className="table-row ">
                        <td>
                          <div className="d-flex justify-content-center">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              style={{ width: "17px", height: "17px" }}
                              checked={checkedIds.includes(user.slug)}
                              onChange={() => handleCheckboxClick(user?.slug)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="ms-2">{user.employee_id}</div>
                        </td>
                        <td>
                          <div className="d-flex ms-3">
                            <img
                              className="userListProfileimg"
                              src={
                                user?.image_url
                                  ? `${user?.image_url} `
                                  : profile
                              }
                              alt=""
                            />
                            <div className="ms-3">
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginTop: "10px !important",
                                }}
                              >
                                {user.first_name} {user.last_name}
                              </span>{" "}
                              <br />
                              <span
                                style={{ fontSize: "12px", marginTop: "-10px" }}
                              >
                                {user.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm text-dark "
                            style={{
                              fontSize: "17px",
                            }}
                          >
                            Employee
                          </button>
                        </td>
                        <td>
                          {user.discount_value}
                          {user?.discount_type === 0 ? " TK" : " %"}
                        </td>
                        <td>{user.mobile}</td>
                        <td>
                          {new Date(user.joining_date).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </td>
                        <td className="">
                          <button
                            className={`status ${
                              user?.offer_status ? "c1" : "c2"
                            }`}
                            style={{ color: `${user.StatusBtnColour}` }}
                          >
                            {user?.offer_status ? "Active" : "Disable"}
                          </button>
                        </td>
                        <td className="">
                          <button
                            className={`status ${
                              user?.is_active ? "c1" : "c2"
                            }`}
                            style={{ color: `${user.StatusBtnColour}` }}
                          >
                            {user?.is_active ? "Active" : "Disable"}
                          </button>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-3">
                            <div
                              className="edit-del blue"
                              onClick={() =>
                                history.push(`/user/user-View/${user?.slug}`)
                              }
                            >
                              <ViewIcon />
                            </div>
                            {has_permissions(can_change_user) ? (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  history.push(
                                    `/employee/edit-employee/${user?.slug}`
                                  )
                                }
                              >
                                <EditIcon />
                              </div>
                            ) : (
                              <div
                                className="edit-del green"
                                onClick={() =>
                                  swal("Sorry , You are not able to edit user.")
                                }
                              >
                                <EditIcon />
                              </div>
                            )}
                            {/* {has_permissions(can_delete_user) ? (
                              <div
                                className="edit-del red"
                                onClick={() => deleteUser(user?.slug)}
                              >
                                <DeleteIcon />
                              </div>
                            ) : (
                              <div
                                className="edit-del red "
                                onClick={() =>
                                  swal(
                                    "Sorry , You are not able to delete user."
                                  )
                                }
                              >
                                <DeleteIcon />
                              </div>
                            )} */}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colspan="9">
                      <div className="not_found d-flex justify-content-center align-items-center py-4">
                        <h4 className="my-4">No Data Found</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* 2nd table for pdf  */}
        <div style={{ display: "none" }} className="pt-4">
          <table
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "20px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={150} alt="" />
                <p className="mt-2 ms-4">
                  User List -
                  <span className="ms-3">Print Date : {currentDate} </span>
                  <span className="ms-3">Total Item : {getData?.length}</span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  className="ms-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                  }}
                >
                  <p className="ms-3 mt-3">Full Name</p>
                </th>
                <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                  <p className="ms-3 mt-3">User Type</p>
                </th>
                <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                  Phone
                </th>
                <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                  Created On
                </th>
                <th style={{ fontSize: "16px", fontWeight: "normal" }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData.length > 0 ? (
                getData
                  .slice(pagesVisited, pagesVisited + usersPerPage)
                  .map((user, index) => (
                    <tr key={user?.slug} className="table-row ">
                      <td>
                        <div className="d-flex ms-3">
                          <img
                            className="userListProfileimg"
                            src={user?.image ? `${user?.image} ` : profile}
                            alt=""
                          />
                          <div className="ms-3">
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                marginTop: "10px !important",
                              }}
                            >
                              {user.first_name} {user.last_name}
                            </span>{" "}
                            <br />
                            <span
                              style={{ fontSize: "12px", marginTop: "-10px" }}
                            >
                              {user.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm text-dark "
                          style={{
                            // backgroundColor: `${(user.role  && "#2F80ED") }`,
                            fontSize: "17px",
                          }}
                        >
                          {"Employee"}
                        </button>
                      </td>
                      <td>{user.mobile}</td>
                      <td>{user.joining_date?.split("T")[0]}</td>
                      <td className="">
                        <button
                          className={`status ${user?.is_active ? "c1" : "c2"}`}
                          style={{ color: `${user.StatusBtnColour}` }}
                        >
                          {user?.is_active ? "Active" : "Disable"}
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* 2nd table for pdf  */}
        {/* entites and paggination */}
        {getData.length > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {userList.length}
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeList;
