import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useHistory } from "react-router-dom";
import Sublink from "./components/Sublink";
import "./css/sideNav.css";

import Header from "./components/header/Header";
import RoutesFile from "../Routes/RoutesFile";
import DropDownNav from "./components/DropDownNav";
import SingleNav from "./components/SingleNav";
import { Navbar_items } from "./NavbarItems";
import { ArrowCircleRight, Power } from "phosphor-react";
import { useEffect } from "react";
import SideComponent from "./components/SideComponent/SideComponent";
import Notification_sm from "./components/small_Notfication/Notification_sm";
import { useRef } from "react";
import Profile from "./components/header/Profile";
import { showToast } from "../../utils/ToastHelper";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import Loader from "../CustomCommons/Loader";
import { logout_func2 } from "../Const/logoutCommon";

const SideNav = () => {
  // for nav dropdown
  const [Inventory, setInventory] = useState("Inventory");
  const [User, setUser] = useState("User");
  const [Sales, setSales] = useState("Sales");
  const [notificationCount, setNotificationCount] = useState({});
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [userData, setUserData] = useState({});

  console.log(userProfile?.hide_section, "data 32");
  // for nav dropdown
  const [navbarWIdth, setnavbarWIdth] = useState(1);
  const [Shownotification, SetShownotification] = useState(0);
  const [showUser, SetShowUser] = useState(1);
  const [arrow, setArrow] = useState(true);
  const ToggleNav = () => {
    setnavbarWIdth(!navbarWIdth);
  };
  const [HideNav, SetHideNav] = useState(0);
  const [CollapseState, SetCollapseState] = useState({
    Inventory: false,
    Sales: false,
    User: false,
    Reports: false,
    Customers: false,
  });
  const compareArrays = (array1, array2) => {
    if (Array.isArray(array1) && Array.isArray(array2)) {
      return array1.length >= array2.length ? array1 : array2;
    } else if (!Array.isArray(array1) && Array.isArray(array2)) {
      return array2;
    } else {
      // In all other cases, including when array1 is null and array2 is an array
      return array2;
    }
  };
  console.log(compareArrays(userProfile?.hide_section,JSON.parse(localStorage.getItem("userData"))?.hide_section),'data 62')
  const renderContent = (text, component) => {
    // const getData = JSON.parse(localStorage.getItem("userData"));
    // const data = 
    // getData && getData?.hide_section ? userData?.hide_section : [];
    const data = compareArrays(userProfile?.hide_section,JSON.parse(localStorage.getItem("userData"))?.hide_section)
    const isMatch = data?.some((option) => option.label === text);
    if (isMatch) {
      return <></>;
    } else {
      return component;
    }
  };
  const imgNotif = useRef();
  const userNotif = useRef();
  const userNotif2 = useRef();
  const userNotif3 = useRef();
  const inventory = useRef();
  const sales = useRef();
  const user = useRef();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (width < 900) {
      setnavbarWIdth(0);
    } else {
      setnavbarWIdth(1);
    }
  }, [width]);

  const HandleNotificationClick = () => {
    SetShownotification((prev) => !prev);
  };
  const HandleShowUser = () => {
    SetShowUser((prev) => !prev);
    setArrow((prev) => !prev);
  };

  useEffect(() => {
    const closeDropdown = (e) => {
      e.path !== undefined &&
        e.path[0] !== imgNotif.current &&
        SetShownotification(0);
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);
  useEffect(() => {
    const closeUser = (e) => {
      e.path !== undefined && e.path[0] !== userNotif.current && SetShowUser(1);
      e.path !== undefined && e.path[0] !== userNotif.current && setArrow(true);
    };
    document.body.addEventListener("click", closeUser);
    return () => document.body.removeEventListener("click", closeUser);
  }, []);
  // =====================

  const [notificationList, setNotificationList] = useState([]);
  const getNotification = () => {
    const url = `${BASE_URL}api/v1/users/inventory/notifications/`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        setNotificationList(result);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          history.push('/login')
          // logout_func2(0,history)
          setIsLoading(false)
        }
      });
  };
  const getNotificationCount = () => {
    const url = `${BASE_URL}api/v1/utility/admin/dashboard-notifications/`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data.data.results);
        const result = res.data.data.results;
        setNotificationCount(result);
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          history.push('/login')
          // logout_func2(0,history)
        }
        setIsLoading(false)
      });
  };
  useEffect(() => {
    getNotificationCount();
  }, []);
  const getUser = () => {
    const url = `${BASE_URL}api/v1/auth/profile/`;
    axios
      .get(url)
      .then((res) => {
        setIsLoading2(true);
        // console.log(res.data.data);
        // console.log(res.data.data.results);
        const result = res.data.data;
        setUserData(result);
        // console.log(userData,'-------------')
        setIsLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          history.push('/login')
          // logout_func2(0,history)
          setIsLoading(false)
        }
      });
  };
  useEffect(() => {
    getUser();
  }, []);

  const history = useHistory();
  const logout_func = () => {
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
    showToast("success", "Logged Out Successfully");

    history.push("/login");
  };

  if (isLoading) {
    return <Loader />;
  }
  // components
  const Dashboard = (
    <SingleNav
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      toggle={navbarWIdth}
      to={Navbar_items["Dashboard"].to}
      name={"Dashboard"}
      icon={Navbar_items["Dashboard"].icon}
      count={0}
    />
  );
  const PDashboard = (
    <SingleNav
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      toggle={navbarWIdth}
      to={Navbar_items["Categories"].to}
      name={"P. Dashboard"}
      icon={Navbar_items["Categories"].icon}
      count={0}
    />
  );
  const InventoryTab = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Inventory"
      icon={Navbar_items["Inventory"].icon}
      sublinks={Navbar_items["Inventory"].sublinks}
      sendName={"Inventory"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  const Combo = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Combo"
      icon={Navbar_items["Combo"].icon}
      sublinks={Navbar_items["Combo"].sublinks}
      sendName={"Combo"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  const NewArrival = (
    <DropDownNav
      toggle={navbarWIdth}
      name="New_Arrival"
      icon={Navbar_items["New_Arrival"].icon}
      sublinks={Navbar_items["New_Arrival"].sublinks}
      sendName={"New_Arrival"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  const Trending = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Trending"
      icon={Navbar_items["Trending"].icon}
      sublinks={Navbar_items["Trending"].sublinks}
      sendName={"Trending"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  const Festival = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Festival"
      icon={Navbar_items["Festival"].icon}
      sublinks={Navbar_items["Festival"].sublinks}
      sendName={"Festival"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  const Matching = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Matching"
      icon={Navbar_items["Matching"].icon}
      sublinks={Navbar_items["Matching"].sublinks}
      sendName={"Matching"}
      ref={inventory}
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      object={notificationCount[0]}
    />
  );
  
  const SalesTab = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Sales"
      icon={Navbar_items["Sales"].icon}
      sublinks={Navbar_items["Sales"].sublinks}
      sendName={"Invoice List"}
      ref={sales}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      count={notificationCount[0]?.invoice}
      object={notificationCount[0]}
    />
  );
  const Customers = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Customers"
      icon={Navbar_items["Customers"].icon}
      sublinks={Navbar_items["Customers"].sublinks}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      object={notificationCount[0]}
    />
  );
  const Outlets = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Outlets"
      icon={Navbar_items["Outlets"].icon}
      sublinks={Navbar_items["Outlets"].sublinks}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      object={notificationCount[0]}
    />
  );
  const Promotions = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Promotions"
      icon={Navbar_items["Promotions"].icon}
      sublinks={Navbar_items["Promotions"].sublinks}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      count={notificationCount[0]?.review}
      object={notificationCount[0]}
    />
  );
  const Settings = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Settings"
      icon={Navbar_items["Settings"].icon}
      sublinks={Navbar_items["Settings"].sublinks}
      sendName={"Settings"}
      // ref={user}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      object={notificationCount[0]}
    />
  );
  const Reports = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Reports"
      icon={Navbar_items["Reports"].icon}
      sublinks={Navbar_items["Reports"].sublinks}
      sendName={"Reports"}
      // ref={user}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      object={notificationCount[0]}
    />
  );
  const Custom = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Custom"
      icon={Navbar_items["Custom"].icon}
      sublinks={Navbar_items["Custom"].sublinks}
      sendName={"Custom"}
      // ref={user}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
    />
  );
  const Purchase = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Purchase"
      icon={Navbar_items["Purchase"].icon}
      sublinks={Navbar_items["Purchase"].sublinks}
      sendName={"Purchase"}
      // ref={user}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
    />
  );
  const Requisition = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Requisition"
      icon={Navbar_items["Requisition"].icon}
      sublinks={Navbar_items["Requisition"].sublinks}
      sendName={"Requisition"}
      // ref={user}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
    />
  );
  const NotificationCom = (
    <SingleNav
      SetCollapseState={SetCollapseState}
      CollapseState={CollapseState}
      toggle={navbarWIdth}
      to={Navbar_items["Notification"].to}
      name={"Notification"}
      icon={Navbar_items["Notification"].icon}
      count={notificationCount[0]?.notification}
      object={notificationCount[0]}
    />
  );
  const Users = (
    <DropDownNav
      toggle={navbarWIdth}
      name="User"
      icon={Navbar_items["User"].icon}
      sublinks={Navbar_items["User"].sublinks}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      count={notificationCount[0]?.user}
      object={notificationCount[0]}
    />
  );
  const Employe = (
    <DropDownNav
      toggle={navbarWIdth}
      name="Employe"
      icon={Navbar_items["Employe"].icon}
      sublinks={Navbar_items["Employe"].sublinks}
      CollapseState={CollapseState}
      SetCollapseState={SetCollapseState}
      count={notificationCount[0]?.user}
      object={notificationCount[0]}
    />
  );
  return (
    <>
      <div className="mainContainer">
        {!HideNav && (
          <Header
            imgRef={imgNotif}
            navbarWIdth={navbarWIdth}
            onBurgerClick={ToggleNav}
            HandleNotificationClick={HandleNotificationClick}
            userNotif={userNotif}
            getNotification={getNotification}
            HandleShowUser={HandleShowUser}
            arrow={arrow}
          />
        )}
        <div
          id="body-pd"
          className={`${!HideNav & navbarWIdth ? "body-pd" : ""}`}
        >
          <div
            className={` ${!HideNav ? "l-navbar" : "hide-navbar"} ${
              !HideNav & navbarWIdth ? "expander" : ""
            }`}
            id="navbar"
          >
            <nav className="nav">
              <div>
                <div className="nav__brand"></div>
                <div className="nav__list mt-3">
                  {renderContent("Dashboard", Dashboard)}
                  {renderContent("P. Dashboard", PDashboard)}
                  {renderContent("Inventory", InventoryTab)}
                  {/* {renderContent("Combo", Combo)} */}
                  {renderContent("New Arrivals", NewArrival)}
                  {renderContent("Trending", Trending)}
                  {renderContent("Festival", Festival)}
                  
                  {/* {renderContent("Matching", Matching)} */}
                 
                  {renderContent("Sales", SalesTab)}
                  {/* <DropDownNav
                    toggle={navbarWIdth}
                    name="Draft"
                    icon={Navbar_items["Draft"].icon}
                    sublinks={Navbar_items["Draft"].sublinks}
                    sendName={"Draft"}
                    ref={sales}
                    CollapseState={CollapseState}
                    SetCollapseState={SetCollapseState}
                    count={notificationCount[0]?.invoice}
                    object={notificationCount[0]}
                  /> */}

                  {renderContent("Customers", Customers)}
                  {renderContent("Outlets", Outlets)}
                  {renderContent("Promotions", Promotions)}
                  {renderContent("Settings", Settings)}
                  {renderContent("Reports", Reports)}
                  {renderContent("Custom", Custom)}
                  {renderContent("Purchases", Purchase)}
                  {renderContent("Requisitions", Requisition)}
                  {renderContent("Notifications", NotificationCom)}
                  {/* <DropDownNav
                    toggle={navbarWIdth}
                    name="User"
                    icon={Navbar_items["User"].icon}
                    sublinks={Navbar_items["User"].sublinks}
                    CollapseState={CollapseState}
                    SetCollapseState={SetCollapseState}
                    count={notificationCount[0]?.user}
                    object={notificationCount[0]}
                  /> */}
                  {renderContent("Employee", Employe)}
                  {renderContent("User", Users)}
                </div>
              </div>

              <div onClick={logout_func} to="#" className="logout_nav__link">
                <Power className="logout__nav__icon" size={24} />
                {navbarWIdth && (
                  <span className="logout__nav__name">Log Out</span>
                )}
              </div>
            </nav>
          </div>
          <div className={`${!HideNav && "OtherComponents"} `}>
            {/* <div className={`OtherComponents ${navbarWIdth&&"blur_background"}`}></div> */}
            <Notification_sm
              notificationList={notificationList ? notificationList : []}
              show={Shownotification}
            />
            {!showUser && <Profile />}
            {/* <a href="" style={{color:'black'}}> */}
            <SideComponent HideNav={HideNav} setHideToolbar={SetHideNav} />
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNav;
