import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Const/Url";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../CustomCommons/Loader";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import * as path from "../../Routes/RoutePaths";
const ViewTestimonial = () => {
    const history = useHistory()
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const url = `${BASE_URL}api/v1/utility/admin/testimonial/${id}/`;
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  useEffect(() => {
    apiFunction(url);
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            View Testimonial
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={data?.name}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Designation"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={data?.designation}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Comment"} />
            <InputGroup className="mb-3" style={{ height: "100px" }}>
              <Form.Control
                aria-label="Username"
                as={'textarea'}
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={data?.comment}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <p>Thumbnail</p>
            <div>
                <img src={data?.thumb_url} width={100}  alt="" />
            </div>
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
           
            <button
                onClick={()=>history.push(path.testimonials)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTestimonial;
