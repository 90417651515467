import React, { useEffect } from "react";
import { useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import eye from "../../../assets/Icon/eye.svg";
import { showToast } from "../../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import * as path from "../../Routes/RoutePaths";
import Loader from "../../CustomCommons/Loader";
const EditCupon = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [invoice_discount_type, setinvoice_discount_type] = useState(1);
  const [cuponData, setCuponData] = useState({
    code: "",
    discount_amount: "0",
    minimum_purchase: "0",
    max_usage: "0",
    start: "",
    end: "",
  });
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data.data;
        setCuponData({
          discount_amount: data?.discount_amount,
          minimum_purchase: data?.minimum_purchase,
          max_usage: data?.max_usage,
          start: data.start,
          end: data.end,code:data?.code
        });
        setinvoice_discount_type(data?.discount_type)
        setCategoryswitch(data?.is_active)
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  const url = `${BASE_URL}api/v1/promotions/admin/coupon/${id}`;
  useEffect(() => {
    apiFunction(url);
  }, []);
  const addCupon = () => {
    const url = `${BASE_URL}api/v1/promotions/admin/coupon/${id}/`;
    if (cuponData["code"] === "") {
      showToast("error", "Offer name is not valid..");
    } else {
      const data = {
        code: cuponData.code,
        discount_amount: cuponData?.discount_amount,
        minimum_purchase: cuponData.minimum_purchase,
        max_usage: cuponData.max_usage,
        start: cuponData.start,
        end: cuponData.end,
        is_active: Categoryswitch,
        discount_type: invoice_discount_type,
      };
      axios
        .patch(url, data)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Coupon Updated.");
            history.push(path.cupons);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          showToast("error", message);
        });
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Edit Coupon</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Offer Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="code"
                value={cuponData["code"]}
                onChange={(e) => handleInputs(e, setCuponData)}
              />
            </InputGroup>
          </div>
          <div className="d-none d-md-block col-md-6"></div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Discount Type"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) =>
                  setinvoice_discount_type(Number(e.target.value))
                }
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={invoice_discount_type}
              >
                <option value={1}>Flat </option>
                <option value={0}>Percentage</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-12 col-md-6">
          <RequiredLabel
              text={`Discount ${invoice_discount_type === 0 ? "%" : "Amount "}`}
            />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="discount_amount"
                min={0}
                value={cuponData["discount_amount"]}
                onChange={(e) => handleInputs(e, setCuponData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Minimum Purchase"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="minimum_purchase"
                min={0}
                value={cuponData["minimum_purchase"]}
                onChange={(e) => handleInputs(e, setCuponData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Max Usages"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="max_usage"
                min={0}
                value={cuponData["max_usage"]}
                onChange={(e) => handleInputs(e, setCuponData)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text=" Start Date" />
            <div className="position-relative">
              <InputGroup className="mb-3">
                <Form.Control
                  type="date"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  placeholder="Select a date"
                  name="start"
                  value={cuponData["start"]}
                  onChange={(e) => handleInputs(e, setCuponData)}
                />
              </InputGroup>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text="Offer Expiry Date" />
            <div className="position-relative">
              <InputGroup className="mb-3">
                <Form.Control
                  type="date"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  placeholder="Select a date"
                  name="end"
                  value={cuponData["end"]}
                  onChange={(e) => handleInputs(e, setCuponData)}
                />
              </InputGroup>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <h5 style={{ marginTop: "30px" }}>Active Status</h5>
            <p style={{ color: "#8E8E93" }}>( If the Offer is available )</p>
            <div className="row mr-4">
              <div
                className=" d-flex justify-content-between col-12 py-3"
                style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
              >
                <div className="d-flex w-100">
                  <div
                    className="d-flex py-1 justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#212121",
                      width: "32px",
                      height: "32",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={eye} alt="" />
                  </div>
                  <span className="mx-3">{Categoryswitch ? "ON" : "OFF"}</span>
                </div>

                <div class="form-check form-switch ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    width={40}
                    height={40}
                    name="category_active"
                    checked={Categoryswitch}
                    onClick={toggleSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={addCupon}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.cupons)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCupon;
