import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import catIcon from "../../assets/Icon/category icon.svg";
import productIcon from "../../assets/Icon/Product icon.svg";
import salesIcon from "../../assets/Icon/sales Icon.svg";
import invoiceIcon from "../../assets/Icon/invoice icon.svg";
import { useHistory, useParams } from "react-router-dom";
import { logout_func2 } from "../Const/logoutCommon";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import Loader from "../CustomCommons/Loader";
import swal from "sweetalert";
import { showToast } from "../../utils/ToastHelper";
import ViewIcon from "../CustomCommons/ViewIcon";
import EditIcon from "../CustomCommons/EditIcon";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import NextIcon from "../CustomCommons/NextIcon";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import {
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import { Link } from "react-router-dom";
import SalesFilter from "../dashboard/SalesFilter";

const ExchangeOutletList = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const history = useHistory();
  const { id } = useParams();
  const [outletData, setOutletData] = useState({});
  const [outlet,setOutlet] = useState({})
  const [Loading2, setLoading2] = useState(true);
  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/auth/dashboard/?outlet=${id}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOutletData(result);
        setLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  const getOutletName = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/${id}/`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOutlet(result);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  useEffect(() => {
    // getOutlet();
    // getOutletName()
  }, []);

  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [query, setQuery] = useState("");

  // ofset code
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  const [total_item, setTotal_item] = useState(0);
  const [isLoading22, SetisLoading22] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 15;
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        setLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&outlet=${id}&is_exchanged=true`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading2(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading2(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading2(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);

  useEffect(() => {
    if (pageNumber > 1 || query) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=false&outlet=${id}&is_exchanged=true`
      );
    }
  }, [pageNumber, query]);

  // ofset code
  const updatedPageNumber = parseInt(
    localStorage.getItem("invoicePageNbr")
      ? localStorage.getItem("invoicePageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;

  const [invoiceList, setInvoiceList] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);

  const invoice_choices = ["Recieved", "Exchange", "Refunded",'Exchange'];

  const delivery_choices = [
    "Returned",
    "Order Placed",
    "Delivered",
    "Pending",
    "Hold",
    "Dispached",
  ];
  //  const invoice_choices = [
    // { value: 0, label: "Recieved" },
    // { value: 1, label: "Exchange" },
    // { value: 2, label: "Refunded" },
    // { value: 3, label: "Exchange" },
  // ];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "Cash",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
    "Rocket",
    "Mixed",
  ];
  
  const keys = ["number", "prepared_by", "delivery_status", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );

  useEffect(() => {
    // getInvoiceList();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);

  const deleteData = (id2) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id2}`;
        axios
          .delete(url)
          .then(() => {
            // getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_custom=false&outlet=${id}&is_exchanged=true`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });

        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  if (Loading2) {
    return <Loader />;
  }
  return (
    <div>
        <div className="invoice-main ">
          <div className="head-select my-2">
            <div>
              <h5>Outlet Invoice Exchange</h5>
              <input
                type="text"
                style={{ color: "black" }}
                className="filter-input"
                placeholder="Search here"
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: "block", overflowX: "auto" }}>
            <table className="table_my">
              <thead
                className="bg-dark text-white head-row"
                style={{
                  height: "50px",
                  borderTopLeftRadius: "20px !important",
                }}
              >
                <tr className="">
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "166px",
                    }}
                  >
                    <p className="ms-3 my-auto">Invoice No</p>
                  </th>
                 
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "147px",
                    }}
                  >
                    Delivery Status
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                   Status
                  </th>
                 
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "170px",
                    }}
                  >
                    Payment Type
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "120px",
                    }}
                  >
                    Total
                  </th>

                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "130px",
                    }}
                  >
                    Create On
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "140px",
                    }}
                  >
                    Last Modified
                  </th>
                  <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      width: "140px",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="tb">
                {items_data.length > 0 && items_data ? (
                  items_data.map((category, index) => (
                    <tr className="table-row " key={category?.slug}>
                      <td>
                        <p className="ms-3 my-auto">{category.number}</p>
                      </td>
                      

                      <td>
                        <button
                          className={`status1 ${
                            (category.delivery_status === 0 &&
                              "bg-danger text-white") ||
                            (category.delivery_status === 1 &&
                              "c5 text-white") ||
                            (category.delivery_status === 2 &&
                              "bg-success text-white ")
                          }`}
                        >
                          {delivery_choices[category.delivery_status]}
                        </button>
                      </td>

                      <td>
          
                          {invoice_choices[category.payment_status]}
                       
                      </td>
                     
                      <td> {paymentType[category?.payment_type]}</td>
                      <td>৳ {category?.total_amount}</td>
                      
                      <td>{category?.invoice_date}</td>
                      <td className="ms-1">{category.last_modified_by}</td>
                      <td className="action">
                    <div className="d-flex justify-content-center gap-3">
                     
                      <div
                        className="edit-del blue"
                        onClick={() =>
                          {
                            if(category?.payment_status === 3){
                              // showToast('success','Edit Exchange Invoice Currently Unavailable')
                              history.push(`/outlet/edit_exchange_invoice/${category?.slug}`)
                            }else{
                              history.push(`/outlet/edit_invoice/${category?.slug}`)
                            }
                          }
                          
                        }
                      >
                        <EditIcon />
                      </div>

                      {has_permissions(can_delete_invoice) ? (
                          <div
                            className="edit-del red"
                            onClick={() => deleteData(category?.slug)}
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del red"
                            onClick={() =>
                              swal(
                                "Sorry , You are not able to delete invoice."
                              )
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                     
                    </div>
                  </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="9">
                      <div className="not_found d-flex justify-content-center align-items-center py-4">
                        <h4 className="my-4">No Data Found</h4>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* ----------2nd Table for pdf print------*/}
          {/* entites and paggination */}
          {totalData > 0 && (
            <div className="row pag" style={{ marginTop: "30px" }}>
              <div className="col-6 col-md-5">
                <p
                  className="inter"
                  style={{ color: "#AEAEB2", fontSize: "16px" }}
                >
                  Total Entries:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {totalData}
                  </span>
                  <span className="ms-2"></span>
                  Total Page:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {Math.ceil(totalData / limit)}
                  </span>
                </p>
              </div>
              {/* <div className="col-12 col-md-6 d-flex paggination-button">
              <ReactPaginate
                previousLabel={<PreviousIcon />}
                nextLabel={<NextIcon />}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"a"}
                nextLinkClassName={"a"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                forcePage={
                  query?.length > 0 || getData?.length < usersPerPage
                    ? 0
                    : updatedPageNumber
                }
              />
            </div> */}
              <div className="col-12 col-md-6 d-flex paggination-button">
                <>
                  <div className="row lead_pagination bg-transparent">
                    <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                      <nav aria-label="...">
                        <ul className="pagination pagination-sm">
                          {!Loading2 ? (
                            <li className="page-item ">
                              {prevUrl !== null ? (
                                <Link
                                  onClick={handlePaginationPrevious}
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast(
                                      "success",
                                      "You are on first page"
                                    )
                                  }
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item ">
                              <Link
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            </li>
                          )}

                          {[...Array(pageCount === null ? 1 : pageCount)].map(
                            (elem, index) => (
                              <li
                                className="page-item"
                                style={{
                                  display: `${
                                    index + 1 !== pageNumber ? "none" : "block"
                                  }`,
                                }}
                              >
                                {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                                {/* setPageNumber(prev=> prev - 1) */}

                                <Link
                                  onClick={() =>
                                    handleChangeCurrentPage(index + 1)
                                  }
                                  className={`${
                                    index + 1 == pageNumber
                                      ? "paginationActive"
                                      : ""
                                  } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                  to="#"
                                >
                                  <span className="px-1 py-1">
                                    {pageNumber}
                                  </span>
                                </Link>
                              </li>
                            )
                          )}
                          {!Loading2 ? (
                            <li className="page-item">
                              {nextUrl !== null ? (
                                <Link
                                  onClick={handlePaginationNext}
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />{" "}
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast("success", "You are on last page")
                                  }
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item">
                              <Link
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      
    </div>
  );
};

export default ExchangeOutletList;
