import React, { useEffect, useState } from "react";
import districts from "../../../assets/json/bd_districts.json";
import upazillas from "../../../assets/json/bd_upazillas.json";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import Select from "react-select";
import { Form, InputGroup } from "react-bootstrap";
import { showToast } from "../../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { useHistory } from "react-router-dom";
import * as path from "../../Routes/RoutePaths";
import { useParams } from "react-router-dom";
import { logout_func2 } from "../../Const/logoutCommon";
import Loader from "../../CustomCommons/Loader";

const EditCharge = () => {
  const { id } = useParams();
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const history = useHistory();
  const [districtOption, setDistrictOption] = useState([]);
  const [upazilaOption, setUpazilaOption] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({ label: "", value: "" });
  const [selectedUpazila, setSelectedUpazila] = useState([]);
  const [charge, setCharge] = useState(0);

  useEffect(() => {
    const newArray = districts.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setDistrictOption(newArray);
  }, [districts]);

  useEffect(() => {
    const newArray = upazillas.filter(({ district_id }) => district_id == selectedDistrict.value).map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setUpazilaOption(newArray);
  }, [selectedDistrict]);

  const handleDistrictInputChange = (value) => {
    if (value === null) {
      setSelectedDistrict({ label: "", value: "" })
    } else {
      setSelectedDistrict(value)
    }
  }

  const handleMultiInputChange = (newValue, actionMeta) => {
    setSelectedUpazila(newValue);
  };
  //   console.log(charge)
  const postCharge = () => {
    const url = `${BASE_URL}api/v1/utility/admin/custom-delivery-charge/${id}/`;
    const data = new FormData();
    if (charge === 0) {
      showToast("error", "Please delivery charge.");
      return 0;
    } else if (selectedUpazila?.length === 0) {
      showToast("error", "Please delivery charge.");
      return 0;
    } else {
      const postData = {
        price: charge,
        upazilla: selectedUpazila?.map((data) => data.value),
      };
      axios
        .patch(url, postData)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Delivery Charge updated.");
            history.push(path.delivery_charge_list);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          showToast("error", message);
        });
    }
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        const result = res.data.data;
        setData(res.data.data);
        setCharge(result.price);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/utility/admin/custom-delivery-charge/${id}/`;
    apiFunction(url);
  }, []);
  console.log({
    charge: charge,
    selectd: selectedUpazila,
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <div
      style={{ maxHeight: "100vh" }}
      className="categorey-parent new-categorey-parent an"
    >
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Add Delivery Charge
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 ">
            <RequiredLabel text={"Select District"} />
            <Select
              // styles={styles}
              placeholder="---Select---"
              isClearable
              // defaultValue={selectedOption}
              onChange={handleDistrictInputChange}
              // onInputChange={inputProduct}
              options={districtOption}
              // value={defaultValue}
            />
          </div>
          <div className="col-12 ">
            <RequiredLabel text={"Select Upazilas"} />
            <Select
              // styles={styles}
              isMulti
              placeholder="---Select---"
              isClearable
              // defaultValue={selectedOption}
              onChange={handleMultiInputChange}
              // onInputChange={inputProduct}
              defaultValue={data?.upazill_details.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              options={upazilaOption}
              // value={defaultValue}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <RequiredLabel text={"Price"} />
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                min={0}
                aria-label="Username"
                name="product_price"
                onChange={(e) => setCharge(e.target.value)}
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                value={charge}
              />
            </InputGroup>
          </div>

          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={postCharge}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.delivery_charge_list)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCharge;
