import React from "react";
import * as path from "../Routes/RoutePaths";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import { logout_func2 } from "../Const/logoutCommon";
import Loader from "../CustomCommons/Loader";
import NextIcon from "../CustomCommons/NextIcon";
import { showToast } from "../../utils/ToastHelper";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import ViewIcon from "../CustomCommons/ViewIcon";
const OutletToOutletReturn = () => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [isLoading2, SetisLoading2] = useState(true);
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [items_data, setItems_data] = useState([]);
  // ofset code
  const [total_item, setTotal_item] = useState(0);

  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [checkedIds, setCheckedIds] = useState([]);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/inventory/admin/return-chalan/"}?limit=${limit}&offset=${offset}&query=${query}&rpc_type=1`
    );
    // apiFunction(
    //   `${BASE_URL}${"api/v1/inventory/admin/outlet-product-return/"}?limit=${limit}&offset=${offset}&query=${query}&outlet=${
    //     userProfile?.outlet
    //   }`
    // );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);

    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  useEffect(() => {
    if (query || pageNumber > 1) {
      apiFunction(
        `${BASE_URL}${"api/v1/inventory/admin/return-chalan/"}?limit=${limit}&offset=${offset}&query=${query}&rpc_type=1`
      );
      // apiFunction(
      //   `${BASE_URL}${"api/v1/inventory/admin/outlet-product-return/"}?limit=${limit}&offset=${offset}&query=${query}&outlet=${
      //     userProfile?.outlet
      //   }`
      // );
    }
  }, [query]);

  // ofset code
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };

  if (isLoading2) {
    return <Loader />;
  }

  return (
    <div className="categorey-parent">
      <div className="cotagorey-head mb-4">
        <div className="d-flex align-align-items-baseline">
          <div>
            <h3>Outlet To Oulet Return </h3>
            <input
              style={{ color: "black" }}
              type="text"
              className="filter-input"
              placeholder="Search here"
              onChange={(e) => {
                const selected = 0;
                setQuery(e.target.value.toLocaleLowerCase());
              }}
            />
          </div>
        </div>
        {/* <div className="filter_div">
          <div
            className="button-div my-auto mx-2 "
            style={{ background: "black", cursor: "pointer" }}
            onClick={() => history.push(path.add_return_products)}
          >
            <span> Return Product</span>
          </div>
        </div> */}
      </div>
      <div style={{ display: "block", overflowX: "auto" }}>
        <table className="table_my pdf-table my-3">
          <thead
            className="bg-dark text-white head-row mt-3 main-head-print"
            style={{ height: "50px" }}
          >
            <tr className="">
              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "100px",
                  padding: "10px",
                  borderTopLeftRadius: "4px ",
                }}
              >
                #ID
              </th>
              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "250px",
                }}
              >
                Outlet Name
              </th>

              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "200px",
                }}
              >
                Number
              </th>

              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "180px",
                }}
              >
                Last Modified
              </th>

              <th
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  width: "90px",
                }}
              >
                Created By
              </th>

              <th
                className="action"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  borderTopRightRadius: "4px ",
                  width: "135px",
                  textAlign: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="tb">
            {items_data.length > 0 && items_data ? (
              items_data.map((category, index) => (
                <tr className="table-row " key={category?.slug}>
                  {/* <td className="text-center">{category?.slug}</td> */}

                  <td className="ms-2">{index + 1}</td>
                  <td>{category?.outlet_name}</td>
                  <td>{category?.number}</td>
                  <td>
                    {new Date(category.updated_at).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </td>

                  <td>{category.created_by_name}</td>

                  <td className="action">
                    <div className="d-flex justify-content-center gap-3">
                      <div
                        className="edit-del blue"
                        onClick={() =>
                          history.push(
                            `/outlet/outlet_to_outlet_return_list/${category?.id}`
                          )
                        }
                      >
                        <ViewIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="9">
                  <div className="not_found d-flex justify-content-center align-items-center py-4">
                    <h4 className="my-4">No Data Found</h4>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalData > 0 && (
        <div className="row pag" style={{ marginTop: "30px" }}>
          <div className="col-6 col-md-5">
            <p className="inter" style={{ color: "#AEAEB2", fontSize: "16px" }}>
              Total Entries:{" "}
              <span style={{ color: "black", fontSize: "14px" }}>
                {totalData}
              </span>
              <span className="ms-2"></span>
              Total Page:{" "}
              <span style={{ color: "black", fontSize: "14px" }}>
                {Math.ceil(totalData / 20)}
              </span>
            </p>
          </div>

          <div className="col-12 col-md-6 d-flex paggination-button">
            <>
              <div className="row lead_pagination bg-transparent">
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                  <nav aria-label="...">
                    <ul className="pagination pagination-sm">
                      {!isLoading2 ? (
                        <li className="page-item ">
                          {prevUrl !== null ? (
                            <Link
                              onClick={handlePaginationPrevious}
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          ) : (
                            <Link
                              onClick={() =>
                                showToast("success", "You are on first page")
                              }
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li className="page-item ">
                          <Link
                            className={` ${
                              prevUrl === null ? "disabled" : ""
                            } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                            to="#"
                            tabindex="-1"
                          >
                            <PreviousIcon />
                          </Link>
                        </li>
                      )}

                      {[...Array(pageCount === null ? 1 : pageCount)].map(
                        (elem, index) => (
                          <li
                            className="page-item"
                            style={{
                              display: `${
                                index + 1 !== pageNumber ? "none" : "block"
                              }`,
                            }}
                          >
                            {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                            {/* setPageNumber(prev=> prev - 1) */}

                            <Link
                              onClick={() => handleChangeCurrentPage(index + 1)}
                              className={`${
                                index + 1 == pageNumber
                                  ? "paginationActive"
                                  : ""
                              } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                              to="#"
                            >
                              <span className="px-1 py-1">{pageNumber}</span>
                            </Link>
                          </li>
                        )
                      )}
                      {!isLoading2 ? (
                        <li className="page-item">
                          {nextUrl !== null ? (
                            <Link
                              onClick={handlePaginationNext}
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />{" "}
                            </Link>
                          ) : (
                            <Link
                              onClick={() =>
                                showToast("success", "You are on last page")
                              }
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li className="page-item">
                          <Link
                            className={`${
                              nextUrl === null ? "disabled" : ""
                            } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                            to="#"
                          >
                            <NextIcon />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutletToOutletReturn;
