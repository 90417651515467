import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { BASE_URL } from "./Url";
import axios from "axios";
const Checkbox = (props) => (
  <input style={{ transform: "scale(1.3)" }} type="checkbox" {...props} />
);

const SelectOpenWithPaggination = ({
  handleMultiInputChange,
  inputProduct,
  ProductsList,
  defaultValue,
  setProductsList,
  inputQuery,
}) => {
  const ref = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);
  const toggleMenuIsOpen = () => {
    setMenuIsOpen((value) => !value);
    const selectEl = ref.current;
    if (!selectEl) return;
    if (menuIsOpen) selectEl.blur();
    else selectEl.focus();
  };
  //   const getProductList = () => {
  //     if (inputQuery?.length > 1) {
  //       let url;
  //       if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
  //         url = `${BASE_URL}api/v1/inventory/inventory/product_list_for_invoice_pagination/`;
  //       } else if (inputQuery) {
  //         url = `${BASE_URL}api/v1/inventory/inventory/product_list_for_invoice_pagination/?query=${inputQuery}`;
  //       }
  //       axios
  //         .get(url)
  //         .then((res) => {
  //           // console.log("productlistinvoice", res.data);
  //           const previous = res.data.data.previous
  //           const next = res.data.data.next
  //           const result = res.data.data.results;
  //           const options = result.map((curr) => ({
  //             label: curr.name + " " + "[[" + curr?.sku + "]]",
  //             value: curr.id,
  //             price: curr.price,
  //             discount: curr.discount,
  //             stock: curr.stock,
  //             variant: curr.variants,
  //             thumb: `${curr.thumb_url}`,
  //           }));

  //           setProductsList(options);
  //           // SetisLoading(false);
  //         })
  //         .catch((err) => {
  //           // const message = JSON.parse(err.request.response).message;
  //           // console.log(err.request.response);
  //         });
  //     }
  //   };
  const getProductList = (
    inputQuery,
    setProductsList,
    setLoading,
    next,
    setNext
  ) => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}api/v1/inventory/inventory/product_list_for_invoice_pagination/`;
      } else if (inputQuery) {
        url = `${BASE_URL}api/v1/inventory/inventory/product_list_for_invoice_pagination/?query=${inputQuery}&limit=20`;
      }

      setLoading(true); // Set loading state to true

      axios
        .get(url)
        .then((res) => {
          const { results } = res.data.data;

          const options = results.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
            image: `${
              curr?.thumb_resized_url
                ? curr.thumb_resized_url
                : `${BASE_URL}media/default/lazy_placeholder.jpg`
            }`,
          }));
          setProductsList(options); // Append new data to the existing list
          setNext(res.data.data.next); // Update next state

          setLoading(false); // Set loading state to false
        })
        .catch((err) => {
          console.error("Error fetching product list:", err);
          setLoading(false); // Set loading state to false even if there's an error
        });
    }
  };
  const getProductListNext = (
    inputQuery,
    setProductsList,
    setLoading,
    next,
    setNext
  ) => {
    setLoading(true); // Set loading state to true

    axios
      .get(next)
      .then((res) => {
        const { results } = res.data.data;

        const options = results.map((curr) => ({
          label: curr.name + " " + "[[" + curr?.sku + "]]",
          value: curr.id,
          price: curr.price,
          discount: curr.discount,
          stock: curr.stock,
          variant: curr.variants,
          thumb: `${curr.thumb_url}`,
          image: `${
            curr?.thumb_resized_url
              ? curr.thumb_resized_url
              : `${BASE_URL}media/default/lazy_placeholder.jpg`
          }`,
        }));
        setProductsList((prevList) => [...prevList, ...options]); // Append new data to the existing list
        setNext(res.data.data.next); // Update next state

        setLoading(false); // Set loading state to false
      })
      .catch((err) => {
        console.error("Error fetching product list:", err);
        setLoading(false); // Set loading state to false even if there's an error
      });
  };

  useEffect(() => {
    let timeoutId;
    const delayedFetch = () => {
      timeoutId = setTimeout(() => {
        getProductList(inputQuery, setProductsList, setLoading, next, setNext);
      }, 1000); //
    };
    delayedFetch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputQuery]);
  useEffect(() => {
    if (ProductsList?.length > 0) {
      setMenuIsOpen(true);
    }
  }, [ProductsList]);
  const handleLoadMore = () => {
    getProductListNext(inputQuery, setProductsList, setLoading, next, setNext);
  };
  // useEffect(() => {
  //   if ( next !== null && !loading) {
  //     setTimeout(handleLoadMore, 2000);
  //   }

  // }, [next, loading]);

  const CustomOption = ({ innerRef, innerProps, data }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option"
      style={{ display: "flex", alignItems: "center", height: "35px" }}
    >
      <img
        src={data.image}
        alt=""
        style={{ width: "30px", marginRight: "10px", marginLeft: "10px" }}
      />
      <span style={{ cursor: "pointer" }}>{data.label}</span>
    </div>
  );

  return (
    <div className="main-select">
      <div className="my-2 d-flex justify-content-between align-items-center gap-3">
        <div>
          <Checkbox
            checked={menuIsOpen}
            onChange={toggleMenuIsOpen}
            id="cypress-single__clearable-checkbox"
          />
          <span className="ms-2">{menuIsOpen ? "Close" : "Open"}</span>
        </div>
        <div>
          {next !== null && (
            <button
              className="btn btn-danger"
              onClick={handleLoadMore}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          )}
        </div>
      </div>

      <div className="custom-menu">
        <Select
          components={{ Option: CustomOption }}
          ref={ref}
          menuIsOpen={menuIsOpen}
          isMulti
          placeholder="---Select---"
          isClearable
          onChange={handleMultiInputChange}
          onInputChange={inputProduct}
          options={ProductsList}
          value={defaultValue}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "blue" : "black",
              boxShadow: state.isFocused ? "0 0 0px 0.5px rgba(255, 0, 0, 1)" : "none",
              "&:hover": {
                borderColor: "blue",
              },
              maxHeight: "120px",
              overflowY: "auto"
            }),
            multiValueContainer: (baseStyles) => ({
              ...baseStyles,
              overflowY: "auto",
              maxHeight: "120px",
              display: "flex",
              flexWrap: "wrap",
            }),
          }}
        />
      </div>
    </div>
  );
};

export default SelectOpenWithPaggination;
