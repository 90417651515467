import React from "react";
import "../CustomCommons/css/CustomModal.css";

const CustomModal = ({ isOpen, onClose, children }) => {
  const modalClasses = `modal-overlay-custom ${isOpen ? "open" : ""}`;
  const contentClasses = `modal-content-custom ${isOpen ? "open" : ""}`;

  return (
    <div className={modalClasses}>
      <div className={contentClasses}>
        <div  className="d-flex justify-content-end ">
          <span style={{cursor:"pointer",fontSize:'20px',margin:'0 10px'}} className="close-button cursor-pointer" onClick={onClose}>
            &times;
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};
export default CustomModal;
