import React, { useEffect, useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Select from "react-select";
import eye from "../../../assets/Icon/eye.svg";
import swal from "sweetalert";
import { showToast } from "../../../utils/ToastHelper";
import * as path from "./../../Routes/RoutePaths";
import { useHistory } from "react-router-dom";

const AddBanner = () => {
  const history = useHistory();
  const [fileName, setFileName] = useState("");
  const [Categoryswitch, setCategoryswitch] = useState(true);

  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [offerAllData, setOfferAllData] = useState({
    name: "",
    discount_value: "",
    expiry_date: "",
    start_date: "",
    description: "",
    order: 1,
  });
  const [description, setDescription] = useState("");
  console.log(offerAllData, "offer");
  const [selectProduct, setSelectProduct] = useState([]);
  const [invoice_discount_type, setinvoice_discount_type] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };

  const postOffer = () => {
    const url = `${BASE_URL}api/v1/promotions/admin/banner/`;
    if (offerAllData["name"] === "") {
      showToast("error", "Banner name is not valid..");
    }
    if (!thumbId) {
      showToast("error", "Please select a image first");
    } else {
      const data = {
        name: offerAllData["name"],
        is_active: Categoryswitch,
        image: thumbId ? thumbId : null,
        main_category: invoice_discount_type,
        description: ".",
        order: offerAllData["order"]
      };
      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Banner Created.");
            history.push(path.banner);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          showToast("error", message);
        });
    }
  };
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Add Banner</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Title"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={offerAllData["name"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
              />
            </InputGroup>
          </div>

          <div className="col-12 col-md-6">
            <RequiredLabel text={"Category"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setinvoice_discount_type(e.target.value)}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={invoice_discount_type}
              >
                <option value={1}>In Style</option>
                <option value={0}>Home Decor</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Banner Order"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                min={1}
                name="order"
                value={offerAllData["order"]}
                onChange={(e) => handleInputs(e, setOfferAllData)}
              />
            </InputGroup>
          </div>
          <p style={{ marginTop: "10px", color: "red" }}>
            NB: Please add a banner order according to your requirements. We will display the banners on the website in the order specified.
          </p>

          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-600px, width-1920px) for Banner.{" "}
              </p>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={postOffer}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.banner)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
