export const selectStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? "#e0e0e0" : "#e0e0e0",
    boxShadow: state.isFocused ? "0 0 0px 0.5px rgba(255, 0, 0, 1)" : "none",
    "&:hover": {
      borderColor: "#e0e0e0",
    },
    maxHeight: "120px",
    overflowY: "auto",
  }),
  multiValueContainer: (baseStyles) => ({
    ...baseStyles,
    overflowY: "auto",
    maxHeight: "120px",
    display: "flex",
    flexWrap: "wrap",
  }),
};
