import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import eye from "./../../../assets/Icon/eye.svg";

import * as path from "../../Routes/RoutePaths";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Loader from "../../CustomCommons/Loader";
// import ProductPaggination from "./ProductPaggination";
import { showToast } from "../../../utils/ToastHelper";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";

const AddMatching = () => {
  const [festivalName, setFestivalName] = useState("");
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [multiImg, setMultiImage] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [matchingType, setMatchingType] = useState(0);
  const [inputQueryExPd, setInputQueryExPd] = useState("");
  const [excludeProduct, setExcludeProduct] = useState([]);
  const [productListExPd, setProductsListExPd] = useState([]);
  const handleMultiInputChangeExcProd = (newValue, actionMeta) => {
    setExcludeProduct(newValue);
  };
  const inputProductExPd = (inputValue) => {
    setInputQueryExPd(inputValue);
  };
  const getProductListExPd = () => {
    if (inputQueryExPd?.length > 1) {
      let url;
      if (
        !inputQueryExPd ||
        inputQueryExPd === "" ||
        inputQueryExPd === undefined
      ) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/`;
      } else if (inputQueryExPd) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/?query=${inputQueryExPd}`;
      }

      axios
        .get(url)
        .then((res) => {
          const result = res.data.data;

          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
          }));

          setProductsListExPd(options);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };

  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const uploadMultiple = (e) => {
    console.log(e.target.files);
    const images = e.target.files;
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const data1 = new FormData();
      data1.append("document", file);
      data1.append("doc_type", 0);

      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setMultiImage([
                  //   ...multiImg,
                  { id: res.data.data.id, url: res.data.data?.doc_url },
                ]);
              }
            };
            showToast("success", "image uploaded");
          }
        })
        .catch((error) => {
          // showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  console.log(multiImg, "57");
  const removeMultiImg = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // setURL("");
        // setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        const newArray = multiImg?.filter((imges) => imges?.id !== id);
        setMultiImage(newArray);
      } else {
        swal("Your  file is safe!");
      }
    });
  };

  // const addMatching = () => {
  //   const url = `${BASE_URL}/api/v1/inventory/inventory/matching-products/`;
  //   const dataFormat = {
  //     title: festivalName,
  //     is_active: Categoryswitch,
  //     thumb: multiImg[0].id,
  //     type: matchingType,
  //     exclude_products: excludeProduct
  //       ? excludeProduct?.map((item) => item.value)
  //       : [],
  //   };


  //   setLoading(true);
  //   axios
  //     .post(url, dataFormat)
  //     .then((res) => {
  //       if (res.data.status) {
  //         showToast("success", "Matcing Created.");
  //           history.push(path.matching_list);
  //       }
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err.request.response).message;
  //       const errorMsg = JSON.parse(err.request.response).errors;
  //       for (let key in errorMsg) {
  //         showToast("error", `${key} : ${errorMsg[key][0]}`);
  //       }
  //       showToast("error", message);
  //     });
  // };

  const addMatching = () => {
    const url = `${BASE_URL}/api/v1/inventory/inventory/matching-products/`;
    const dataFormat = {
      title: festivalName,
      is_active: Categoryswitch,
      type: matchingType,
      exclude_products: excludeProduct ? excludeProduct?.map((item) => item.value) : [],
    };
  
    // Check if thumb is available
    if (!multiImg[0]?.id) {
      showToast("error", "Thumb is mandatory.");
      return; // Stop execution if thumb is not available
    }
    dataFormat.thumb = multiImg[0].id; // Add thumb if available
  
    setLoading(true);
    axios
      .post(url, dataFormat)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Matching Created.");
          history.push(path.matching_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  

  useEffect(() => {
    getProductListExPd();
  }, [inputQueryExPd]);

  //   if (loading === true) {
  //     return <Loader />;
  //   }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Matching Product
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12">
            <RequiredLabel text={"Matching Type"} />
            <div className="mb-3" style={{ height: "47px" }}>
              <Form.Select
                size="lg"
                placeholder="---Select---"
                onChange={(e) => setMatchingType(parseInt(e.target.value))}
                style={{
                  backgroundColor: "#FAFAFA",
                  fontSize: "16px",
                  height: "47px",
                  borderRadius: "8px",
                  color: "#828282",
                }}
                value={matchingType}
              >
                <option value={0}>Desgin</option>
                <option value={1}>Color</option>
              </Form.Select>
            </div>
          </div>
          <div className="col-12 ">
            <RequiredLabel text={"Title"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={festivalName}
                onChange={(e) => setFestivalName(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="col-12 mt-2">
            {/* <RequiredLabel text="" /> */}
            <p>Exclude Products</p>
            <div className="position-relative">
              <Select
                // styles={styles}
                isMulti
                placeholder="---Select---"
                isClearable
                onChange={handleMultiInputChangeExcProd}
                onInputChange={inputProductExPd}
                options={productListExPd}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "black",
                    boxShadow: state.isFocused
                      ? "0 0 0px 0.5px rgba(255, 0, 0, 1)"
                      : "none",
                    "&:hover": {
                      borderColor: "blue",
                    },
                  }),
                }}
              />
            </div>
          </div>
          <div className="col-12  ">
            <h5 style={{ marginTop: "30px" }}>Add image <span style={{color: "red" }}>*</span></h5> 
            <p style={{ marginTop: "10px", color: "red" }}>
              NB: You can add multiple banner image its not mandatory.The actual
              size of the uploaded images will be displayed on the website.{" "}
            </p>
            <p style={{ color: "#8E8E93" }}>
              ( Select your file & upload one by one )
            </p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                {" "}
                {" File Choosen"}
              </p>

              <div className="file-up">
                <input
                  type="file"
                  onChange={uploadMultiple}
                  // multiple
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            <div className="d-flex gap-2">
              {multiImg?.length > 0 &&
                multiImg?.map((data) => (
                  <>
                    <div className="my-2" key={data?.id}>
                      <img height={90} width={90} src={data?.url} alt="" />{" "}
                      <br />
                      <button
                        onClick={() => removeMultiImg(data?.id)}
                        className="remove-btn btn btn-small mt-1  rounded"
                        style={{ border: "1px solid gray !important" }}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ))}
            </div>
          </div>
          <div className="col-12 col-md-5">
            <h5 style={{ marginTop: "30px" }}>Status</h5>
            <p style={{ color: "#8E8E93" }}>
              ( If the Category Option is available )
            </p>
            <div className="row mr-4">
              <div
                className=" d-flex justify-content-between col-12 py-3"
                style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
              >
                <div className="d-flex w-100">
                  <div
                    className="d-flex py-1 justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#212121",
                      width: "32px",
                      height: "32",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={eye} alt="" />
                  </div>
                  <span className="mx-3">{Categoryswitch ? "ON" : "OFF"}</span>
                </div>

                <div class="form-check form-switch ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    width={40}
                    height={40}
                    name="category_active"
                    checked={Categoryswitch}
                    onClick={toggleSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={addMatching}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMatching;
