import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Alarm, CaretDown, PencilSimple, XCircle } from "phosphor-react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import PhoneInput from "react-phone-input-2";
import "./invoice.css";

import Select from "react-select";
import DoIcon from "../CustomCommons/DoIcon";

import { handleInputs } from "../../utils/HandleInputs";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import swal from "sweetalert";
import { useRef } from "react";
import PreviewCreate from "./PreviewCreate";
import * as path from "./../Routes/RoutePaths";
import { useHistory } from "react-router-dom";
import Loader from "../CustomCommons/Loader";
import { can_add_invoice, has_permissions } from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import { logout_func2 } from "../Const/logoutCommon";
import Scanner2 from "./Scanner2";

import divisions from "../../assets/json/bd_divisions.json";
import { Controller, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditOutletExchange = () => {
  const [customCharge, setCustomCharge] = useState(80);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [redeem, setRedeem] = useState(0);
  const { id } = useParams();
  const [sInvoice, setSInvoice] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [Thumb, setThumb] = useState(null);
  const [CustomerList, setCustomerList] = useState([]);
  const [isLoading, SetisLoading] = useState(true);
  const [exArray, setExarray] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [exchanged, setExchanged] = useState([]);
  const [Found, setFound] = useState(false);
  const [FoundCustomer, setFoundCustomer] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState({
    label: "",
    value: "",
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "",
    value: "",
  });
  const [selectedTown, setSelectedTown] = useState({ label: "", value: "" });
  const [selectLoading, setSelectLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [prevSubTotal, setPrevSubTotal] = useState(0);
  const [prevPaid, setPrevPaid] = useState(0);
  const [prevInvoice, setPrevInvoice] = useState("");
  const [recievedAmount, setRecievedAmount] = useState(0);
  const [changeAmount, setChangeAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);

  // Address Form methods start
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    console.log(sInvoice, "found");
    if (Object.keys(sInvoice).length !== 0) {
      if (sInvoice.redex_area_details) {
        reset({
          // division: { label: sInvoice.to_division, value: sInvoice.to_division},
          division: {
            label: sInvoice.redex_division_details.name,
            value: sInvoice.redex_division_details.id,
          },
          // district: { label: sInvoice.to_district, value: sInvoice.to_district},
          district: {
            label: sInvoice.redex_district_details.name,
            value: sInvoice.redex_district_details.id,
          },
          // town: { label: sInvoice.to_city, value: sInvoice.to_city },
          town: {
            label: sInvoice.redex_area_details.name,
            value: sInvoice.redex_area_details.id,
          },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: sInvoice.to_zip_code,
        });
        setSelectedDivision({
          label: sInvoice.redex_division_details.name,
          value: sInvoice.redex_division_details.id,
        });
        setSelectedDistrict({
          label: sInvoice.redex_district_details.name,
          value: sInvoice.redex_district_details.id,
        });
        setSelectedTown({
          label: sInvoice.redex_area_details.name,
          value: sInvoice.redex_area_details.id,
        });
      } else {
        reset({
          division: { label: sInvoice.to_division, value: "" },
          district: { label: sInvoice.to_district, value: "" },
          town: { label: sInvoice.to_city, value: "" },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: sInvoice.to_zip_code,
        });
        setSelectedDivision({ label: sInvoice.to_division, value: "" });
        setSelectedDistrict({ label: sInvoice.to_district, value: "" });
        setSelectedTown({ label: sInvoice.to_city, value: "" });
      }
    }
  }, [sInvoice, reset]);
  const [districtList, setDistrictList] = useState([]);
  const [upazillaList, setUpazillaList] = useState([]);
  const [zipList, setZipList] = useState([]);
  const watchFields = watch([
    // "town",
    "division",
    "district",
    "town",
    "zip",
  ]);
  useEffect(() => {
    if (selectedDivision.value !== "") {
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivision.value}/`
        )
        .then((response) => {
          // setDistrictList(response.data.data.districts)
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  }, [selectedDivision]);
  useEffect(() => {
    if (selectedDistrict.value !== undefined) {
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrict.value
      )[0]?.areas;
      // setUpazillaList(foundAreas)
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
  }, [selectedDistrict, districtList]);
  const handleDivisionChange = (division) => {
    if (selectedDivision?.value !== undefined) {
      console.log(division, "div");
      setSelectedDistrict({ label: "", value: "" });
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setDistrictList([]);
      setSelectLoading(true);
      setCustomCharge(0);
      setSelectedDivision(division);
      setValue("division", division);
      const selectedDivisionId = division.value;
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivisionId}/`
        )
        .then((response) => {
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  };
  const handleDistrictChange = (district) => {
    if (selectedDistrict?.value !== undefined) {
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setUpazillaList([]);
      setSelectLoading(false);
      setCustomCharge(0);
      setSelectedDistrict(district);
      setValue("district", district);
      const selectedDistrictId = district.value;
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrictId
      )[0]?.areas;
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
  };
  const handleTownChange = (town) => {
    if (selectedTown?.value !== undefined) {
      setValue("zip", "");
      setSelectedTown(town);
      setValue("town", town);
      const selectedAreaId = town.value;
      const foundDeliveryCharge = upazillaList?.filter(
        ({ id }) => id == selectedAreaId
      )[0]?.delivery_charge;
      // setCustomCharge(foundDeliveryCharge)
      if (foundDeliveryCharge !== undefined) {
        console.log(foundDeliveryCharge, selectedAreaId, "fdc");
        setCustomCharge(Number(foundDeliveryCharge));
      }
    }
  };
  // useEffect(() => {
  //   console.log(watchFields, "www")
  //   if (watchFields[0]) {
  //     // const foundDistricts = allDistricts.filter(
  //     //   ({ division_id }) => division_id == watchFields[0].value
  //     // );
  //     // setDistrictList(foundDistricts);
  //     const selectedDivisionId = watchFields[0].value
  //     console.log(selectedDivisionId, "ssid")
  //     axios.get(`${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivisionId}/`).then(response => {
  //       console.log(response.data.data.districts, "dis")
  //       if (response.data.data.districts !== undefined) {
  //         setDistrictList(response.data.data.districts)
  //       } else {
  //         setDistrictList([])
  //       }
  //     })
  //   }
  //   console.log(watchFields[1]?.label, districtList, "div");
  // }, [watchFields[0]?.value]);
  // useEffect(() => {
  //   console.log(districtList, "dist")
  //   if (watchFields[1]?.value !== undefined) {
  //     // const foundUpazillas = upazillas.filter(
  //     //   ({ district_id }) => district_id == watchFields[1].value
  //     // );
  //     // setUpazillaList(foundUpazillas);
  //     const selectedDistrictId = watchFields[1].value
  //     const foundAreas = districtList.filter(({ id }) => id == selectedDistrictId)[0]?.areas;
  //     if (foundAreas !== undefined) {
  //       setUpazillaList(foundAreas)
  //     } else {
  //       setUpazillaList([])
  //     }
  //     console.log(foundAreas, "fff")
  //   }
  //   // if (watchFields[1].label !== )
  // }, [watchFields[1]?.value, districtList]);

  // useEffect(() => {
  //   if (watchFields[2]?.value !== undefined) {
  //     console.log(upazillaList, watchFields[2], "upl")
  //     const selectedAreaId = watchFields[2].value;
  //     const foundDeliveryCharge = upazillaList?.filter(({ id }) => id == selectedAreaId)[0]?.delivery_charge
  //     console.log(foundDeliveryCharge, selectedAreaId, "fdc")
  //     if (foundDeliveryCharge !== undefined) {
  //       setCustomCharge(foundDeliveryCharge)
  //     }
  //   }
  // }, [watchFields[2]?.value, upazillaList])

  // useEffect(() => {
  //   if (watchFields[2]) {
  //     const foundZips = zips.filter(
  //       ({ name, postCode, upazila }) => upazila == watchFields[2].label
  //     );
  //     setZipList(foundZips);
  //   }
  // }, [watchFields[2]]);
  // Address form methods end
  const delivery_charge = {
    0: 80,
    1: 150,
  };
  const options1 = [
    { value: 0, label: "Regular" },
    { value: 1, label: "Urgent" },
  ];

  const deliveryType = [
    { value: 0, label: "Regular" },
    { value: 1, label: "Urgent" },
  ];
  const invoice_choices = [
    { value: 0, label: "Recieved" },
    { value: 1, label: "Pending" },
    { value: 2, label: "Refunded" },
  ];

  const delivery_choices = [
    { value: 0, label: "Returned" },
    { value: 1, label: "Order Placed" },
    { value: 2, label: "Delivered" },
    { value: 3, label: "Pending" },
    { value: 4, label: "Hold" },
    { value: 5, label: "Dispatched" },
  ];
  console.log(delivery_choices);
  const paymentType = [
    { value: 0, label: "Cash On Delivery" },
    { value: 1, label: "Card" },
    { value: 2, label: "Bank Transfer" },
    { value: 3, label: "Bkash" },
    { value: 4, label: "SSLECOMMERZ" },
    { value: 5, label: "Nagad" },
    { value: 6, label: "Kaaruj Delivery" },
  ];

  const deliverypriceChoices = {
    0: "Inside Dhaka : 80/-",
    1: "Outside Dhaka : 150/-",
  };
  const deliveryPrice_options = [
    { value: 0, label: "Inside Dhaka : 80/-" },
    { value: 1, label: "Outside Dhaka : 150/-" },
  ];

  const history = useHistory();

  const [InvoiceAllData, SetInvoiceAllData] = useState({
    invoice_date: sInvoice.invoice_date,
    invoice_due_date: sInvoice.due_date,
    invoice_bill_from: sInvoice.bill_from,
    invoice_bill_to: sInvoice.bill_to,
    invoice_from_mobile: sInvoice.from_mobile,
    invoice_to_mobile: "",
    invoice_from_address: sInvoice.from_address,
    invoice_to_address: sInvoice.to_address,
    invoice_delivery_charge: sInvoice.delivery_charge,
    invoice_notes: sInvoice.notes,
    invoice_to_email: "type",
    invoice_from_email: "type",
    invoice_number: "#INV01221",
    invoice_barcode: "12121212121",
    invoice_to_address2: "",
    to_zip_code: "",
    to_country: "",
    to_district: "",
    to_division: "",
    to_city: "",
  });

  console.log(InvoiceAllData["invoice_to_mobile"], "mobile");

  const [paymentAllData, setPaymentAllData] = useState({
    card_holder_name: "",
    card_number: "",
    bank_name: "",
    banK_account_number: "",
    banK_account_name: "",
    banK_branch_name: "",
    bkash_number: "",
    bkash_trx_number: "",
    nagad_number: "",
    nagad_trx_number: "",
    reference_id: "",
  });

  const clearPaymentALlData = () => {
    setPaymentAllData({
      card_holder_name: "",
      card_number: "",
      card_expiry_date: "",
      bank_name: "",
      banK_account_number: "",
      banK_routing_number: "",
      banK_branch_name: "",
      bkash_number: "",
    });
  };
  const [fromCode, setFromCode] = useState("880");
  const [toCode, setToCode] = useState("880");
  const [oldMainState, setOldMainState] = useState({});
  const [oldMainInnerState, setOldMainInnerState] = useState({});
  const [mainState, setMainState] = useState({});
  const [mainInnerState, setMainInnerState] = useState({});
  const [pS, setPs] = useState(0);
  const [Jdata, setJData] = useState([]);
  useEffect(() => {
    let temp1 = mainState;
    console.log(temp1, "--------mainState found temp1------");

    var output = [];
    for (let i in temp1) {
      console.log(temp1[i]["variantObj"]);
      for (let j in temp1[i]["variantObj"]) {
        console.log(j);
        console.log(temp1[i]["variantObj"][j]);
        const d = temp1[i]["variantObj"][j];
        d.total !== 0 &&
          output.push({
            name: i,
            basePrice: temp1[i].price,
            thumb: temp1[i].thumb,
            discount: isNaN(temp1[i].discount) === true ? 0 : temp1[i].discount,
            price: d["price"],
            quantity: d["quantity"],
            id: d["id"],
            total: d["total"],
            variant: d["name"],
          });
      }
    }
    setJData(output);
  }, [mainState]);
  const [oldInputQuery, setOldInputQuery] = useState("");
  const [inputQuery, setInputQuery] = useState("");
  console.log("Jdata", Jdata);
  const [optional, setOptional] = useState("");
  const [URL, setURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [Items, SetItems] = useState([]);
  const [Signature, SetSignature] = useState(null);
  const [pdJson, setPdJson] = useState([]);
  const [invProduct, setInvProduct] = useState([]);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const fileHandle = (e) => {
    SetSignature(e.target.files[0]);

    setFileName(e.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setURL(reader.result);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setURL(reader.result);
      }
    };
  };
  const handleOptional = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    setOptional(reader.result);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setOptional(reader.result);
      }
    };
  };
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
  };

  const convertData = (date) => {
    var current = new Date(date);
    return current.toISOString().substring(0, 10);
  };

  const clearData = () => {
    SetInvoiceAllData({
      invoice_date: "",
      invoice_due_date: "",
      invoice_bill_from: "",
      invoice_bill_to: "",
      invoice_from_mobile: "",
      invoice_to_mobile: "",
      invoice_from_address: "",
      invoice_to_address: "",
      invoice_delivery_charge: "",
      invoice_notes: "",
      barcode: "",
      invoice_to_address2: "",
      to_zip_code: "",
      to_country: "",
      to_district: "",
      to_division: "",
      to_city: "",
    });
  };
  console.log("found-----------", Found);
  console.log("found-----------", FoundCustomer);
  const [ProductsList, setProductsList] = useState([]);
  const [oldProductsList, setOldProductsList] = useState([]);
  const getOldProductList = () => {
    if (inputQuery?.length > 1) {
      const url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/?query=${inputQuery}`;

      axios
        .get(url)
        .then((res) => {
          // console.log(res.data.data.results);
          const result = res.data.data;
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: curr.thumb ? curr?.thumb : curr?.thumb_url,
          }));
          setOldProductsList(options);
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  const getProductList = () => {
    if (inputQuery?.length > 1) {
      const url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/?query=${inputQuery}`;

      axios
        .get(url)
        .then((res) => {
          // console.log(res.data.data.results);
          const result = res.data.data;
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: curr.thumb ? curr?.thumb : curr?.thumb_url,
          }));
          setProductsList(options);
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  console.log("ProductsList", ProductsList);

  // console.log(InvoiceAllData["invoice_date"]);
  // function transformData(inputData) {
  //   const transformedData = {};

  //   for (const key in inputData) {
  //     const variantDetails = inputData[key][0].variant_details;

  //     transformedData[key] = inputData[key].map((item) => ({
  //       label: variantDetails.name,
  //       value: item.variant,
  //       price: variantDetails.price,
  //       stock: variantDetails.stock,
  //       quantity: 1,
  //     }));
  //   }

  //   return transformedData;
  // }
  function transformData(inputData) {
    const transformedData = {};

    for (const key in inputData) {
      transformedData[key] = inputData[key].map((item) => ({
        label: item.variant_details.name,
        value: item.variant,
        price: item.variant_details.price,
        stock: item.variant_details.stock,
        quantity: 1,
      }));
    }

    return transformedData;
  }
  // const getNewGrandTotal = () => {
  //   const discountCalc =
  //     invoice_discount_type === 0 || invoice_discount_type === "0"
  //       ? getFlatDiscount()
  //       : getPercentageDiscount();
  //   if (getSubTotal() === 0 || getNewSubTotal() === 0) {
  //     return 0;
  //   }
  //   return (
  //     parseFloat(getNewSubTotal()) - discountCalc - parseFloat(getSubTotal())
  //   );
  // };
  const getInvoice = () => {
    let url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
    axios
      .get(url, url)
      .then((res) => {
        if (res.data.status) {
          // showToast("success", "Invoice Created.");

          setSInvoice(res.data.data);
          setRedeem(res.data.data?.redeem_amount);
          setSubTotal(res.data.data.sub_total);
          setPrevPaid(res.data.data.previous_paid);
          setPrevInvoice(res.data.data.previous_invoice_number);
          setPrevSubTotal(res.data.data.previous_paid);
          setRecievedAmount(res.data.data.total_cash_recieved);
          setChangeAmount(res.data.data.change_amount);
          setDiscount(res.data.data.invoice_discount);
          setExchanged(res.data.data.invoice_products);
          SetInvoiceAllData({
            invoice_date: res.data.data.invoice_date,
            invoice_due_date: res.data.data.due_date,
            invoice_bill_from: res.data.data.bill_from,
            invoice_bill_to: res.data.data.bill_to,
            invoice_from_mobile:
              res.data.data.from_mobile !== undefined &&
              res.data.data.from_mobile.replace("+880", ""),
            invoice_to_mobile:
              res.data.data.to_mobile !== undefined &&
              res.data.data.to_mobile.replace("+880", ""),
            invoice_from_address: res.data.data.from_address,
            invoice_to_address: res.data.data.to_address,
            invoice_to_address2: res.data.data.to_address2,
            to_zip_code: res.data.data.to_zip_code,
            to_country: res.data.data.to_country,
            to_district: res.data.data.to_district,
            to_division: res.data.data.to_division,
            to_city: res.data.data.to_city,
            invoice_delivery_charge: res.data.data.delivery_charge_type,
            invoice_delivery_status: res.data.data.delivery_status,
            invoice_payment_status: res.data.data.payment_status,
            invoice_notes: res.data.data.notes,
            invoice_to_email: res.data?.data?.to_email,
            invoice_from_email: res.data?.data?.from_email,
            invoice_number: res?.data?.data?.number,
            invoice_barcode: res?.data?.data?.barcode_text,
            barcode: res?.data?.data?.barcode,
          });
          SetSignature(res.data?.data?.signature);
          setURL(res.data?.data?.signature);
          setpaid(res.data?.data?.total_paid);
          setDueAmount(res.data?.data?.total_due);
          setDiscount(res.data?.data?.total_discount);
          setinvoice_discount_type(res.data?.data?.discount_type);
          setinvoice_payment_type(res.data?.data?.payment_type);
          const dataJson = JSON.parse(res.data.data.product_list_json);
          // setMainState(dataJson?.mainstate);
          // console.log(dataJson, "dddd");
          setOldMainInnerState(
            transformData(res.data.data?.exchange_products_formatted)
          );
          setMainInnerState(
            transformData(res.data.data?.invoice_products_formatted)
          );
          setPdJson(JSON.parse(res.data.data?.invoice_view_json));
          console.log(JSON.parse(res.data.data?.invoice_view_json), "dddd");
          setinvoice_delivery_type(res.data?.data?.delivery_type);
          // res.data?.data?.discount_type === 0 ? setDiscount(res.data?.data?.flat_discount) : setDiscount(res.data?.data?.percent_discount);
          setDelivrey_status(res.data?.data?.delivery_status);
          setinvoice_status(res.data?.data?.payment_status);
          setinvoice_delivery_charge(res.data?.data?.delivery_charge_type);
          setCustomCharge(Number(res.data?.data?.delivery_charge));

          setInvProduct(res?.data?.data?.invoice_products);

          setPaymentAllData({
            card_holder_name: res.data?.data?.card_holder_name,
            card_number: res.data?.data?.card_number,
            bank_name: res.data?.data?.bank_name,
            banK_account_number: res.data?.data?.banK_account_number,
            banK_account_name: res.data?.data?.banK_account_name,
            banK_branch_name: res.data?.data?.banK_branch_name,
            bkash_number: res.data?.data?.bkash_number,
            bkash_trx_number: res.data?.data?.bkash_trx_number,
            nagad_number: res.data?.data?.nagad_number,
            nagad_trx_number: res.data?.data?.nagad_trx_number,
            reference_id: res.data?.data?.reference_id,
          });
          SetisLoading(false);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);
        // for (let value of Object.values(errorMsg)) {
        //   showToast("error", value[0]);
        // }

        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  useEffect(() => {
    getOldProductList();
  }, [oldInputQuery]);
  useEffect(() => {
    getProductList();
  }, [inputQuery]);
  useEffect(() => {
    getInvoice();
  }, []);

  const oldInputProduct = (inputValue) => {
    setOldInputQuery(inputValue);
  };
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };

  const getOldMainDefaultValues = () => {
    return (
      !isLoading &&
      // Object.keys(mainState).map((curr, index) => ({
      //   value: index,
      //   label: curr,
      // }))
      Object.keys(sInvoice?.exchange_products_formatted).map((curr, index) => ({
        value: index,
        // label: curr?.product_name + " " + "[[" + curr?.sku + "]]",
        label: curr,
      }))
    );
  };
  const getMainDefaultValues = () => {
    return (
      !isLoading &&
      // Object.keys(mainState).map((curr, index) => ({
      //   value: index,
      //   label: curr,
      // }))
      Object.keys(sInvoice?.invoice_products_formatted).map((curr, index) => ({
        value: index,
        // label: curr?.product_name + " " + "[[" + curr?.sku + "]]",
        label: curr,
      }))
    );
  };
  const getOldMainOptions = () => {
    const onlyDefaultLabels = Object.keys(oldMainInnerState);
    const filterdata = oldProductsList.filter(
      (o) => !onlyDefaultLabels.includes(o.label)
    );
    return filterdata;
  };
  const getMainOptions = () => {
    const onlyDefaultLabels = Object.keys(mainInnerState);
    const filterdata = ProductsList.filter(
      (o) => !onlyDefaultLabels.includes(o.label)
    );
    return filterdata;
  };

  console.log(mainInnerState, "fromweb inv");

  const [invoice_status, setinvoice_status] = useState(0);
  const [Delivery_status, setDelivrey_status] = useState(0);
  const [invoice_delivery_type, setinvoice_delivery_type] = useState(0);
  const [invoice_payment_type, setinvoice_payment_type] = useState(0);
  const [invoice_delivery_charge, setinvoice_delivery_charge] = useState(2);
  const [invoice_discount_type, setinvoice_discount_type] = useState(0);

  console.log(pdJson, "pdjson");
  console.log(sInvoice?.invoice_products_formatted, "pdjson-new");

  const [InvoiceBelowData, SetInvoiceBelowData] = useState({
    invoice_vat: 0,
    invoice_tax: 0,
    invoice_due: 0,
    invoice_paid: 0,
    invoice_total: 0,
  });
  const ValidateData = (sendEmail) => {
    const bd = /^[1][0-9]{9}$/;
    if (InvoiceAllData["invoice_date"] === "") {
      showToast("error", "Issue  Date can't be empty..");
      return 0;
    } else if (
      !bd.test(InvoiceAllData["invoice_from_mobile"]?.replace("+880", ""))
    ) {
      showToast("error", "From Mobile : Please Enter Valid phone number");
      return 0;
    } else if (
      !bd.test(InvoiceAllData["invoice_to_mobile"]?.replace("+880", ""))
    ) {
      showToast("error", "To Mobile : Please Enter Valid phone number");
      return 0;
    } else if (!Found && InvoiceAllData["invoice_bill_from"] === "") {
      showToast("error", "Bill from  can't be empty..");
      return 0;
    } else if (!Found && InvoiceAllData["invoice_bill_to"] === "") {
      showToast("error", "Bill to  can't be empty..");
      return 0;
    } else if (!Found && InvoiceAllData["invoice_bill_to"] === "") {
      showToast("error", "Bill to  can't be empty..");
      return 0;
    }

    if (InvoiceAllData["invoice_from_email"] === "") {
      showToast("error", "From email  can't be empty..");
      return 0;
    } else if (!validateEmail(InvoiceAllData["invoice_from_email"])) {
      showToast("error", "From email  not valid..");
      return 0;
    } else if (
      Found &&
      !validateEmail(FoundCustomer?.email) === "" &&
      InvoiceAllData["invoice_to_email"].length > 0
    ) {
      showToast("error", "To email  not valid...");
      return 0;
    } else if (
      !Found &&
      !validateEmail(InvoiceAllData["invoice_to_email"]) &&
      InvoiceAllData["invoice_to_email"].length > 0
    ) {
      showToast("error", "To email  not valid..");
      return 0;
    }
    // else if (!Found && InvoiceAllData["invoice_to_address"] === "") {
    //   showToast("error", "To address  can't be empty..");
    //   return 0;
    // }

    if (Object.keys(mainState).length <= 0) {
      showToast("error", "Product Details is empty..");
      return 0;
    }
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    // if (InvoiceAllData["invoice_notes"] === null) {
    //   showToast("error", "Notes is empty..");
    //   return 0;
    // }

    postInvoice(sendEmail);
  };

  const allvariants = Object?.values(mainState).map(
    (current) => current["variantObj"]
  );
  console.log("allvariants----------", {
    allv: allvariants,
    mins: mainState,
  });

  const postInvoiceAPi = (invoiceProductId, data) => {
    const URLINvoice = `${BASE_URL}api/v1/sales/inventory/invoice-products/${invoiceProductId}/`;

    axios
      .patch(URLINvoice, data)
      .then((res) => {
        if (res.data.status) {
          // clearData();
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  const getInvoiceIdAndPost = async (invoiceid, variant_name, data) => {
    const invoiceGetID = `${BASE_URL}api/v1/sales/inventory/invoice-product-id/`;
    const dataProduct = new FormData();
    dataProduct.append("invoice", invoiceid);
    dataProduct.append("variant", variant_name);
    await axios
      .post(invoiceGetID, dataProduct)
      .then((res) => {
        if (res.data.status) {
          console.log("res", res);
          postInvoiceAPi(res.data.data.id, data);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  const postInvoiceProduct = () => {
    console.log("in post invoice");
    const url = `${BASE_URL}api/v1/sales/inventory/invoice-products/`;
    const allvariants = Object.values(mainState).map(
      (current) => current["variantObj"]
    );
    console.log("allvariants", allvariants);
    var dataForUpdate = [];

    for (let items in allvariants) {
      const itemsList = Object.values(allvariants[items]);
      for (let currentitem in itemsList) {
        if (parseInt(itemsList[currentitem]["quantity"]) > 0) {
          var postinfo = {
            price: parseInt(itemsList[currentitem]["price"]),
            quantity: parseInt(itemsList[currentitem]["quantity"]),
            // // data.append("vat", parseInt(mainState[item]["in_vat"]));
            total: parseInt(itemsList[currentitem]["total"]),
            product: parseInt(itemsList[currentitem]["product"]),
            variant: parseInt(itemsList[currentitem]["id"]),
            inv_product_id: parseInt(itemsList[currentitem]["inv_product_id"]),
            invoice: id,
          };
          dataForUpdate.push(postinfo);
        }
      }
    }
    console.log("dataForUpdate", dataForUpdate);

    axios
      .patch(url, { data: dataForUpdate })
      .then((res) => {
        // clearData();
        SetisLoading(false);
        showToast("success", "Invoice Updated.");
        history.push(path.invoice_list);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);

        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  const [is_send_email, set_is_send_email] = useState(false);
  // const SaveAndSendEmail =
  const SaveAndSendEmail = () => {
    ValidateData(true);
  };
  function findIdByName(param) {
    for (let i = 0; i < pdJson.length; i++) {
      if (pdJson[i].name === param) {
        return pdJson[i].id;
      }
    }
    return null;
  }
  function formatInv(inputData) {
    const formattedData = [];

    // Iterate over the data object
    for (const key in inputData) {
      const product = inputData[key];
      const variants = product.variant;

      // Iterate over the variants array for each product
      for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];

        // Create a new formatted object using the variant data
        const formattedObject = {
          quantity: variant.quantity,
          total: variant.total.toString(),
          product_name: key.trim(),
          variant_name: variant.name,
          is_custom: false,
          invoice_date: InvoiceAllData["invoice_date"],
          product: product.id,
          variant: variant.id,
          id: product.id,
          inv_product: product.id,
        };

        // Add the formatted object to the formattedData array
        formattedData.push(formattedObject);
      }
    }

    return formattedData;
  }
  function findMatchingUniqueIds(arr1, arr2) {
    const matchingIds = new Set();

    arr1.forEach((item) => {
      if (arr2.includes(item.product_name)) {
        matchingIds.add(item.id);
      }
    });

    return Array.from(matchingIds);
  }
  useEffect(() => {
    const data = findMatchingUniqueIds(exchanged, deleted);
    setExarray(data);
  }, [mainState]);
  const postInvoice = (sendEmail) => {
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}/`;
    const data = new FormData();

    const toMobile = `+${toCode}${InvoiceAllData["invoice_to_mobile"]}`;
    const fromMobile = `+${toCode}${InvoiceAllData["invoice_from_mobile"]}`;

    const current_user = JSON.parse(localStorage.getItem("userData"));
    data.append(
      "invoice_date",
      InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"])
    );

    data.append("bill_from", InvoiceAllData["invoice_bill_from"]);
    data.append("bill_to", InvoiceAllData["invoice_bill_to"]);
    data.append("from_mobile", fromMobile);
    data.append("to_mobile", toMobile);
    data.append("to_email", InvoiceAllData["invoice_to_email"]);
    data.append("from_address", InvoiceAllData["invoice_from_address"]);
    data.append(
      "to_address",
      Found ? FoundCustomer.address : InvoiceAllData["invoice_to_address"]
    );
    data.append(
      "delivery_charge",
      parseInt(invoice_delivery_charge) === 2
        ? customCharge
        : delivery_charge[invoice_delivery_charge]
    );

    data.append("delivery_charge_type", parseInt(invoice_delivery_charge));
    data.append("delivery_status", Delivery_status);
    data.append("delivery_type", invoice_delivery_type);
    data.append("discount_type", invoice_discount_type);
    data.append("total_discount", Discount);
    data.append("payment_type", invoice_payment_type);
    data.append("card_holder_name", paymentAllData["card_holder_name"]);
    data.append("card_number", paymentAllData["card_number"]);
    data.append("card_expiry_date", paymentAllData["card_expiry_date"]);
    data.append("bank_name", paymentAllData["bank_name"]);
    data.append("banK_account_number", paymentAllData["banK_account_number"]);
    data.append("banK_account_name", paymentAllData["banK_account_name"]);
    data.append("banK_branch_name", paymentAllData["banK_branch_name"]);
    data.append("bkash_number", paymentAllData["bkash_number"]);
    data.append("bkash_trx_number", paymentAllData["bkash_trx_number"]);

    data.append("payment_type", invoice_payment_type);
    data.append("payment_status", invoice_status);
    data.append(
      "total_due",
      parseInt(
        getDueAmount(
          getGrandTotal(
            Discount,
            invoice_discount_type,
            getFlatDiscount,
            getPercentageDiscount,
            getSubTotal(mainState, getAllVariantName)
          ),
          paid
        )
      )
    );
    data.append("total_paid", paid);
    data.append(
      "total_amount",
      parseInt(
        getGrandTotal(
          Discount,
          invoice_discount_type,
          getFlatDiscount,
          getPercentageDiscount,
          getSubTotal(mainState, getAllVariantName)
        )
      )
    );
    data.append("total_vat", 0);
    data.append("notes", InvoiceAllData["invoice_notes"]);
    data.append("created_by", current_user.id);
    data.append("total_discount", Discount);
    data.append("send_pdf", sendEmail);
    data.append("discount_type", invoice_discount_type);
    data.append(
      "product_list_json",
      JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      })
    );
    data.append("invoice_view_json", JSON.stringify(Jdata));
    SetisLoading(true);
    const dataObj = {
      invoice_date:
        InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"]),
      bill_from: InvoiceAllData["invoice_bill_from"],
      bill_to: InvoiceAllData["invoice_bill_to"],
      from_mobile: fromMobile,
      to_mobile: toMobile,
      to_email: InvoiceAllData["invoice_to_email"],
      from_address: InvoiceAllData["invoice_from_address"],
      to_address: Found
        ? FoundCustomer.to_address
        : InvoiceAllData["invoice_to_address"],
      delivery_charge:
        parseInt(invoice_delivery_charge) === 2
          ? customCharge
          : delivery_charge[invoice_delivery_charge],
      delivery_charge_type: parseInt(invoice_delivery_charge),
      delivery_status: Delivery_status,
      delivery_type: invoice_delivery_type,
      discount_type: invoice_discount_type,
      total_discount: Discount,
      payment_type: invoice_payment_type,
      card_holder_name: paymentAllData["card_holder_name"],
      card_number: paymentAllData["card_number"],
      card_expiry_date: paymentAllData["card_expiry_date"],
      bank_name: paymentAllData["bank_name"],
      banK_account_number: paymentAllData["banK_account_number"],
      banK_account_name: paymentAllData["banK_account_name"],
      banK_branch_name: paymentAllData["banK_branch_name"],
      bkash_number: paymentAllData["bkash_number"],
      bkash_trx_number: paymentAllData["bkash_trx_number"],
      nagad_number: paymentAllData["nagad_number"],
      nagad_trx_number: paymentAllData["nagad_trx_number"],
      reference_id: paymentAllData["reference_id"],
      payment_status: invoice_status,
      total_due:
        subTotal >= prevSubTotal && paid < subTotal - prevSubTotal
          ? subTotal - prevSubTotal - paid
          : 0,
      sub_total: subTotal,
      total_cash_recieved: recievedAmount,
      total_paid: paid,
      previous_paid: paid,
      initial_paid: prevPaid,
      total_amount: subTotal >= prevSubTotal ? subTotal - prevSubTotal : 0,
      total_vat: 0,
      notes: InvoiceAllData["invoice_notes"],
      notes2: InvoiceAllData["invoice_notes2"],
      created_by: current_user.id,
      send_pdf: sendEmail,
      product_list_json: JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      }),
      invoice_view_json: JSON.stringify(Jdata),
      invoice_products_details: formatInv(mainState),
      exchange_products_details: formatInv(oldMainState),
      to_address2: Found
        ? FoundCustomer.to_address2
        : InvoiceAllData["invoice_to_address2"],
      to_country: Found
        ? FoundCustomer.to_country
        : InvoiceAllData["to_country"],
      to_district: Found ? FoundCustomer.to_district : watchFields[1].label,
      to_division: Found ? FoundCustomer.to_division : watchFields[0].label,
      to_zip_code: Found ? FoundCustomer.to_zip_code : watchFields[3],
      to_city: Found ? FoundCustomer.to_city : watchFields[2].label,
      exchange_products_json: {
        data: Object.entries(oldMainState).map(
          ([inv_product, { id, variantObj }]) => ({
            quantity: variantObj[Object.keys(variantObj)[0]].quantity || "",
            inv_product: id,
          })
        ),
      },
      previous_invoice_number: prevInvoice,
      old_exchange_products_json: {
        data: sInvoice?.exchange_product_details?.data.map((item, index) => {
          return {
            // product: item.product,
            // variant: item.variant,
            quantity: item.quantity,
            inv_product: item.inv_product,
            // invoice: item.invoice,
            // previous_product: item.product,
            // previous_variant: item.variant,
          };
        }),
      },
      is_exchanged: true,
      is_outlet: true,
      outlet: sInvoice.outlet,
      // mixed_payment_details: mixPaymentData,
      redex_division: watchFields[0]?.value,
      redex_district: watchFields[1]?.value,
      redex_area: watchFields[2]?.value,
    };

    axios
      .patch(url, dataObj)
      .then((res) => {
        if (res.data.status) {
          // console.log("calling api");
          // postInvoiceProduct();
          SetisLoading(false);
          showToast("success", "Invoice Updated.");
          // history.push(path.invoice_list);
          // handleClick(res.data.data);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        console.log(errorMsg);
        // for (let value of Object.values(errorMsg)) {
        //   showToast("error", value[0]);
        // }

        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  console.log(InvoiceAllData, "iData");

  console.log("mainstate format", formatInv(mainState));

  // const getCustomerList = () => {
  //   const url = `${BASE_URL}api/v1/inventory/inventory/customer/`;

  //   axios
  //     .get(url)
  //     .then((res) => {
  //       const result = res.data.data.results;
  //       setCustomerList(result);
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err.request.response).message;
  //       // console.log(err.request.response);
  //       if (
  //         message === "Invalid token." ||
  //         JSON.parse(err.request.response).code === 401
  //       ) {
  //         // history.push('/login')
  //         logout_func2(setOnlogoutHide, history);
  //       }
  //     });
  // };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const print = useRef();
  console.log("mainStateRakib", mainState);
  const getOldFormatData = (data) => {
    const result = {};

    for (const key in data) {
      const items = data[key];
      const itemObj = {
        variant: [],
        variantObj: {},
      };

      items.forEach((item) => {
        const variantObj = {
          id: item.variant_details.id,
          name: item.variant_details.name,
          price: item.variant_details.price,
          stock: item.variant_details.stock,
          quantity: item.quantity,
          total: item.total,
        };

        itemObj.variant.push(variantObj);
        itemObj.variantObj[item.variant_details.name] = variantObj;
      });

      const firstItem = items[0];
      itemObj.id = firstItem.id;
      itemObj.price = firstItem.product_price;
      itemObj.stock = firstItem.variant_details.stock;
      itemObj.thumb = firstItem.product_thumb;
      itemObj.discount = 0; // assuming discount is always 0

      result[key] = itemObj;
    }

    return result;
  };
  const getFormatData = (data) => {
    const result = {};

    for (const key in data) {
      const items = data[key];
      const itemObj = {
        variant: [],
        variantObj: {},
      };

      items.forEach((item) => {
        const variantObj = {
          id: item.variant_details.id,
          name: item.variant_details.name,
          price: item.variant_details.price,
          stock: item.variant_details.stock,
          quantity: item.quantity,
          total: item.total,
        };

        itemObj.variant.push(variantObj);
        itemObj.variantObj[item.variant_details.name] = variantObj;
      });

      const firstItem = items[0];
      itemObj.id = firstItem.id;
      itemObj.price = firstItem.product_price;
      itemObj.stock = firstItem.variant_details.stock;
      itemObj.thumb = firstItem.product_thumb;
      itemObj.discount = 0; // assuming discount is always 0

      result[key] = itemObj;
    }

    return result;
  };
  console.log(sInvoice.invoice_products_formatted, "before ffd");
  console.log(getFormatData(sInvoice.invoice_products_formatted), "ffd");
  const HandleNewProductsInputValue = (e, product_name) => {
    let name = e.target.name;
    let value = parseInt(e.target.value);
    setMainState({
      ...mainState,
      [product_name]: { ...mainState[product_name], [name]: value },
    });
  };
  useEffect(() => {
    setOldMainState(getOldFormatData(sInvoice.exchange_products_formatted));
  }, [sInvoice]);
  useEffect(() => {
    setMainState(getFormatData(sInvoice.invoice_products_formatted));
  }, [sInvoice]);

  console.log(mainState, "formated web invoice");

  useEffect(() => {
    Object.keys(oldProductsList).map((curr, index) => {
      setOldMainState({
        ...oldMainState,
        [curr]: {
          ...oldMainState[curr],
          in_price: oldProductsList[index]["price"],
        },
      });
    });
    // getCustomerList();
  }, []);
  useEffect(() => {
    Object.keys(ProductsList).map((curr, index) => {
      setMainState({
        ...mainState,
        [curr]: { ...mainState[curr], in_price: ProductsList[index]["price"] },
      });
    });
    // getCustomerList();
  }, []);
  const toPhoneHandle = (e) => {
    let value = `+${toCode}${e.target.value}`;

    // handleInputs(e, SetInvoiceAllData);
    // Object.values(CustomerList).map((curr) => {
    //   console.log("curr.mobile", curr.mobile);
    //   console.log("value", value);
    //   if (curr.mobile === value) {
    //     setFound(true);
    //     setFoundCustomer(curr);
    //     showToast("success", "Found Customer");
    //   }
    //   // else {
    //   //   setFound(false);
    //   // }
    // });
    for (let item in CustomerList) {
      console.log("item", item);
      console.log("item", CustomerList[item]);
      if (CustomerList[item]["mobile"] === value) {
        setFound(true);
        setFoundCustomer(CustomerList[item]);
        showToast("success", "Found Customer");
        break;
      } else if (CustomerList[item]["mobile"] !== value) {
        setFound(false);
      }
    }
  };
  const OldVariantThead = () => {
    return (
      <>
        {Object.keys(oldMainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  const VariantThead = () => {
    return (
      <>
        {Object.keys(mainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  const [paid, setpaid] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const getTotal = (curr, innerCur) => {
    return (
      Object.keys(mainState).length > 0 &&
      mainState !== undefined &&
      mainState[curr] !== undefined &&
      mainState[curr]["variant"]["total"]
    );
    // return Object.keys(mainState).length > 0 && mainState !== undefined && mainState[curr] !== undefined && mainState[curr]["variant"]["total"];
  };

  const getOldAllVariantName = () => {
    var res =
      oldMainInnerState !== undefined &&
      [].concat(
        ...Object.values(oldMainInnerState).map((a) => a.map((b) => b.label))
      );
    return res;
  };
  const getAllVariantName = () => {
    var res =
      mainInnerState !== undefined &&
      [].concat(
        ...Object.values(mainInnerState).map((a) => a.map((b) => b.label))
      );
    return res;
  };

  const getOldSubTotal = () => {
    const allarray = Object.values(oldMainState).map(
      (curr) =>
        curr.variant !== undefined &&
        curr.variant
          .map((variants) =>
            getOldAllVariantName().includes(variants.name)
              ? parseInt(variants.total)
              : 0
          )
          .reduce((a, b) => a + b, 0)
    );

    var sum = 0;
    for (let i in allarray) {
      sum += parseInt(allarray[i]);
    }
    return sum;
  };
  const getSubTotal = () => {
    const allarray = Object.values(mainState).map(
      (curr) =>
        curr.variant !== undefined &&
        curr.variant
          .map((variants) =>
            getAllVariantName().includes(variants.name)
              ? parseInt(variants.total)
              : 0
          )
          .reduce((a, b) => a + b, 0)
    );

    var sum = 0;
    for (let i in allarray) {
      sum += parseInt(allarray[i]);
    }
    return sum;
  };

  const getFlatDiscount = () => {
    return Discount;
  };
  const getDeliveryCharge = () => {
    if (parseInt(invoice_delivery_charge) === 2) {
      return customCharge;
    } else {
      return delivery_charge[invoice_delivery_charge];
    }
  };
  // prev 16april 2024
  // const getPercentageDiscount = () => {
  //   let total =
  //     getSubTotal() + invoice_delivery_charge !== 2
  //       ? getDeliveryCharge()
  //       : customCharge;
  //   return parseInt((Discount / 100) * total);
  // };

  // new 16april 2024
  const getPercentageDiscount = () => {
    let total = getSubTotal();
    return parseInt((Discount / 100) * total);
  };

  const getOldDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      oldMainState[curr] !== undefined &&
        (oldMainState[curr].discount === 0
          ? oldMainState[curr].price
          : oldMainState[curr].discount)
    );
  };
  const getDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      mainState[curr] !== undefined &&
        (mainState[curr].discount === 0
          ? mainState[curr].price
          : mainState[curr].discount)
    );
  };
  const getOldBasePlusVariant = (curr, innerCur) => {
    return getOldDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const getBasePlusVariant = (curr, innerCur) => {
    return getDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };

  const getGrandTotal = () => {
    console.log(customCharge, "cust");
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? getFlatDiscount()
        : getPercentageDiscount();
    // const charge =
    //   parseInt(invoice_delivery_charge) !== 2
    //     ? parseInt(getDeliveryCharge())
    //     : parseInt(customCharge);
    const grand = getSubTotal() - (parseInt(discountCalc) + parseInt(prevPaid));
    return grand < 0 ? 0 : parseInt(grand);
  };

  const getDueAmount = () => {
    const due = getGrandTotal() - parseInt(paid);
    return due < 0 ? 0 : due;
  };
  const handleOldInnerVariant = (currentName, value) => {
    console.log("value-- currentName", currentName);
    console.log("value---", value);
    setOldMainInnerState({
      ...oldMainInnerState,
      [currentName]: value,
    });
  };
  const handleInnerVariant = (currentName, value) => {
    console.log("value-- currentName", currentName);
    console.log("value---", value);
    setMainInnerState({
      ...mainInnerState,
      [currentName]: value,
    });
  };
  const handleOldMultiInputChange = (newValue, actionMeta) => {
    console.log("actionMeta main", actionMeta);
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    // setProductCom(newValue);
    console.log("actionMeta.action", actionMeta);
    if (actionMeta.action === "select-option") {
      let variants = actionMeta.option.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: actionMeta.option?.value,
        quantity: -1,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: actionMeta.option?.value,
            quantity: -1,
            total: 0,
          })
      );
      setOldMainState({
        ...oldMainState,
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
        },
      });
    }
    if (actionMeta.action === "remove-value") {
      if (actionMeta.removedValue.label?.includes("[[")) {
        setDeleted([
          ...deleted,
          actionMeta.removedValue.label?.split("[[")[0].trimEnd(),
        ]);
      } else {
        setDeleted([...deleted, actionMeta.removedValue.label?.trimEnd()]);
      }
      delete mainState[actionMeta.removedValue.label?.split("[[")[0]];
      setOldMainState({ ...oldMainState });
    }
  };
  const handleMultiInputChange = (newValue, actionMeta) => {
    console.log("actionMeta main", actionMeta);
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    // setProductCom(newValue);
    console.log("actionMeta.action", actionMeta);
    if (actionMeta.action === "select-option") {
      let variants = actionMeta.option.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: actionMeta.option?.value,
        quantity: -1,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: actionMeta.option?.value,
            quantity: -1,
            total: 0,
          })
      );
      setMainState({
        ...mainState,
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
        },
      });
    }
    if (actionMeta.action === "remove-value") {
      if (actionMeta.removedValue.label?.includes("[[")) {
        setDeleted([
          ...deleted,
          actionMeta.removedValue.label?.split("[[")[0].trimEnd(),
        ]);
      } else {
        setDeleted([...deleted, actionMeta.removedValue.label?.trimEnd()]);
      }
      delete mainState[actionMeta.removedValue.label?.split("[[")[0]];
      setMainState({ ...mainState });
    }
  };
  // useEffect(() => {
  //   Object.keys(ProductsList).map((curr, index) => {
  //     setMainState({
  //       ...mainState,
  //       [curr]: { ...mainState[curr], in_price: ProductsList[index]["price"] },
  //     });
  //   });
  // }, []);
  const handlePrint = useReactToPrint({
    content: () => print.current,
    documentTitle: "emp-data",
    bodyClass: "dis",
  });
  const handlePrint2 = () => {
    if (
      InvoiceAllData["invoice_from_email"] !== "" ||
      InvoiceAllData["invoice_to_email"] ||
      InvoiceAllData["invoice_bill_to"] ||
      InvoiceAllData["invoice_bill_form"]
    ) {
      handlePrint();
    } else {
      swal("error");
    }
  };

  const handleOldQuantity = (e, curr, innerCur) => {
    // HandleNewProductsInputValue(e, innerCur);
    const productName = curr;
    // if(e.target.value.include(0,1,2,3,4,5,6,7,8,9) === false) {
    //   alert('hi')
    // }
    if (e.target.value < 0) {
      setPs(0);
    } else {
      setPs(e.target.value);
    }

    setOldMainState((prevInputs) => {
      const copiedObjects = Object.assign({}, oldMainState); // Shallow copy
      console.log("copiedObjects", copiedObjects);
      // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
      const keyOfVariant = Object.keys(
        copiedObjects[productName]["variant"]
      ).filter(
        (curr) =>
          copiedObjects[productName]["variant"][curr]["name"] === innerCur.label
      );
      const variantName = innerCur.label;
      console.log("innercur", innerCur);
      if (parseInt(e.target.value) < 0) {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
      } else if (parseInt(e.target.value) > innerCur.stock) {
        showToast("error", `Max Stock : ${innerCur.stock}`);
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          innerCur.stock;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          innerCur.stock;
      } else {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          e.target.value;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          e.target.value;
      }

      let checkDiscountPrice =
        parseInt(copiedObjects[productName]["discount"]) === 0
          ? parseInt(copiedObjects[productName]["price"])
          : parseInt(copiedObjects[productName]["discount"]);
      console.log("checkDiscountPrice", checkDiscountPrice);
      const baseAndVariant =
        parseInt(copiedObjects[productName]["variant"][keyOfVariant]["price"]) +
        checkDiscountPrice;
      copiedObjects[productName]["variant"][keyOfVariant]["total"] =
        parseInt(
          copiedObjects[productName]["variant"][keyOfVariant]["quantity"]
        ) * baseAndVariant;

      const baseAndVariantObj =
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["price"]
        ) + checkDiscountPrice;
      console.log("baseAndVariantObj price--", baseAndVariantObj);
      copiedObjects[productName]["variantObj"][variantName]["total"] =
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["quantity"]
        ) * baseAndVariantObj;

      return copiedObjects; // Return copied object
    });
  };
  const handleQuantity = (e, curr, innerCur) => {
    // HandleNewProductsInputValue(e, innerCur);
    const productName = curr;
    // if(e.target.value.include(0,1,2,3,4,5,6,7,8,9) === false) {
    //   alert('hi')
    // }
    if (e.target.value < 0) {
      setPs(0);
    } else {
      setPs(e.target.value);
    }

    setMainState((prevInputs) => {
      const copiedObjects = Object.assign({}, mainState); // Shallow copy
      console.log("copiedObjects", copiedObjects);
      // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
      const keyOfVariant = Object.keys(
        copiedObjects[productName]["variant"]
      ).filter(
        (curr) =>
          copiedObjects[productName]["variant"][curr]["name"] === innerCur.label
      );
      const variantName = innerCur.label;
      console.log("innercur", innerCur);
      if (parseInt(e.target.value) < 0) {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
      } else if (parseInt(e.target.value) > innerCur.stock) {
        showToast("error", `Max Stock : ${innerCur.stock}`);
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          innerCur.stock;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          innerCur.stock;
      } else {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          e.target.value;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          e.target.value;
      }

      let checkDiscountPrice =
        parseInt(copiedObjects[productName]["discount"]) === 0
          ? parseInt(copiedObjects[productName]["price"])
          : parseInt(copiedObjects[productName]["discount"]);
      console.log("checkDiscountPrice", checkDiscountPrice);
      const baseAndVariant =
        parseInt(copiedObjects[productName]["variant"][keyOfVariant]["price"]) +
        checkDiscountPrice;
      copiedObjects[productName]["variant"][keyOfVariant]["total"] =
        parseInt(
          copiedObjects[productName]["variant"][keyOfVariant]["quantity"]
        ) * baseAndVariant;

      const baseAndVariantObj =
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["price"]
        ) + checkDiscountPrice;
      console.log("baseAndVariantObj price--", baseAndVariantObj);
      copiedObjects[productName]["variantObj"][variantName]["total"] =
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["quantity"]
        ) * baseAndVariantObj;

      return copiedObjects; // Return copied object
    });
  };
  if (isLoading || !mainState) {
    return <Loader />;
  }
  console.log(paymentAllData, "============payment all data=============");
  console.log(
    oldMainState,
    oldMainInnerState,
    mainState,
    "=========== invoice_products============="
  );

  return (
    <div className="categorey-parent">
      <div
        className="invoice-b my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Product Details </h4>
        <div>
          <label htmlFor="old-invoice">Old Invoice</label>
          <input
            name="old-invoice"
            className="minWidthForInvoiceCreateTable mb-5"
            type="text"
            placeholder="Old Invoice Number"
            value={prevInvoice}
            onChange={(e) => setPrevInvoice(e.target.value)}
          />
        </div>
        <h5 className="mx-2">Old product</h5>
        {/* <table className="table mx-4 p-4">
          <tr>
            <td>Invoice</td>
            <td>Product name</td>
            <td>Variant name</td>
            <td>Quantity</td>
            <td>discount</td>
            <td>price</td>
          </tr>
          <tr className="">
            {sInvoice.exchange_products_json.data.map((item, index) => (
              <>
                <td key={index}>{item.inv_product_details.invoice}</td>
                <td key={index} className="font-weight-bold">{item.inv_product_details.product_name}</td>
                <td key={index}>{item.inv_product_details.variant_name}</td>
                <td key={index}>{item.inv_product_details.quantity}</td>
                <td key={index}>{item.inv_product_details.discount}</td>
                <td key={index} className="font-weight-bold">{item.inv_product_details.final_total}</td>
              </>
            ))}
          </tr>
        </table> */}
        <div className="padding-main">
          <div className="main-select">
            <Select
              // styles={styles}disabled
              isMulti
              placeholder="---Select---"
              isClearable
              onChange={handleOldMultiInputChange}
              defaultValue={getOldMainDefaultValues()}
              options={getOldMainOptions()}
              // options={ProductsList}
              onInputChange={oldInputProduct}
              // isDisabled={true}
            />
          </div>

          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <table className="item-tables table_my2">
                <tbody>
                  {/* //Here-------------------------- */}
                  {oldMainState !== undefined &&
                    Object.keys(oldMainState).map((curr, index) => {
                      return (
                        <>
                          <div
                            className="mt-4  px-2"
                            style={{
                              background: " rgba(245, 245, 245, 0.36)",
                              border: "none",
                            }}
                          >
                            <div
                              className="row py-3 my-3"
                              style={{ height: "45px" }}
                            >
                              <div
                                className="col-2 minWidthForInvoiceCreateTable ps-4"
                                style={{ textAlign: "start" }}
                              >
                                <p>Product Name</p>
                              </div>
                              <div
                                className="col-4 minWidthForInvoiceCreateTable  py-1"
                                style={{ textAlign: "center" }}
                              >
                                Total Stock
                              </div>
                              <div
                                className="col-5 minWidthForInvoiceCreateTable  py-1"
                                style={{ textAlign: "center" }}
                              >
                                Variants
                              </div>
                            </div>

                            <div className="row  " style={{ height: "45px" }}>
                              <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                <p className="text-capitalize">{curr}</p>
                                <img
                                  width={50}
                                  style={{
                                    margin: "6px 0px !important",
                                    border: "0.2px solid #bcb0b0",
                                  }}
                                  height={50}
                                  // src={!isLoading && oldMainState[curr]?.["thumb"]}
                                  alt="failed"
                                  className="ms-3 shadow-sm"
                                />
                              </div>
                              <div className="col-4 py-1">
                                <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                  {/* {Number(mainState[curr]["stock"] - pS)} */}
                                  {Number(oldMainState[curr]["stock"])}
                                </p>
                              </div>
                              <div className="col-5 text-center   py-1 pe-2">
                                <div
                                  className=""
                                  style={{ marginTop: "-10px" }}
                                >
                                  {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                  <Select
                                    // isDisabled={true}
                                    styles={{
                                      height: "45px",
                                      background: "black",
                                    }}
                                    isMulti
                                    placeholder="-Select Variants-"
                                    isClearable
                                    onChange={(value) => {
                                      handleOldInnerVariant(curr, value);
                                    }}
                                    // defaultValue={
                                    //   mainState[curr]["variantObj"] !== undefined &&
                                    //   Object.values(mainInnerState[curr]["variantObj"]).map((curr) => ({
                                    //     label: curr.name,
                                    //     value: curr.id,
                                    //     price: curr.price,
                                    //     stock: curr.stock,
                                    //     quantity: 1,
                                    //   }))
                                    // }

                                    defaultValue={oldMainInnerState[curr]}
                                    options={
                                      oldMainState[curr]["variantObj"] !==
                                        undefined &&
                                      Object.values(
                                        oldMainState[curr]["variantObj"]
                                      ).map((curr) => ({
                                        label: curr.name,
                                        value: curr.id,
                                        price: curr.price,
                                        stock: curr.stock,
                                        quantity: 1,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <table className="mapTable shadow-sm ms-2">
                            <tbody className="innerTbody w-100">
                              {!isLoading &&
                                oldMainInnerState !== undefined &&
                                oldMainInnerState[curr] !== undefined &&
                                oldMainInnerState[curr].map(
                                  (innerCur, index) => {
                                    return (
                                      <>
                                        {index === 0 && <OldVariantThead />}

                                        <tbody>
                                          <tr
                                            className="border-invoice"
                                            style={{ height: "45px" }}
                                          >
                                            <td className="ps-4 py-3">
                                              <p className="text-capitalize">
                                                {innerCur.label}
                                              </p>
                                              {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                            </td>
                                            <td
                                              className="text-center   py-3"
                                              style={{ minWidth: "300px" }}
                                            >
                                              <input
                                                name="in_price"
                                                // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                // value={innerCur.price}
                                                value={getOldBasePlusVariant(
                                                  curr,
                                                  innerCur
                                                )}
                                                className="invoiceCreateSmallInputs"
                                                placeholder="00.00"
                                                type="text"
                                              />
                                            </td>
                                            <td className="text-center   py-3">
                                              <input
                                                name="in_price"
                                                // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                value={innerCur.stock}
                                                className="invoiceCreateSmallInputs"
                                                placeholder="00.00"
                                                type="text"
                                              />
                                            </td>
                                            <td className="text-center   py-3">
                                              <input
                                                // disabled
                                                name="in_quantity"
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) =>
                                                  handleOldQuantity(
                                                    e,
                                                    curr,
                                                    innerCur
                                                  )
                                                }
                                                className="invoiceCreateSmallInputs"
                                                placeholder="00.00"
                                                value={
                                                  (oldMainState === undefined &&
                                                    oldMainState[curr] ===
                                                      undefined &&
                                                    oldMainState[curr][
                                                      "variantObj"
                                                    ] === undefined &&
                                                    oldMainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label] ===
                                                      undefined &&
                                                    oldMainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ] === undefined) ||
                                                  oldMainState[curr][
                                                    "variantObj"
                                                  ][innerCur.label][
                                                    "quantity"
                                                  ] === "-1" ||
                                                  oldMainState[curr][
                                                    "variantObj"
                                                  ][innerCur.label][
                                                    "quantity"
                                                  ] === -1
                                                    ? 0
                                                    : oldMainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label][
                                                        "quantity"
                                                      ]
                                                }
                                              />
                                            </td>
                                            <td className="text-center py-3 ">
                                              <input
                                                // name="in_total"
                                                value={
                                                  (oldMainState === undefined &&
                                                    oldMainState[curr] ===
                                                      undefined) ||
                                                  isNaN(
                                                    oldMainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label]["total"]
                                                  )
                                                    ? 0
                                                    : oldMainState[curr][
                                                        "variantObj"
                                                      ][innerCur.label]["total"]
                                                }
                                                className="invoiceCreateSmallInputs"
                                                placeholder="00.00"
                                                type="text"
                                              />
                                            </td>{" "}
                                          </tr>
                                        </tbody>
                                      </>
                                    );
                                    // return <h2>{innerCurr.label}</h2>;
                                  }
                                )}
                            </tbody>

                            {/* <VariantTable /> */}
                          </table>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="border-p"></div>
        <h5 className="mx-2">New product</h5>
        <div className="padding-main">
          <div className="main-select">
            <Select
              // styles={styles}disabled
              isMulti
              placeholder="---Select---"
              isClearable
              onChange={handleMultiInputChange}
              defaultValue={getMainDefaultValues()}
              options={getMainOptions()}
              // options={ProductsList}
              onInputChange={inputProduct}
              // isDisabled={true}
            />
          </div>

          <div className="mt-3">
            <div
              className="rounded"
              style={{
                background: "#FAFAFA",
                display: "block",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              <table className="item-tables table_my2">
                <tbody>
                  {/* //Here-------------------------- */}
                  {mainState !== undefined &&
                    Object.keys(mainState).map((curr, index) => {
                      return (
                        <>
                          <div
                            className="mt-4  px-2"
                            style={{
                              background: " rgba(245, 245, 245, 0.36)",
                              border: "none",
                            }}
                          >
                            <div
                              className="row py-3 my-3"
                              style={{ height: "45px" }}
                            >
                              <div
                                className="col-2 minWidthForInvoiceCreateTable ps-4"
                                style={{ textAlign: "start" }}
                              >
                                <p>Product Name</p>
                              </div>
                              <div
                                className="col-4 minWidthForInvoiceCreateTable  py-1"
                                style={{ textAlign: "center" }}
                              >
                                Total Stock
                              </div>
                              <div
                                className="col-5 minWidthForInvoiceCreateTable  py-1"
                                style={{ textAlign: "center" }}
                              >
                                Variants
                              </div>
                            </div>

                            <div className="row  " style={{ height: "45px" }}>
                              <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                <p className="text-capitalize">{curr}</p>
                                <img
                                  width={50}
                                  style={{
                                    margin: "6px 0px !important",
                                    border: "0.2px solid #bcb0b0",
                                  }}
                                  height={50}
                                  src={
                                    !isLoading &&
                                    mainState[curr]["product_thumb"]
                                  }
                                  alt="failed"
                                  className="ms-3 shadow-sm"
                                />
                                {console.log(
                                  " mainState[curr][thumb",
                                  mainState[curr]["thumb"]
                                )}
                              </div>
                              <div className="col-4 py-1">
                                <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                  {/* {Number(mainState[curr]["stock"] - pS)} */}
                                  {Number(mainState[curr]["stock"])}
                                </p>
                              </div>
                              <div className="col-5 text-center   py-1 pe-2">
                                <div
                                  className=""
                                  style={{ marginTop: "-10px" }}
                                >
                                  {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                  <Select
                                    // isDisabled={true}
                                    styles={{
                                      height: "45px",
                                      background: "black",
                                    }}
                                    isMulti
                                    placeholder="-Select Variants-"
                                    isClearable
                                    onChange={(value) => {
                                      handleInnerVariant(curr, value);
                                    }}
                                    // defaultValue={
                                    //   mainState[curr]["variantObj"] !== undefined &&
                                    //   Object.values(mainInnerState[curr]["variantObj"]).map((curr) => ({
                                    //     label: curr.name,
                                    //     value: curr.id,
                                    //     price: curr.price,
                                    //     stock: curr.stock,
                                    //     quantity: 1,
                                    //   }))
                                    // }

                                    defaultValue={mainInnerState[curr]}
                                    options={
                                      mainState[curr]["variantObj"] !==
                                        undefined &&
                                      Object.values(
                                        mainState[curr]["variantObj"]
                                      ).map((curr) => ({
                                        label: curr.name,
                                        value: curr.id,
                                        price: curr.price,
                                        stock: curr.stock,
                                        quantity: 1,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <table className="mapTable shadow-sm ms-2">
                            <tbody className="innerTbody w-100">
                              {!isLoading &&
                                mainInnerState !== undefined &&
                                mainInnerState[curr] !== undefined &&
                                mainInnerState[curr].map((innerCur, index) => {
                                  return (
                                    <>
                                      {index === 0 && <VariantThead />}

                                      <tbody>
                                        <tr
                                          className="border-invoice"
                                          style={{ height: "45px" }}
                                        >
                                          <td className="ps-4 py-3">
                                            <p className="text-capitalize">
                                              {innerCur.label}
                                            </p>
                                            {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                          </td>
                                          <td
                                            className="text-center   py-3"
                                            style={{ minWidth: "300px" }}
                                          >
                                            <input
                                              name="in_price"
                                              // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                              // value={innerCur.price}
                                              value={getBasePlusVariant(
                                                curr,
                                                innerCur
                                              )}
                                              className="invoiceCreateSmallInputs"
                                              placeholder="00.00"
                                              type="text"
                                            />
                                          </td>
                                          <td className="text-center   py-3">
                                            <input
                                              name="in_price"
                                              // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                              value={innerCur.stock}
                                              className="invoiceCreateSmallInputs"
                                              placeholder="00.00"
                                              type="text"
                                            />
                                          </td>
                                          <td className="text-center   py-3">
                                            <input
                                              // disabled
                                              name="in_quantity"
                                              type="number"
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) =>
                                                handleQuantity(
                                                  e,
                                                  curr,
                                                  innerCur
                                                )
                                              }
                                              className="invoiceCreateSmallInputs"
                                              placeholder="00.00"
                                              value={
                                                (mainState === undefined &&
                                                  mainState[curr] ===
                                                    undefined &&
                                                  mainState[curr][
                                                    "variantObj"
                                                  ] === undefined &&
                                                  mainState[curr]["variantObj"][
                                                    innerCur.label
                                                  ] === undefined &&
                                                  mainState[curr]["variantObj"][
                                                    innerCur.label
                                                  ]["quantity"] ===
                                                    undefined) ||
                                                mainState[curr]["variantObj"][
                                                  innerCur.label
                                                ]["quantity"] === "-1" ||
                                                mainState[curr]["variantObj"][
                                                  innerCur.label
                                                ]["quantity"] === -1
                                                  ? 0
                                                  : mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label][
                                                      "quantity"
                                                    ]
                                              }
                                            />
                                          </td>
                                          <td className="text-center py-3 ">
                                            <input
                                              // name="in_total"
                                              value={
                                                (mainState === undefined &&
                                                  mainState[curr] ===
                                                    undefined) ||
                                                isNaN(
                                                  mainState[curr]["variantObj"][
                                                    innerCur.label
                                                  ]["total"]
                                                )
                                                  ? 0
                                                  : mainState[curr][
                                                      "variantObj"
                                                    ][innerCur.label]["total"]
                                              }
                                              className="invoiceCreateSmallInputs"
                                              placeholder="00.00"
                                              type="text"
                                            />
                                          </td>{" "}
                                        </tr>
                                      </tbody>
                                    </>
                                  );
                                  // return <h2>{innerCurr.label}</h2>;
                                })}
                            </tbody>

                            {/* <VariantTable /> */}
                          </table>
                        </>
                      );
                    })}
                </tbody>
              </table>

              <div className="d-flex flex-column flex-md-row mt-4 justify-content-between mx-4">
                {/* sub_total, delivery_charge, discount, total, paid_amount, Due_amount */}
                <div>
                  <h6>Total Item : {Object?.keys(mainState)?.length}</h6>
                </div>
                <div>
                  <div
                    className="d-flex justify-content-between "
                    style={{
                      color: "#212121",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <p>New Sub Total :</p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss"
                        type="text"
                        value={
                          isNaN(getSubTotal(mainState, getAllVariantName))
                            ? 0
                            : getSubTotal(mainState, getAllVariantName)
                        }
                        // value={subTotal}
                        // onChange={(e) => setSubTotal(e.target.value)}
                      />
                    </p>
                  </div>
                  <div
                    className="d-flex justify-content-between "
                    style={{
                      color: "#212121",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <p>Previous Sub Total :</p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss"
                        type="text"
                        value={
                          isNaN(
                            getOldSubTotal(oldMainState, getOldAllVariantName)
                          )
                            ? 0
                            : getOldSubTotal(oldMainState, getOldAllVariantName)
                        }
                      />
                    </p>
                  </div>
                  {redeem && (
                    <div className="d-flex justify-content-between d-none cl ">
                      <p>Redeem Discount : </p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="text"
                          disabled
                          value={redeem}
                          style={{ border: "1px solid gray" }}
                        />
                      </p>
                    </div>
                  )}

                  <div className="d-flex justify-content-between pl pt-2">
                    <p>
                      Discount{" "}
                      {invoice_discount_type === "0" ||
                      invoice_discount_type === 0
                        ? ""
                        : "%"}{" "}
                      :
                    </p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss paid_input_css"
                        name="invoice_paid"
                        type="text"
                        value={Discount}
                        onChange={(e) => {
                          if (e.target.value[0] === "-") {
                            return showToast(
                              "error",
                              "Negative value is not accepted"
                            );
                          }
                          setDiscount(e.target.value);
                        }}
                      />
                    </p>
                  </div>
                  {/* <div
                      className="d-flex justify-content-between"
                      style={{
                        color: "#212121",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>
                        Price Difference
                        {getNewGrandTotal() < 0
                          ? "(Non-refundable):"
                          : "(Have To Pay):"}
                      </p>
                      <p className="ms-3">
                        <input
                          className="ms-3 font-weight-bold invoiceInpCss"
                          style={{ fontWeight: "700" }}
                          type="text"
                          value={
                            isNaN(getNewGrandTotal())
                              ? 0
                              : Math.abs(getNewGrandTotal())
                          }
                        />
                      </p>
                    </div> */}

                  <div className="d-flex justify-content-between pt-2">
                    <p>Previous Paid amount :</p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss paid_input_css"
                        name="invoice_paid"
                        type="text"
                        value={prevPaid}
                        onChange={(e) => setPrevPaid(e.target.value)}
                      />
                    </p>
                  </div>

                  {/* <div className="d-flex justify-content-between cl">
                      <p>Due amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="text"
                          name="invoice_due"
                          value={
                            isNaN(getDueAmount()) ? 0 : Math.abs(getDueAmount())
                          }
                          onChange={(e) => setDueAmount(e.target.value)}
                        />
                      </p>{" "}
                    </div> */}
                  <div className="d-flex justify-content-between cl">
                    <p>Recieved amount :</p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss paid_input_css"
                        type="number"
                        name="recived_amount"
                        min={0}
                        value={recievedAmount}
                        onChange={(e) => {
                          if (e.target.value[0] === "-") {
                            return showToast(
                              "error",
                              "Negative value is not accepted"
                            );
                          }
                          handleInputs(e, SetInvoiceAllData);
                          setRecievedAmount(
                            // InvoiceAllData["recived_amount"] >=
                            //   getNewDueAmount()
                            //   ? getNewDueAmount()
                            //   : 0
                            e.target.value
                          );
                        }}
                      />
                    </p>{" "}
                  </div>
                  {/* <div className="d-flex justify-content-between cl">
                      <p>Change Amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="number"
                          name="invoice_examount"
                          // value={Math.abs(
                          //   isNaN(getNewDueAmount()) ||
                          //     InvoiceAllData["recived_amount"] == 0 ||
                          //     InvoiceAllData["recived_amount"] <
                          //       getNewDueAmount()
                          //     ? 0
                          //     : getNewDueAmount() -
                          //         InvoiceAllData["recived_amount"]
                          // )}
                          value={changeAmount}
                          onChange={(e) => setChangeAmount(e.target.value)}
                        />
                      </p>{" "}
                    </div> */}
                  <div className="d-flex justify-content-between pl pt-2">
                    <p>New Paid amount :</p>
                    <p className="ms-3 ">
                      <input
                        className="ms-3 invoiceInpCss paid_input_css"
                        name="invoice_paid"
                        type="text"
                        value={paid}
                        onChange={(e) => {
                          if (e.target.value[0] === "-") {
                            return showToast(
                              "error",
                              "Negative value is not accepted"
                            );
                          }
                          setpaid(e.target.value);
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white cat-child ">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 24px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3
            style={{ fontSize: "23px", marginTop: "6px", marginLeft: "12px" }}
          >
            Invoice Details
          </h3>
        </div>
        {/* -----------product form---------- */}
        <div className="product-main createInvoiceInputContainer">
          <div className="row">
            <div className="col-12 col-md-6">
              <RequiredLabel text="Invoice Number" />

              <InputGroup className="mb-3">
                <Form.Control
                  disabled
                  aria-label="Username"
                  placeholder="Inv00014"
                  value={InvoiceAllData?.invoice_number}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <p>Barcode</p>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled
                  placeholder="This will be generated automatically"
                  aria-label="Username"
                  value={InvoiceAllData?.invoice_barcode}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="Invoice Date" />
              <div className="position-relative">
                <InputGroup className="">
                  <Form.Control
                    type="date"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    placeholder="Select a date"
                    name="invoice_date"
                    value={InvoiceAllData["invoice_date"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
                {/* <div className="calenderContainer">
                <CalendarBlank size={20} />
              </div> */}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text={"Delivery Type"} />
              <div className="mb-3" style={{ height: "47px" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  onChange={(e) => setinvoice_delivery_type(e.target.value)}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                >
                  <option value={0}>Regular</option>
                  <option value={1}>Urgent</option>
                </Form.Select>
              </div>
            </div>

            {/* 
            <div className="col-12 col-md-6 ">
              <RequiredLabel text="Due Date" />

              <div className="position-relative ">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="date"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    placeholder="Select a date"
                    name="invoice_due_date"
                    value={InvoiceAllData["invoice_due_date"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
         
              </div>
            </div> */}
            <div className="col-12 col-md-6">
              <RequiredLabel text="From Mobile" />

              <div className="d-flex phoneContainer ">
                <PhoneInput
                  inputClass="phoneinput"
                  disableDropdown
                  onChange={(e) => setFromCode(e)}
                  country={"bd"}
                />
                {/* <PhoneInput inputClass="phoneinput" onChange={(e) => setFromCode(e)} enableSearch={true} country={"bd"} /> */}
                <div className="w-100">
                  <Form.Control
                    aria-label="Input number"
                    placeholder="Input number"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    className="phone_insert_input"
                    name="invoice_from_mobile"
                    value={InvoiceAllData["invoice_from_mobile"]?.replace(
                      "+880",
                      ""
                    )}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <RequiredLabel text="To Mobile" />
              <div className="d-flex phoneContainer ">
                <PhoneInput
                  inputClass="phoneinput"
                  disableDropdown
                  onChange={(e) => setFromCode(e)}
                  country={"bd"}
                />
                {/* <PhoneInput inputClass="phoneinput" onChange={(e) => setToCode(e)} enableSearch={true} country={"bd"} /> */}
                <div className="w-100">
                  {/* <Form.Control
                    aria-label="Input number"
                    placeholder="Input number"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    className="phone_insert_input"
                    name="invoice_to_mobile"
                    value={InvoiceAllData["invoice_to_mobile"]?.replace(
                      "+880",
                      ""
                    )}
                    onChange={(e) => toPhoneHandle(e)}
                  /> */}
                  <Form.Control
                    aria-label="Input number"
                    placeholder="Input number"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    className="phone_insert_input"
                    name="invoice_to_mobile"
                    value={InvoiceAllData["invoice_to_mobile"]?.replace(
                      "+880",
                      ""
                    )}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text="Bill From" />

              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="invoice_bill_from"
                  value={InvoiceAllData["invoice_bill_from"]}
                  onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 ">
              <RequiredLabel text="Bill To" />
              {console.log("FoundCustomer", FoundCustomer)}
              <InputGroup className="mb-3">
                <Form.Control
                  name="invoice_bill_to"
                  value={
                    Found
                      ? FoundCustomer.name
                      : InvoiceAllData["invoice_bill_to"]
                  }
                  onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            {/* new  */}

            <div className="col-12 col-md-6 mt-3">
              <RequiredLabel text={"Delivery Charge"} />
              <div className="mb-3" style={{ height: "47px" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  value={invoice_delivery_charge}
                  onChange={(e) => {
                    if (Number(e.target.value) === 0) {
                      setCustomCharge(80);
                    } else if (Number(e.target.value) === 1) {
                      setCustomCharge(150);
                    }
                    setinvoice_delivery_charge(e.target.value);
                  }}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                >
                  <option value={0}>Inside Dhaka : 80/-</option>
                  <option value={1}>Outside Dhaka : 150/-</option>
                  <option value={2}>Custom</option>
                </Form.Select>

                {/* <Select
                  placeholder="---Select---"
                  isClearable
                  styles={{height:"100%"}}
                  options={deliveryPrice_options}
                  defaultValue={deliveryPrice_options.filter((curr) => curr.value === invoice_delivery_charge)[0]}
                  onChange={(option) => setinvoice_delivery_charge(option.value)}
                /> */}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <RequiredLabel text={"Discount Type"} />
              <div className="" style={{ height: "47px" }}>
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  onChange={(e) => setinvoice_discount_type(e.target.value)}
                  value={invoice_discount_type}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                >
                  <option value={0}>Flat </option>
                  <option value={1}>Percentage</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="From Email" />

              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="invoice_from_email"
                  value={InvoiceAllData["invoice_from_email"]}
                  onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6 ">
              {/* <RequiredLabel text="To Email" /> */}
              <p>To Email</p>
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="invoice_to_email"
                  value={InvoiceAllData["invoice_to_email"]}
                  onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="From Address" />

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  name="invoice_from_address"
                  value={InvoiceAllData["invoice_from_address"]}
                  onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6"></div>

            <div className="col-12 col-md-6">
              <RequiredLabel text="To Address 1" />

              <InputGroup className=" addressInput">
                <Form.Control
                  aria-label="Username"
                  name="invoice_to_address"
                  value={
                    Found
                      ? FoundCustomer.to_address
                      : InvoiceAllData["invoice_to_address"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_address: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              <p>To Address 2</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="invoice_to_address2"
                  value={
                    Found
                      ? FoundCustomer.to_address2
                      : InvoiceAllData["invoice_to_address2"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_address2: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            {/* zip */}
            {/* <div className="col-12 col-md-6">
              <p>To Zipcode</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="to_zip_code"
                  value={
                    Found
                      ? FoundCustomer.to_zip_code
                      : InvoiceAllData["to_zip_code"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_zip_code: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div> */}

            {/* city */}
            {/* <div className="col-12 col-md-6">
              <p>To City</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="to_city"
                  value={
                    Found ? FoundCustomer.to_city : InvoiceAllData["to_city"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_city: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div> */}
            {/* dist */}
            {/* <div className="col-12 col-md-6">
              <RequiredLabel text="To District" />

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="to_district"
                  value={
                    Found
                      ? FoundCustomer.to_district
                      : InvoiceAllData["to_district"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_district: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div> */}
            {/* divi */}
            {/* <div className="col-12 col-md-6">
              <RequiredLabel text="To Division" />
              <p>To Division</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="to_division"
                  value={
                    Found
                      ? FoundCustomer.to_division
                      : InvoiceAllData["to_division"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_division: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div> */}
            {/* new address inputs start */}
            <div className="col-12 col-md-6">
              <RequiredLabel text="To Division" />
              {/* <p>*</p> */}
              <Controller
                name="division"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isSearchable
                    {...field}
                    value={selectedDivision}
                    onChange={handleDivisionChange}
                    styles={customStyles}
                    options={divisions.map(({ name, id }) => {
                      return { label: name, value: id };
                    })}
                  />
                )}
              />
              {/* <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="to_division"
                    value={
                      Found
                        ? FoundCustomer.to_division
                        : InvoiceAllData["to_division"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_division: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup> */}
              {/* <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                          ...FoundCustomer,
                          to_division: e.target.value,
                        })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    {divisions.map((curr) => {
                      return <option value={curr.name}>{curr.name}</option>;
                    })}
                  </Form.Select>
                </div> */}
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text="To District" />
              {/* <p>*</p> */}
              <Controller
                name="district"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isSearchable
                    {...field}
                    value={selectedDistrict}
                    onChange={handleDistrictChange}
                    styles={customStyles}
                    // defaultValue={ label: "", value: "" }
                    options={districtList?.map(({ name, id }) => {
                      return { label: name, value: id };
                    })}
                  />
                )}
              />
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text="To City" />
              {/* <p>*</p> */}
              <Controller
                name="town"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    isSearchable
                    {...field}
                    value={selectedTown}
                    onChange={handleTownChange}
                    styles={customStyles}
                    // defaultValue={ label: "", value: "" }
                    options={upazillaList?.map(({ name, id }) => {
                      return { label: name, value: id };
                    })}
                  />
                )}
              />
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="To Division" /> */}
              <p>Post Code</p>
              {/* <Controller
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      styles={customStyles}
                      options={zipList.map(({ name, postCode }) => {
                        return {
                          label: `${name}-${postCode}`,
                          value: postCode,
                        };
                      })}
                    />
                  )}
                /> */}
              <input
                style={{ width: "100%" }}
                type="text"
                {...register("zip")}
              />
            </div>
            {/* new address input end */}
            {/* country */}
            <div className="col-12 col-md-6">
              <RequiredLabel text="To Country" />

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="to_country"
                  value={
                    Found
                      ? FoundCustomer.to_country
                      : InvoiceAllData["to_country"]
                  }
                  onChange={(e) =>
                    Found
                      ? setFoundCustomer({
                          ...FoundCustomer,
                          to_country: e.target.value,
                        })
                      : handleInputs(e, SetInvoiceAllData)
                  }
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 ">
              <RequiredLabel text={"Payment Type"} />
              {/* <RequiredLabel text={"Payment Type"} /> */}
              <div className="mb-3">
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  onChange={(e) => {
                    setinvoice_payment_type(e.target.value);
                  }}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  value={invoice_payment_type}
                >
                  {paymentType.map((curr) => {
                    return <option value={curr.value}>{curr.label}</option>;
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="To District" /> */}
              <p>Reference ID</p>
              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  name="reference_id"
                  value={paymentAllData["reference_id"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>

      {/* {
          invoice_payment_type !== 0 || invoice_payment_type !== '0' &&
        } */}

      <div
        className={`invoice-b my-4 bg-white cat-child ${
          invoice_payment_type === 0 ||
          invoice_payment_type === "0" ||
          invoice_payment_type === 6 ||
          invoice_payment_type === "6"
            ? "d-none"
            : ""
        }`}
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className=" my-2 mb-4 padding-main-head">Payment Details</h4>
        <div className="border-p"></div>
        {/* ==========for bKash========== */}
        {invoice_payment_type === 5 ||
          (invoice_payment_type === "5" && (
            <div className="padding-main row">
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="bKash Number" /> */}
                <p>Nagad Number</p>

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                    name="nagad_number"
                    value={paymentAllData["nagad_number"]}
                    onChange={(e) => handleInputs(e, setPaymentAllData)}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="TRX Number" /> */}
                <p>TNX Number</p>
                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                    name="nagad_trx_number"
                    value={paymentAllData["nagad_trx_number"]}
                    onChange={(e) => handleInputs(e, setPaymentAllData)}
                  />
                </InputGroup>
              </div>
            </div>
          ))}
        {(invoice_payment_type === 3 || invoice_payment_type === "3") && (
          <div className="padding-main row">
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="bKash Number" /> */}
              <p>BKash Number</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="bkash_number"
                  value={paymentAllData["bkash_number"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="TRX Number" /> */}
              <p>TNX Number</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="bkash_trx_number"
                  value={paymentAllData["bkash_trx_number"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
          </div>
        )}
        {/* ==========for bKash========== */}
        {/* ===========For Bank Transfer */}
        {(invoice_payment_type === 2 || invoice_payment_type === "2") && (
          <div className="padding-main row">
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Bank Name" /> */}
              <p>Bank Name</p>
              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="bank_name"
                  value={paymentAllData["bank_name"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Branch Name" /> */}
              <p>Branch Name</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="banK_branch_name"
                  value={paymentAllData["banK_branch_name"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Acount Number" /> */}
              <p>Account Number</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="banK_account_number"
                  value={paymentAllData["banK_account_number"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Account Name" /> */}
              <p>Account Name</p>
              {/* <RequiredLabel text="Account Name" /> */}

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="banK_account_name"
                  value={paymentAllData["banK_account_name"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
          </div>
        )}

        {/* ===========For Bank Transfer=============== */}
        {/* ===========For Card Payment=============== */}
        {(invoice_payment_type === 1 || invoice_payment_type === "1") && (
          <div className="padding-main row">
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Card Number" /> */}
              <p>Card Number</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="card_number"
                  value={paymentAllData["card_number"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              {/* <RequiredLabel text="Card Holder Name" /> */}
              <p>Card Holder Name</p>

              <InputGroup className="mb-3 addressInput">
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name="card_holder_name"
                  value={paymentAllData["card_holder_name"]}
                  onChange={(e) => handleInputs(e, setPaymentAllData)}
                />
              </InputGroup>
            </div>
          </div>
        )}

        {/* ===========For Card Payment=============== */}
      </div>
      {/* ====================attribute=================== */}

      {/* ====================attribute=================== */}
      <div
        className="product-main my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <div>
          <p>Payment Status</p>
          {/* <SelectDropDownForInvoice
            options={invoice_choices}
            defaultValue={invoice_choices.filter((curr) => curr.value === InvoiceAllData["invoice_payment_status"])[0]}
            setSelectedOptionCategory={setinvoice_status}
          /> */}
          <Select
            placeholder="---Select---"
            isClearable
            options={invoice_choices}
            defaultValue={
              invoice_choices.filter((curr) => curr.value === invoice_status)[0]
            }
            onChange={(option) => setinvoice_status(option.value)}
          />

          <p className="mt-4">Delivery Status</p>

          {/* <SelectDropDownForInvoice
            options={delivery_choices}
            defaultValue={delivery_choices.filter((curr) => curr.value === InvoiceAllData["invoice_delivery_status"])[0]}
            setSelectedOptionCategory={setDelivrey_status}
          /> */}

          <Select
            placeholder="---Select---"
            isClearable
            options={delivery_choices}
            defaultValue={
              delivery_choices.filter(
                (curr) => curr.value === Delivery_status
              )[0]
            }
            onChange={(option) => setDelivrey_status(option.value)}
          />
          {console.log("delivery status", Delivery_status)}

          {/* notes */}
          <div className="mt-3 d-flex align-content-center">
            <PencilSimple size={12} weight="light" className="mt-0 pe-1" />{" "}
            <p style={{ color: "#333333" }} className="mb-2">
              Notes
            </p>
          </div>
          <Form.Control
            as="textarea"
            placeholder=""
            className="mb-3"
            name="invoice_notes"
            value={InvoiceAllData["invoice_notes"]}
            onChange={(e) => handleInputs(e, SetInvoiceAllData)}
            style={{
              height: "100px",
              resize: "none",
              backgroundColor: "#FAFAFA",
            }}
          />
        </div>

        <div className="row d-flex justify-content-between"></div>
      </div>

      <div
        className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
        style={{ border: "0.5px solid #E0E0E0" }}
      >
        <div className="" style={{ marginLeft: "-10px" }}>
          <button
            className="btn rounded border align-items-center d-none me-2 mt-2 "
            disabled={!InvoiceAllData}
            onClick={handlePrint2}
          >
            <DoIcon className="doc" /> Preview PDF
          </button>
          {/* <button onClick={postInvoiceProduct} className="btn rounded border me-2 mt-2"> */}
          <button
            onClick={() => ValidateData(false)}
            className="btn rounded border me-2 mt-2"
          >
            Save Invoice
          </button>
          {InvoiceAllData["invoice_to_email"] !== "" &&
            !Found &&
            validateEmail(InvoiceAllData["invoice_to_email"]) && (
              <button
                onClick={SaveAndSendEmail}
                className="btn rounded border text-white mt-2"
                style={{ background: "black" }}
              >
                Save & Send PDF
              </button>
            )}

          <button
            className="btn rounded border me-2 ms-2 mt-2"
            onClick={() => history.push(path.invoice_list)}
          >
            Cancel
          </button>
          {/* 
          {Found && FoundCustomer?.email !== "" && validateEmail(InvoiceAllData["invoice_to_email"]) && (
            <button
              onClick={() => {
                set_is_send_email(true);
                ValidateData();
                console.log("save and send working");
              }}
              className="btn rounded border text-white mt-2"
              style={{ background: "black" }}
            >
              Save & Send PDF
            </button>
          )} */}
          {/* <div className="d-none">
            <PreviewEdit
              print={print}
              status={invoice_payment_type}
              Found={Found}
              InvoiceAllData={InvoiceAllData}
              FoundCustomer={FoundCustomer}
              mainState={mainState}
              // jData={jData}
            ></PreviewEdit>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EditOutletExchange;
