export const deliveryStatus = {
  0: "Returned",
  1: "Recieved",
  2: "Delivered",
};
export const paymentStatus = {
  0: "Paid",
  1: "Unpaid",
  2: "Due",
};
export const handleSelectAll = (newValue, setState) => {
  const uniqueValues = Array.from(
    new Set(newValue.map((item) => item.value))
  ).map((value) => newValue.find((item) => item.value === value));

  setState(uniqueValues);
};
export const handleSelectAll2 = (ProductsList, setProductCom,setMainState,setdefaultValue) => {
  // Ensure unique values based on item.value
  const uniqueValues = Array.from(
    new Set(ProductsList.map((item) => item.value))
  ).map((value) => ProductsList.find((item) => item.value === value));

  setProductCom(uniqueValues);

  // Additional processing for product variants
  uniqueValues.forEach((product) => {
    if (product.variant) {
      const newVal = product.label;
      
      // Prepare variants
      let variants = product.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        total: 0,
      }));

      // Prepare variants object for quick access
      const variantsObj = {};
      product.variant.forEach((curr) => {
        variantsObj[curr.name] = {
          id: curr.id,
          name: curr.name,
          price: curr.price,
          stock: curr.stock,
          product: curr.product,
          quantity: -1,
          total: 0,
        };
      });

      // Update main state with new selection
      setMainState((prevMainState) => ({
        [newVal]: {
          id: product.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(product.price),
          stock: parseInt(product.stock),
          thumb: product.thumb,
          discount: parseInt(product.discount),
        },
        ...prevMainState,
      }));

      // Update default values
      setdefaultValue((prevDefaultValue) => [
        ...prevDefaultValue,
        {
          value: product.value,
          label: newVal,
        },
      ]);
    }
  });
};
