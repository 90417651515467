import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import salesIcon from "../../assets/Icon/sales Icon.svg";
import invoiceIcon from "../../assets/Icon/invoice icon.svg";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import { showToast } from "../../utils/ToastHelper";

const SalesFilter = ({ mid ,secondParam = ''}) => {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [data, setData] = useState({});
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        showToast('success','Data is Loading')
        const data = res.data.data;
        setData(data);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
        }
      });
  };
  const url = `${BASE_URL}${mid}/?start=${date1}&end=${date2}${secondParam}`;
  useEffect(() => {
    if (date1 && date2) {
      apiFunction(url);
    }
  }, [date1, date2]);
  return (
    <div className="only-margin ">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h4 style={{ color: "#637A9F" }}> Sales Filter</h4>
        </div>
        <div className="filter_div">
          <input
            type="date"
            format="DD-MM-YYYY"
            className="date-input"
            onChange={(e) => {
              const date = e.target.value;
              setDate1(
                `${date.split("-")[1]}/${date.split("-")[2]}/${
                  date.split("-")[0]
                }`
              );
            }}
          />
          <span className="mx-2 my-auto" placeholder="09-09-2022">
            {" "}
            To
          </span>
          <input
            type="date"
            className="date-input"
            onChange={(e) => {
              const date = e.target.value;

              setDate2(
                `${date.split("-")[1]}/${date.split("-")[2]}/${
                  date.split("-")[0]
                }`
              );
            }}
          />
        </div>
      </div>
      { Object.keys(data).length === 0 ? <>
      <p style={{color:'red'}}>Please Select  date first </p>
      </> : (
        <div
          className="stat-box-main"
          style={{ justifyContent: "flex-start", gap: "10px" }}
        >
          <div className=" state-main  mt-2 bg-white">
            <div className="state-img-box">
              <img src={salesIcon} width={26} height={24} alt="" />
            </div>
            <div className="mx-4 mb-2">
              <p>Total Invoice</p>
              <h5 className="state-head">
                <CountUp end={data ? data?.total_invoices : 0} duration={1} />
              </h5>
            </div>
          </div>
          <div className=" state-main  mt-2 bg-white">
            <div className="state-img-box">
              <img src={salesIcon} width={26} height={24} alt="" />
            </div>
            <div className="mx-4 mb-2">
              <p>Total Sales</p>
              <h5 className="state-head">
                ৳
                <CountUp end={data ? data?.total_amount : 0} duration={0.2} />
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesFilter;
