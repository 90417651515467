import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";

import Page404 from "../errorPages/Page404";
import ForgetPassword from "../forgetpass/ForgetPassword";
import ResetPassword from "../resetpass/ResetPassword";
import AddCategory from "../inventory/category/AddCategory";
import CategoryList from "../inventory/category/CategoryList";
import AddProduct from "../inventory/product/AddNewProduct";
import Attribute from "../inventory/product/Attribute";
import BulkImport from "../inventory/product/BulkImport";
import ProductList from "../inventory/product/ProductList";
import ViewProduct from "../inventory/product/ViewProduct";
import CreateInvoice from "../invoice/CreateInvoice";
import InvoiceList from "../invoice/InvoiceList";
import ViewInvoice from "../invoice/ViewInvoice";
import Login from "../login/Login";
import Notification from "../notification/Notification";
import Reports from "../Reports/Reports";
import AddGroup from "../user/AddGroup";
import AddRole from "../user/AddRole";
import AddUser from "../user/AddUser";
import UserList from "../user/UserList";
import ViewUser from "../user/ViewUser";
import CustomerList from "../customers/CustomerList";
import * as path from "./RoutePaths";
import { User } from "phosphor-react";
import DailySalesReport from "../Reports/DailySalesReport";
import UserProfile from "../user/UserProfile";
import EditCategory from "../inventory/category/EditCategory";
import EditInvoice from "../invoice/EditInvoice";
import EditProduct from "../inventory/product/EditProduct";
import EditUser from "../user/EditUser";
import EditCustomer from "../customers/EditCustomer";
import CustomerView from "../customers/CustomerView";
import AddCustomer from "../customers/AddCustomer";
import CustomerBulk from "../customers/CustomerBulk";
import CustomInvoiceList from "../Custom/CustomInvoiceList";
import CustomSalesReport from "../Custom/CustomSalesReport";
import CustomCreateInvoice from "../Custom/CustomCreateInvoice";
import CustomInvoiceView from "../Custom/CustomInvoiceView";
import CustomInvoiceEdit from "../Custom/CustomInvoiceEdit.jsx";
import Loader from "../CustomCommons/Loader";
import Offer from "../Promotion/Offer";
import Cupon from "../Promotion/Cupon";
import Reviews from "../Promotion/Reviews";
import ContactUs from "../Promotion/ContactUs";
import GlobalSettings from "../WebSettings/GlobalSettings";
import MainSettings from "../WebSettings/MainSettings";
import TeamMember from "../WebSettings/TeamMember";
import Testimonials from "../WebSettings/Testimonials";
import AddOffer from "../Promotion/AddOffer";
import EditOffer from "../Promotion/EditOffer";
import ViewOffer from "../Promotion/ViewOffer";
import AddCupon from "../Promotion/Cupon/AddCupon";
import WorkWithUs from "../Promotion/WorkWithUs";
import AddTestiminial from "../WebSettings/Testimonial/AddTestiminial";
import EditTestimonial from "../WebSettings/Testimonial/EditTestimonial";
import ViewTestimonial from "../WebSettings/Testimonial/ViewTestimonial";
import AddTeamMember from "../WebSettings/Team/AddTeamMember";
import EditTeamMember from "../WebSettings/Team/EditTeamMember";
import AddVideo from "../WebSettings/Slider/AddVideo";
import DisplaySettings from "../WebSettings/DisplaySettings";
import AddDisplayCenter from "../WebSettings/DisplayCenter/AddDisplayCenter";
import SocialMedia from "../WebSettings/SocialMedia";
import AddMedia from "../WebSettings/Media/AddMedia";
import EditMedia from "../WebSettings/Media/EditMedia";
import EditCupon from "../Promotion/Cupon/EditCupon";
import EditWebInvoice from "../invoice/EditWebInvoice";
import ViewWebInvoice from "../invoice/ViewWebInvoice";
import EditDisplayCenter from "../WebSettings/DisplayCenter/EditDisplayCenter";
import EditVideo from "../WebSettings/Slider/EditVideo";
import Banner from "../Promotion/Banner";
import AddBanner from "../Promotion/Banner/AddBanner";
import EditBanner from "../Promotion/Banner/EditBanner";
import PurchaceInvoiceList from "../invoice/PurchaceInvoiceList";
// import AddPurchaceOrder from "../invoice/AddPurchaceOrder";
// import EditPurchaceOrder from "../invoice/EditPurchaceOrder";
import AreaInvoiceReport from "../Reports/AreaInvoiceReport";
import PurchaceReport from "../Reports/PurchaceReport";
import ReusableInvoice from "../invoice/ReusableInvoice.jsx";
import EditReusableInvoice from "../invoice/EditReusableInvoice.jsx";
import RequisitionList from "../invoice/RequisitionList";
import RequisitionReport from "../Reports/RequisitionReport";
import AddOutLet from "../Outlet/AddOutLet";
import EditOutlet from "../Outlet/EditOutlet";
import OutletList from "../Outlet/OutletList";
import ViewOutlet from "../Outlet/ViewOutlet";
import AddOutletUser from "../OutletUser/AddOutletUser";
import EditOutletUser from "../OutletUser/EditOutletUser";
import OutletUserList from "../OutletUser/OutletUserList";
import EditOutletProduct from "../OutletProduct/EditOutletProduct";
import ViewOutletProduct from "../OutletProduct/ViewOutletProduct";
import AddOutletProduct from "../OutletProduct/AddOutletProduct";
import OutletProductList from "../OutletProduct/OutletProductList";
import ReturnProductList from "../Returnproduct/ReturnProductList";
import ReturnProductView from "../Returnproduct/ReturnProductView";
import AddArrival from "../inventory/Arrival/AddArrival";
import EditArrival from "../inventory/Arrival/EditArrival";
import ArrivalList from "../inventory/Arrival/ArrivalList";
import TrendingList from "../inventory/Trending/TrendingList";
import AddTrending from "../inventory/Trending/AddTrending";
import EditTrending from "../inventory/Trending/EditTrending";
import EditCharge from "../WebSettings/DeliveryCharge/EditCharge";
import AddCharge from "../WebSettings/DeliveryCharge/AddCharge";
import ChargeList from "../WebSettings/DeliveryCharge/ChargeList";
import MultipleArrival from "../inventory/Arrival/MultipleArrival";
import MultipleTrending from "../inventory/Trending/MultipleTrending";
import ChalanView from "../OutletChalan/ChalanView";
import ChalanList from "../OutletChalan/ChalanList";
import CreateDraft from "../Draft/CreateDraft";
import DraftList from "../Draft/DraftList";
import EditDraft from "../Draft/EditDraft";
import ViewDraft from "../Draft/ViewDraft";
import HomeDecorList from "../inventory/product/HomeDecorList.jsx";
import EditOutletInvoice from "../invoice/EditOutletInvoice.jsx";
import AddChalanProduct from "../OutletChalan/AddChalanProduct.jsx";
import Events from "../WebSettings/Events.jsx";
import AddEvent from "../WebSettings/Events/AddEvent.jsx";
import EditEvent from "../WebSettings/Events/EditEvent.jsx";
import DashCategory from "../DashboardCategory/DashCategory.js";
import PdashboardDetail from "../inventory/product/PdashboardDetail.jsx";
import DailyReportOP from "../Reports/DailyReportOP.jsx";
import EditRequisition from "../invoice/EditRequisition.jsx";
import EditPurchaceOrder from "../invoice/EditPurchaceOrder.jsx";
import NewReturnProductView from "../Returnproduct/NewReturnProductView.jsx";
import ComboProductList from "../inventory/ComboProduct/ComboProductList.jsx";
import AddComboProduct from "../inventory/ComboProduct/AddComboProduct.jsx";
import EditComboProduct from "../inventory/ComboProduct/EditComboProduct.jsx";
import NewOutletEditUser from "../OutletUser/NewOutletEditUser.jsx";
import NewoutletAddUser from "../OutletUser/NewoutletAddUser.jsx";
import OutletToOutletReturn from "../Returnproduct/OutletToOutletReturn.jsx";
import OutletToOutletReturnView from "../Returnproduct/OutletToOutletReturnView.jsx";
import DiscountReport from "../Reports/DiscountReport.jsx";
import FestivalProductList from "../inventory/Festival/FestivalProductList.jsx";
import AddMultipleFestivalProduct from "../inventory/Festival/AddMultipleFestivalProduct.jsx";
import AddFestival from "../inventory/Festival/AddFestival.jsx";
import EditFestival from "../inventory/Festival/EditFestival.jsx";
import FestivalList from "../inventory/Festival/FestivalList.jsx";
import AddPurchaceOrder from "../invoice/AddPurchaceOrder.jsx";
import AddRequisition from "../invoice/AddRequisition.jsx";
import ComboList from "../Promotion/ComboList.jsx";
import AddComboOffer from "../Promotion/AddComboOffer.jsx";
import EditComboOffer from "../Promotion/EditComboOffer.jsx";
import AddOfferBackup from "../Promotion/AddOfferBackup.jsx";
import EditOfferBackup from "../Promotion/EditOfferBackup.jsx";
import OfferBackup from "../Promotion/OfferBackup.jsx";
import AddMatching from "../inventory/Matching/AddMatching.jsx";
import MatcingList from "../inventory/Matching/MatcingList.jsx";
import EditMatching from "../inventory/Matching/EditMatching.jsx";
import ViewMatching from "../inventory/Matching/ViewMatching.jsx";
import EditOutletExchange from "../invoice/EditOutletExchange.jsx";
import ReportFromInv from "../Reports/ReportFromInv.jsx";
import ProductReportFromDashboard from "../Reports/ProductReportFromDashboard.jsx";
import EmployeList from "../user/EmployeList.jsx";
import AddEmploye from "../user/AddEmploye.jsx";
import EditEmploye from "../user/EditEmploye.jsx";
import VoucherList from "../Promotion/VoucherList.jsx";
import AddVoucher from "../Promotion/AddVoucher.jsx";
import EditVoucher from "../Promotion/EditVoucher.jsx";
import CreateInvoiceWithLoading from "../invoice/CreateInvoiceWithLoading.jsx";
import ViewVoucher from "../Promotion/ViewVoucher.jsx";
import PopUp from "../WebSettings/PopUp.jsx";
import AddPopup from "../WebSettings/Media/AddPopup.jsx";
import EditPopUp from "../WebSettings/Media/EditPopUp.jsx";

const LazyCustomer = React.lazy(() => import("../customers/CustomerList"));
const LazyProducts = React.lazy(() =>
  import("../inventory/product/ProductList")
);
const LazyDasboard = React.lazy(() => import("../dashboard/Dashboard"));
const LazyCategory = React.lazy(() =>
  import("../inventory/category/CategoryList")
);
const LazyInvoice = React.lazy(() => import("../invoice/InvoiceList"));
const LazyCustomInvoice = React.lazy(() =>
  import("../Custom/CustomInvoiceList")
);
const LazyUser = React.lazy(() => import("../user/UserList"));
const WebUser = React.lazy(() => import("../user/WebsiteUser"));

const RoutesFile = ({ setHideToolbar }) => {
  return (
    <div>
      {/* <Router> */}
      <Switch>
        <Route exact path={path.add_category}>
          <AddCategory />
        </Route>
        <Route exact path={path.category_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCategory />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.category_list}>
          <CategoryList />
        </Route> */}
        <Route exact path={"/inventory/edit-category/:id"}>
          <EditCategory />
        </Route>
        <Route exact path={`/inventory/add-product/:id`}>
          <AddProduct />
        </Route>
        <Route exact path={"/inventory/edit-product/:id"}>
          <EditProduct />
        </Route>
        <Route exact path={path.product_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyProducts />
          </React.Suspense>
        </Route>
        <Route exact path={path.home_decor_list}>
         <HomeDecorList/>
        </Route>
        {/* <Route exact path={path.product_list}>
          <ProductList />
        </Route> */}
        <Route exact path={"/inventory/product-view/:id"}>
          <ViewProduct />
        </Route>
        {/* trending */}
        <Route exact path={path.trending_product_list}>
          <TrendingList/>
        </Route>
        <Route exact path={path.add_trending_product}>
          <AddTrending/>
        </Route>
        <Route exact path={path.add_multiple_trending_product}>
          <MultipleTrending/>
        </Route>
        <Route exact path={"/inventory/edit-trending-product/:id"}>
          <EditTrending/>
        </Route>
        {/* trending */}
        {/* arrival */}
        <Route exact path={path.arrival_product_list}>
          <ArrivalList />
        </Route>
        <Route exact path={path.add_arrival_product}>
          <AddArrival />
        </Route>
        <Route exact path={path.add_multiple_arrival_product}>
          <MultipleArrival />
        </Route>
        <Route exact path={'/inventory/edit-arrival-product/:id'}>
          <EditArrival />
        </Route>
        {/* Festival */}
        <Route exact path={path.festival_product_list}>
          <FestivalProductList/>
        </Route>
        <Route exact path={path.add_festival_product}>
          <AddMultipleFestivalProduct/>
        </Route>
        <Route exact path={path.add_festival}>
          <AddFestival/>
        </Route>
        <Route exact path={'/festival/add_festival/:id'}>
          <EditFestival/>
        </Route>
        <Route exact path={path.festival_list}>
          <FestivalList/>
        </Route>
        {/* Festival */}

        {/* combo Product */}
        <Route exact path={'/inventory/combo-product-list'}>
          <ComboProductList/>
        </Route>
        <Route exact path={'/inventory/add-combo-product'}>
          <AddComboProduct/>
        </Route>
        <Route exact path={'/inventory/edit-combo-product/:id'}>
          <EditComboProduct/>
        </Route>
        {/* arrival */}
        <Route exact path={path.add_attribute}>
          <Attribute />
        </Route>
        <Route exact path={path.bulk_import}>
          <BulkImport />
        </Route>
        <Route exact path={path.user_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyUser />
          </React.Suspense>
        </Route>
        
        <Route exact path={path.web_user_list}>
          <React.Suspense fallback={<Loader />}>
            <WebUser />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.user_list}>
          <UserList />
        </Route> */}
        <Route exact path={path.add_user}>
          <AddUser />
        </Route>
        <Route exact path={path.add_role}>
          <AddRole />
        </Route>
        <Route exact path={path.add_group}>
          <AddGroup />
        </Route>
        <Route exact path={"/user/user-View/:id"}>
          <ViewUser />
        </Route>
        <Route exact path={"/user/edit-user/:id"}>
          <EditUser />
        </Route>
        <Route exact path={"/user-profile"}>
          <UserProfile />
        </Route>

        <Route exact path={path.employe_list}>
          <EmployeList/>
        </Route>
        <Route exact path={path.add_employe}>
          <AddEmploye/>
        </Route>
        <Route exact path={"/employee/edit-employee/:id"}>
          <EditEmploye/>
        </Route>

        <Route exact path={path.notification}>
          <Notification />
        </Route>
        {/* <Route exact path={path.create_invoice}>
          <CreateInvoice />
        </Route> */}
        <Route exact path={path.create_invoice}>
        <CreateInvoiceWithLoading/>
        </Route>
        {/* <Route exact path={path.create_invoice_l}>
          <CreateInvoiceWithLoading/>
        </Route> */}

        <Route exact path={path.invoice_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyInvoice />
          </React.Suspense>
        </Route>
        <Route exact path={"/sales/view-invoice/:id"}>
          <ViewInvoice />
        </Route>
        <Route exact path={"/sales/view-web-invoice/:id"}>
          <ViewWebInvoice />
        </Route>
        <Route exact path={"/sales/view-custom-invoice/:id"}>
          <CustomInvoiceView />
        </Route>
        <Route exact path={"/sales/edit-custom-invoice/:id"}>
          <CustomInvoiceEdit />
        </Route>
        <Route exact path={"/sales/edit-invoice/:id"}>
          <EditInvoice />
        </Route>
        <Route exact path={"/sales/edit-web-invoice/:id"}>
          <EditWebInvoice/>
        </Route>
        {/* Draft */}
        <Route exact path={path.create_draft_invoice}>
          <CreateDraft />
        </Route>
        <Route exact path={path.draft_invoice_list}>
          <DraftList/>
        </Route>
        <Route exact path={"/draft/edit-draft-invoice/:id"}>
          <EditDraft />
        </Route>
        <Route exact path={"/draft/view-draft-invoice/:id"}>
          <ViewDraft />
        </Route>
        {/* Draft */}

        <Route exact path={path.customer_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCustomer />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.customer_list}>
          <CustomerList/>
        </Route> */}
        <Route exact path={path.add_customer}>
          <AddCustomer />
        </Route>
        <Route exact path={path.customer_bulk_import}>
          <CustomerBulk />
        </Route>
        <Route exact path={"/customer/customer-view/:id"}>
          <CustomerView />
        </Route>
        <Route exact path={`/customer/edit-customer/:id`}>
          <EditCustomer />
        </Route>
        <Route exact path="/">
          <React.Suspense fallback={<Loader />}>
            <LazyDasboard />
          </React.Suspense>
        </Route>
        {/* dash category */}
        <Route exact path={'/product/categories'}>
          <DashCategory/>
        </Route>
        <Route exact path={'/product/categories/:id/:id2'}>
          <PdashboardDetail/>
        </Route>
        {/* <Route exact path="/">
          <Dashboard />
        </Route> */}
        <Route exact path={path.reports}>
          <Reports />
        </Route>
        <Route exact path={path.sales_report}>
          <DailySalesReport />
        </Route>
        {/* <Route exact path={path.sales_report_optimise}>
          <DailyReportOP />
        </Route> */}

        <Route exact path={path.area_report}>
          <AreaInvoiceReport />
        </Route>
        <Route exact path={path.discount_report}>
          <DiscountReport />
        </Route>
        <Route exact path={path.sales_report_inv}>
          <ReportFromInv/>
        </Route>
        <Route exact path={path.sales_report_dash}>
          <ProductReportFromDashboard/>
        </Route>

        {/* custom */}
        <Route exact path={path.custom_invoice_list}>
          <React.Suspense fallback={<Loader />}>
            <LazyCustomInvoice />
          </React.Suspense>
        </Route>
        {/* <Route exact path={path.custom_invoice_list}>
          <CustomInvoiceList />
        </Route> */}
        <Route exact path={path.custom_sales_report}>
          <CustomSalesReport />
        </Route>
        <Route exact path={path.custom_create_invoice}>
          <CustomCreateInvoice />
        </Route>
        <Route exact path={path.purchace_order_list}>
          <PurchaceInvoiceList />
        </Route>
        <Route exact path={'/custom/add-purchace-order'}>
          <AddPurchaceOrder />
          {/* <ReusableInvoice orderType="purchase" /> */}
        </Route>
        <Route exact path={path.purchace_order_report}>
          <PurchaceReport/>
        </Route>
        <Route exact path={'/custom/edit-purchace-order/:id'}>
          <EditPurchaceOrder />
          {/* <EditReusableInvoice orderType="purchase" /> */}
        </Route>
        {/* Requisition */}
        <Route exact path={path.add_requisition_order}>
          {/* <ReusableInvoice orderType="requisition" /> */}
          <AddRequisition/>
        </Route>
        <Route exact path={'/custom/edit-requisition-order/:id'}>
          <EditRequisition/>
          {/* <EditReusableInvoice orderType="requisition" /> */}
        </Route>
        <Route exact path={path.requisition_order_list}>
          <RequisitionList />
        </Route>
        <Route exact path={path.requisition_order_report}>
          <RequisitionReport/>
        </Route>
        {/* promotion */}
        <Route exact path={path.offer}>
          <Offer />
          {/* <OfferBackup/> */}
        </Route>
        <Route exact path={path.combooffer}>
          <ComboList />
        </Route>
        <Route exact path={path.comboofferAdd}>
          <AddComboOffer/>
        </Route>
        <Route exact path={"/promotions/edit-combo-offer/:id"}>
          <EditComboOffer />
        </Route>        

        <Route exact path={"/promotions/add-offer"}>
          <AddOffer />
          {/* <AddOfferBackup/> */}
        </Route>
        <Route exact path={"/promotions/edit-offer/:id"}>
          <EditOffer />
          {/* <EditOfferBackup/> */}
        </Route>
        <Route exact path={"/promotions/view-offer/:id"}>
          <ViewOffer />
        </Route>
        <Route exact path={path.cupons}>
          <Cupon />
        </Route>
        <Route exact path={"/promotions/add-cupon"}>
          <AddCupon />
        </Route>
        <Route exact path={"/promotions/edit-cupon/:id"}>
          <EditCupon />
        </Route>
        <Route exact path={"/promotions/voucher-list"}>
          <VoucherList />
        </Route>
        <Route exact path={"/promotions/add-voucher"}>
          <AddVoucher/>
        </Route>
        <Route exact path={"/promotions/edit-voucher/:id"}>
          <EditVoucher/>
        </Route>
        <Route exact path={"/promotions/view-voucher-history/:id"}>
          <ViewVoucher/>
        </Route>
        <Route exact path={path.review}>
          <Reviews />
        </Route>
        <Route exact path={path.contactUs}>
          <ContactUs />
        </Route>
        <Route exact path={path.work_with_us}>
          <WorkWithUs />
        </Route>
        <Route exact path={path.banner}>
          <Banner />
        </Route>
        <Route exact path={'/promotions/add-banner'}>
          <AddBanner />
        </Route>
        <Route exact path={'/promotions/edit-banner/:id'}>
          <EditBanner />
        </Route>
        {/* promotion */}
        {/* web settings */}
        <Route exact path={path.global_setting}>
          <GlobalSettings />
        </Route>
        <Route exact path={'/web-settings/pop-up-banner'}>
          <PopUp/>
        </Route>
        <Route exact path={'/web-settings/add-pop-up-banner'}>
          <AddPopup/>
        </Route>
        <Route exact path={'/web-settings/edit-pop-up-banner/:id'}>
          <EditPopUp/>
        </Route>
        <Route exact path={path.main_setting}>
          <MainSettings />
        </Route>
        <Route exact path={'/web-settings/add-display-center'}>
          <AddDisplayCenter />
        </Route>
        <Route exact path={'/web-settings/edit-display-center/:id'}>
          <EditDisplayCenter/>
        </Route>
        <Route exact path={path.display_center}>
          <DisplaySettings />
        </Route>
        <Route exact path={"/web-settings/add-video-slider"}>
          <AddVideo />
        </Route>
        <Route exact path={"/web-settings/edit-video-slider/:id"}>
          <EditVideo/>
        </Route>
        <Route exact path={path.teammember}>
          <TeamMember />
        </Route>
        {/* events */}
        <Route exact path={path.events}>
          <Events />
        </Route>
        <Route exact path={path.add_event}>
          <AddEvent />
        </Route>
        <Route exact path={'/web-settings/edit_event/:slug'}>
          <EditEvent />
        </Route>
        {/* social media */}
        <Route exact path={path.social_media}>
          <SocialMedia />
        </Route>
        <Route exact path={path.add_social_media}>
          <AddMedia />
        </Route>
        <Route exact path={'/web-settings/edit-social-media/:id'}>
          <EditMedia />
        </Route>
        <Route exact path={"/web-settings/add-teammember"}>
          <AddTeamMember />
        </Route>
        <Route exact path={"/web-settings/edit-teammember/:id"}>
          <EditTeamMember />
        </Route>
        <Route exact path={path.testimonials}>
          <Testimonials />
        </Route>
        <Route exact path={"/web-settings/add-testimonials"}>
          <AddTestiminial />
        </Route>
        <Route exact path={"/web-settings/edit-testimonials/:id"}>
          <EditTestimonial />
        </Route>
        <Route exact path={"/web-settings/view-testimonials/:id"}>
          <ViewTestimonial />
        </Route>

        <Route exact path={path.delivery_charge_list}>
          <ChargeList />
        </Route>
        <Route exact path={path.add_delivery_charge}>
          <AddCharge />
        </Route>
        <Route exact path={"/web-settings/edit_delivery_charge/:id"}>
          <EditCharge />
        </Route>
        {/* web settings */}
        {/* outlets */}
        <Route exact path={path.add_outlet}>
          <AddOutLet/>
        </Route>
        <Route exact path={'/outlet/edit_outlet/:id'}>
          <EditOutlet/>
        </Route>
        <Route exact path={path.outlet_list}>
          <OutletList/>
        </Route>
        <Route exact path={'/outlet/view_outlet/:id'}>
          <ViewOutlet/>
        </Route>
        <Route exact path={'/outlet/edit_invoice/:id'}>
          <EditOutletInvoice/>
        </Route>
        <Route exact path={'/outlet/edit_exchange_invoice/:id'}>
          <EditOutletExchange/>
        </Route>
        <Route exact path={path.outlet_user_list}>
          <OutletUserList/>
        </Route>
        {/* <Route exact path={path.add_outlet_user}>
          <AddOutletUser/>
        </Route>
        <Route exact path={'/outlet/edit_outlet_user/:id'}>
          <EditOutletUser/>
        </Route> */}
        {/* new multiple */}
        <Route exact path={path.add_outlet_user}>
          <NewoutletAddUser/>
        </Route>
        <Route exact path={'/outlet/edit_outlet_user/:id'}>
          <NewOutletEditUser/>
        </Route>
        {/* newMultiple */}
        <Route exact path={'/outlet/edit_outlet_products/:id'}>
          <EditOutletProduct/>
        </Route>
        <Route exact path={'/outlet/view_outlet_products/:id/:outlet'}>
          <ViewOutletProduct/>
        </Route>
        <Route exact path={path.add_outlet_products}>
          <AddOutletProduct/>
        </Route>
        <Route exact path={path.outlet_products_list}>
          <OutletProductList/>
        </Route>
        <Route exact path={path.return_products_list}>
          <ReturnProductList/>
        </Route>
        <Route exact path={'/outlet/return_products_view/:id'}>
          <ReturnProductView/>
        </Route>
        <Route exact path={'/outlet/new_return_products_view/:id'}>
          <NewReturnProductView/>
        </Route>
        <Route exact path={'/outlet/outlet_to_outlet_return_list'}>
          <OutletToOutletReturn/>
        </Route>
        <Route exact path={'/outlet/outlet_to_outlet_return_list/:id'}>
          <OutletToOutletReturnView/>
        </Route>
        {/* outlets */}
        {/* chalan */}
        <Route exact path={'/outlet/chalan_list'}>
          <ChalanList/>
        </Route>
        <Route exact path={'/outlet/view_chalan/:id'}>
          <ChalanView/>
        </Route>
        <Route exact path={'/outlet/add_chalan_product/:id/:outletName/:outletId'}>
          <AddChalanProduct/>
        </Route>
        {/* matching */}
        <Route exact path={path.add_matching}>
          <AddMatching/>
        </Route>
        <Route exact path={path.matching_list}>
          <MatcingList/>
        </Route>
        <Route exact path={'/product/edit-matching/:id'}>
          <EditMatching/>
        </Route>
        <Route exact path={'/product/view-matching/:id'}>
          <ViewMatching/>
        </Route>
        {/* matching */}
        <Route exact path="/login">
          <Login setHideToolbar={setHideToolbar} />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPassword setHideToolbar={setHideToolbar} />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword setHideToolbar={setHideToolbar} />
        </Route>
        <Route>
          <Page404 setHideToolbar={setHideToolbar} />
        </Route>
      </Switch>
      {/* </Router> */}
    </div>
  );
};

export default RoutesFile;
