import React from "react";
import { useHistory, useParams } from "react-router-dom";
// import './Product.css'
import add from "./../../../assets/Icon/add.svg";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import eye from "./../../../assets/Icon/eye.svg";
import doc from "./../../../assets/Icon/Icon 24 x 24.svg";
import "../product/Product.css";
import { useState } from "react";
import { PlusCircle, X, XCircle } from "phosphor-react";
import CustomSelect from "../../CustomCommons/CustomSelect";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { ActionMeta, OnChangeValue } from "react-select";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { DropzoneArea } from "material-ui-dropzone";
import {
  AttachFile,
  AudioTrack,
  Description,
  PictureAsPdf,
  Theaters,
} from "@material-ui/icons";
import swal from "sweetalert";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import SelectDropDown from "../../CustomCommons/SelectDropDown";
import { useEffect } from "react";
import { handleInputs } from "../../../utils/HandleInputs";
import { showToast } from "../../../utils/ToastHelper";
import * as path from "../../Routes/RoutePaths";
import UseData from "../../Const/UseData";
import Loader from "../../CustomCommons/Loader";
import { logout_func2, logout_func3 } from "../../Const/logoutCommon";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactQuill from "react-quill";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((item, index) => {
    item.order = index;
  });

  return result;
};


const EditArrival = () => {
  const [inputQuery, setInputQuery] = useState("");
  const [productList, setProductsList] = useState([]);
  const [prevProductList, setPrevProductsList] = useState([]);
  const [foundProduct, setFoundProduct] = useState([]);
  const [selectProduct, setSelectProduct] = useState([]);
  const [thumbId, setThumbId] = useState(null);
  const [thumbId2, setThumbId2] = useState(null);
  const [thumbId3, setThumbId3] = useState(null);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [isTrend, setIsTrend] = useState(true);
  const [isShowWebsite, setIsShowWebsite] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, SetisLoading] = useState(true);
  const [singleProduct, setSingleProduct] = useState({});
  const [subCatDef, setSubCatDif] = useState([]);
  const [attributesCom, setAttributeCom] = useState([]);
  const [colorOption, setColorOption] = useState(["Red", "Red"]);
  const [sizeOption, setSizeOption] = useState([]);
  const [Dropzoneimages, setDropzoneimages] = useState(null);
  // state
  const [mainState, setMainState] = useState({});
  // state
  const [optional, setOptional] = useState([]);
  const [fileLenth, setFilelength] = useState(null);
  const [fileLenth2, setFilelength2] = useState(null);
  const [slug, setSlug] = useState("");
  const [URL, setURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [URL2, setURL2] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [URL3, setURL3] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [selectedOptionCategory, setSelectedOptionCategory] = useState(null);
  const [atttibutesList, setAtttibutesList] = useState([]);
  const [multiId, setMultiId] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [mainCategory, setMainCategory] = useState(null);
  const [tags, setTegs] = useState(null);
  const [productAllData, SetproductAllData] = useState({
    product_name: "",
    product_description: "",
    product_tax: "",
    product_vat: "",
    product_discount: "",
    product_quantity: "",
    product_price: "",
    sku: "",
    // new field
    vendor_info: "",
    about_brand: "",
    shipping_info: "",
  });
  const handleTags = (newValue, actionMeta) => {
    setTegs(newValue);
  };

  const [AttributesInputValue, SetAttributesInputValue] = useState({});

  const [Thumb, setThumb] = useState(null);
  const [Thumb2, setThumb2] = useState(null);
  const [Thumb3, setThumb3] = useState(null);
  const [itemData, setitemData] = useState([]);
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const [ParentCategory, setParentCategory] = useState(true);
  const [addStock, setAddStock] = useState([]);
  console.log(multiId);

  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setFilelength(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const fileHandle2 = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb2(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId2(res.data.data.id);
            setFileName2(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setFilelength2(e.target.files[0]);
            setURL2(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL2(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const fileHandle3 = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb3(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId3(res.data.data.id);
            setFileName3(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL3(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL3(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };

  function getElementsNotInSecondArray(firstArray, secondArray) {
    const newArray = [];

    for (const item of firstArray) {
      // Check if the item is not present in the second array
      if (
        !secondArray.some(
          (element) =>
            element.label === item.label && element.value === item.value
        )
      ) {
        newArray.push(item);
      }
    }

    return newArray;
  }
  console.log("pdl", {
    selectProduct: selectProduct,
    prevProductList: prevProductList,
    getElementsNotInSecondArray: getElementsNotInSecondArray(
      prevProductList,
      selectProduct
    ),
  });

  const getSingleProduct = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/${id}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status) {
          // showToast("success", "Invoice Created.");
          SetisLoading(false);
          setSingleProduct(res.data.data);

          const result = res.data.data;
          console.log("===========res===================", res.data);
          setCategoryswitch(result?.is_active);
        //   setIsNew(res.data.data?.is_new_arrival);
          setIsTrend(res.data.data?.is_trending);
          setIsShowWebsite(res.data.data?.is_show_website);
          setSlug(res.data.data?.slug);
          setThumbId(res.data.data.thumb);
          setThumbId2(res.data.data.thumb2);
          setThumbId3(res.data.data.variant_chart);
          setTegs(
            res.data.data.tags.map((data) => {
              return { label: data?.name, value: data?.name };
            })
          );
          // setURL(res.data.data?.thumb_url);
          setMultiId(res.data.data?.feature_images_details);
          SetproductAllData({
            product_name: res.data.data.name,
            product_description: res.data.data.description,
            product_tax: res.data.data.tax,
            product_vat: res.data.data.vat,
            product_discount: res.data.data?.discount,
            product_quantity: res.data.data.quantity,
            product_price: res.data.data.price,
            product_category: res.data.data.category !== undefined && {
              value: res.data.data.category,
              label: res.data.data.category_name,
            },
            sku: res.data.data.sku,
            // new field
            vendor_info: res.data.data.vendor_info,
            about_brand: res.data.data.about_brand,
            shipping_info: res.data.data.shipping_info,
          });
          // setMainCategory(
          //   res.data.data?.main_category === 0
          //     ? { label: "Home Decor", value: 0 }
          //     : { label: "In Style", value: 1 }
          // );
          setMainCategory(res.data.data?.main_category);
          setParentCategory(res.data.data.category);
          setSelectedOptionCategory(res.data.data.category[0].toString());
          setURL(res.data.data?.thumb_url);
          setURL2(res.data?.data?.thumb_url2);
          setURL3(res.data?.data?.variant_chart_url);
          setMainState(result.variants_json.mainState);
          SetAttributesInputValue(result.variants_json.AttributesInputValue);
          // setAddStock(
          //   Object.keys(result.variants_json.AttributesInputValue).map(cur => ({
          //     name: cur,
          //     value: "0",
          //   }))
          // );
          setAddStock(
            itemData.map((item) => ({
              name: item,
              value: 0,
            }))
          );
          setPrevProductsList(
            result?.related_products_details?.map((data) => ({
              label: data?.name + " " + "[[" + data?.sku + "]]",
              value: data?.id,
            }))
          );
          setSelectProduct(
            result?.related_products_details?.map((data) => ({
              label: data?.name + " " + "[[" + data?.sku + "]]",
              value: data?.id,
            }))
          );
          setAttributeCom(
            Object.keys(result.variants_json.mainState).map((cur, index) => ({
              label: cur,
              value: index,
            }))
          );
          // atttibutesList?.filter(attr=> {if(attr?.label === cur) return attr.value})

          let res_attributeinp = result.map((curr) => ({
            [curr.variant]: { variant_stock: curr.stock },
          }));
          let res_mainstate = result.variants.map((curr) => curr.variant);
          let res_attributeCom = result.variants.map((curr) => ({
            ...curr,
            label: curr.value,
            value: curr.label,
          }));

          // console.log("===========");
          // console.log("hello thereeee");
          // console.log("res", res.data.data, "-------------------------------");
          // console.log("-------------------working----------------");
          // console.log("res _attributeinp", res_attributeinp);
          // console.log("res _AttributesInputValue", AttributesInputValue);
          // console.log("res _mainstate", res_mainstate);
          // console.log("res _mainStateValue", mainState);
          // console.log("res _attributeCom", res_attributeCom);
          // console.log("res _attributeComvalue", attributesCom);
          // console.log("===========");

          // setAttributeCom(Object.keys(result.variants_json.mainState).map((cur, index) => ({ label: cur, value: index })));
        }
      })
      .catch((err) => {
        // const message = JSON.parse(err?.request?.response).message;
        // const errorMsg = JSON.parse(err?.request?.response).errors;
        // console.log(err);
        // // for (let value of Object.values(errorMsg)) {
        // //   showToast("error", value[0]);
        // // }
        // for (let key in errorMsg) {
        //   showToast("error", `${key} : ${errorMsg[key][0]}`);
        // }
        // showToast("error", message);
      });
  };

  const [newAttr, setNewAttr] = useState(null);

  console.log(mainCategory, "data321");
  const getAttributeList = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/attributes/`;

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results, "======atrlist=======");
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setAtttibutesList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
      });
  };
  console.log("productAllDatanewAttr-----", newAttr);
  function getValueByLabel(label) {
    const obj = atttibutesList.find((o) => o.label === label);
    return obj ? obj.value.toString() : 1;
  }
  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/?query=${inputQuery}`;
      }
      axios
        .get(url)
        .then((res) => {
          const result = res.data.data.results;

          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            category: curr?.category[0],
            main_category: curr?.main_category,
            is_new_arrival: curr?.is_new_arrival,
            is_show_website: curr?.is_show_website,
            is_trending: curr?.is_trending,
            description: curr?.description,
            name: curr.name,
            price: curr?.price,
            category_details: curr?.category_details,
            tag_details: curr?.tag_details,
          }));
          console.log(options, "result");
          setProductsList(options);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };
  useEffect(() => {
    getProductList();
  }, [inputQuery]);

  useEffect(() => {
    getSingleProduct();
    getAttributeList();
    setSubCatDif(
      categoryList.filter((data) => data?.value === singleProduct?.category[0])
    );
  }, []);
  useEffect(() => {
    getCategory();
    setSubCatDif(
      categoryList.filter((data) => data?.value === singleProduct?.category[0])
    );
  }, [mainCategory, singleProduct]);

  console.log(categoryList, "cdd");

  useEffect(() => {
    if (attributesCom) {
      const data = attributesCom?.map((curr) => ({
        label: curr?.label,
        value: getValueByLabel(curr?.label),
      }));
      setAttributeCom(data);
    }
  }, [singleProduct]);
  console.log("attributesCom ----bablu123", atttibutesList);
  console.log("attributesCom ----bablu123", attributesCom);
  const handleMultiInputChange2 = (newValue, actionMeta) => {
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    console.log(newValue, "newValue");
    setSelectProduct(newValue);
    // setFoundProduct(newValue[0]);
    // if (!foundProduct) {
    //   SetproductAllData((prevData) => ({
    //     ...prevData,
    //     product_name: newValue[0]?.name,
    //     product_description: newValue[0]?.description,
    //     product_price: newValue[0]?.price,
    //   }));
    //   setMainCategory(
    //     newValue[0]?.main_category
    //   );
    //   setIsNew(newValue[0]?.is_new_arrival);
    //   setIsShowWebsite(newValue[0]?.is_show_website);
    //   setIsTrend(newValue[0] ? newValue[0]?.is_trending : isTrend);
    //   setSelectedOptionCategory(newValue[0].category);
    //   setTegs(newValue[0]?.tag_details?.map(data=> ({
    //     label:data?.name,
    //     value:data?.id
    //   })))
    // }
  };
  useEffect(() => {
    // SetproductAllData((prevData) => ({
    //   ...prevData,
    //   product_name: foundProduct?.name,
    //   product_description: foundProduct?.description,
    //   product_price:foundProduct?.price,
    // }));
    // setTegs(foundProduct?.tag_details?.map(data=> ({
    //   label:data?.name,
    //   value:data?.id
    // })))
  }, [foundProduct]);

  useEffect(() => {
    // if (attributesCom) {
    //   const newArray = attributesCom.map((obj2) => {
    //     const obj1 = atttibutesList.find((o) => o.label === obj2.label);
    //     if (obj1) {
    //       return {
    //         label: obj2.label,
    //         value: obj1.value,
    //       };
    //     }
    //   });
    //   setAttributeCom(newArray);
    //   // return newArray;
    // }
  }, [attributesCom]);
  console.log("attributeslist ----bablu123", atttibutesList);
  console.log("attributesCom ----bablu123", attributesCom);

  function findAttributeKeyWithValue(data, value) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let values = data[key];

        if (values.includes(value)) {
          const data = attributesCom?.filter((data) => data?.label === key);

          return data[0]?.value;
        }
      }
    }

    return null; // Return null if the value is not found
  }
  function getValueByLabel(label) {
    const obj = atttibutesList.find((o) => o.label === label);
    return obj ? obj.value.toString() : "1";
  }
  function convertToObjectArray(obj) {
    let result = [];
    for (let variantName in obj) {
      if (obj.hasOwnProperty(variantName)) {
        let variant = obj[variantName];
        variant.variant_name = variantName;
        // Split the variant name using '-'
        let parts = variantName.split("~");
        // Create the attribute_value array dynamically
        variant.attribute_value = parts.map((part, index) => ({
          attribute: findAttributeKeyWithValue(mainState, part),
          value: part,
        }));
        const totalVariantStock =
          variant.variant_stock !== undefined
            ? parseInt(variant.variant_stock) +
            parseInt(
              addStock.find(({ name }) => name === variant.variant_name)
                ?.value
            )
            : parseInt(
              addStock.find(({ name }) => name === variant.variant_name)
                ?.value
            );

        result.push({
          ...variant,
          variant_stock: totalVariantStock,
        });
      }
    }
    // console.log(result,'update data 11')
    // const id = singleProduct?.variant?.filter(data => data.name === obj.variant_name)[0]?.id

    //  console.log(id,'update data id')

    const newArray = result.map((obj) => ({
      price: obj.variant_price ? Number(obj.variant_price) : 0,
      name: obj.variant_name,
      stock: obj.variant_stock,
      is_active: Categoryswitch === true ? (obj?.variant_action === false ? obj?.variant_action   : true) : false,
      images: obj.images,
      attribute_value: obj.attribute_value,
      id: singleProduct?.variant?.filter(
        (data) => data.name === obj.variant_name
      )[0]?.id
        ? singleProduct?.variant?.filter(
          (data) => data.name === obj.variant_name
        )[0]?.id
        : null,
    }));

    return newArray;
  }

  const getCategory = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/category/?category_type=2&main_category=${Array.isArray(mainCategory) === true
      ? mainCategory[0]?.value
      : mainCategory
      }`;
    axios
      .get(url)
      .then((res) => {
        console.log(
          res.data.data.results,
          "========================,==========,==========="
        );
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        console.log(options, "catlist");
        setcategoryList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        console.log(err.request.response);
      });
  };

  const clearData = () => {
    SetproductAllData({
      product_name: "",
      product_description: "",
      product_tax: "",
      product_vat: "",
      product_discount: "",
      product_quantity: "",
      product_price: "",
    });
    setURL("");
    setFileName("");
    setSelectedOptionCategory(null);
  };

  const postFeatureImages = (productID) => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products-image/`;

    Dropzoneimages.map((curr) => {
      console.log("images", curr);
      const data = new FormData();
      data.append("image", curr);
      data.append("product", productID);

      axios
        .post(url, data)
        .then((res) => {
          if (res.data.status) {
            console.log(res.data);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          // showToast("error", message);
        });
    });
  };
  let img = [];
  // const uploadMulti = (data) => {
  //   const url = `${BASE_URL}api/v1/auth/documents/upload/`;
  //   data.map((curr, index) => {
  //     const data1 = new FormData();
  //     data1.append("document", curr);
  //     data1.append("doc_type", 0);
  //     data1.append("order", multiId.length)
  //     axios.post(url, data1).then((res) => {
  //       if (res.data.status) {
  //         setMultiId((prevData) => [
  //           ...prevData,
  //           {
  //             id: res.data.data.id,
  //             doc_url: res.data.data.doc_url,
  //             order: prevData.length
  //           },
  //         ]);
  //       }
  //     });
  //   });
  // };
  const uploadMulti = (data) => {
    console.log(data, "image Data inside");
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
  
    // Filter out data that has already been uploaded
    const dataToUpload = data.filter((curr) => {
      // Check if the data is not already in the list of uploaded data
      return !multiId.some((uploadedItem) => uploadedItem.name === curr.name);
    });
  
    // Create an array to hold all upload promises
    const uploadPromises = dataToUpload.map((curr, index) => {
      const data1 = new FormData();
      data1.append("document", curr);
      data1.append("doc_type", 0);
      data1.append("order", index);
  
      // Return the axios promise
      return axios.post(url, data1);
    });
  
    
    Promise.all(uploadPromises)
      .then((responses) => {
        const newMultiId = responses
          .filter((res) => res.data.status)
          .map((res, index) => ({
            id: res.data.data.id,
            doc_url: res.data.data.doc_url,
            order: index,
            name: dataToUpload[index].name, 
          }));
        setMultiId((prevData) => [...prevData, ...newMultiId]);
      })
      .catch((error) => {
        console.error("Error uploading files:", error);
      });
  };

  
  const deleteFeatureImages = (image_id) => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products-image/${image_id}/`;
    axios
      .delete(url)
      .then((res) => {
        if (res.data.status) {
          // showToast("success", "product variant deleted");
          getSingleProduct();
        }
      })
      .catch((err) => {
        console.log("url", url);
        console.log("err", err);
        showToast("error", "err.message");
      });
  };
  console.log(multiId, "image Dta");

  const PostVariants = (productID) => {
    // const url = `${BASE_URL}api/v1/inventory/inventory/products-variant/`;
    const url = `${BASE_URL}api/v1/inventory/inventory/variant-update/`;
    console.log("AttributesInputValue", AttributesInputValue);
    var variantdata = [];

    for (let item in AttributesInputValue) {
      var singleData = {};

      if (AttributesInputValue[item]["variant_action"] === undefined) {
        singleData["is_active"] = true;
      } else {
        singleData["is_active"] = AttributesInputValue[item]["variant_action"];
      }

      if (
        AttributesInputValue[item]["variant_price"] === undefined ||
        AttributesInputValue[item]["variant_price"] === ""
      ) {
        // showToast("error", `${item} Variant Price is empty `);
        // data.append("price", 0);
        singleData["price"] = 0;
        // data.append("price", parseInt(productAllData["product_price"]) + 0);
      } else {
        singleData["price"] = parseInt(
          AttributesInputValue[item]["variant_price"]
        );
        // data.append("price", parseInt(productAllData["product_price"]) + parseInt(AttributesInputValue[item]["variant_price"]));
      }
      singleData["stock"] = parseInt(
        AttributesInputValue[item]["variant_stock"]
      );
      singleData["variant"] = item;
      singleData["product"] = productID;

      variantdata.push(singleData);
    }
    console.log("variantdata", variantdata);
    // debugger;
    axios
      .post(url, { data: variantdata })
      .then((res) => {
        if (res?.data?.status) {
          showToast("success", "Product updated.");
          history.push(path.product_list);

          // showToast("success", "product variant added");
        }
      })
      .catch((err) => {
        showToast("error", "err.message");
      });
  };
  const DeletePreviousVariants = () => {
    let variantIds = singleProduct?.variants.map((curr) => curr.id);
    console.log(variantIds);
    variantIds.map((curr) => {
      const url = `${BASE_URL}api/v1/inventory/inventory/products-variant/${curr}/`;
      axios
        .delete(url)
        .then((res) => {
          if (res.data.status) {
            // showToast("success", "product variant deleted");
          }
        })
        .catch((err) => {
          showToast("error", "err.message");
        });
    });
  };

  const postProduct = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/${slug}/`;
    const data = new FormData();
    data.append("name", productAllData["product_name"]);
    // data.append("stock", parseInt(productAllData["product_quantity"]));
    isEmpty(AttributesInputValue) &&
      data.append("stock", productAllData["product_quantity"]);
    isEmpty(AttributesInputValue) &&
      data.append("quantity", productAllData["product_quantity"]);
    !isEmpty(AttributesInputValue) &&
      data.append("quantity", parseInt(GetTotalQuantity()));
    !isEmpty(AttributesInputValue) &&
      data.append("stock", parseInt(GetTotalQuantity()));
    data.append("description", productAllData["product_description"]);
    // data.append("price", productAllData["product_price"]);
    data.append("price", parseInt(productAllData["product_price"]));
    if (productAllData["product_discount"] === "") {
      data.append("discount", 0);
    } else {
      data.append("discount", productAllData["product_discount"]);
    }

    // data.append("discount", productAllData["product_discount"]);
    const variants = convertToObjectArray(AttributesInputValue);
    const v = variants?.filter((item, index, arr) => {
      const isDuplicate = arr.findIndex(
        (otherItem, otherIndex) =>
          otherIndex !== index && otherItem.name === item.name
      );
      return isDuplicate === -1 || item.id !== null;
    });
    data.append("vat", productAllData["product_vat"]);
    data.append("tax", productAllData["product_tax"]);
    // const updatedAttributesInputValue =

    function updateVariantStock(attributesInputValue, addStock) {
      let updatedAttributes = { ...attributesInputValue };
      addStock.forEach((item) => {
        if (updatedAttributes[item.name]) {
          const currentValue =
            parseInt(updatedAttributes[item.name].variant_stock) || 0;
          const valueToAddOrSubtract = parseInt(item.value);
          updatedAttributes[item.name].variant_stock =
            currentValue + valueToAddOrSubtract;
        }
      });
      return updatedAttributes;
    }

    data.append(
      "variants_json",
      JSON.stringify({
        mainState: mainState,
        // AttributesInputValue: updateVariantStock(AttributesInputValue, addStock),
        AttributesInputValue: AttributesInputValue,
      })
    );
    selectedOptionCategory != null &&
      data.append("category", selectedOptionCategory);

    data.append("variants", 1);
    Thumb != null && data.append("thumb", Thumb);
    data.append("is_active", Categoryswitch);
    if (data.name === "") {
      showToast("error", "Product Name can't be empty..");
      return 0;
    }
    if (productAllData["sku"].length < 1) {
      showToast("error", "SKU can not be empty!");
      return 0;
    } else {
      data.append("sku", productAllData["sku"]);
    }
    const tg = tags?.map((obj) => ({ name: obj.label }));
    data.append("tags", tg);
    data.append("variant", v);
    data.append("main_category", 1);
    const reletedRemoved = getElementsNotInSecondArray(prevProductList, selectProduct);
    const postData = {
      main_category: mainCategory ? mainCategory?.value : 0,
      variant_update_info: v,
      // variant_update_info: updateVariantStock(AttributesInputValue, addStock),
      tags: tg,
      sku: productAllData["sku"],
      is_active: Categoryswitch,
      related_products: selectProduct?.map((data) => data?.value),
      removed_products: reletedRemoved?.map((data) => data?.value),
      is_update_related:  false,
      // is_update_related: reletedRemoved || selectProduct?.length > prevProductList ? true : false,
      category: Array.isArray(selectedOptionCategory)
        ? [Number(selectedOptionCategory)]
        : [Number(selectedOptionCategory)],
      name: productAllData["product_name"],
      stock: parseInt(GetTotalQuantity()),
      quantity: parseInt(GetTotalQuantity()),
      description: productAllData["product_description"],
      price: parseInt(productAllData["product_price"]),
      vendor_info: productAllData["vendor_info"],
      about_brand: productAllData["about_brand"],
      shipping_info: productAllData["shipping_info"],
      is_new_arrival: true,
      is_trending: isTrend,
      is_show_website: Categoryswitch === true ? isShowWebsite : false,
      thumb: thumbId,
      thumb2: thumbId2 ? thumbId2 : null,
      variant_chart: thumbId3 ? thumbId3 : null,
      feature_images: multiId.map((data) => data?.id),
      feature_images_details: multiId,
      variants_json: {
        mainState: mainState,
        // AttributesInputValue: updateVariantStock(AttributesInputValue, addStock),
        AttributesInputValue: AttributesInputValue,
      },
      is_from_bulk: false,
    };
    // console.log(GetTotalQuantity(), postData.stock, "ttt")
    axios
      .patch(url, postData)
      .then((res) => {
        if (res.data.status) {
          // clearData();
          // DeletePreviousVariants();
          // PostVariants(res.data.data.id);
          // postFeatureImages(res.data.data.id);
          showToast("success", "New Arrival Product Updated.");
          history.push(path.arrival_product_list);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          // showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          logout_func3(history);
        }
      });
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const ValidateData = () => {
    console.log("selectedOptionCategory.value", selectedOptionCategory?.value);
    console.log("selectedOptionCategory", selectedOptionCategory);
    if (productAllData["product_name"] === "") {
      showToast("error", "Product Name can't be empty..");
      return 0;
    } else if (
      productAllData["product_category"] === null ||
      productAllData["product_category"] === undefined
    ) {
      showToast("error", "Category can't be empty..");
      return 0;
    }
    //  else if (productAllData["product_quantity"] === "") {
    //   showToast("error", "Quantity  can't be empty..");
    //   return 0;
    // }
    // else if ((productAllData["product_discount"].match(/[^0-9]/))) {
    //   showToast("error", "Discount  can't contains letters..");
    //   return 0;
    // }

    // else if (productAllData["product_vat"] === "") {
    //   showToast("error", "Vat  can't be empty..");
    //   return 0;
    // }

    // else if (productAllData["product_tax"] === "") {
    //   showToast("error", "Tax  can't be empty..");
    //   return 0;
    // }
    // else if (productAllData["product_description"] === "") {
    //   showToast("error", "Description can't be empty..");
    //   return 0;
    // }

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    if (isEmpty(AttributesInputValue)) {
      showToast("error", `No attribute added `);
      return 0;
    }
    for (let item in AttributesInputValue) {
      console.log("AttributesInputValue[item]", AttributesInputValue[item]);
      if (isEmpty(AttributesInputValue[item])) {
        showToast("error", `${item} Variant Price,Stock is empty `);
        return 0;
      }
      if (
        AttributesInputValue[item]["variant_price"] === undefined ||
        AttributesInputValue[item]["variant_price"] === null
      ) {
        showToast("error", `${item} Variant Price is empty `);
        return 0;
      }
      if (
        AttributesInputValue[item]["variant_stock"] === undefined &&
        addStock.find(({ name }) => name === item).value === 0
      ) {
        showToast("error", `${item} Variant Stock is empty `);
        return 0;
      }
    }

    // if (Thumb === null) {
    //   showToast("error", "Thumbnail can't be empty..");
    //   return 0;
    // }

    postProduct();
  };
  const postALLProductsData = () => {
    ValidateData();
  };

  const handleOptional = (event) => {
    setSelectedImages([]);
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return window.URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    window.URL.revokeObjectURL(image);
  }
  const [test, setTest] = useState("");

  const combinationMake = (arrays, joinChar) => {
    var result = arrays.reduce((a, b) =>
      a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
    );
    let output = result.map((a) => a.join(joinChar));

    return output;
  };
  const handleMultiInputChange = (newValue, actionMeta) => {
    console.log(actionMeta);
    const newVal = actionMeta?.option?.label;
    setAttributeCom(newValue);
    console.log("actionMeta.action", actionMeta.action);
    if (
      actionMeta.action === "create-option" ||
      actionMeta.action === "select-option"
    ) {
      // !(itemData.includes(newVal)) &&
      setMainState({ ...mainState, [newVal]: [] });
    }
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
    }
  };
  console.log("mainState", mainState);
  console.log("itemData", itemData);

  // console.log(mainState)
  const handleMultiInputInner = (item, val) => {
    mainState[item] = val.map((curr) => curr.label.trim());
    console.log("mainState[item]", mainState[item]);
    let arrays = [];
    for (let i in mainState) {
      console.log(i);
      console.log(mainState[i]);
      arrays.push(mainState[i]);

      // if (mainState[i].length !== 0) {
      //   arrays.push(mainState[i]);
      // }
    }
    // console.log("arrays", arrays);
    if (arrays.length > 1) {
      console.log(combinationMake(arrays, "~"));
      setitemData(combinationMake(arrays, "~"));
    }
    if (arrays.length <= 1) {
      arrays.push([""]);
      console.log(combinationMake(arrays, ""));
      setitemData(combinationMake(arrays, "~"));
    }
    //SetAttributesInputValue({}); Clearing variant list whenever add new

    // SetAttributesInputValue(() => {
    // for (let key in AttributesInputValue) {
    //   itemData.map((curr) => {
    //     if (curr !== key) {
    //       delete AttributesInputValue[key];
    //       SetAttributesInputValue({ ...AttributesInputValue });
    //     }
    //   });
    // }
    // });
  };
  const handleSize = (newValue, actionMeta) => {
    setAtttibutesList(newValue);
    setSizeOption(newValue);
  };
  const GetTotalQuantity = () => {
    let currentStock = 0;
    let newStock = 0;
    // const prevAttributesInputValue = singleProduct.variants_json.AttributesInputValue
    for (let i in AttributesInputValue) {
      console.log(i);
      console.log(AttributesInputValue[i]);
      currentStock += parseInt(AttributesInputValue[i]["variant_stock"]);
    }
    for (let i = 0; i < addStock.length; i++) {
      const addValue = addStock[i]?.value || 0;
      newStock += parseInt(addValue);
    }
    let totalQuantity = currentStock + newStock;
    return isNaN(totalQuantity) === true ? newStock : totalQuantity;
  };
  const setDataToTable = () => {
    console.log("===============");
    console.log("setDataToTable");
    console.log("===============");

    let arrays = [];
    for (let i in mainState) {
      console.log("=======Loop========");

      console.log(i);
      console.log(mainState[i]);
      console.log("===============");

      arrays.push(mainState[i]);
    }
    console.log("arrays", arrays);
    if (arrays.length > 1) {
      console.log(combinationMake(arrays, "~"));
      console.log("===============");
      console.log("setDataToTable length > 1");
      console.log("===============");
      setitemData(combinationMake(arrays, "~"));
    } else if (arrays.length === 1) {
      arrays.push([""]);
      console.log("===============");
      console.log("setDataToTable length === 1");
      console.log("===============");
      console.log(combinationMake(arrays, ""));
      setitemData(combinationMake(arrays, ""));
    } else if (arrays.length < 1) {
      console.log("===============");
      console.log("setDataToTable length < 1");
      console.log("===============");
    } else {
      console.log("===============");
      console.log("setDataToTable Noone");
      console.log("===============");

      console.log(mainState);
      console.log("===============");
      console.log("===============");
    }
  };

  console.log(AttributesInputValue,'main arri')
  console.log(mainState,'main arri')


  const HandleVariantsInputValue = (e, index, variant) => {
    let name = e.target.name;
    let value = e.target.value;
    console.log("variant", variant);
    SetAttributesInputValue({
      ...AttributesInputValue,
      [variant]: { ...AttributesInputValue[variant], [name]: value },
    });
  };
  const HandleVariantsImage = (e, variant) => {
    const selectedFiles = e.target.files;
    const promises = [];
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i] !== null) {
        const data = new FormData();
        data.append("document", selectedFiles[i]);
        data.append("doc_type", 0);
        promises.push(
          axios.post(url, data).then((res) => {
            if (res.data.status) {
              SetAttributesInputValue((prevAttributes) => {
                const imagesPrev = prevAttributes[variant]?.images;
                const imagesPrevDetails = prevAttributes[variant]?.img_details;
                const newImageId = res.data.data.id;
                const dataUrl = res.data.data.doc_url;
                return {
                  ...prevAttributes,
                  [variant]: {
                    ...prevAttributes[variant],
                    images: imagesPrev
                      ? [...imagesPrev, newImageId]
                      : [newImageId],
                    img_details: imagesPrevDetails
                      ? [
                        ...imagesPrevDetails,
                        {
                          id: newImageId,
                          url: dataUrl,
                        },
                      ]
                      : [
                        {
                          id: newImageId,
                          url: dataUrl,
                        },
                      ],
                  },
                };
              });
            }
          })
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        showToast("success", "Images uploaded");
      })
      .catch((error) => {
        showToast("error", error.response.data.errors.document[0]);
      });
  };
  const deleteVariantImage = (id, variant) => {
    SetAttributesInputValue((prevAttributes) => {
      const imagesPrev = prevAttributes[variant]?.images;
      const imagesPrevDetails = prevAttributes[variant]?.img_details;
      return {
        ...prevAttributes,
        [variant]: {
          ...prevAttributes[variant],
          images: imagesPrev?.filter((data) => data !== id),
          img_details: imagesPrevDetails?.filter((data) => data?.id !== id),
        },
      };
    });
  };
  useEffect(() => {
    const resultObject = {};
    for (const item of itemData) {
      console.log(AttributesInputValue[item], "AttributesInputValue 123");
      if (AttributesInputValue[item] === undefined) {
        resultObject[item] = {
          variant_price: 0,
          variant_stock: 0,
          variant_action:true,
          images: [],
          img_details: [],
          attribute_value: [],
        };
      }
    }
    const newObj = { ...AttributesInputValue, ...resultObject };
    SetAttributesInputValue(newObj);
  }, [itemData]);

  const handleAddStock = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const updatedAddStock = addStock.map((item) =>
      item.name === name ? { ...item, value: value } : item
    );
    // SetAttributesInputValue({
    //   ...AttributesInputValue,
    //   [name]: { ...AttributesInputValue[name], variant_stock: parseInt(value) + parseInt(AttributesInputValue[name]["variant_stock"]) ? parseInt(AttributesInputValue[name]["variant_stock"]) : 0,attribute_value:[]}
    // })
    setAddStock(updatedAddStock);
  };

  console.log(AttributesInputValue, "AttributesInputValue");

  const HandleVariantsSwitch = (e, variant) => {
    let name = e.target.name;
    let value = e.target.checked;
    console.log("variant", variant);
    SetAttributesInputValue({
      ...AttributesInputValue,
      [variant]: { ...AttributesInputValue[variant], [name]: value },
    });
  };

  useEffect(() => {
    getAttributeList();
  }, []);

  useEffect(() => {
    setDataToTable();
  }, [isLoading]);

  useEffect(() => {
    setAddStock(
      itemData.map((item) => ({
        name: item,
        value: 0,
      }))
    );
  }, [itemData]);

  console.log(
    selectedOptionCategory,
    "------------selectedOptionCategory=========="
  );

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    // if (type.startsWith("video/")) return <Theaters {...iconProps} />;
    // if (type.startsWith("audio/")) return <AudioTrack {...iconProps} />;

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />;
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeImg2 = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL2("");
        setFileName2("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const removeImg3 = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL3("");
        setFileName3("");
        setThumb3(null);
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const handleDescriptionChange = (value) => {
    // Update only the product_description field while keeping the rest of the state unchanged
    SetproductAllData((prevData) => ({
      ...prevData,
      product_description: value,
    }));
  };

  const getVariantAction = (item) => {
    // console.log("items ", item, " variant actions : ");
    // console.log("items  AttributesInputValue[item][variant_action]", AttributesInputValue[item]["variant_action"]);
    return AttributesInputValue[item] !== undefined &&
      AttributesInputValue[item]["variant_action"] !== undefined &&
      AttributesInputValue[item]["variant_action"] !== null|| AttributesInputValue[item]?.variant_action === false 
      ? AttributesInputValue[item]?.variant_action
      : true;
  };
  console.log(AttributesInputValue,'variant 12345')
  const getMainOptions = () => {
    let addedList = Object.keys(mainState);
    // console.log("added list", addedList);
    // console.log("added atttibutesList", atttibutesList);
    // return atttibutesList.filter(curr=>!curr.label.includes(addedList))
    // return atttibutesList.filter((curr) => !curr.label.includes(addedList));
    const filterdata = atttibutesList.filter(
      (o) => !addedList.includes(o.label)
    );
    return filterdata;
  };

  if (isLoading) {
    return <Loader />;
  }

  const categortFun = () => {
    const data = categoryList?.filter(
      (curr) => curr?.value === selectedOptionCategory[0]
    );
    return data[0];
  };

  // Reorder with drag-n-drop
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      multiId,
      result.source.index,
      result.destination.index
    );

    setMultiId(items)
    items.map(item => {
      axios.patch(`${BASE_URL}/api/v1/auth/documents/update/${item.id}/`, {
        order: item.order
      }).then(res => console.log(res, "res"))
    })
  }
  console.log(categortFun(), "selected ctae");
  return (
    <div className="categorey-parent">
      <div className="bg-white cat-child ">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          {/* <img src={add} alt="" /> */}
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Edit New Arrival Product</h3>
        </div>
        {/* -----------product form---------- */}
        <div className="product-main">
          <div className="row">
            <div className="col-12 mb-3">
              {/* <RequiredLabel text="Products" /> */}
              <p>Related Products</p>
              <div className="position-relative">
                <Select
                  // styles={styles}
                  isMulti
                  placeholder="---Select---"
                  isClearable
                  // defaultValue={selectedOption}
                  onChange={handleMultiInputChange2}
                  onInputChange={inputProduct}
                  options={productList}
                  defaultValue={selectProduct}
                  value={selectProduct}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* <p>Name*</p> */}
              <RequiredLabel text={"Name"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_name"
                  value={productAllData["product_name"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6" style={{ opacity: "0" }}>
              <RequiredLabel text={"Category"} />
              <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
                {/* <SelectDropDown options={categoryList && categoryList} setSelectedOptionCategory={setSelectedOptionCategory} /> */}

                {/* <Select
                  placeholder="---Select---"
                  isClearable
                  options={categoryList}
                  onChange={(option) => setSelectedOptionCategory(option.value)}
                  defaultValue={categoryList[0]}
                /> */}
                <select name="" id="">
                  {categoryList?.map((data) => (
                    <option value={data?.value}>{data?.label}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text={"Main Category"} />
              <div className="mb-3" style={{ backgroundColor: "#FAFAFA" }}>
                {/* <Select
                  styles={{ height: "45px" }}
                  options={[
                    { label: "Home Decor", value: 0 },
                    { label: "In Style", value: 1 },
                  ]}
                  defaultValue={
                    mainCategory === 0 || mainCategory?.value
                      ? { label: "Home Decor", value: 0 }
                      : { label: "In Style", value: 1 }
                  }
                  placeholder="Enter a choice value"
                  onChange={(newValue) => setMainCategory(newValue)}
                /> */}
                <Form.Select
                  size="lg"
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                  name=""
                  id=""
                  // defaultValue={selectedOptionCategory}
                  value={mainCategory}
                  defaultValue={mainCategory}
                  onChange={(e) => setMainCategory(e.target.value)}
                >
                  <option value={0}>{"Home Decor"}</option>
                  <option value={1}>{"In Style"}</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <RequiredLabel text={"Category"} />
              <div className="mb-3">
                <Form.Select
                  size="lg"
                  placeholder="---Select---"
                  value={selectedOptionCategory}
                  onChange={(e) => setSelectedOptionCategory(e.target.value)}
                  style={{
                    backgroundColor: "#FAFAFA",
                    fontSize: "16px",
                    height: "47px",
                    borderRadius: "8px",
                    color: "#828282",
                  }}
                >
                  {categoryList?.map((data) => (
                    <option value={data?.value}>{data?.label}</option>
                  ))}
                </Form.Select>
              </div>
            </div>

            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Quantity"} />
              {/* <p>*</p> */}
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_quantity"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  value={productAllData["product_quantity"]}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  disabled
                  // value={productAllData["product_quantity"]}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6">
              {/* <p>*</p> */}
              <RequiredLabel text={"Price"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_price"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_price"]}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Vat(%)"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_vat"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_vat"]}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              {/* <p>*</p> */}
              <RequiredLabel text={"Tax"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="product_tax"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["product_tax"]}
                />
              </InputGroup>
            </div>
            <div className="col-12 ">
              <RequiredLabel text={"Stock Keeping Unit -SKU"} />
              <InputGroup className="mb-3">
                <Form.Control
                  aria-label="Username"
                  name="sku"
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{ backgroundColor: "#FAFAFA" }}
                  value={productAllData["sku"]}
                />
              </InputGroup>
            </div>
            {/* ====================new fields start ============== */}
            <div className="col-12 mb-3 ">
              <RequiredLabel text={"Tags"} />
              <div>
                <CreatableSelect
                  isMulti
                  placeholder="Enter a choice value"
                  onChange={handleTags}
                  defaultValue={tags}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Vendor Info"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="vendor_info"
                  value={productAllData["vendor_info"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"About Brand"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="about_brand"
                  value={productAllData["about_brand"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6 d-none">
              <RequiredLabel text={"Shipping Info"} />
              <InputGroup className="mb-3">
                <Form.Control
                  as={"textarea"}
                  aria-label="Username"
                  name="shipping_info"
                  value={productAllData["shipping_info"]}
                  onChange={(e) => handleInputs(e, SetproductAllData)}
                  aria-describedby="basic-addon1"
                  style={{
                    height: "100px",
                    resize: "none",
                    backgroundColor: "#FAFAFA",
                  }}
                />
              </InputGroup>
            </div>
            {/* <div className="col-12 col-md-6">
              <RequiredLabel text={"Is New"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isNew ? "Yes" : "No"}`}
                  checked={isNew === true}
                  onChange={() => setIsNew(!isNew)}
                />
              </InputGroup>
            </div> */}
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Is Trending"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isTrend ? "Yes" : "No"}`}
                  checked={isTrend === true}
                  onChange={() => setIsTrend(!isTrend)}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md-6">
              <RequiredLabel text={"Is Show Website"} />
              <InputGroup className="mb-3">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label={`${isShowWebsite ? "Yes" : "No"}`}
                  checked={isShowWebsite === true}
                  onChange={() => setIsShowWebsite(!isShowWebsite)}
                />
              </InputGroup>
            </div>
            
            <div className="col-12 col-md-6 mb-4">
              {/* <p></p> */}
              {/* <RequiredLabel text={"Description"} /> */}
              <p>Description</p>
              <InputGroup className="mb-3" style={{ height: "150px" }}>
                <ReactQuill
                  style={{ width: "100%", maxHeight: "150px" }}
                  theme="snow"
                  // name='product_description'
                  value={productAllData["product_description"]}
                  onChange={handleDescriptionChange}
                />
              </InputGroup>
            </div>
            

            {/* ====================new fields end ============== */}
          </div>
        </div>
      </div>
      {/* ====================attribute=================== */}

      <div
        className="product-main my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <h4 className="fw-bold my-2 mb-4">Attribute</h4>
        <div>
          <h6 style={{ color: "red" }}>
            NB : Don't Use (~) for make any product and upload variant image in
            (height: 50px, width-auto) this size.
          </h6>
        </div>
        {/* <AttributeMultipleInput /> */}
        <div className="main-select">
          <CreatableSelect
            isMulti
            styles={{ height: "45px" }}
            
            // options={atttibutesList !== undefined && atttibutesList}
            options={getMainOptions()}
            isDisabled={!singleProduct?.is_from_bulk}
            defaultValue={
              !isLoading &&
              mainState !== undefined &&
              Object.keys(mainState).map((curr, index) => ({
                value: atttibutesList?.filter((data) => data?.label === curr)[0]
                  ?.value,
                label: curr,
              }))
            }
            // defaultValue={attributesCom}
            placeholder="Enter a choice value"
            onChange={handleMultiInputChange}
          />
        </div>

        {attributesCom?.map((newInput, index) => (
          <div className="row mt-3" key={index}>
            <div className=" col-lg-5 col-md-5  col-sm-12">
              <input
                type="text"
                style={{ height: "38px" }}
                className="multiple_input ps-3"
                value={newInput?.label}
                // disabled={!singleProduct?.is_from_bulk}
              />
            </div>
            <div className="col-lg-7 col-md-5 col-sm-12 main-select main-select-edit">
              <CreatableSelect
                defaultValue={
                  !isLoading &&
                  mainState[newInput?.label] !== undefined &&
                  mainState[newInput?.label].map((curr, index) => ({
                    label: curr,
                    value: index,
                  }))
                }
                // isDisabled={!singleProduct?.is_from_bulk}
                isMulti
                isClearable={false}
                placeholder="Enter a choice value"
                onChange={(value) =>
                  handleMultiInputInner(newInput?.label, value)
                }
              />
            </div>
          </div>
        ))}

        <div>
          <table
            className="item-tables mt-3 "
            style={{ overflowX: "auto", display: "block" }}
          >
            <thead>
              <tr className="trod" style={{ height: "45px" }}>
                <td
                  className="minw"
                  style={{ width: "340px", textAlign: "center" }}
                >
                  Variant
                </td>
                <td
                  className="minw"
                  style={{ width: "380px", textAlign: "center" }}
                >
                  Variant Price
                </td>
                <td
                  className="minw"
                  style={{ width: "280px", textAlign: "center" }}
                >
                  Current Variant Stock
                </td>
                <td
                  className="minw"
                  style={{ width: "200px", textAlign: "center" }}
                >
                  Add Stock
                </td>
                <td
                  className="minw"
                  style={{ width: "410px", textAlign: "center" }}
                >
                  Images
                </td>

                <td
                  className="minw"
                  style={{ width: "190px", textAlign: "center" }}
                >
                  Action
                </td>
              </tr>
            </thead>
            <tbody>
              {itemData.map((item, index) => (
                <tr className="trod" key={index + 1} style={{ height: "45px" }}>
                  <td className="ps-1 ps-md-2 ps-lg-4">{item}</td>
                  <td className="text-cente ">
                    <div className="d-flex justify-content-center align-items-center">
                      {singleProduct?.price.split(".")[0]} +
                      <input
                        name="variant_price"
                        onChange={(e) => HandleVariantsInputValue(e, 0, item)}
                        className=" productVariantTdinput"
                        type="text"
                        placeholder="0.00"
                        value={
                          AttributesInputValue[item] !== undefined
                            ? AttributesInputValue[item]?.variant_price
                            : 0
                        }
                      // value={singleProduct.variants_json.AttributesInputValue[item].variant_price}
                      />
                    </div>
                  </td>
                  <td className="text-center">
                    <input
                      // name="variant_stock"
                      // onChange={(e) => HandleVariantsInputValue(e, 1, item)}
                      className="attribute_input"
                      type="text"
                      placeholder="0"
                      value={
                        AttributesInputValue[item] !== undefined
                          ? AttributesInputValue[item]?.variant_stock
                          : ""
                      }
                    // value={
                    //   singleProduct.variants_json.AttributesInputValue[item] !== undefined
                    //     ? singleProduct.variants_json.AttributesInputValue[item].variant_stock
                    //     : ""
                    // }
                    />
                  </td>
                  <td className="text-center">
                    <input
                      name={item}
                      // onChange={(e) => HandleVariantsInputValue(e, 1, item)}
                      onChange={handleAddStock}
                      className="attribute_input"
                      type="text"
                      placeholder="0"
                      value={addStock.find(({ name }) => name === item)?.value}
                    />
                  </td>
                  <td>
                    <div className="row m-1 m-md-2">
                      {AttributesInputValue[item]?.img_details &&
                        AttributesInputValue[item]?.img_details?.map((data) => (
                          <>
                            <div
                              className="col-md-4"
                              style={{ position: "relative" }}
                              key={data?.id}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  deleteVariantImage(data?.id, item)
                                }
                              >
                                <XCircle size={32} weight="fill" />
                              </div>
                              <img
                                width={60}
                                style={{ objectFit: "cover" }}
                                src={data?.url}
                                alt=""
                              />
                            </div>
                          </>
                        ))}
                    </div>
                    {
                      <div
                        className="d-flex justify-content-center my-1 my-md-2"
                        style={{
                          position: "relative",
                        }}
                      >
                        <input
                          type="file"
                          onChange={(e) => HandleVariantsImage(e, item)}
                          accept="image/*"
                          multiple
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            opacity: "0",
                            cursor: "pointer",
                          }}
                        />
                        <PlusCircle
                          style={{ cursor: "pointer" }}
                          size={40}
                          weight="bold"
                        />
                      </div>
                    }
                  </td>
                  <td>
                    <div class="form-check form-switch d-flex justify-content-center">
                      <input
                        class="form-check-input "
                        // onChange={(e) => {
                        //   console.log(e.target.value);
                        //   console.log(e);
                        // }}
                        name="variant_action"
                        // value={getVariantAction(item)}
                        // value={
                        //   AttributesInputValue[item] !== undefined && AttributesInputValue[item]["variant_action"] !== null
                        //     ? AttributesInputValue[item]["variant_action"]
                        //     : true
                        // }
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={getVariantAction(item)}
                        // onChange={(e) => console.log(e.target.checked)}
                        onChange={(e) => HandleVariantsSwitch(e, item)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ====================attribute=================== */}
      <div
        className="product-main my-4 bg-white cat-child"
        style={{ borderTop: "0.5px solid #E0E0E0" }}
      >
        <p style={{ marginTop: "10px", color: "red" }}>
          NB: Upload image in (height-523px, width-392px) for thumbnail.{" "}
        </p>{" "}
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Thumbnail image</h5>
            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  You can upload or change
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL && (
              <div className="my-2">
                <img height={90} width={90} src={URL} alt="" /> <br />
                <button
                  onClick={removeImg}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
            {/* {
              singleProduct?.images?.map(m => (<div key={m.id} className="my-2">
              <img height={90} width={90} src={`${BASE_URL}${m?.image}`} alt="" /> <br />
              <button  className="remove-btn btn btn-small mt-1  rounded" style={{ border: "1px solid gray !important" }}>
                Remove
              </button>
            </div>))
            } */}
          </div>
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Thumbnail image (Optional)</h5>
            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName2 === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  You can upload or change
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName2}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle2(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL2 && (
              <div className="my-2">
                <img height={90} width={90} src={URL2} alt="" /> <br />
                <button
                  onClick={removeImg2}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
            {/* {
              singleProduct?.images?.map(m => (<div key={m.id} className="my-2">
              <img height={90} width={90} src={`${BASE_URL}${m?.image}`} alt="" /> <br />
              <button  className="remove-btn btn btn-small mt-1  rounded" style={{ border: "1px solid gray !important" }}>
                Remove
              </button>
            </div>))
            } */}
          </div>
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Size Chart image (optional)</h5>
            <p style={{ color: "#8E8E93" }}>( Select your file & uploaded )</p>
            <div
              className="w-100 browse-main d-flex align-items-center"
              style={{
                height: "55px",
                border: "1px solid #E0E0E0",
                borderRadius: "7px",
                overflowX: "hidden",
              }}
            >
              {fileName3 === "" ? (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  You can upload or change
                </p>
              ) : (
                <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                  {" "}
                  {fileName3}
                </p>
              )}

              <div className="file-up">
                <input
                  type="file"
                  onChange={(e) => fileHandle3(e)}
                  accept="image/*"
                />
                <p>Browse</p>
              </div>
            </div>
            {URL3 && (
              <div className="my-2">
                <img height={90} width={90} src={URL3} alt="" /> <br />
                <button
                  onClick={removeImg3}
                  className="remove-btn btn btn-small mt-1  rounded"
                  style={{ border: "1px solid gray !important" }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>

          {/* ----------status--------- */}
          <div className="col-12 col-lg-5 ">
            <h5 style={{ marginTop: "30px" }}>Status</h5>
            <div className="row mr-4">
              <div
                className=" d-flex justify-content-between col-12 py-3"
                style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
              >
                <div className="d-flex w-100">
                  <div
                    className="d-flex py-1 justify-content-center align-items-center"
                    style={{
                      backgroundColor: "#212121",
                      width: "32px",
                      height: "32",
                      borderRadius: "3px",
                    }}
                  >
                    <img src={eye} alt="" />
                  </div>
                  <span className="mx-3">{Categoryswitch ? "ON" : "OFF"}</span>
                </div>

                <div class="form-check form-switch ">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    width={40}
                    height={40}
                    checked={Categoryswitch}
                    onClick={toggleSwitch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="optional">
            <p>Feature Images (Optional)</p>
          </div>
          <div className="col-12 ">
            {/*  */}
            {/* <div>
              <img width={50} height={50} src={doc} alt="" />
              <span></span>
              <p>Drag n Drop here</p>
              <p className="or">Or</p>
              <p className="browse">Browse</p>
              <button>Upload File</button>
            </div>
            <input
              type="file"
              onChange={handleOptional}
              multiple
              
            /> */}
            <div>
              <span>NB: Add image with 1664px(width) 600px(height)</span> <br />
              <span></span> <br />
            </div>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => uploadMulti(files)}
              filesLimit={40}
            // isMulti={false}
            />
            <div className="d-flex">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="d-flex"
                    >
                      {multiId &&
                        multiId?.map((data, index) => (
                          <Draggable key={data?.id} draggableId={data.id.toString()} index={index}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="my-2 mx-2">
                                <img height={90} width={90} src={data?.doc_url} alt="" />{" "}
                                <br />
                                <button
                                  onClick={() => {
                                    const filteredData = multiId?.filter(
                                      (item) => item.id !== data?.id
                                    );
                                    setMultiId(filteredData);
                                  }}
                                  className="remove-btn btn btn-small mt-1  rounded"
                                  style={{ border: "1px solid gray !important" }}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {/* <DropzoneArea filesLimit={5} getPreviewIcon={handlePreviewIcon} /> */}
          </div>
          <div className="d-flex my-4">
            {singleProduct !== undefined &&
              singleProduct.images !== undefined &&
              singleProduct?.images?.map((img, index) => (
                <div
                  key={index}
                  className="main-box-img"
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <img
                    key={index}
                    src={`${BASE_URL}${img.image}`}
                    width={70}
                    height={70}
                    className="mx-2"
                    alt=""
                    srcset=""
                  />{" "}
                  <br />
                  <button
                    className="del-btn"
                    onClick={() => deleteFeatureImages(img?.id)}
                  >
                    <X size={14} />
                  </button>
                </div>
              ))}
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={ValidateData}
              className="save-btn me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.product_list)}
              className="save-btn mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditArrival;
