import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ProductVariantModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title  id="contained-modal-title-vcenter">
          Variant Sales
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.variants?.map((data) => (
            <div className="d-flex justify-content-around my-1">
              <div>
                <h5>Variant Name</h5>
                <p>{data?.name}</p>
              </div>
              <div>
                <h5>Total Sold</h5>
                <p>{data?.total_sold_quantity}</p>
              </div>
              <div>
                <h5>Total Stock</h5>
                <p>{data?.stock}</p>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductVariantModal;
