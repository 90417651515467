import React from "react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { useState } from "react";
import eye from "../../assets/Icon/eye.svg";
import swal from "sweetalert";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";

import * as path from "../Routes/RoutePaths";
import { useHistory ,useParams} from "react-router-dom";
import { handleInputs } from "../../utils/HandleInputs";
import { logout_func2 } from "../Const/logoutCommon";
import { useEffect } from "react";
import Loader from "../CustomCommons/Loader";

const EditOutlet = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const {id} = useParams()
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const history = useHistory();
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const [outlatAllData, setOutlatAllData] = useState({
    name: "",
    mobile: "",
    email: "",
    location: "",
  });
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [isLoading, SetisLoading] = useState(true);
  const fileHandle = (e) => {

    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    console.log(e.target.files[0], "file");
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/${id}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOutlatAllData({
          name: result?.name,
          mobile: result?.mobile,
          email: result?.email,
          location:result?.location,
        })
        setURL(result.thumb_url);
        setThumbId(result.thumb)
        SetisLoading(false);
        setCategoryswitch(result?.is_active);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  useEffect(()=>{
    getOutlet()
  },[])
  const updateOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/${id}/`;
      const data = {
        name: outlatAllData["name"],
        email:outlatAllData["email"],
        mobile:outlatAllData['mobile'],
        is_active:Categoryswitch,
        location:outlatAllData['location'],
        thumb:thumbId
      };
      axios
        .patch(url, data)
        .then((res) => {
          if (res.data.status) {
            showToast("success", "Outlet Updated.");
            history.push(path.outlet_list);
          }
        })
        .catch((err) => {
          const message = JSON.parse(err.request.response).message;
          const errorMsg = JSON.parse(err.request.response).errors;
          for (let key in errorMsg) {
            showToast("error", `${key} : ${errorMsg[key][0]}`);
          }
          showToast("error", message);
        });
    
  };
  if (isLoading ) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Edit Outlet</h3>
        </div>

        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Outlet Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={outlatAllData["name"]}
                onChange={(e) => handleInputs(e, setOutlatAllData)}
                required
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Email"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="email"
                type="email"
                value={outlatAllData["email"]}
                onChange={(e) => handleInputs(e, setOutlatAllData)}
                required
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Mobile"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="mobile"
                type="number"
                value={outlatAllData["mobile"]}
                onChange={(e) => handleInputs(e, setOutlatAllData)}
                required
              />
            </InputGroup>
          </div>
          <div className="col-12 ">
            <p>Location</p>
            <Form.Control
              as="textarea"
              placeholder="Type outlet"
              className="mb-3"
              style={{
                height: "100px",
                resize: "none",
                backgroundColor: "#FAFAFA",
              }}
              name="location"
              value={outlatAllData["location"]}
              onChange={(e) => handleInputs(e, setOutlatAllData)}
            />
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                {" "}
                Upload image in (height-300px, width-300px) .{" "}
              </p>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>

            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
                onClick={updateOutlet}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Update
            </button>

            <button
              onClick={() => history.push(path.outlet_list)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditOutlet