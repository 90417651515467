import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import plus from "../../assets/Icon/plus.svg";
import catIcon from "../../assets/Icon/category icon.svg";
import productIcon from "../../assets/Icon/Product icon.svg";
import salesIcon from "../../assets/Icon/sales Icon.svg";
import invoiceIcon from "../../assets/Icon/invoice icon.svg";
import blueTime from "../../assets/Icon/blue time.svg";
import blueArrow from "../../assets/Icon/blue arrow.svg";
import AreaChart from "./AreaChart";
import ViewIcon from "../CustomCommons/ViewIcon";
import EditIcon from "../CustomCommons/EditIcon";
import DeleteIcon from "../CustomCommons/DeleteIcon";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import * as path from "../Routes/RoutePaths";
import { BASE_URL } from "../Const/Url";
import axios from "axios";
import { deliveryStatus, paymentStatus } from "../Const/Status";
import { Cube } from "react-preloaders";
import UseData from "../Const/UseData";
import { HashLoader } from "react-spinners";
import Loader from "../CustomCommons/Loader";
import ReactPaginate from "react-paginate";
import NextIcon from "../CustomCommons/NextIcon";
import PreviousIcon from "../CustomCommons/PreviousIcon";
import CountUp from "react-countup";
import {
  can_change_invoice,
  can_delete_invoice,
  can_view_invoice,
  has_permissions,
} from "../CustomCommons/utils";
import OfsetPaggination from "./OfsetPaggination";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/ToastHelper";
import { logout_func2 } from "../Const/logoutCommon";
import SalesFilter from "./SalesFilter";
import AddresTopLow from "../Reports/AddresTopLow";

const Dashboard = () => {
  const [totalData, setTotalData] = useState(0);
  
  const [query, setQuery] = useState("");
  const history = useHistory();
  const [items_data, setItems_data] = useState([]);
  const [selectVal, setSelectVal] = useState("Lsat Week");
  const [dataDrop, setDataDrop] = useState(false);
  if (!localStorage.getItem("is_logged_in")) {
    history.push("/login/");
  }
  //  ofet code
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 10;

  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const logout_func = () => {
    localStorage.removeItem("is_logged_in");
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
    setOnlogoutHide(1);

    history.push("/login");
  };

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setInvoiceList(res.data.data.results);

        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);

        // console.log(res.data);
        SetisLoading2(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(message, "errrrrrr");
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };

  useEffect(() => {
    apiFunction(
      `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=false&is_purchase_order=false&is_requisition_order=false`
    );
  }, []);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
    // SetisLoading(false)
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
    // SetisLoading(false)
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  useEffect(() => {
    if (pageNumber > 1 || query) {
      apiFunction(
        `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=false&is_purchase_order=false&is_requisition_order=false`
      );
    }
  }, [pageNumber, query]);
  //  ofet code
  const updatedPageNumber = parseInt(
    localStorage.getItem("allInvoicePageNbr")
      ? localStorage.getItem("allInvoicePageNbr")
      : 0
  );

  const usersPerPage = 15;

  const [dashboardCountData, setDashboardCountData] = useState({
    product: 0,
    invoice: 0,
    sales: 0,
    category: 0,
  });
  console.log("count", dashboardCountData);
  const [invoiceList, setInvoiceList] = useState([]);

  const [isLoading, SetisLoading] = useState(true);

  // const [data,loading] = UseData(`${BASE_URL}api/v1/sales/inventory/invoice/`)
  const getInvoiceList = () => {
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/?is_outlet=false&is_custom=true&is_regular=true`;
    axios
      .get(url)
      .then((res) => {
        setInvoiceList(res.data.data.results);
        // console.log(res.data);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        SetisLoading(false);
      });
  };
  useEffect(() => {
    getInvoiceList();
  }, []);
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.id);
      setCheckedIds(data);
    } else {
      setCheckedIds([]);
    }
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=false&is_custom=true&is_regular=true`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(err.request.response);
          });

        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/sales/inventory/invoice/${id}`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getInvoiceList();
            apiFunction(
              `${BASE_URL}${"api/v1/sales/inventory/invoice/"}?limit=${limit}&offset=${offset}&query=${query}&is_outlet=false&is_custom=true&is_regular=true`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  const keys = ["number", "total_amount", "prepared_by", "invoice_date"];
  const getData = invoiceList.filter((p) =>
    keys.some((key) => p[key]?.toString().toLowerCase().includes(query))
  );

  // const getData = invoiceList === undefined ? [] : invoiceList.filter((p) => keys.some((key) => p[key].toLowerCase().includes(query)));

  const select = ["Last Week", "Last Month", "Last Year"];
  const lastSelect = useRef();
  const handleSelect = () => {
    setDataDrop(!dataDrop);
  };
  // useEffect(() => {
  //   const closeUser = (e) => {
  //     if (e.path[0] !== lastSelect.current) {
  //       // setDataDrop(1)
  //     }
  //   };
  //   document.body.addEventListener("click", closeUser);

  //   return () => document.body.removeEventListener("click", closeUser);
  // }, []);
  // console.log(invoiceList, "-------list----------");

  const getDashboard = () => {
    const url = `${BASE_URL}api/v1/auth/dashboard/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setDashboardCountData(res.data.data);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getDashboard();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const invoice_choices = ["Paid", "Unpaid", "Due", "Exchanged"];

  const delivery_choices = ["Processing", "Recieved", "Delivered"];
  const delivery_type_choices = ["Regular", "Urgent"];
  const paymentType = [
    "C.O.D",
    "Card",
    "Bank Transfer",
    "bKash",
    "SSLECOMMERZ",
    "Nagad",
    "KOD",
  ];
  const sourceList = ["Website", "Inventory", "Admin"];
  return (
    <div>
      <div className="categorey-parent " style={{ height: "100%" }}>
        <div className="only-margin">
          <h4 style={{ color: "#637A9F" }}> Statistics For Admin</h4>
          {/* <Cube/> Statitcs  */}
          <div className="  stat-box-main">
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={catIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Category</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.category !== undefined
                        ? dashboardCountData.category
                        : 1232
                    }
                    duration={1}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={productIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Product</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.product !== undefined
                        ? dashboardCountData.product
                        : 0
                    }
                    duration={1}
                  />

                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={salesIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.sales !== undefined
                        ? dashboardCountData.sales
                        : 0
                    }
                    duration={0.2}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={invoiceIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Total Invoice</p>
                <h5 className="state-head">
                  <CountUp
                    end={
                      dashboardCountData.invoice !== undefined
                        ? dashboardCountData.invoice
                        : 0
                    }
                    duration={1}
                  />

                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* ==============React Chart ============== */}{" "}
        {/* <AreaChart chartLabel="Sales Report" />{" "} */}
        {/* Resent Sale card start fana */}
        <div className="only-margin">
          {/* <Cube/> */}
          <div className="  stat-box-main">
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={catIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Today Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.todays_sale_amount !== undefined
                        ? dashboardCountData.todays_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={productIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>This Week Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.weekly_sale_amount !== undefined
                        ? dashboardCountData.weekly_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={salesIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>This Month Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.monthly_sale_amount !== undefined
                        ? dashboardCountData.monthly_sale_amount
                        : 0
                    }
                    duration={0.2}
                  />
                </h5>
              </div>
            </div>
            <div className=" state-main  mt-2 bg-white">
              <div className="state-img-box">
                <img src={invoiceIcon} width={26} height={24} alt="" />
              </div>
              <div className="mx-4 mb-2">
                <p>Last Month Sales</p>
                <h5 className="state-head">
                  ৳
                  <CountUp
                    end={
                      dashboardCountData.previous_month_sale_amount !==
                      undefined
                        ? dashboardCountData.previous_month_sale_amount
                        : 0
                    }
                    duration={1}
                  />
                  <span className="text-white">৳</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        {/* Resent Sale card */}
        {/* sales filter */}
        <SalesFilter mid={'/api/v1/auth/dashboard-sales/'}/>
        <AddresTopLow/>
        {/* ==============React Chart ============== */}{" "}
        <AreaChart chartLabel="Sales Report" />{" "}
        {/* ==============React Chart ============== */}
        
        {/* imvoice Table */}
        {has_permissions(can_view_invoice) && (
          <div className="invoice-main ">
            <div className="head-select">
              <div>
                <h5>All Invoice</h5>
                <input
                  type="text"
                  style={{ color: "black" }}
                  className="filter-input"
                  placeholder="Search here"
                  onChange={(e) => {
                    setQuery(e.target.value);
                    localStorage.setItem("dashboardQuery", query);
                    // (updatedPageNumber = selected)
                  }}
                />
              </div>

              <div
                className="drop-p d-none"
                ref={lastSelect}
                onClick={handleSelect}
              >
                <div className="select d-flex align-items-center gap-1">
                  <img className="me-1" src={blueTime} alt="" />
                  <span className="me-1">{selectVal}</span>
                  <img
                    className={`${dataDrop ? "blue-arrow" : "blue-arrow1"}`}
                    src={blueArrow}
                    alt=""
                  />
                </div>
                {dataDrop && (
                  <div className="py-2 px-3 bg-white drop">
                    <p className="mx-1" onClick={() => setSelectVal(select[0])}>
                      {select[0]}
                    </p>
                    <p className="mx-1" onClick={() => setSelectVal(select[1])}>
                      {select[1]}
                    </p>
                    <p className="mx-1" onClick={() => setSelectVal(select[2])}>
                      {select[2]}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {checkedIds?.length > 0 && (
              <div className="my-2">
                <button
                  className="btn text-light"
                  style={{
                    background: "black",
                    cursor: "pointer",
                    maxWidth: "120px",
                    color: "white",
                  }}
                  onClick={() => deleteDataMulti(checkedIds)}
                >
                  Delete
                </button>
              </div>
            )}
            {/* ===================table ========================*/}

            <div className="mt-4">
              <div className="" style={{ display: "block", overflowX: "auto" }}>
                <table className=" w-100">
                  <thead
                    className="bg-dark text-white head-row"
                    style={{
                      height: "50px",
                    }}
                  >
                    <tr className="">
                      {/* <th
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      minWidth: "90px",
                      textAlign: "center",
                      borderTopLeftRadius: "4px ",
                    }}
                  >
                    S/N
                  </th> */}
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          width: "120px",
                          // display:'flex',
                          // justifyContent:'center',
                          margin: "center",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <input
                            style={{
                              margin: "0 auto",
                              opacity: "1",
                              width: "17px",
                              height: "17px",
                            }}
                            type="checkbox"
                            checked={checkedIds?.length > 0}
                            onChange={(event) => handleCheckboxChangeAll(event)}
                          />
                        </div>
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "120px",
                        }}
                      >
                        <p className="ms-3 my-auto">Invoice No</p>
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "140px",
                        }}
                      >
                        Prepared From
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "150px",
                        }}
                      >
                        Customer Name
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "100px",
                        }}
                      >
                        Type
                      </th>
                      {/* <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "130px",
                        }}
                      >
                        Delivery Status
                      </th> */}
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          width: "120px",
                        }}
                      >
                        Delivery Type
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          width: "150px",
                        }}
                      >
                        Payment Type
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "100px",
                        }}
                      >
                        Total
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "120px",
                        }}
                      >
                        Create On
                      </th>
                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          minWidth: "80px",
                        }}
                      >
                        Status
                      </th>

                      <th
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          borderTopRightRadius: "4px ",
                          minWidth: "120px",
                        }}
                      >
                        <span className="ms-4">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tb">
                    {items_data.length > 0 && items_data !== undefined ? (
                      items_data?.map((category, index) => (
                        <tr className="table-row " key={category?.slug}>
                          {/* <td className="text-center">{category?.slug}</td> */}
                          <td>
                            <div className="d-flex justify-content-center">
                              <input
                                type="checkbox"
                                name=""
                                id=""
                                style={{ width: "17px", height: "17px" }}
                                checked={checkedIds.includes(category.id)}
                                onChange={() =>
                                  handleCheckboxChange(category?.id)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <p className="ms-3 my-auto">{category.number}</p>
                          </td>
                          <td>{sourceList[category?.source]}</td>
                          <td>{category?.bill_to}</td>
                          <td>
                            {category.is_custom === true ? "Custom" : "Regular"}
                          </td>

                          <td>
                            <button
                              className={`status1 ${
                                (category.delivery_type === 0 &&
                                  "c5 text-white") ||
                                (category.delivery_type === 1 &&
                                  "bg-danger text-white")
                              }`}
                            >
                              {/* {category.delivery_status === 1 ? "Delivered" : "Processing"} */}
                              {delivery_type_choices[category.delivery_type]}
                            </button>
                          </td>
                          <td> {paymentType[category?.payment_type]}</td>
                          <td>৳ {category.total_amount}</td>
                          <td>{category.invoice_date}</td>
                          <td className="">
                            <button
                              className={`status1 ${
                                (category.payment_status === 0 && "i1") ||
                                (category.payment_status === 1 &&
                                  "c4 text-white") ||
                                (category.payment_status === 2 &&
                                  "c3 text-white")
                              }`}
                            >
                              {invoice_choices[category.payment_status]}
                            </button>
                          </td>
                          <td>
                            <div className="d-flex gap-3">
                              {category?.source === 0 ? (
                                <>
                                  <div
                                    className="edit-del blue"
                                    onClick={() =>
                                      history.push(
                                        `/sales/view-web-invoice/${category?.slug}`
                                      )
                                    }
                                  >
                                    <ViewIcon />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="edit-del blue"
                                    onClick={() =>
                                      history.push(
                                        category?.is_custom === true
                                          ? `/sales/view-custom-invoice/${category?.slug}`
                                          : `/sales/view-invoice/${category?.slug}`
                                      )
                                    }
                                  >
                                    <ViewIcon />
                                  </div>
                                </>
                              )}

                              {has_permissions(can_change_invoice) ? (
                                <>
                                  {category?.source === 0 ? (
                                    <>
                                      <div
                                        className="edit-del green"
                                        onClick={() =>
                                          history.push(
                                            `/sales/edit-web-invoice/${category?.slug}`
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="edit-del green"
                                        onClick={() =>
                                          history.push(
                                            category?.is_custom === true
                                              ? `/sales/edit-custom-invoice/${category?.slug}`
                                              : `/sales/edit-invoice/${category?.slug}`
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <div
                                  className="edit-del green"
                                  onClick={() =>
                                    swal(
                                      "Sorry , You are not able to edit invoice."
                                    )
                                  }
                                >
                                  <EditIcon />
                                </div>
                              )}
                              {has_permissions(can_delete_invoice) ? (
                                <div
                                  className="edit-del red"
                                  onClick={() => deleteData(category?.id)}
                                >
                                  <DeleteIcon />
                                </div>
                              ) : (
                                <div
                                  className="edit-del red"
                                  onClick={() =>
                                    swal(
                                      "Sorry , You are not able to delete invoice."
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="9">
                          <div className="not_found d-flex justify-content-center align-items-center py-4">
                            <h4 className="my-4">No Data Found</h4>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* ===================table ========================*/}
            {totalData > 0 && (
              <div className="row pag" style={{ marginTop: "30px" }}>
                <div className="col-6 col-md-5">
                  <p
                    className="inter"
                    style={{ color: "#AEAEB2", fontSize: "16px" }}
                  >
                    Total Entries:{" "}
                    <span style={{ color: "black", fontSize: "14px" }}>
                      {totalData}
                    </span>
                    <span className="ms-2"></span>
                    Total Page:{" "}
                    <span style={{ color: "black", fontSize: "14px" }}>
                      {Math.ceil(totalData / 10)}
                    </span>
                  </p>
                </div>
                <div className="col-12 col-md-6 d-flex paggination-button">
                  <div className="row lead_pagination bg-transparent">
                    <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                      <nav aria-label="...">
                        <ul className="pagination pagination-sm">
                          {!isLoading ? (
                            <li className="page-item ">
                              {prevUrl !== null ? (
                                <Link
                                  onClick={handlePaginationPrevious}
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast(
                                      "success",
                                      "You are on first page"
                                    )
                                  }
                                  className={` ${
                                    prevUrl === null ? "disabled" : ""
                                  } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                  to="#"
                                  tabindex="-1"
                                >
                                  <PreviousIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item ">
                              <Link
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            </li>
                          )}

                          {[...Array(pageCount === null ? 1 : pageCount)].map(
                            (elem, index) => (
                              <li
                                className="page-item"
                                style={{
                                  display: `${
                                    index + 1 !== pageNumber ? "none" : "block"
                                  }`,
                                }}
                              >
                                {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                                {/* setPageNumber(prev=> prev - 1) */}

                                <Link
                                  onClick={() =>
                                    handleChangeCurrentPage(index + 1)
                                  }
                                  className={`${
                                    index + 1 == pageNumber
                                      ? "paginationActive"
                                      : ""
                                  } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                  to="#"
                                >
                                  <span className="px-1 py-1">
                                    {pageNumber}
                                  </span>
                                </Link>
                              </li>
                            )
                          )}
                          {!isLoading ? (
                            <li className="page-item">
                              {nextUrl !== null ? (
                                <Link
                                  onClick={handlePaginationNext}
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />{" "}
                                </Link>
                              ) : (
                                <Link
                                  onClick={() =>
                                    showToast("success", "You are on last page")
                                  }
                                  className={`${
                                    nextUrl === null ? "disabled" : ""
                                  } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                  to="#"
                                >
                                  <NextIcon />
                                </Link>
                              )}
                            </li>
                          ) : (
                            <li className="page-item">
                              <Link
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
