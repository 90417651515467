export const dStatus = ["Returned", "Inv Recieved", "Delivered"];
export const menuItems = [
  { label: "Dashboard", value: "Dashboard" },
  { label: "P. Dashboard", value: "P. Dashboard" },
  { label: "Inventory", value: "Inventory" },
  { label: "New Arrivals", value: "New Arrivals" },
  { label: "Trending", value: "Trending" },
  { label: "Festival", value: "Festival" },
  { label: "Matching", value: "Matching" },
  { label: "Sales", value: "Sales" },
  { label: "Customers", value: "Customers" },
  { label: "Outlets", value: "Outlets" },
  { label: "Promotions", value: "Promotions" },
  { label: "Settings", value: "Settings" },
  { label: "Reports", value: "Reports" },
  { label: "Custom", value: "Custom" },
  { label: "Purchases", value: "Purchases" },
  { label: "Notifications", value: "Notifications" },
  { label: "Requisitions", value: "Requisitions" },
  { label: "User", value: "User" },
  { label: "Employee", value: "Employee" },
  { label: "Combo", value: "Combo" },
];
export const membershipLevels = ["NONE", "PRO", "PREMIUM", "ELITE", "SUPREME"];
