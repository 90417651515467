import React, { useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import * as path from "../../Routes/RoutePaths";
import AddressSearch from "./AddressSearch";
import eye from "../../../assets/Icon/eye.svg";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../CustomCommons/Loader";

const EditDisplayCenter = () => {
  const { id } = useParams();
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const history = useHistory();
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [loading, setLoading] = useState(true);
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const [testi, setTesti] = useState({
    name: "",
    mobile: "",
    description: "",
    location_url: "",
    lat: "",
    lon: "",
  });
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        // setData(res.data.data);
        const data1 = res.data.data;
        setTesti({
          name: res.data.data.name,
          mobile: res.data.data.mobile,
          description: res.data.data.description,
          location_url: res.data.data.location_url,
          lat: res.data.data.latitude,
          lon: res.data.data.longitude,
        });
        setURL(res.data.data?.thumb_url);
        setThumbId(res.data.data?.thumb);
        setCategoryswitch(res.data.data.is_active);
        if (data1) {
          const address2 = {
            location: res.data.data.location,
            latitude: res.data.data.latitude,
            longitude: res.data.data.longitude,
          };
          localStorage.setItem("edit-address", JSON.stringify(address2));
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
        // if (
        //   message === "Invalid token." ||
        //   JSON.parse(err.request.response).code === 401
        // ) {
        // }
      });
  };
  useEffect(() => {
    const url = `${BASE_URL}api/v1/utility/admin/display-center/${id}/`;
    apiFunction(url);
  }, []);

  const postDisplayCenter = async () => {
    const url = `${BASE_URL}api/v1/utility/admin/display-center/${id}/`;
    const address = await JSON.parse(localStorage.getItem("address"));
    const data = {
      name: testi.name,
      location_url: testi.location_url,
      mobile: testi.mobile,
      latitude: testi.lat,
      longitude: testi.lon,
      description: testi.description,
      is_active: Categoryswitch,
      thumb: thumbId ? thumbId : null,
    };

    axios
      .patch(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Display Center Updated.");
          localStorage.removeItem("edit-address");
          history.push(path.display_center);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Edit Display Center
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={testi["name"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Mobile"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="number"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="mobile"
                value={testi["mobile"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Location URL"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="location_url"
                value={testi["location_url"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Latitude"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="lat"
                value={testi["lat"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Longitude"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="lon"
                value={testi["lon"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-md-6 d-none d-md-block"></div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Description"} />
            <InputGroup className="mb-3" style={{ height: "100px" }}>
              <Form.Control
                as={"textarea"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="description"
                value={testi["description"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          {/* <div className="col-12 col-md-6 d-none d-md-block"></div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Search Location"} />
            <AddressSearch />
          </div> */}
          <div className="row d-flex justify-content-between">
            <p style={{ marginTop: "10px", color: "red" }}>
              NB: Upload image in (height-300px, width-300px) for Thumbnail.{" "}
            </p>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    You can select new
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            {/* ----------status--------- */}
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={postDisplayCenter}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.display_center)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDisplayCenter;
