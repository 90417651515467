import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { BASE_URL } from "../Const/Url";

import DeleteIcon from "../CustomCommons/DeleteIcon";
import Loader from "../CustomCommons/Loader";

import ReactPaginate from "react-paginate";

import PreviousIcon from "../CustomCommons/PreviousIcon";
import NextIcon from "../CustomCommons/NextIcon";
import {
  can_delete_report,
  can_view_report,
  has_permissions,
} from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import { useReactToPrint } from "react-to-print";
import { logout_func2 } from "../Const/logoutCommon";
import Select from "react-select";

const DailySalesReport = () => {
  // mewcode
  const [adminOrOutlet, setAdminOrOutlet] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [outletList, setOutletList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  // mewcode
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [query, setQuery] = useState("");
  const [totalSale, setTotalSale] = useState(0);
  const history = useHistory();
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${month}-${day}-${year}`;
  const [mainReportLength, setMainReportLength] = useState(0);

  const updatedPageNumber = parseInt(
    localStorage.getItem("saleReportPageNbr")
      ? localStorage.getItem("saleReportPageNbr")
      : 0
  );
  const [pageNumber, setPageNumber] = useState(updatedPageNumber);
  const usersPerPage = 15;
  const pagesVisited = pageNumber * usersPerPage;
  var options = {
    day: "numeric",
    year: "numeric",
    month: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // hour12: true,
  };

  const [isLoading, setIsLoading] = useState(true);
  const [Showdata, setShowdata] = useState(true);
  const cRef = useRef();

  function convert(date) {
    return new Date(date).toLocaleDateString("en-US", options);
  }

  const [dailyReport, setDailyReport] = useState([]);
  const [dailyReportAll, setDailyReportAll] = useState({});

  const userProfile = JSON.parse(window.localStorage.getItem("userData"));

  const getReport = () => {
    let url;
    if (date1 && date2) {
      url = `${BASE_URL}api/v1/sales/inventory/real-time-daily-report/?start=${date1}&end=${date2}&${
        adminOrOutlet ? adminOrOutlet : ""
      }${
        adminOrOutlet === "outlet=true" && selectedOutlet
          ? `&outlet_id=${selectedOutlet}`
          : ""
      }`;
    } else {
      url = `${BASE_URL}api/v1/sales/inventory/real-time-daily-report/?${
        adminOrOutlet ? adminOrOutlet : ""
      }${
        adminOrOutlet === "outlet=true" && selectedOutlet
          ? `&outlet_id=${selectedOutlet}`
          : ""
      }`;
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res?.data);
        const resData = res?.data?.data?.product_data.map((curr) => ({
          ...curr,
          created_at: convert(curr.created_at),
        }));
        setDailyReportAll(res?.data?.data);
        setDailyReport(resData);
        setMainReportLength(res?.data?.data?.data);
        setTotalSale(resData?.map((data) => parseInt(data?.total_amount)));
        setIsLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response)?.code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    getReport();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
    // // setShowdata(false);
  }, [selectedOutlet, adminOrOutlet]);

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getReport();
    }
  };

  // console.log(date1, date2);
  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Daily Sales Report",
    // onAfterPrint: () => swal("Printed"),
    bodyClass: "dis",
  });
  const handlePrints = () => {
    HandlePrint();
  };

  const keys = ["total_sales", "product", "created_at"];
  const getData = dailyReport?.filter((p) =>
    keys.some((key) => p[key]?.toString().toLowerCase().includes(query))
  );
  const pageCount = Math.ceil(getData?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
    localStorage.setItem("saleReportPageNbr", selected);
  };
  // newCode
  const options3 = [
    { value: "", label: "See All" },
    { value: "admin=true", label: "Admin" },
    { value: "outlet=true", label: "Outlet" },
    { value: "website=true", label: "Website" },
  ];
  const getSelectOutlet = (val, action) => {
    setSelectedOutlet(val.value);
  };
  const handleAdminOrOutlet = (newVal, action) => {
    setSelectedOutlet(null);
    setAdminOrOutlet(newVal.value);
  };
  const getOutlet = () => {
    const url = `${BASE_URL}api/v1/sales/admin/outlet/`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data.results;
        const options = result.map((curr) => ({
          label: curr.name,
          value: curr.id,
        }));
        setOutletList(options);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getOutlet();
  }, []);
  // new code
  if (!has_permissions(can_view_report)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }

  // console.log("showdata", Showdata);
  let finalSale = totalSale?.reduce(function (a, b) {
    return a + b;
  }, 0);

  const toggleRow = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  return (
    <div className="categorey-parent " style={{ height: "100%" }}>
      <div className="bg-white me-lg-4 me-md-3 me-1 px-3 rounded">
        <div className="py-3 d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex align-self-center gap-4">
            <div>
              <h5>Product Sales</h5>
              <input
                type="text"
                style={{ color: "black" }}
                className="filter-input"
                placeholder="Search here"
                onChange={(e) => {
                  const selected = 0;
                  changePage({ selected });
                  setQuery(e.target.value.toLocaleLowerCase());
                  localStorage.setItem("saleReportPageNbr", selected);
                  // (updatedPageNumber = selected)
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="me-2 mb-3 mb-md-0">
              <p className="">Source :</p>
              <div
                className="sl"
                style={{ width: "180px", marginTop: "-15px" }}
              >
                <Select
                  onChange={handleAdminOrOutlet}
                  defaultValue={
                    adminOrOutlet === "outlet=true"
                      ? [{ value: "outlet=true", label: "Outlet" }]
                      : adminOrOutlet === "admin=true"
                      ? [{ value: "admin=true", label: "Admin" }]
                      : adminOrOutlet === "website=true"
                      ? [{ value: "website=true", label: "Website" }]
                      : [{ value: "", label: "See All" }]
                  }
                  value={
                    adminOrOutlet === "outlet=true"
                      ? [{ value: "outlet=true", label: "Outlet" }]
                      : adminOrOutlet === "admin=true"
                      ? [{ value: "admin=true", label: "Admin" }]
                      : adminOrOutlet === "website=true"
                      ? [{ value: "website=true", label: "Website" }]
                      : [{ value: "", label: "See All" }]
                  }
                  options={options3}
                  placeholder="---Select---"
                />
              </div>
            </div>
            {adminOrOutlet === "outlet=true" && (
              <div className="me-2 mb-3 mb-md-0">
                <p className="">Outlets :</p>
                <div
                  className="sl"
                  style={{ width: "180px", marginTop: "-15px" }}
                >
                  <Select
                    onChange={getSelectOutlet}
                    options={outletList}
                    placeholder="---Select---"
                  />
                </div>
              </div>
            )}
            <div className="d-flex flex-wrap">
              <input
                type="date"
                format="DD-MM-YYYY"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div className="d-flex flex-wrap">
              <div
                className="button-div my-auto ms-2 px-3 py-2 text-white rounded"
                style={{ background: "black", cursor: "pointer" }}
                onClick={generateData}
              >
                <span> Generate</span>
              </div>
              {getData?.length > 0 && (
                <div
                  className="button-div my-auto ms-2 px-2 py-2 text-white rounded"
                  style={{ background: "black", cursor: "pointer" }}
                  onClick={() => handlePrints()}
                >
                  <span> Print</span>
                </div>
              )}
              <div
                className="button-div my-auto ms-2 px-3 py-2 text-white rounded"
                style={{ background: "black", cursor: "pointer" }}
              >
                {dailyReport?.length !== 0 ? (
                  <>
                    <a
                      rel="noreferrer"
                      style={{ color: "white" }}
                      href={`${BASE_URL}api/v1/sales/inventory/real-time-daily-report/?export=true&query=${query}&start=${date1}&end=${date2}&${
                        adminOrOutlet ? adminOrOutlet : ""
                      }${
                        adminOrOutlet === "outlet=true" && selectedOutlet
                          ? `&outlet_id=${selectedOutlet}`
                          : ""
                      }`}
                      target="_blank"
                    >
                      Export
                    </a>
                  </>
                ) : (
                  <>
                    <span
                      onClick={() =>
                        swal({
                          title: "Error!",
                          text: "There Is No Data",
                        })
                      }
                    >
                      {" "}
                      Export
                    </span>
                  </>
                )}

                {/* <a
                style={{ color: "white" }}
                href={`${BASE_URL}api/v1/sales/inventory/export/daily-report/outlet/`}
                target="_blank"
              >
                Export
              </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2">
          <div>
            <h6>Total Sales</h6>
            <p
              style={{
                border: "1px solid #d4c1c1",
                padding: "5px 3px",
                borderRadius: "6px",
              }}
            >
              ৳ {dailyReportAll?.combined_total_sales}
            </p>
          </div>
          {/* <div>
            <h6>Cash Recieved</h6>
            <p
              style={{
                border: "1px solid #d4c1c1",
                padding: "5px 3px",
                borderRadius: "6px",
              }}
            >
              ৳ {dailyReportAll?.combined_total_cash_recieved}
            </p>
          </div> */}
          {/* <div>
            <h6>Change Amount</h6>
            <p
              style={{
                border: "1px solid #d4c1c1",
                padding: "5px 3px",
                borderRadius: "6px",
              }}
            >
              ৳ {dailyReportAll?.combined_change_amount}
            </p>
          </div> */}
          {/* <div>
            <h6>Discount Amount</h6>
            <p
              style={{
                border: "1px solid #d4c1c1",
                padding: "5px 3px",
                borderRadius: "6px",
              }}
            >
              ৳ {dailyReportAll?.combined_total_discount}
            </p>
          </div> */}
        </div>
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my pdf-table my-4" ref={cRef}>
            <thead className="d-none thed">
              Daily Report -- Date:{" "}
              {date1 && date2 ? `${date1} - ${date2}` : currentDate}
              <span style={{ marginLeft: "15px" }}>
                Total Sale :৳{finalSale}
              </span>
            </thead>
            <thead
              className="bg-dark text-white head-row"
              style={{ height: "50px", borderTopLeftRadius: "20px !important" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "280px",
                    textAlign: "center",
                  }}
                >
                  #ID
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "350px",
                  }}
                >
                  Product Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Quantity
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Total Amount
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  SKU
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {getData?.length !== 0
                ? getData
                    ?.slice(pagesVisited, pagesVisited + usersPerPage)
                    .map((report, index) => (
                      <>
                        <tr
                          className="table-row"
                        >
                          <td className="text-center">{index + 1}</td>
                          <td>{report.product}</td>
                          <td> {report.quantity}</td>
                          <td>৳ {report.total_sales}</td>
                          <td> {report.sku}</td>
                        </tr>
                        
                      </>
                    ))
                : Showdata && (
                    <tr>
                      <td colspan="9">
                        <div className="not_found d-flex justify-content-center align-items-center py-4">
                          <h4 className="my-4">No Data Found</h4>
                        </div>
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>
        {getData?.length > 0 && (
          <div>
            <div
              className="row pag"
              style={{ marginTop: "30px", paddingBottom: "30px" }}
            >
              <div className="col-6 col-md-5">
                <p
                  className="inter"
                  style={{ color: "#AEAEB2", fontSize: "16px" }}
                >
                  Total Entries:{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {dailyReport?.length}
                  </span>
                </p>
              </div>
              <div className="col-12 col-md-6 d-flex paggination-button">
                <ReactPaginate
                  previousLabel={<PreviousIcon />}
                  nextLabel={<NextIcon />}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"a"}
                  nextLinkClassName={"a"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  initialPage={query?.length > 0 ? 0 : updatedPageNumber}
                  forcePage={
                    query?.length > 0 || getData?.length < usersPerPage
                      ? 0
                      : updatedPageNumber
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailySalesReport;
