import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
const Checkbox = (props) => <input style={{ transform: "scale(1.3)" }} type="checkbox" {...props} />;

const SelectWithOpenEdit  = ({
    handleMultiInputChange,
    inputProduct,
    ProductsList,
    defaultValue,
  }) => {
    const ref = useRef(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const toggleMenuIsOpen = () => {
      setMenuIsOpen((value) => !value);
      const selectEl = ref.current;
      if (!selectEl) return;
      if (menuIsOpen) selectEl.blur();
      else selectEl.focus();
    };
    useEffect(() => {
      if (ProductsList?.length > 0 ) {
        setMenuIsOpen(true);
      }
    }, [ProductsList]);
    return (
      <div className="main-select">
        <div className="my-2">
          <Checkbox
            checked={menuIsOpen}
            onChange={toggleMenuIsOpen}
            id="cypress-single__clearable-checkbox"
          />
          <span className="ms-1">{menuIsOpen ? "Close" : "Open"}</span>
        </div>
  
        <div >
          <Select
            ref={ref}
            menuIsOpen={menuIsOpen}
            isMulti
            placeholder="---Select---"
            isClearable
            onChange={handleMultiInputChange}
            onInputChange={inputProduct}
            options={ProductsList}
            defaultValue={defaultValue}
          />
        </div>
      </div>
    );
  };
  

export default SelectWithOpenEdit