import React, { useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL } from "../../Const/Url";
import { showToast } from "../../../utils/ToastHelper";
import { useHistory } from "react-router-dom";
import eye from "../../../assets/Icon/eye.svg";
import * as path from "../../Routes/RoutePaths";

const AddPopup = () => {
  const history = useHistory();
  const [Categoryswitch, setCategoryswitch] = useState(true);
  const toggleSwitch = () => {
    setCategoryswitch((current) => !current);
  };
  const [fileName, setFileName] = useState("");
  const [URL, setURL] = useState("");
  const [Thumb, setThumb] = useState(null);
  const [thumbId, setThumbId] = useState(null);
  const [fileName1, setFileName1] = useState("");
  const [URL1, setURL1] = useState("");
  const [Thumb1, setThumb1] = useState(null);
  const [thumbId1, setThumbId1] = useState(null);
  const [testi, setTesti] = useState({
    name: "",
    url: "",
  });
  const fileHandle = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId(res.data.data.id);
            setFileName(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          console.log(res.data.data, "img");
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL("");
        setFileName("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb(null);
        setThumbId(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const fileHandle1 = (e) => {
    const url = `${BASE_URL}api/v1/auth/documents/upload/`;
    setThumb1(e.target.files[0]);
    if (e.target.files[0] !== null) {
      const data1 = new FormData();
      data1.append("document", e.target.files[0]);
      data1.append("doc_type", 0);
      axios
        .post(url, data1)
        .then((res) => {
          if (res.data.status) {
            setThumbId1(res.data.data.id);
            setFileName1(e.target.files[0].name);
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            setURL1(reader.result);
            reader.onload = () => {
              if (reader.readyState === 2) {
                setURL1(reader.result);
              }
            };
            showToast("success", "image uploaded");
          }
          
        })
        .catch((error) => {
          showToast("error", error.response.data.errors.document[0]);
        });
    }
  };
  const removeImg1 = () => {
    swal({
      title: "Are you sure?",
      text: "Once Removed, you will not be able to recover this  file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setURL1("");
        setFileName1("");
        swal("Poof! Your  file has been Removed!", {
          icon: "success",
        });
        setThumb1(null);
        setThumbId1(null);
      } else {
        swal("Your  file is safe!");
      }
    });
  };
  const postMember = () => {
    const url = `${BASE_URL}api/v1/utility/admin/popup/`;
    const data = new FormData();
    data.append("name", testi.name);
    data.append("url", testi.url);
    data.append("is_active", Categoryswitch);
    thumbId !== null && data.append("thumb", thumbId);
    thumbId1 !== null && data.append("small_thumb", thumbId1);
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Popup Banner Added.");
          history.push('/web-settings/pop-up-banner');
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>Add Popup</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Name"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="name"
                value={testi["name"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <RequiredLabel text={"Media Url"} />
            <InputGroup className="mb-3" style={{ height: "47px" }}>
              <Form.Control
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                name="url"
                value={testi["url"]}
                onChange={(e) => handleInputs(e, setTesti)}
              />
            </InputGroup>
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail image </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-80px, width-80px) for Thumbnail.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL && (
                <div className="my-2">
                  <img height={90} width={90} src={URL} alt="" /> <br />
                  <button
                    onClick={removeImg}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5 ">
              <h5 style={{ marginTop: "30px" }}>Thumbnail (Mobile) </h5>{" "}
              <p style={{ marginTop: "10px", color: "red" }}>
                NB: Upload image in (height-80px, width-80px) for Thumbnail.{" "}
              </p>
              <span></span>
              <p style={{ color: "#8E8E93" }}>
                ( Select your file & uploaded )
              </p>
              <div
                className="w-100 browse-main d-flex align-items-center"
                style={{
                  height: "55px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "7px",
                  overflowX: "hidden",
                }}
              >
                {fileName1 === "" ? (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    No File Choosen
                  </p>
                ) : (
                  <p className="my-2 ms-2" style={{ color: "#4F4F4F" }}>
                    {" "}
                    {fileName1}
                  </p>
                )}

                <div className="file-up">
                  <input
                    type="file"
                    onChange={(e) => fileHandle1(e)}
                    accept="image/*"
                  />
                  <p>Browse</p>
                </div>
              </div>
              {URL1 && (
                <div className="my-2">
                  <img height={90} width={90} src={URL1} alt="" /> <br />
                  <button
                    onClick={removeImg1}
                    className="remove-btn btn btn-small mt-1  rounded"
                    style={{ border: "1px solid gray !important" }}
                  >
                    Remove
                  </button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-5">
              <h5 style={{ marginTop: "30px" }}>Status</h5>
              <p style={{ color: "#8E8E93" }}>
                ( If the Category Option is available )
              </p>
              <div className="row mr-4">
                <div
                  className=" d-flex justify-content-between col-12 py-3"
                  style={{ backgroundColor: "#F2F2F7", borderRadius: "8px" }}
                >
                  <div className="d-flex w-100">
                    <div
                      className="d-flex py-1 justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#212121",
                        width: "32px",
                        height: "32",
                        borderRadius: "3px",
                      }}
                    >
                      <img src={eye} alt="" />
                    </div>
                    <span className="mx-3">
                      {Categoryswitch ? "ON" : "OFF"}
                    </span>
                  </div>

                  <div class="form-check form-switch ">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      width={40}
                      height={40}
                      name="category_active"
                      checked={Categoryswitch}
                      onClick={toggleSwitch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
              onClick={postMember}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.social_media)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPopup;
