import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import DeleteIcon from "../../CustomCommons/DeleteIcon";
import EditIcon from "../../CustomCommons/EditIcon";
import ViewIcon from "../../CustomCommons/ViewIcon";
import Select from "react-select";
import plus from "./../../../assets/Icon/plus.svg";

import * as path from "../../Routes/RoutePaths";
import { useEffect } from "react";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Loader from "../../CustomCommons/Loader";
import PreviousIcon from "../../CustomCommons/PreviousIcon";
import NextIcon from "../../CustomCommons/NextIcon";
import kaarujLogo from "../../../assets/Icon/KAARUJ 1.png";
import {
  can_add_products,
  can_change_products,
  can_delete_products,
  can_view_products,
  has_permissions,
} from "../../CustomCommons/utils";
import Permission from "../../CustomCommons/Permission";
import { useReactToPrint } from "react-to-print";
import { showToast } from "../../../utils/ToastHelper";
import { logout_func2 } from "../../Const/logoutCommon";
import {
  ArrowDown,
  ArrowUp,
  DotsThreeCircle,
  Eye,
  Share,
} from "phosphor-react";
import CustomModal from "../../CustomCommons/CustomModal";
import ViewProduct from "./ViewProduct";
import ProductVariantModal from "../../CustomCommons/ProductVariantModal";
import { getExportFile } from "../../../utils/HelperFunction";
import HelperExportComponent from "../../../utils/HelperExportComponent";

const HomeDecorList = () => {
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPdId, setModalPdId] = useState(null);
  // variant Modal
  const [modalShow, setModalShow] = useState(false);
  const [modalVariantData, setModalVariantData] = useState([]);

  const [totalData, setTotalData] = useState(0);

  const [items_data, setItems_data] = useState([]);
  const [forcePage, setForcePage] = useState(0);
  const [select, setSelect] = useState(0);
  const saveQuery = localStorage.getItem("homeDecorQuery");
  const [query, setQuery] = useState(saveQuery ? saveQuery : "");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [isLoading, SetisLoading] = useState(true);
  const renkingHomeDecor = localStorage.getItem("renkingHomeDecor");
  const activeHomeDecor = localStorage.getItem("activeHomeDecor");
  const [renking, setRenking] = useState(
    renkingHomeDecor ? renkingHomeDecor : ""
  );
  const [active, setActive] = useState(activeHomeDecor ? activeHomeDecor : "");
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
  const [bulkStatus, setBulkStatus] = useState([]);
  // ofset code
  const [total_item, setTotal_item] = useState(0);
  const [isLoading2, SetisLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const limit = 20;
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (id, currStatus, uniqueId) => {
    // Check if the ID is already in the state
    const isChecked = checkedIds.includes(id);

    if (isChecked) {
      // If checked, remove the ID from the state
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
      setBulkStatus(bulkStatus.filter((bulkData) => bulkData?.slug !== id));
    } else {
      // If unchecked, add the ID to the state
      setCheckedIds([...checkedIds, id]);
      setBulkStatus((prev) => [
        ...prev,
        {
          slug: id,
          is_show_website: currStatus,
          id: uniqueId,
        },
      ]);
    }
  };
  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked) {
      const data = items_data.map((item) => item.slug);
      setCheckedIds(data);
      const statusData = items_data.map((item) => ({
        id: item.id,
        slug: item.slug,
        is_show_website: item.is_show_website,
      }));
      setBulkStatus(statusData);
    } else {
      setCheckedIds([]);
      setBulkStatus([]);
    }
  };
  console.log(bulkStatus, "data from bulk");

  const [homeDecorCall, setHomeDecorCall] = useState(true);
  const item_limit = pageNumber * limit;
  const this_page_items_show =
    item_limit > total_item ? total_item : item_limit;
  const page_show_start = item_limit - limit + 1;
  const pageCount = Math.ceil(total_item / limit);
  const indexOfLastPost = pageCount < pageNumber ? limit : pageNumber * limit;
  const offset = pageCount < pageNumber ? 0 : indexOfLastPost - limit;
  const [urlState, setUrlState] = useState([]);
  const homeDecorUrl = localStorage.getItem("homeDecorCurrentUrl");
  const orderingIndex = homeDecorUrl?.indexOf("ordering=");

  const orderingValue =
    orderingIndex !== -1 ? homeDecorUrl?.split("ordering=")[1] : "";
  const [sortBy, setSortBy] = useState(orderingValue ? orderingValue : "");
  const [sortState, setSortState] = useState(false);

  const apiFunction = (url) => {
    axios
      .get(url)
      .then((res) => {
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        localStorage.setItem("homeDecorCurrentUrl", res.config.url);
        SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  const getOffsetFromUrl = (url, limit) => {
    const urlParams = new URLSearchParams(url);
    const offsetValue = urlParams.get("offset");
    const offset = offsetValue ? parseInt(offsetValue, 10) : 0;

    // Calculate the page number based on the offset and limit
    let pageNumber = offset === 0 ? 1 : Math.floor(offset / limit) + 1;

    // Check if pageNumber is NaN and set it to 1
    if (isNaN(pageNumber)) {
      pageNumber = 1;
    }

    return pageNumber;
  };

  useEffect(() => {
    const homeDecorUrl = localStorage.getItem("homeDecorCurrentUrl");
    const getPageNumber = getOffsetFromUrl(homeDecorUrl, 20);
    setPageNumber(getPageNumber);
    apiFunction(
      homeDecorUrl && homeDecorCall === true
        ? homeDecorUrl
        : `${BASE_URL}${"/api/v1/inventory/inventory/products/"}?limit=${limit}&main_category=0&offset=${offset}&filter=${renking}&query=${query?.toLocaleLowerCase()}${
            sortBy ? `&ordering=${sortBy}` : ""
          }&is_show_website=${active}`
    );
    setHomeDecorCall(false);
  }, [query, renking, sortBy, active]);

  const handlePaginationPrevious = () => {
    apiFunction(prevUrl);
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };
  const handlePaginationNext = () => {
    // SetisLoading(true);
    apiFunction(nextUrl);
    setPageNumber((prev) => prev + 1);
  };
  const handleChangeCurrentPage = (val) => {
    setPageNumber(val);
  };

  useEffect(() => {
    if (pageCount !== 0) {
      if (pageCount < pageNumber) {
        setPageNumber(pageCount);
      }
    }
  }, [pageCount]);
  console.log(typeof query, "query");
  // useEffect(() => {
  //   if ( pageNumber > 1 || renking !== "") {
  //     apiFunction(
  //       `${BASE_URL}${"/api/v1/inventory/inventory/products/"}?limit=${limit}&main_category=0&offset=${offset}&filter=${renking}&query=${query}`
  //     );

  //   }
  // }, [renking]);

  // ofset code

  const options2 = [
    { value: "", label: "See All" },
    { value: "today", label: "Daily" },
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
    { value: "year", label: "Yearly" },
  ];
  const options3 = [
    { value: "", label: "All" },
    { value: "true", label: "Active" },
    { value: "false", label: "Disable" },
  ];

  // const [data,loader] = UseData(`${BASE_URL}api/v1/inventory/inventory/products/`)
  const cRef = useRef();
  const date1Ref = useRef();
  const date2Ref = useRef();

  const [productList, setProductList] = useState([]);
  const keys = [
    "name",
    "category",
    "updated_at",
    "total",
    "total_sales",
    "stock",
    "sku",
  ];
  // const getData = productList
  const getData = productList?.filter((p) =>
    keys.some((key) => p[key]?.toString()?.toLowerCase()?.includes(query))
  );
  const history = useHistory();
  const usersPerPage = 15;
  const updatedPageNumber = parseInt(
    localStorage.getItem("productPageNbr")
      ? localStorage.getItem("productPageNbr")
      : 0
  );
  // const [pageNumber, setPageNumber] = useState(updatedPageNumber);

  const pagesVisited = pageNumber * usersPerPage;

  const getProduct = () => {
    // let url = `${BASE_URL}api/v1/inventory/inventory/products/?main_category=0&is_show_website=${active}`;
    let url = `${BASE_URL}api/v1/inventory/inventory/products/?start=${date1}&end=${date2}&main_category=0${
      sortBy ? `&ordering=${sortBy}` : ""
    }&is_show_website=${active}&filter=${renking}&query=${query?.toLocaleLowerCase()}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data?.data;

        setProductList(res?.data?.data?.results);
      })
      .catch((err) => {
        const message = JSON.parse(err?.request?.response)?.message;
        // console.log(err.request.response);
        if (
          message === "Invalid token." ||
          JSON.parse(err?.request?.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        console.log(err);
      });
  };
  const getProduct2 = () => {
    let url = `${BASE_URL}api/v1/inventory/inventory/products/?start=${date1}&end=${date2}&main_category=0${
      sortBy ? `&ordering=${sortBy}` : ""
    }&is_show_website=${active}&filter=${renking}&query=${query?.toLocaleLowerCase()}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data.results);
        const result = res.data.data?.data;
        setProductList(
          res.data.data?.results?.sort((a, b) => b.total_sales - a.total_sales)
        );
        setTotal_item(res.data?.data?.count);
        setTotalData(res.data?.data?.count);
        setItems_data(res.data?.data?.results);
        setNextUrl(res.data?.data?.next);
        setPrevUrl(res.data?.data?.previous);
        // SetisLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        console.log(err.request.response);
      });
  };
  useEffect(() => {
    getProduct();
    // date1ref.date1Ref?.value = '' ;
    // date2ref.date2Ref?.value = '' ;
    setDate1("");
    setDate2("");
  }, [renking]);

  const generateData = () => {
    if (!date1 && !date2) {
      swal("Please select two dates");
    } else if (
      (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") ||
      (date1 === "" && date2 === "")
    ) {
      swal("Please select two dates");
    } else if (date1 === "undefined/undefined/" || date1 === "") {
      swal("Please select  start Date");
    } else if (date2 === "undefined/undefined/" || date2 === "") {
      swal("Please select  end Date");
    } else {
      getProduct2();
    }
  };
  useEffect(() => {
    // getProduct();
    if (date1 === "undefined/undefined/" && date2 === "undefined/undefined/") {
      swal("Please select  dates");
    }
  }, []);
  // console.log(productList,'--------------product---------');
  var options = {
    // weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: 'numeric', minute: 'numeric', hour12: true
  };
  const deleteData = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const url = `${BASE_URL}api/v1/inventory/inventory/products/${id}`;
        axios
          .delete(url)
          .then(() => {
            // showToast("Delele", "Data Deleted");
            getProduct();
            apiFunction(
              `${BASE_URL}${"/api/v1/inventory/inventory/products/"}?limit=${limit}&offset=${offset}&main_category=0`
            );
          })
          .catch((err) => {
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          });
        swal(" Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  const deleteDataMulti = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/inventory/inventory/products/${id}/`;
          return axios.delete(url);
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been deleted!", {
              icon: "success",
            });
            setCheckedIds([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getProduct();
            apiFunction(
              `${BASE_URL}${"/api/v1/inventory/inventory/products/"}?limit=${limit}&offset=${offset}&main_category=0`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };
  const statusBulkChanges = (ids) => {
    swal({
      title: "Are you sure?",
      text: "Once Click, You will not be able to go back.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const deletePromises = ids.map((id) => {
          const url = `${BASE_URL}api/v1/inventory/inventory/products/${id.slug}/`;
          return axios.patch(url, {
            is_show_website: id.is_show_website === true ? false : true,
          });
        });

        Promise.all(deletePromises)
          .then(() => {
            // All deletions were successful
            swal(" Your data has been updated!", {
              icon: "success",
            });
            setCheckedIds([]);
            setBulkStatus([]);
          })
          .catch((err) => {
            // At least one deletion failed
            const message = JSON.parse(err.request.response).message;
            console.log(message);
          })
          .finally(() => {
            // Call getProduct and apiFunction regardless of success or failure
            getProduct();
            apiFunction(
              `${BASE_URL}${"/api/v1/inventory/inventory/products/"}?limit=${limit}&offset=${offset}&main_category=0`
            );
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  useEffect(() => {
    setForcePage(0);
    localStorage.setItem("productPageNbr", forcePage);
    console.log("forcePage", forcePage);
    console.log("selected", select);
    console.log("productPageNbr", updatedPageNumber);
  }, [query]);

  // const pageCount = Math.ceil(getData?.length / usersPerPage);

  const HandlePrint = useReactToPrint({
    content: () => cRef.current,
    documentTitle: "Product List",
    bodyClass: "dis",
    onBeforePrint: () => SetisLoading(true),
    onAfterPrint: () => SetisLoading(false),
  });
  const getPrint = () => {
    HandlePrint();
  };
  if (!has_permissions(can_view_products)) {
    return <Permission />;
  }

  if (isLoading) {
    return <Loader />;
  }
  const handleRank = (newVal, action) => {
    localStorage.setItem(
      "renkingHomeDecor",
      newVal.value === "all" ? "" : newVal.value
    );
    setRenking(newVal.value);
  };
  const handleRank2 = (newVal, action) => {
    localStorage.setItem(
      "activeHomeDecor",
      newVal.value === "all" ? "" : newVal.value
    );
    setActive(newVal.value);
  };
  const openModal = (id) => {
    setModalPdId(id);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setModalPdId(null);
    setIsModalOpen(false);
  };
  const openModalVariant = (data) => {
    setModalVariantData(data);
    setModalShow(true);
  };

  // const getExportFile = (url) => {
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       const result = res.data.data?.data;
  //       console.log(result)
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err?.request?.response)?.message;
  //       // console.log(err.request.response);
  //       if (
  //         message === "Invalid token." ||
  //         JSON.parse(err?.request?.response).code === 401
  //       ) {
  //         // history.push('/login')
  //         logout_func2(setOnlogoutHide, history);
  //       }
  //       console.log(err);
  //     });
  // };
  const handleTab = (categorey, url) => {
    if (categorey) {
      // Create a hidden anchor element
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.rel = "noopener noreferrer";

      // Append it to the body
      document.body.appendChild(link);

      // Programmatically click the link
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } else {
      console.error("Category or slug is not defined");
    }
  };

  return (
    <div>
      <div className="categorey-parent">
        <div className="cotagorey-head mb-4">
          <div className="d-flex align-align-items-baseline">
            <div>
              <h3>Home Decor List </h3>
              <input
                style={{ color: "black" }}
                type="text"
                className="filter-input"
                placeholder="Search here"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  localStorage.setItem("homeDecorQuery", e.target.value);
                }}
              />
            </div>
          </div>
          <div className="filter_div">
            <div
              className="sl mt-1"
              style={{ width: "150px", marginRight: "5px" }}
            >
              <Select
                onChange={handleRank2}
                defaultValue={options3?.filter(
                  (data) => data?.value === activeHomeDecor
                )}
                options={options3}
                placeholder="Active/Disable"
              />
            </div>
            <div
              className="sl mt-1"
              style={{ width: "150px", marginRight: "5px" }}
            >
              <Select
                onChange={handleRank}
                defaultValue={options2?.filter(
                  (data) => data?.value === renkingHomeDecor
                )}
                options={options2}
                placeholder="---Select---"
              />
            </div>

            <div className="my-2 my-lg-0">
              <input
                type="date"
                name="date1ref"
                format="DD-MM-YYYY"
                className="date-input"
                ref={date1Ref}
                onChange={(e) => {
                  const date = e.target.value;
                  setDate1(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
              <span className="mx-2 my-auto" placeholder="09-09-2022">
                {" "}
                To
              </span>
              <input
                type="date"
                className="date-input"
                name="date2ref"
                ref={date2Ref}
                onChange={(e) => {
                  const date = e.target.value;

                  setDate2(
                    `${date.split("-")[1]}/${date.split("-")[2]}/${
                      date.split("-")[0]
                    }`
                  );
                }}
              />
            </div>
            <div
              className="button-div my-auto mx-2 "
              style={{ background: "black", cursor: "pointer" }}
              onClick={generateData}
            >
              <span> Generate</span>
            </div>
            {totalData > 0 && (
              <div
                className="button-div my-auto me-2  text-white "
                style={{ background: "black", cursor: "pointer" }}
                onClick={() => getPrint()}
              >
                <span> Print</span>
              </div>
            )}

            {/* {has_permissions(can_add_products) && (
              <div
                className="button-div"
                onClick={() => history.push(`/inventory/add-product/${0}`)}
              >
                <img src={plus} alt="" />
                <span>Add Product</span>
              </div>
            )} */}

            {/* ${BASE_URL}${"/api/v1/inventory/inventory/products/"}?filter=${renking}&query=${query}&start=${date1}&end=${date2} */}
            {/* ${BASE_URL}api/v1/inventory/inventory/products/filter/? */}
            <div
              className="button-div ms-2 my-auto me-2  text-white "
              style={{ background: "black", cursor: "pointer" }}
            >
              {/* {items_data?.length !== 0 ? (
                <>
                  <span
                    onClick={() =>
                      getExportFile(
                        `${BASE_URL}api/v1/inventory/inventory/products/?export=true&start=${date1}&end=${date2}&main_category=0&is_show_website=${active}&filter=${renking}&query=${query?.toLocaleLowerCase()}`,
                        "home_decor_export_file.xlsx"
                      )
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() =>
                      swal({
                        title: "Error!",
                        text: "There Is No Data",
                      })
                    }
                  >
                    {" "}
                    Export
                  </span>
                </>
              )} */}
             <HelperExportComponent
                url={`${BASE_URL}api/v1/inventory/inventory/products/?export=true&start=${date1}&end=${date2}&main_category=0&is_show_website=${active}&filter=${renking}&query=${query?.toLocaleLowerCase()}`}
                fileName={"homedecor_export_file.xlsx"}
                items_data={items_data}
              />
            </div>
          </div>
        </div>
        {checkedIds?.length > 0 && (
          <>
            <div className="my-2 d-flex ">
              <button
                className="btn text-light me-2"
                style={{
                  background: "black",
                  cursor: "pointer",
                  maxWidth: "160px",
                  color: "white",
                }}
                onClick={() => deleteDataMulti(checkedIds)}
              >
                Delete
              </button>
              <button
                className="btn text-light"
                style={{
                  background: "black",
                  cursor: "pointer",
                  maxWidth: "160px",
                  color: "white",
                }}
                onClick={() => statusBulkChanges(bulkStatus)}
              >
                Change Status
              </button>
            </div>
          </>
        )}

        {/* categorey list table */}
        <div style={{ display: "block", overflowX: "auto" }}>
          <table className="table_my pdf-table my-3">
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                    // display:'flex',
                    // justifyContent:'center',
                    margin: "center",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <input
                      style={{
                        margin: "0 auto",
                        opacity: "1",
                        width: "17px",
                        height: "17px",
                      }}
                      type="checkbox"
                      checked={checkedIds?.length > 0}
                      onChange={(event) => handleCheckboxChangeAll(event)}
                    />
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  <p className="ms-3 my-auto">Thumbnail</p>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  <div
                    style={{ width: "100px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Name</p>
                    {sortBy === "-name" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("name");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowDown color="white" size={24} weight="bold" />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("-name");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowUp color="white" size={24} weight="bold" />
                      </div>
                    )}
                  </div>
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  <div
                    style={{ width: "80px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Stock</p>
                    {sortBy === "-stock" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("stock");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowDown color="white" size={24} weight="bold" />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("-stock");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowUp color="white" size={24} weight="bold" />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "110px",
                  }}
                >
                  <div
                    style={{ width: "100px" }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <p className="my-auto">Total Sold</p>
                    {sortBy === "-total_sold" ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("total_sold");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowDown color="white" size={24} weight="bold" />
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          localStorage.setItem("catDeshOrdering", sortBy);
                          setSortBy("-total_sold");
                          setSortState((prev) => !prev);
                        }}
                      >
                        <ArrowUp color="white" size={24} weight="bold" />
                      </div>
                    )}
                  </div>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  SKU
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Main Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Last Modified
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Variants
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "90px",
                  }}
                >
                  Website
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "90px",
                  }}
                >
                  New Tab
                </th>

                <th
                  className="action"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "135px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {items_data.length > 0 && items_data ? (
                items_data.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    {/* <td className="text-center">{category?.slug}</td> */}
                    <td>
                      <div className="d-flex justify-content-center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          style={{ width: "17px", height: "17px" }}
                          checked={checkedIds.includes(category.slug)}
                          onChange={() =>
                            handleCheckboxChange(
                              category?.slug,
                              category.is_show_website,
                              category.id
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <img
                        width={50}
                        style={{ margin: "6px 0px !important" }}
                        height={50}
                        src={`${
                          category?.thumb_resized
                            ? category?.thumb_resized
                            : category?.thumb_url
                        }`}
                        alt=""
                        className="ms-3"
                      />
                    </td>
                    <td>{category.name}</td>
                    <td>{category.stock}</td>
                    <td>{category.total_sold}</td>
                    <td>{category?.sku ? category?.sku : "N/A"}</td>
                    <td>
                      {category?.main_category === 0
                        ? "Home Decor"
                        : "In Style"}
                    </td>
                    <td>
                      {category.category_details?.length > 0
                        ? category?.category_details[0]?.name
                        : "N/A"}
                    </td>

                    <td>
                      {new Date(category.updated_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>
                      <a
                        href="#"
                        onClick={() =>
                          openModalVariant(category?.variant_sold_details)
                        }
                      >
                        View
                      </a>
                    </td>

                    <td>
                      <button
                        className={`status ${
                          category.is_show_website === true ? "c1" : "c2"
                        }`}
                        style={{ color: `${category.btnColour}` }}
                      >
                        {category.is_show_website === true
                          ? "Active"
                          : "Disable"}
                      </button>
                    </td>
                    <td className="action">
                      <div className="d-flex gap-3">
                        <div
                         
                          // onClick={() => {
                          //   handleTab(
                          //     `${category?.slug}`,
                          //     `/inventory/product-view/${category?.slug}`
                          //   );
                          // }}
                        >
                          <a className="edit-del green"
                            href={`/inventory/product-view/${category?.slug}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <Eye size={24} weight="bold" />
                          </a>
                        </div>
                        {has_permissions(can_change_products) ? (
                          <div
                           
                            // onClick={() => {
                            //   window.open(
                            //     `/inventory/edit-product/${category?.slug}`,
                            //     "_blank",
                            //     "noopener,noreferrer"
                            //   );
                            // }}
                          >
                            <a className="edit-del green"
                              href={`/inventory/edit-product/${category?.slug}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Share size={24} />
                            </a>
                          </div>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal("Sorry, You are not able to edit product.")
                            }
                          >
                            <Share size={24} />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="action">
                      <div className="d-flex gap-3">
                        <div
                          className="edit-del blue"
                          onClick={() => openModal(category?.slug)}
                        >
                          <ViewIcon />
                        </div>
                        {has_permissions(can_change_products) ? (
                          <div
                            className="edit-del green"
                            onClick={() => {
                              history.push(
                                `/inventory/edit-product/${category?.slug}`
                              );
                            }}
                          >
                            <EditIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del green"
                            onClick={() =>
                              swal("Sorry, You are not able to edit product.")
                            }
                          >
                            <EditIcon />
                          </div>
                        )}

                        {has_permissions(can_delete_products) ? (
                          <div
                            className="edit-del red"
                            onClick={() => deleteData(category?.slug)}
                          >
                            <DeleteIcon />
                          </div>
                        ) : (
                          <div
                            className="edit-del red"
                            onClick={() =>
                              swal("Sorry , You are not able to delete product")
                            }
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* entites and paggination */}
        <div style={{ display: "none" }} className="pt-4">
          <table
            style={{ marginTop: "50px", marginRight: "600px" }}
            className="table_my pdf-table my-3 pt-4 print_table"
            ref={cRef}
          >
            <thead
              className="d-none  thed w-100 text-center me-4"
              style={{ marginTop: "40px" }}
            >
              <div className="mb-4 pb-4 d-flex justify-content-center align-items-center">
                <img className="pb-3" src={kaarujLogo} width={100} alt="" />
                <p className="mt-2 ms-4">
                  Product List Print Date : {currentDate}{" "}
                  <span className="ms-3">
                    Total Item : {productList?.length}
                  </span>
                  <span className="ms-2">
                    Filter By :{" "}
                    {renking === "all"
                      ? "All"
                      : renking === "today"
                      ? "Today"
                      : renking === "week"
                      ? "This Week"
                      : renking === "month"
                      ? "This Month"
                      : renking === "year"
                      ? "This Year"
                      : ""}
                  </span>
                </p>
              </div>
            </thead>
            <thead
              className="bg-dark text-white head-row mt-3 main-head-print"
              style={{ height: "50px" }}
            >
              <tr className="">
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "120px",
                  }}
                >
                  <p className="ms-3 my-auto">Thumbnail</p>
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "200px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "150px",
                  }}
                >
                  SKU
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "170px",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  Last Modified
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    width: "100px",
                  }}
                >
                  Stock
                </th>

                <th
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    borderTopRightRadius: "4px ",
                    width: "141px",
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="tb">
              {totalData > 0 ? (
                productList.map((category, index) => (
                  <tr className="table-row " key={category?.slug}>
                    {/* <td className="text-center">{category?.slug}</td> */}
                    <td>
                      <img
                        width={50}
                        style={{ margin: "6px 0px !important" }}
                        height={50}
                        src={`${
                          category?.thumb_resized
                            ? category?.thumb_resized
                            : category?.thumb_url
                        }`}
                        alt=""
                        className="ms-3"
                      />
                    </td>
                    <td>{category.name}</td>
                    <td>{category?.sku ? category?.sku : "N/A"}</td>
                    <td>{category.category ? category?.category : "N/A"}</td>

                    <td>
                      {new Date(category.updated_at).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>

                    <td>{category.stock}</td>

                    <td>
                      <button
                        className={`status ${
                          category.is_active === true ? "c1" : "c2"
                        }`}
                        style={{ color: `${category.btnColour}` }}
                      >
                        {category.is_active === true ? "Active" : "Disable"}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="9">
                    <div className="not_found d-flex justify-content-center align-items-center py-4">
                      <h4 className="my-4">No Data Found</h4>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {totalData > 0 && (
          <div className="row pag" style={{ marginTop: "30px" }}>
            <div className="col-6 col-md-5">
              <p
                className="inter"
                style={{ color: "#AEAEB2", fontSize: "16px" }}
              >
                Total Entries:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {totalData}
                </span>
                <span className="ms-2"></span>
                Total Page:{" "}
                <span style={{ color: "black", fontSize: "14px" }}>
                  {Math.ceil(totalData / 20)}
                </span>
              </p>
            </div>

            <div className="col-12 col-md-6 d-flex paggination-button">
              <>
                <div className="row lead_pagination bg-transparent">
                  <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-md-end  col-md-6 col-sm-6  col-xs-12 pagination_container">
                    <nav aria-label="...">
                      <ul className="pagination pagination-sm">
                        {!isLoading ? (
                          <li className="page-item ">
                            {prevUrl !== null ? (
                              <Link
                                onClick={handlePaginationPrevious}
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts  pagination_btn font_size_for_small py-1 mt-1 me-3 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on first page")
                                }
                                className={` ${
                                  prevUrl === null ? "disabled" : ""
                                } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                                to="#"
                                tabindex="-1"
                              >
                                <PreviousIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item ">
                            <Link
                              className={` ${
                                prevUrl === null ? "disabled" : ""
                              } page-link pagination_texts pagination_btn font_size_for_small me-3 py-1 mt-1 a`}
                              to="#"
                              tabindex="-1"
                            >
                              <PreviousIcon />
                            </Link>
                          </li>
                        )}

                        {[...Array(pageCount === null ? 1 : pageCount)].map(
                          (elem, index) => (
                            <li
                              className="page-item"
                              style={{
                                display: `${
                                  index + 1 !== pageNumber ? "none" : "block"
                                }`,
                              }}
                            >
                              {/*  style={{
                      display: `${index + 1 !== pageNumber ? "none" : "block"}`,
                    }} */}
                              {/* setPageNumber(prev=> prev - 1) */}

                              <Link
                                onClick={() =>
                                  handleChangeCurrentPage(index + 1)
                                }
                                className={`${
                                  index + 1 == pageNumber
                                    ? "paginationActive"
                                    : ""
                                } page-link lead_page_link lead_page_link1 font_size_for_small pagination_texts px-2 text-dark`}
                                to="#"
                              >
                                <span className="px-1 py-1">{pageNumber}</span>
                              </Link>
                            </li>
                          )
                        )}
                        {!isLoading ? (
                          <li className="page-item">
                            {nextUrl !== null ? (
                              <Link
                                onClick={handlePaginationNext}
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />{" "}
                              </Link>
                            ) : (
                              <Link
                                onClick={() =>
                                  showToast("success", "You are on last page")
                                }
                                className={`${
                                  nextUrl === null ? "disabled" : ""
                                } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                                to="#"
                              >
                                <NextIcon />
                              </Link>
                            )}
                          </li>
                        ) : (
                          <li className="page-item">
                            <Link
                              className={`${
                                nextUrl === null ? "disabled" : ""
                              } page-link pagination_btn font_size_for_small pagination_texts py-1 mt-1 ms-3 a`}
                              to="#"
                            >
                              <NextIcon />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            </div>
          </div>
        )}
      </div>
      <CustomModal isOpen={isModalOpen} onClose={closeModal}>
        {modalPdId ? <ViewProduct pdId={modalPdId} /> : <Loader />}
      </CustomModal>
      <ProductVariantModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        variants={modalVariantData}
      />
    </div>
  );
};

export default HomeDecorList;
